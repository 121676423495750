import React from 'react'
import { Divider } from '../../../components'
import { api } from '../../../store'
import {
  PhotoImage,
  StyledCarousel,
  StyledDiv,
  StyledItemsInputPhoto,
  StyledPhotoText,
  StyledRedText,
  StyledText,
  StyledUrlImage,
  Url,
} from '../styled'
import { ShowPhotosProps } from '../types'
import { useTranslation } from 'react-i18next'

export const ShowPhotos: React.FC<ShowPhotosProps> = ({
  items,
  setItems,
  setFixedItems,
  setDeleted,
  type,
}) => {
  const { t } = useTranslation('clinics')

  const onPressDeleteFile = (file: any) => () => {
    if (!!file?.url) {
      setFixedItems((p: any) => [...p.filter((el: any) => el.id !== file.id)])

      setDeleted(prev => [...prev, file])
    }

    setItems((p: any) => [...p.filter((el: any) => el !== file)])
  }

  const fileHandler = (e: any) => {
    setItems((p: any) => [...p, ...e.target?.files])
  }

  return (
    <>
      <StyledText>{type}</StyledText>

      <Divider height={10} />

      {!!items?.length ? (
        <StyledCarousel>
          {items?.map((item: any, index: number) => {
            return (
              <React.Fragment
                key={(!!item.url ? item.url : item?.lastModified) + index}
              >
                <StyledDiv
                  onDragOver={e => e.preventDefault()}
                  onDrop={e => {
                    e.preventDefault()

                    if (e.dataTransfer.items) {
                      const files = []

                      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
                        if (e.dataTransfer.items[i].kind === 'file') {
                          const file = e.dataTransfer.items[i].getAsFile()

                          if (file?.type.startsWith('image/')) {
                            files.push(file)
                          } else {
                            alert(t('pleaseUploadImagesOnly'))
                          }
                        }
                      }

                      fileHandler({ target: { files } })
                    }
                  }}
                >
                  {type === t('gallery') ? (
                    <StyledUrlImage
                      href={
                        item?.url?.includes('https')
                          ? item?.url
                          : api.withImageAPI + '/clinic/' + item.url
                      }
                      target="_blank"
                    >
                      <PhotoImage
                        src={
                          !!item?.url
                            ? item?.url?.slice(0, 5) === 'https'
                              ? item?.url
                              : api.withImageAPI + '/clinic/' + item?.url
                            : URL.createObjectURL(item)
                        }
                      />
                    </StyledUrlImage>
                  ) : (
                    <Url
                      href={
                        item?.url?.slice(0, 5) === 'https'
                          ? item.url
                          : api.withImageAPI + '/clinic/' + item.url
                      }
                      target="_blank"
                    >
                      {!!item?.name
                        ? item?.name
                        : item?.url
                            ?.split('/')
                            [item?.url.split('/').length - 1]?.split('?')[0]}
                    </Url>
                  )}

                  <Divider height={7} />

                  <StyledPhotoText onClick={onPressDeleteFile(item)}>
                    {t('delete')}
                  </StyledPhotoText>
                </StyledDiv>
              </React.Fragment>
            )
          })}
        </StyledCarousel>
      ) : (
        <StyledRedText>{t('empty')}</StyledRedText>
      )}

      <Divider height={10} />

      {type === t('gallery') ? (
        <StyledItemsInputPhoto
          id="image"
          name="image"
          type="file"
          accept="image/*"
          onChange={fileHandler}
          multiple
        />
      ) : (
        <StyledItemsInputPhoto
          id="file"
          name="file"
          type="file"
          onChange={fileHandler}
          multiple
        />
      )}
    </>
  )
}
