import { Accordion } from 'react-bootstrap'
import { Assets } from '../../../assets'
import { api } from '../../../store'
import { LanguageKeys } from '../../ApplicationContent/services/types'
import { useGetAdditionalOptions } from '../../ClinicInfo/services/useGetAdditionalOptions'
import { AdditionalOptionsWrap } from '../../ClinicInfo/styled'
import { CheckboxImage, StyledAccordion } from '../styled'
import { useTranslation } from 'react-i18next'
import { TAdditionalOptionProps } from './types'

export const AdditionalOptions = ({
  form,
  setForm,
  lang,
}: TAdditionalOptionProps) => {
  const { additionalOptionsList } = useGetAdditionalOptions()
  const { t } = useTranslation('clinics')
  const additionalOptions = additionalOptionsList?.models.map(item => ({
    title: item.title[lang as LanguageKeys],
    id: item._id,
    image: item.image,
  }))

  const changeService = (data: {
    title: string
    id: string
    image: string
  }) => {
    const elementInForm = form.additionalOptions.find(
      item => item.id === data.id,
    )
    if (elementInForm) {
      // delete from form
      setForm(prev => ({
        ...prev,
        additionalOptions: prev.additionalOptions.filter(
          item => item.id !== elementInForm.id,
        ),
      }))
    } else {
      // add to form
      setForm(prev => ({
        ...prev,
        additionalOptions: [...prev.additionalOptions, data],
      }))
    }
  }

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('additionalOptions')}</Accordion.Header>
          <Accordion.Body>
            {additionalOptions?.map((el, index) => (
              <AdditionalOptionsWrap key={el?.id + index}>
                <CheckboxImage
                  onClick={() => changeService(el)}
                  src={
                    !!form.additionalOptions?.filter(
                      service => service.title === el?.title,
                    ).length
                      ? Assets.CHECKBOX_ACTIVE_ICON
                      : Assets.CHECKBOX_ICON
                  }
                />
                <img
                  alt="icon"
                  src={api.withImageAPI + '/additional-options/' + el.image}
                />
                <p>{el?.title}</p>
              </AdditionalOptionsWrap>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
