import {
  takeLatest,
  select,
  call,
  put,
  take,
} from '@redux-saga/core/effects'
import {
  addDocumentToPlaceAction,
  addPhotoToPlaceAction,
  createPlaceAction,
  deleteDocumentFromPlaceAction,
  deleteImageFromPlaceAction,
  getPlaceByIdAction,
  getPlacesAction,
  putPlaceLogoAction,
  searchPlaceAction,
  updatePlaceAction,
  deletePlaceAction,
} from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { PlacesAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getPlacesWorker({
  payload,
}: ReturnType<typeof getPlacesAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof PlacesAPI.getPlaces>> =
      yield call([PlacesAPI, PlacesAPI.getPlaces], {
        authorization: token,
        ...payload,
      })

    yield put(
      getPlacesAction.success({
        places: response.data.places,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getPlacesWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения клиник')),
    )

    yield put(getPlacesAction.failure(e as AxiosError))
  }
}

function* addImageToPlaceWorker({
  payload,
}: ReturnType<typeof addPhotoToPlaceAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.addImageToPlace>
    > = yield call([PlacesAPI, PlacesAPI.addImageToPlace], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(getPlaceByIdAction.request({ id: payload.id }))

    yield take(getPlaceByIdAction.success)

    yield put(addPhotoToPlaceAction.success(response.data))
  } catch (e) {
    console.log('Error: addPhotoToPlaceWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка добавления фото')),
    )

    yield put(addPhotoToPlaceAction.failure(e as AxiosError))
  }
}

function* addDocumentToPlaceWorker({
  payload,
}: ReturnType<typeof addDocumentToPlaceAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.addDocumentToPlace>
    > = yield call([PlacesAPI, PlacesAPI.addDocumentToPlace], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(getPlaceByIdAction.request({ id: payload.id }))

    yield take(getPlaceByIdAction.success)

    yield put(addDocumentToPlaceAction.success(response.data))
  } catch (e) {
    console.log('Error: addDocumentToPlaceWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка добавления документа'),
      ),
    )

    yield put(addDocumentToPlaceAction.failure(e as AxiosError))
  }
}

function* deleteDocumentFromPlaceWorker({
  payload,
}: ReturnType<typeof deleteDocumentFromPlaceAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.deleteDocumentFromPlace>
    > = yield call([PlacesAPI, PlacesAPI.deleteDocumentFromPlace], {
      authorization: token,
      image: payload.image,
      id: payload.id,
    })

    yield put(deleteDocumentFromPlaceAction.success(response.data))

    yield put(getPlaceByIdAction.request({ id: payload.id }))

    yield take(getPlaceByIdAction.success)
  } catch (e) {
    console.log('Error: deleteDocumentFromPlaceWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления документа')),
    )

    yield put(deleteDocumentFromPlaceAction.failure(e as AxiosError))
  }
}

function* deleteImageFromPlaceWorker({
  payload,
}: ReturnType<typeof deleteImageFromPlaceAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.deleteImageFromPlace>
    > = yield call([PlacesAPI, PlacesAPI.deleteImageFromPlace], {
      authorization: token,
      image: payload.image,
      id: payload.id,
    })

    yield put(deleteImageFromPlaceAction.success(response.data))

    yield put(getPlaceByIdAction.request({ id: payload.id }))

    yield take(getPlaceByIdAction.success)
  } catch (e) {
    console.log('Error: deleteImageFromPlaceWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления картинки')),
    )

    yield put(deleteImageFromPlaceAction.failure(e as AxiosError))
  }
}

function* createPlaceWorker({
  payload,
}: ReturnType<typeof createPlaceAction['request']>) {

  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.createPlace>
    > = yield call([PlacesAPI, PlacesAPI.createPlace], {
      authorization: token,
      data: payload.data,
    })

    if (response) {
      yield put(createPlaceAction.success(response.data.data))

      yield put(
        putPlaceLogoAction.request({
          data: payload.image,
          id: response.data.data._id,
        }),
      )

      yield take(putPlaceLogoAction.success)

      if (!!payload.dataImage) {
        yield put(
          addPhotoToPlaceAction.request({
            data: payload.dataImage as FormData,
            id: response.data.data._id,
          }),
        )

        yield take(addPhotoToPlaceAction.success)
      }
    }

    yield put(
      showToastAction.request(generateSuccessToast('Клиника успешно создана')),
    )
  } catch (e) {
    console.log('Error: createPlaceWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания клиники')),
    )

    yield put(createPlaceAction.failure(e as AxiosError))
  } 
}

function* updatePlaceWorker({
  payload,
}: ReturnType<typeof updatePlaceAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.updatePlace>
    > = yield call([PlacesAPI, PlacesAPI.updatePlace], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    if (response) {
      yield put(updatePlaceAction.success(response.data.data))

      if (!!payload.image) {
        yield put(
          putPlaceLogoAction.request({ data: payload.image, id: payload.id }),
        )

        yield take(putPlaceLogoAction.success)
      }

      if (!!payload.dataImage) {
        yield put(
          addPhotoToPlaceAction.request({
            data: payload.dataImage as FormData,
            id: payload.id,
          }),
        )

        yield take(addPhotoToPlaceAction.success)
      }

      const res: PromiseReturnType<ReturnType<typeof PlacesAPI.getPlaceById>> =
        yield call([PlacesAPI as any, PlacesAPI.getPlaceById as any], {
          authorization: token,
          id: payload.id,
        })

      yield put(getPlaceByIdAction.success(res.data))
    }

    yield put(
      showToastAction.request(
        generateSuccessToast('Клиника успешно обновлёна'),
      ),
    )
  } catch (e) {
    console.log('Error: updatePlaceWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления клиники')),
    )

    yield put(updatePlaceAction.failure(e as AxiosError))
  }
}

function* putPlaceLogoWorker({
  payload,
}: ReturnType<typeof putPlaceLogoAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.putPlaceLogo>
    > = yield call([PlacesAPI, PlacesAPI.putPlaceLogo], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(putPlaceLogoAction.success(response.data.data))
  } catch (e) {
    console.log('Error: putPlaceLogoWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка добавления фотографии'),
      ),
    )

    yield put(putPlaceLogoAction.failure(e as AxiosError))
  }
}

function* deletePlaceWorker({ payload }: ReturnType<typeof deletePlaceAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.deletePlace>
    > = yield call([PlacesAPI, PlacesAPI.deletePlace], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      showToastAction.request(generateSuccessToast('Клиника удалена успешно')),
    )
  } catch (e) {
    Log.ruddy('Error: deletePlaceWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления клиники')),
    )
  }
}

function* searchPlaceWorker({
  payload,
}: ReturnType<typeof searchPlaceAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof PlacesAPI.searchPlace>
    > = yield call([PlacesAPI, PlacesAPI.searchPlace], {
      authorization: token,
      page: payload.page,
      limit: payload.limit,
      title: payload.title,
    })

    yield put(
      searchPlaceAction.success({
        data: response.data.clinics,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: searchPlaceWorker', e)

    yield put(searchPlaceAction.failure(e as AxiosError))
  }
}

function* getPlaceByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<ReturnType<typeof PlacesAPI.getPlaceById>> =
      yield call([PlacesAPI, PlacesAPI.getPlaceById], {
        authorization: token,
        id: payload.id,
      })

    yield put(getPlaceByIdAction.success(res.data))
  } catch (e) {
    Log.ruddy('Error: getPlaceByIdWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения клиники')),
    )

    yield put(getPlaceByIdAction.failure(e as AxiosError))
  }
}

export function* placesWatcher() {
  yield takeLatest(getPlacesAction.request, getPlacesWorker)
  yield takeLatest(getPlaceByIdAction.request, getPlaceByIdWorker)
  yield takeLatest(createPlaceAction.request, createPlaceWorker)
  yield takeLatest(updatePlaceAction.request, updatePlaceWorker)
  yield takeLatest(deletePlaceAction, deletePlaceWorker)
  yield takeLatest(putPlaceLogoAction.request, putPlaceLogoWorker)
  yield takeLatest(addPhotoToPlaceAction.request, addImageToPlaceWorker)
  yield takeLatest(addDocumentToPlaceAction.request, addDocumentToPlaceWorker)
  yield takeLatest(searchPlaceAction.request, searchPlaceWorker)
  yield takeLatest(
    deleteImageFromPlaceAction.request,
    deleteImageFromPlaceWorker,
  )
  yield takeLatest(
    deleteDocumentFromPlaceAction.request,
    deleteDocumentFromPlaceWorker,
  )
}
