import { Assets } from '../../../../assets'
import { languageKeysArray } from '../../../../consts'
import {
  CreteForm,
  Errormessage,
  HeadingWrap,
  InputsWrap,
} from '../../../ApplicationContent/components/CreateServiceWindow/styled'
import { EditOptionForm, TEditMedicalDirectionWindow } from './type'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import { useTranslation } from 'react-i18next'
import { useTypedSelector } from '../../../../hooks'
import { Service } from '../../../../services'
import { api } from '../../../../store'

export const EditAddOption = ({
  id,
  setId,
  setVisibleEditModal,
}: TEditMedicalDirectionWindow) => {
  const { t } = useTranslation('medicalDirection')
  const { language } = useTypedSelector(state => state.user)
  const inputRef = useRef<HTMLInputElement>(null)
  const { mutate } = useSWRConfig()

  const { data: option } = useSWR(
    id ? ['get-additional-option', { _id: id }] : null,
    ([key, params]) => Service.AdditionalOptions.getAdditionalOption(params),
  )

  const [imagePreview, setImagePreview] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<EditOptionForm>({
    defaultValues: { lang: language || 'uk' },
  })

  const onSubmit: SubmitHandler<EditOptionForm> = async data => {
    if (!option) {
      return
    }

    try {
      const payload = new FormData()

      const title = option?.title

      title[data.lang] = data.title

      payload.append('title', JSON.stringify(title))

      file && payload.append('image', file)

      await Service.AdditionalOptions.updateAdditionalOption({
        _id: id,
        data: payload,
      })

      mutate(['get-additional-options', {}])
    } catch (error) {
      console.error(error)
    } finally {
      closeModal()
    }
  }

  const deleteItem = async () => {
    try {
      await Service.AdditionalOptions.deleteAdditionalOption({ _id: id })
    } catch (error) {
      console.error(error)
    } finally {
      mutate(['get-additional-options', {}])
      closeModal()
    }
  }

  const closeModal = () => {
    setId('')
    setVisibleEditModal(prev => !prev)
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      setFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = () => {
    inputRef.current?.click()
  }

  useEffect(() => {
    const currentLang = watch('lang')
    option?.title && setValue('title', option?.title?.[currentLang] || '')
  }, [option, setValue, watch])

  return (
    <>
      <CreteForm onSubmit={handleSubmit(onSubmit)}>
        <HeadingWrap>
          <h3>{t('controlEdit')}</h3>
          <div onClick={closeModal}>
            <img src={Assets.CYAN_ICON} alt="close" />
          </div>
        </HeadingWrap>
        <InputsWrap>
          <div>
            <div>
              <p>{t('language')}</p>
              <select {...register('lang')}>
                {languageKeysArray.map(item => (
                  <option key={item}>{item}</option>
                ))}
              </select>
            </div>
            <label>
              {t('name')}
              <input
                placeholder={t('name')}
                {...register('title', { required: t('mandatoryField') })}
              />
              {errors.title && (
                <Errormessage>{errors.title.message}</Errormessage>
              )}
            </label>
          </div>
          <div>
            {t('image')}
            <img
              src={Assets.DOWNLOAD_ARROW_ICON}
              onClick={handleButtonClick}
              style={{ width: '20px', height: '20px' }}
            />

            <label>
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleImageChange}
                hidden
              />
            </label>

            {option?.image && !imagePreview && (
              <img
                src={`${api.withImageAPI}additional-options/${option?.image}`}
                alt="option image"
                style={{
                  maxWidth: '50px',
                  width: '100%',
                  objectFit: 'contain',
                }}
              />
            )}
            {imagePreview && (
              <img
                src={imagePreview}
                alt="option image"
                style={{
                  maxWidth: '200px',
                  width: '100%',
                  objectFit: 'contain',
                }}
              />
            )}
          </div>
        </InputsWrap>

        <HeadingWrap>
          <button onClick={deleteItem} type="button">
            {t('profile:delete')}
          </button>
          <button type="submit">{t('edit')}</button>
        </HeadingWrap>
      </CreteForm>
    </>
  )
}
