import React, { FC, useState } from 'react'
import {
  AddButton,
  ButtonField,
  StyledAccordion,
  StyledDeleteButton,
  StyledEntity,
  StyledPhoneNumber,
} from '../styled'
import { Accordion } from 'react-bootstrap'
import { Divider, EInputTemplate, Input } from '../../../components'
import { PhonesProps } from '../types'
import { useTranslation } from 'react-i18next'

export const PhoneNumbers: FC<PhonesProps> = ({ form, setForm }) => {
  const [addNumberIndicator, setAddNumberIndicator] = useState<boolean>(false)
  const [temporaryNumber, setTemporaryNumber] = useState<string>('')

  const { t } = useTranslation('clinics')

  const addIndicatorHandler = () => {
    setAddNumberIndicator(prev => !prev)
  }

  const temporaryNumberHandler = (e: any) => {
    setTemporaryNumber(e.target.value)
  }

  const addNumberToStack = () => {
    if (temporaryNumber !== '' && form.phones.indexOf(temporaryNumber) === -1)
      setForm(prev => ({ ...prev, phones: [...prev.phones, temporaryNumber] }))
  }

  const deleteNumber = (number: string) => {
    setForm(prev => ({
      ...prev,
      phones: prev.phones.filter(el => el !== number),
    }))
  }

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('contactNumbers')}</Accordion.Header>
          <Accordion.Body>
            {form.phones.length !== 0 ? (
              <div>
                {form.phones.map(el => (
                  <StyledPhoneNumber key={el}>
                    <div>{el}</div>
                    <Divider width={10} />
                    <StyledDeleteButton onClick={() => deleteNumber(el)}>
                      {t('delete')}
                    </StyledDeleteButton>
                  </StyledPhoneNumber>
                ))}
                <Divider height={10} />

                {addNumberIndicator ? (
                  <>
                    <StyledEntity>
                      <Input
                        label={t('profile:phoneNumber')}
                        name="number"
                        value={temporaryNumber ?? ''}
                        placeholder={t('profile:enterNumber')}
                        template={EInputTemplate.NAME}
                        onChange={v => temporaryNumberHandler(v)}
                      />
                    </StyledEntity>
                    <ButtonField>
                      <AddButton onClick={addNumberToStack}>
                        {t('add')}
                      </AddButton>

                      <Divider width={10} />

                      <StyledDeleteButton onClick={addIndicatorHandler}>
                        {t('cancel')}
                      </StyledDeleteButton>
                    </ButtonField>
                  </>
                ) : (
                  <AddButton onClick={addIndicatorHandler}>
                    {t('addANumber')}
                  </AddButton>
                )}
              </div>
            ) : addNumberIndicator ? (
              <>
                <StyledEntity>
                  <Input
                    label={t('number')}
                    name="number"
                    value={temporaryNumber ?? ''}
                    placeholder={t('profile:enterNumber')}
                    template={EInputTemplate.NAME}
                    onChange={v => temporaryNumberHandler(v)}
                  />
                </StyledEntity>
                <ButtonField>
                  <AddButton onClick={addNumberToStack}>Добавить</AddButton>

                  <Divider width={10} />

                  <StyledDeleteButton onClick={addIndicatorHandler}>
                    {t('cancel')}
                  </StyledDeleteButton>
                </ButtonField>
              </>
            ) : (
              <AddButton onClick={addIndicatorHandler}>
                {t('addANumber')}
              </AddButton>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
