import { api } from '../config'

export const DASHBOARD_URL = {
  getDashboardInfo: (year: number) =>
    `${api.withAPI}administrative/api/v1/admin/dashboard/full/?year=${year}`,
  getYearInfo: (year: number) =>
    `${api.withAPI}administrative/api/v1/admin/dashboard/full/?year=${year}`,
  getActiveIosUsers: `${api.withAPI}administrative/api/v1/admin/analytics/activeUsers`,
  getActiveAndroidUsers: `${api.withAPI}administrative/api/v1/admin/analytics/activeUsers`,
  getActiveUsersSummary: `${api.withAPI}administrative/api/v1/admin/analytics/activeUsers/summary`,
  getOses: `${api.withAPI}administrative/api/v1/admin/analytics/oses`,
  getSessionCounts: `${api.withAPI}administrative/api/v1/admin/analytics/sessionCounts`,
  getModels: `${api.withAPI}administrative/api/v1/admin/analytics/models`,
  getSessionDuration: `${api.withAPI}administrative/api/v1/admin/analytics/sessionDuration`,
  getVersions: `${api.withAPI}administrative/api/v1/admin/analytics/versions`,
  getEvents: (eventName: string) =>
    `${api.withAPI}administrative/api/v1/admin/analytics/${eventName}/aggregated`,
  getUsersByMonth: `${api.withAPI}administrative/api/v1/admin/dashboard/usersByMonth`,
  getUsersByDate: `${api.withAPI}administrative/api/v1/admin/dashboard/usersByDate`,
  getPetsByMonth: `${api.withAPI}administrative/api/v1/admin/dashboard/petsByMonth`,
  getAllVersions: `${api.withAPI}administrative/api/v1/admin/analytics/versions/props`,
}
