import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  changePasswordAction,
  forgotPasswordAction,
  getUserAction,
  initAppAction,
  loginAction,
} from './actions'

import { AuthorizationError } from '../../errors'
import { getUserSelector } from './selectors'
import { PromiseReturnType } from '../types'
import { UserAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { AxiosError } from 'axios'

export function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getUserWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof UserAPI.getUser>> =
      yield call([UserAPI, UserAPI.getUser], { authorization: token })

    yield put(getUserAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getUserWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения пользователя'),
      ),
    )

    yield put(getUserAction.failure(e as AxiosError))
  }
}

function* loginWorker({ payload }: ReturnType<typeof loginAction['request']>) {
  try {
    const response: PromiseReturnType<ReturnType<typeof UserAPI.login>> =
      yield call([UserAPI, UserAPI.login], payload)

    if (response) {
      const parseJwt = (token: string) => {
        let base64Url = token.split('.')[1]
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        let jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join(''),
        )

        return JSON.parse(jsonPayload)
      }
      const jwt: string = yield response.data.accessToken
      const user = parseJwt(jwt)

      console.log(user)

      yield put(
        loginAction.success({ accessToken: response.data.accessToken, user }),
      )
    }
  } catch (e) {
    Log.ruddy('Error: loginWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast(
          'Данные для входа введены неверно! Проверьте правильность пароля или email',
        ),
      ),
    )

    yield put(loginAction.failure(e as AxiosError))
  }
}

function* forgotPasswordWorker({
  payload,
}: ReturnType<typeof forgotPasswordAction>) {
  try {
    const response: PromiseReturnType<
      ReturnType<typeof UserAPI.forgotPassword>
    > = yield call([UserAPI, UserAPI.forgotPassword], payload)

    yield put(
      showToastAction.request(
        generateSuccessToast(
          'Код для восстановления успешно отправлен на почту',
        ),
      ),
    )
  } catch (e) {
    Log.ruddy('Error: forgotPasswordWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка отправки кода для восстановления'),
      ),
    )
  }
}

function* changePasswordWorker({
  payload,
}: ReturnType<typeof changePasswordAction>) {
  try {
    const response: PromiseReturnType<
      ReturnType<typeof UserAPI.changePassword>
    > = yield call([UserAPI, UserAPI.changePassword], {
      password: payload.password,
      authorization: payload.authorization,
    })

    yield put(
      showToastAction.request(generateSuccessToast('Пароль изменен успешно')),
    )
  } catch (e) {
    Log.ruddy('Error: changePasswordWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка изменения пароля')),
    )
  }
}

function* initAppWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    if (token) {
      yield put(getUserAction.request())
    }

    yield put(initAppAction.success())
  } catch (e) {
    Log.ruddy('Error: initAppWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Невозможно получить данные')),
    )
    yield put(initAppAction.failure(e as AxiosError))
  }
}

export function* userWatcher() {
  yield takeLatest(loginAction.request, loginWorker)
  yield takeLatest(getUserAction.request, getUserWorker)
  yield takeLatest(forgotPasswordAction, forgotPasswordWorker)
  yield takeLatest(initAppAction.request, initAppWorker)
  yield takeLatest(changePasswordAction, changePasswordWorker)
}
