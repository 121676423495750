import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  clearCustomerAction,
  getCustomerByIdAction,
  getCustomerEventsAction,
  getCustomersAction,
} from './actions'

const InitialState: TInitialState = {
  customers: [],
  customer: null,
  loading: false,
  events: null,
  total: 0,
}

export const customerReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getCustomersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCustomersAction.success, (state, { payload }) => ({
      ...state,
      customers: payload.customers,
      total: payload.total,
      loading: false,
    }))
    builder.addCase(getCustomersAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getCustomerByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCustomerByIdAction.success, (state, { payload }) => ({
      ...state,
      customer: payload.data,
      loading: false,
    }))
    builder.addCase(getCustomerByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getCustomerEventsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCustomerEventsAction.success, (state, { payload }) => ({
      ...state,
      events: payload.data,
      loading: false,
    }))
    builder.addCase(getCustomerEventsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(clearCustomerAction, state => ({
      ...state,
      customer: null,
    }))
  },
)
