import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from '@mui/material'
import { useTypedSelector, useValidation } from '../../../hooks'
import { generateErrorToast } from '../../../helpers'
import { Divider } from '../../../components'
import { ModalProps, TPermissions } from '../types'
import {
  StyledModalDeleteButton,
  ModalButtonContainer,
  StyledSaveButton,
  StyledCheckboxContainer,
  StyledCheckboxText,
  CheckboxImage,
} from '../styled'
import { Assets } from '../../../assets'
import {
  updateDefaultPermissionsAction,
  updateRegionAction,
} from '../../../store'
import { useTranslation } from 'react-i18next'

export const Modal: FC<ModalProps> = ({
  currentService,
  handleModal,
  open,
  goToStart,
}) => {
  const { controlInfo } = useTypedSelector(state => state.control)
  const dispatch = useDispatch()
  const { t } = useTranslation('regions')

  const NameOfPermission = {
    events: t('petEventAccess'),
    medHistory: t('petMedicalHistory'),
    passport: t('petPassport'),
    services: t('allServices'),
    sharing: t('sharePet'),
  }

  const [serviceData, setServiceData] = useState<TPermissions>({
    events: false,
    medHistory: false,
    passport: false,
    services: false,
    sharing: false,
  })

  const handleChange = (name: keyof TPermissions) => () => {
    setServiceData(prev => ({ ...prev, [name]: !prev[name] }))
  }

  const handleSave = () => {
    !!currentService?.numericCode
      ? dispatch(
          updateRegionAction.request({
            numericCode: currentService?.numericCode ?? '-1',
            permissions: serviceData,
          }),
        )
      : dispatch(
          updateDefaultPermissionsAction.request({ permissions: serviceData }),
        )

    goToStart()

    handleModal(null)()
  }

  useEffect(() => {
    !!currentService?.numericCode
      ? setServiceData({
          events: currentService?.permissions?.events,
          medHistory: currentService?.permissions?.medHistory,
          passport: currentService?.permissions?.passport,
          services: currentService?.permissions?.services,
          sharing: currentService?.permissions?.sharing,
        })
      : setServiceData({
          events: controlInfo?.defaultPermissions?.events ?? false,
          medHistory: controlInfo?.defaultPermissions?.medHistory ?? false,
          passport: controlInfo?.defaultPermissions?.passport ?? false,
          services: controlInfo?.defaultPermissions?.services ?? false,
          sharing: controlInfo?.defaultPermissions?.sharing ?? false,
        })
  }, [currentService])

  return (
    <Dialog open={open} onClose={handleModal(null)}>
      <DialogTitle>{t('editAccess')}</DialogTitle>
      <DialogContent>
        {Object.entries(serviceData).map(el => (
          <StyledCheckboxContainer key={el[0]}>
            <CheckboxImage
              onClick={handleChange(el[0] as keyof TPermissions)}
              src={el[1] ? Assets.CHECKBOX_ACTIVE_ICON : Assets.CHECKBOX_ICON}
            />

            <Divider width={10} />

            <StyledCheckboxText>
              {NameOfPermission[el[0] as keyof typeof NameOfPermission]}
            </StyledCheckboxText>
          </StyledCheckboxContainer>
        ))}
      </DialogContent>
      <DialogActions>
        <ModalButtonContainer>
          <StyledModalDeleteButton onClick={handleModal(null)}>
            {t('cancel')}
          </StyledModalDeleteButton>
          <StyledSaveButton onClick={handleSave}>{t('save')}</StyledSaveButton>
        </ModalButtonContainer>
      </DialogActions>
    </Dialog>
  )
}
