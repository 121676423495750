import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import { AxiosError } from 'axios'

import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'

import {
  createCategoriesClinicsCategoryAction,
  deleteCategoriesClinicsCategoryAction,
  getCategoriesClinicsCategoryAction,
  updateCategoriesClinicsCategoryAction,
} from './actions'
import { ClinicsCategoryAPI } from './api.service'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getCategoriesClinicsAdsWorker({
  payload,
}: ReturnType<typeof getCategoriesClinicsCategoryAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ClinicsCategoryAPI.getClinicsCategory>
    > = yield call(
      [ClinicsCategoryAPI, ClinicsCategoryAPI.getClinicsCategory],
      {
        authorization: token,
        ...payload,
      },
    )

    yield put(
      getCategoriesClinicsCategoryAction.success({
        categoriesClinics: response.data.models,
        totalCount: response.data.totalCount,
        response: 'GET_CATEGORIES',
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getClinicsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категорий')),
    )
  }
}

function* createCategoriesClinicsWorker({
  payload,
}: ReturnType<typeof createCategoriesClinicsCategoryAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([ClinicsCategoryAPI, ClinicsCategoryAPI.createClinicsCategory], {
      authorization: token,
      data: payload.data,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Категорию успешно создано'),
      ),
    )
  } catch (e) {
    console.log('Error: createClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания категории')),
    )

    yield put(createCategoriesClinicsCategoryAction.failure(e as AxiosError))
  } finally {
    yield put(
      createCategoriesClinicsCategoryAction.success({
        response: 'CREATE_CATEGORIES',
      }),
    )
  }
}

function* updateCategoriesClinicsWorker({
  payload,
}: ReturnType<typeof updateCategoriesClinicsCategoryAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([ClinicsCategoryAPI, ClinicsCategoryAPI.updateClinicsCategory], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Категория успешно обновлена'),
      ),
    )
  } catch (e) {
    console.log('Error: updateClinicWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoriesClinicsCategoryAction.failure(e as AxiosError))
  } finally {
    yield put(
      updateCategoriesClinicsCategoryAction.success({
        response: 'UPDATE_CATEGORIES',
      }),
    )
  }
}

function* deleteCategoriesClinicsWorker({
  payload,
}: ReturnType<typeof deleteCategoriesClinicsCategoryAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)
    yield call([ClinicsCategoryAPI, ClinicsCategoryAPI.deleteClinicsCategory], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Категория удалена успешно'),
      ),
    )
  } catch (e) {
    Log.ruddy('Error: deleteClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления категории')),
    )
  } finally {
    yield put(
      deleteCategoriesClinicsCategoryAction.success({
        response: 'DELETE_CATEGORIES',
      }),
    )
  }
}

export function* categoriesClinicsWorker() {
  yield takeLatest(
    getCategoriesClinicsCategoryAction.request,
    getCategoriesClinicsAdsWorker,
  )
  yield takeLatest(
    createCategoriesClinicsCategoryAction.request,
    createCategoriesClinicsWorker,
  )
  yield takeLatest(
    updateCategoriesClinicsCategoryAction.request,
    updateCategoriesClinicsWorker,
  )
  yield takeLatest(
    deleteCategoriesClinicsCategoryAction.request,
    deleteCategoriesClinicsWorker,
  )
}
