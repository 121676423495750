import { usePetsTypes } from '../../PetDetails/services'
import { CheckboxImage, ContainerAccordion, StyledAccordion } from '../styled'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Assets } from '../../../assets'
import { TPetsTypesProps } from '../types'
import { LanguageKeys } from '../../ApplicationContent/services/types'

export const PetsTypes: React.FC<TPetsTypesProps> = ({
  form,
  setForm,
  lang,
}) => {
  const { t } = useTranslation()
  const { petsTypesList } = usePetsTypes()

  const changeService = (id: string) => {
    const elementInForm = form.petType.find(item => item === id)
    if (elementInForm) {
      // delete from form
      setForm(prev => ({
        ...prev,
        petType: prev.petType.filter(item => item !== elementInForm),
      }))
    } else {
      // add to form
      setForm(prev => ({ ...prev, petType: [...prev.petType, id] }))
    }
  }

  return (
    <>
      <StyledAccordion>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{t('pets:petsTypes')}</Accordion.Header>
            <Accordion.Body>
              <Accordion defaultActiveKey="0">
                {petsTypesList?.models.map((type, index) => (
                  <ContainerAccordion key={type?._id}>
                    <CheckboxImage
                      onClick={() => changeService(type?._id)}
                      src={
                        !!form.petType.filter(id => id === type?._id).length
                          ? Assets.CHECKBOX_ACTIVE_ICON
                          : Assets.CHECKBOX_ICON
                      }
                    />

                    <StyledAccordion>
                      <Accordion.Item eventKey={(index + 1).toString()}>
                        <Accordion.Header>
                          {type?.title[lang as LanguageKeys]}
                        </Accordion.Header>
                      </Accordion.Item>
                    </StyledAccordion>
                  </ContainerAccordion>
                ))}
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </StyledAccordion>
    </>
  )
}
