import { put, call, takeLatest, select } from "redux-saga/effects";

import { supportActions } from "./actions";
import { TDataWrapper, TResponse } from "./types";
import { ApiSupportService } from "./api.service";
import { getUserSelector } from "../user";
import { AuthorizationError } from "../../errors";


function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return ("Bearer " + token) as string;

  throw new AuthorizationError('verifyTokenWorker')
}


function* getSupportsWorker({ payload }: TDataWrapper<any>) {
	yield put(supportActions.setSupporsState({ loading: true}))

	const token: string = yield call(verifyTokenWorker)

	try {
		const response: TResponse = yield call([ApiSupportService, ApiSupportService.getSupports], { ...payload, token })
		if (response.data) yield put(supportActions.setSupports(response.data))
	} catch (e) {
		yield put(supportActions.setSupports({data: [], meta: null}))
	}
	yield put(supportActions.setSupporsState({ loading: false }))
};

function* getSupportkWorker({payload}: TDataWrapper<any>) {
	yield put(supportActions.setSupporsState({loading: true}));

	const {_id} = payload;
	const token: string = yield call(verifyTokenWorker);

	try {
		const response: TResponse = yield call([ApiSupportService, ApiSupportService.getSupport], {_id, token})
		if (response.data) {
			yield put(supportActions.setSupport({...response.data}))
			yield put(supportActions.setSupporsState({loading: false}))
		} else {
			yield put(supportActions.setSupporsState({loading: false}))
		}
	} catch (e) {}
};

function* getReviewWorker({payload}: TDataWrapper<any>) {
	yield put(supportActions.setSupporsState({loading: true}));

	const {_id} = payload;
	const token: string = yield call(verifyTokenWorker);

	try {
		const response: TResponse = yield call([ApiSupportService, ApiSupportService.getReview], {_id, token})
		if (response.data) {
			yield put(supportActions.setReview({...response.data}))
			yield put(supportActions.setSupporsState({loading: false}))
		} else {
			yield put(supportActions.setSupporsState({loading: false}))
		}
	} catch (e) {}
};

function* getReviewsWorker({payload}: TDataWrapper<any>) {
	yield put(supportActions.setSupporsState({loading: true}));

	const token: string = yield call(verifyTokenWorker);

	try {
		const response: TResponse = yield call([ApiSupportService, ApiSupportService.getReviews], {...payload, token})
		if (response.data) {
			yield put(supportActions.setReviews({...response.data}))
			yield put(supportActions.setSupporsState({loading: false}))
		} else {
			yield put(supportActions.setSupporsState({loading: false}))
		}
	} catch (e) {}
};


function* deleteSupportWorker({
  payload,
}: TDataWrapper<any>) {
  const { _id } = payload;
  const token: string = yield call(verifyTokenWorker);

  try {
    yield call(
      [ApiSupportService, ApiSupportService.deleteSupport],
      { _id, token }
    );
  } catch (e) {
  }
}

export function* supportWatcher() {
	yield takeLatest(supportActions.getSupports, getSupportsWorker);
	yield takeLatest(supportActions.getSupport, getSupportkWorker);
	yield takeLatest(supportActions.deleteSupport, deleteSupportWorker);
	yield takeLatest(supportActions.getReviews, getReviewsWorker);
	yield takeLatest(supportActions.getReview, getReviewWorker)
}
