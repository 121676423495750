import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getComplaintAction,
  clearComplaintAction,
  getProposalAction,
  clearProposalAction,
  getComplaintByIdAction,
  getProposalByIdAction,
  getInaccuracyAction,
  getInaccuracyByIdAction,
  clearInaccuracyAction,
} from './actions'

const InitialState: TInitialState = {
  complaints: [],
  complaint: null,
  inaccuracy: [],
  proposals: [],
  proposal: null,
  inaccur: null,
  loading: false,
  complaintTotal: 0,
  proposalTotal: 0,
  inaccuracyTotal: 0,
}

export const feedbackReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getComplaintAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getComplaintAction.success, (state, { payload }) => ({
      ...state,
      complaints: payload.data,
      complaintTotal: payload.total,
      loading: false,
    }))
    builder.addCase(getComplaintAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getInaccuracyAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getInaccuracyAction.success, (state, { payload }) => ({
      ...state,
      inaccuracy: payload.data,
      inaccuracyTotal: payload.total,
      loading: false,
    }))
    builder.addCase(getInaccuracyAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(clearComplaintAction, state => ({
      ...state,
      complaint: null,
    }))

    builder.addCase(getComplaintByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getComplaintByIdAction.success, (state, { payload }) => ({
      ...state,
      complaint: payload,
      loading: false,
    }))
    builder.addCase(getComplaintByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getInaccuracyByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getInaccuracyByIdAction.success, (state, { payload }) => ({
      ...state,
      inaccur: payload.data,
      loading: false,
    }))
    builder.addCase(getInaccuracyByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getProposalAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getProposalAction.success, (state, { payload }) => ({
      ...state,
      proposals: payload.data,
      proposalTotal: payload.total,
      loading: false,
    }))
    builder.addCase(getProposalAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(clearProposalAction, state => ({
      ...state,
      proposal: null,
    }))

    builder.addCase(clearInaccuracyAction, state => ({
      ...state,
      inaccur: null,
    }))

    builder.addCase(getProposalByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getProposalByIdAction.success, (state, { payload }) => ({
      ...state,
      proposal: payload.data,
      loading: false,
    }))
    builder.addCase(getProposalByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
  },
)
