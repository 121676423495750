import { createReducer } from '@reduxjs/toolkit'

import {
  createAdsAction,
  deleteAdsAction,
  deleteComplaintsAdsAction,
  getAdsAction,
  getAdsActionComplaints,
  getAdsByIdAction,
  updateAdsAction,
} from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
  ads: [],
  adsOne: null,
  loading: false,
  complaints: [],
  totalCount: 0,
  totalCountComplaints: 0,
  response: null
}

export const adsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getAdsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getAdsAction.success, (state, { payload }) => ({
      ...state,
      ads: payload.ads,
      totalCount: payload.totalCount,
      loading: false,
      response: 'GET_ADS'
    }))
    builder.addCase(getAdsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getAdsActionComplaints.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getAdsActionComplaints.success, (state, { payload }) => ({
      ...state,
      complaints: payload.complaints,
      totalCountComplaints: payload.totalCountComplaints,
      loading: false,
    }))
    builder.addCase(getAdsActionComplaints.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getAdsByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getAdsByIdAction.success, (state, { payload }) => ({
      ...state,
      adsOne: payload,
      loading: false,
    }))
    builder.addCase(getAdsByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
    builder.addCase(createAdsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createAdsAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
      response: "CREATE_ADS"
    }))
    builder.addCase(createAdsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
      response: "CREATE_ADS"
    }))

    builder.addCase(updateAdsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateAdsAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
      response: "UPDATE_ADS"
    }))
    builder.addCase(updateAdsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
      response: "UPDATE_ADS"
    }))

    builder.addCase(deleteAdsAction, state => ({
      ...state,
      adsOne: null,
    }))

    builder.addCase(deleteComplaintsAdsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteComplaintsAdsAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
      response: 'DELETE_COMPLAINTS'
    }))
    builder.addCase(deleteComplaintsAdsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
      response: 'DELETE_COMPLAINTS'
    }))
  
  },
)
