import styled from 'styled-components'
import { COLORS } from '../../../../assets'
export const CreteForm = styled.form`
  width: 490px;
  height: auto;
`
export const HeadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 20px;
    }
  }
  button {
    border: 1px solid ${COLORS.gray};
    border-radius: 8px;
    padding: 12px;
  }
`
export const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  div {
    display: flex;
    width: 100%;
    gap: 30px;
    div {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
    select {
      border: 1px solid ${COLORS.gray};
      border-radius: 8px;
      height: 40px;
    }
    label {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    input {
      width: 360px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid ${COLORS.gray};
      padding: 0 10px;
    }
  }
`
export const TextArea = styled.textarea`
  position: relative;
  display: block;
  border: 1px solid ${COLORS.gray};
  border-radius: 8px;
  min-height: 80px;
  padding: 12px;
`
export const PhotoWrap = styled.div`
  display: flex;
  gap: 20px;
  position: relative;
  img {
    width: 155px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
  }
  button {
    cursor: pointer;
    width: 20px;
    height: 20px;
    outline: none;
    border: none;
    background-color: transparent;
    position: absolute;
    left: 155px;
    img {
      width: 20px;
      height: 20px;
    }
  }
`
export const PhotoUpload = styled.label<{ isError: boolean }>`
  width: 150px;
  height: 150px;
  padding: 4px;
  border-radius: 8px;
  border: 1px dashed ${({ isError }) => (!isError ? COLORS.black : COLORS.red)};
  cursor: pointer;
  input {
    width: 1px;
    height: 1px;
    &.visually-hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      padding: 0;
      white-space: nowrap;
      clip-path: inset(100%);
      clip: rect(0 0 0 0);
      overflow: hidden;
    }
  }
`
export const Errormessage = styled.p`
  color: ${COLORS.red};
  font-size: 14px;
  position: absolute;
  bottom: -18px;
`
export const ErrorDescription = styled.p`
  color: ${COLORS.red};
  font-size: 14px;
  position: absolute;
  bottom: 347px; ;
`
export const SelectWrap = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0;
  select {
    border: 1px solid ${COLORS.gray};
    border-radius: 8px;
    height: 40px;
  }
`
