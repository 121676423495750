import { AxiosResponse } from 'axios'
import {
  CreateAdminActionPayload,
  DeleteAdminActionPayload,
  GetAdminByIdActionPayload,
  GetAdminsActionResponse,
  UpdateAdminActionPayload,
} from './types'

import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'
import { ADMIN_URL } from './config'
// import { USER_URL } from './config'

export class AdminAPI extends HttpService {
  static createAdmin(
    request: CreateAdminActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('CREATE_ADMIN', {
      url: ADMIN_URL.create_admin,
      method: 'POST',
      data: {
        name: request.name,
        access: request.access,
        password: request.password,
        email: request.email,
      },
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getAdmins(
    request: PayloadAuth,
  ): Promise<AxiosResponse<GetAdminsActionResponse>> {
    return this.request<GetAdminsActionResponse>('GET_ADMINS', {
      url: ADMIN_URL.get_admins,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateAdmin(
    request: UpdateAdminActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('UPDATE_ADMIN', {
      url: ADMIN_URL.updateAdmin(request.id),
      method: 'PATCH',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static deleteAdmin(
    request: DeleteAdminActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_PET', {
      url: ADMIN_URL.deleteAdmin(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getAdminById(
    request: GetAdminByIdActionPayload,
  ): Promise<AxiosResponse<GetAdminsActionResponse>> {
    return this.request<GetAdminsActionResponse>('GET_ADMIN_BY_ID', {
      url: ADMIN_URL.getAdminById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
