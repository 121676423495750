import React, { FC, useEffect, useState } from 'react'
import { StyledAccordion, StyledEntity } from '../styled'
import { Accordion } from 'react-bootstrap'
import { EInputTemplate, Input } from '../../../components'
import { PhonesProps, TSocialNet } from '../types'
import { useTypedSelector } from '../../../hooks'
import { useTranslation } from 'react-i18next'

export const SocialNets: FC<PhonesProps> = ({ form, setForm }) => {
  const { clinic } = useTypedSelector(state => state.clinics)
  const { t } = useTranslation('clinics')
  const [social, setSocial] = useState<TSocialNet>({
    viber: '',
    telegram: '',
    whatsApp: '',
    facebook: '',
    vk: '',
    instagram: '',
  })

  const changeSocialNetLink = (e: any) => {
    setSocial(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const onPressBlur = () => {
    setForm(prev => ({
      ...prev,
      social,
    }))
  }

  useEffect(() => {
    setSocial({
      viber: clinic?.contactInfo?.social?.viber ?? '',
      telegram: clinic?.contactInfo?.social?.telegram ?? '',
      whatsApp: clinic?.contactInfo?.social?.whatsApp ?? '',
      facebook: clinic?.contactInfo?.social?.facebook ?? '',
      vk: clinic?.contactInfo?.social?.vk ?? '',
      instagram: clinic?.contactInfo?.social?.instagram ?? '',
    })
  }, [clinic])

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('socialNetworks')}</Accordion.Header>
          <Accordion.Body>
            {Object.keys(social).map(el => (
              <StyledEntity key={el}>
                <Input
                  label={el}
                  name={el}
                  value={social[el as keyof typeof social] ?? ''}
                  placeholder={`${t('insertLinkTo')} ${el}`}
                  template={EInputTemplate.NAME}
                  onChange={v => changeSocialNetLink(v)}
                  onBlur={onPressBlur}
                />
              </StyledEntity>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
