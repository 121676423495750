import { useForm, SubmitHandler } from 'react-hook-form'
import { Assets } from '../../../../assets'
import { languageKeysArray } from '../../../../consts'
import { useEffect, useState } from 'react'
import {
  editCurentSetviceCategory,
  deleteServiceCategory,
} from '../../services'
import { useTypedSelector } from '../../../../hooks'
import { mutate } from 'swr'
import {
  CreteForm,
  ErrorDescription,
  Errormessage,
  HeadingWrap,
  InputsWrap,
  SelectWrap,
  TextArea,
} from '../CreateServiceWindow/styled'
import { TEditServiceProps, TEditServiceTypes } from './types'
import { useGetCurrentInfo } from './useGetCurentInfo'
import { useTranslation } from 'react-i18next'
import { PhotoUpload, PhotoWrap } from './styled'
import { api } from '../../../../store'

export const EditServiceWindow = ({
  setId,
  setVisibleEditModal,
  id,
}: TEditServiceProps) => {
  const [preview, setPreview] = useState('')
  const { token, language } = useTypedSelector(state => state.user)
  const [previewIcon, setPreviewIcon] = useState('')
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TEditServiceTypes>({ defaultValues: { lang: language || 'uk' } })

  const { currentServiceData } = useGetCurrentInfo({ id, token })
  const { t } = useTranslation('functionals')

  const types = [
    { value: 'clinic', label: t('functionals:clinic') },
    { value: 'place', label: t('functionals:place') },
    { value: 'social', label: t('functionals:shelter') },
  ]

  useEffect(() => {
    const file = watch('image')?.[0]
    const iconFile = watch('icon')?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
    if (iconFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewIcon(reader.result as string)
      }
      reader.readAsDataURL(iconFile)
    }
  }, [watch('image'), watch('icon')])

  const closeModal = () => {
    setId('')
    setVisibleEditModal(prev => !prev)
  }

  const onSubmit: SubmitHandler<TEditServiceTypes> = async data => {
    const formData = new FormData()
    formData.append('title', JSON.stringify({ [data.lang]: data.title }))
    formData.append('type', data.type)
    formData.append(
      'description',
      JSON.stringify({ [data.lang]: data.description }),
    )

    if (data.image && data.image[0]) {
      formData.append('image', data.image[0])
    }
    if (data.icon && data.icon[0]) {
      data.icon && formData.append('icon', data.icon[0])
    }
    token && (await editCurentSetviceCategory(formData, id))
    mutate(['serviceCategories', undefined])
    closeModal()
  }

  const deleteImage = (name: string) => {
    if (name === 'image') {
      setValue('image', null)
      setPreview('')
      setValue('oldPhoto', '')
    } else {
      setValue('icon', null)
      setPreviewIcon('')
      setValue('oldIcon', '')
    }
  }

  const deleteItem = async () => {
    token && (await deleteServiceCategory(id))
    mutate(['serviceCategories', undefined])
    closeModal()
  }
  useEffect(() => {
    if (currentServiceData) {
      setValue('oldPhoto', currentServiceData.image)

      const currentLang = watch('lang')
      if (currentServiceData.title && currentServiceData.title[currentLang]) {
        setValue('title', currentServiceData.title[currentLang])
      }

      if (
        currentServiceData.description &&
        currentServiceData.description[currentLang]
      ) {
        setValue('description', currentServiceData.description[currentLang])
      }
      setValue('type', currentServiceData.type)
      setValue('oldIcon', currentServiceData.icon || '')
    }
  }, [currentServiceData, setValue, watch('lang')])

  return (
    <CreteForm onSubmit={handleSubmit(onSubmit)}>
      <HeadingWrap>
        <h3>{t('controlEdit')}</h3>
        <div onClick={closeModal}>
          <img src={Assets.CYAN_ICON} alt="close" />
        </div>
      </HeadingWrap>
      <InputsWrap>
        <div>
          <div>
            <p>{t('language')}</p>
            <select {...register('lang')}>
              {languageKeysArray.map(item => (
                <option key={item}>{item}</option>
              ))}
            </select>
          </div>
          <label>
            {t('name')}
            <input
              placeholder={t('name')}
              {...register('title', { required: t('fieldRequired') })}
            />
            {errors.title && (
              <Errormessage>{errors.title.message}</Errormessage>
            )}
          </label>
        </div>
        <TextArea
          placeholder={t('enterDescription')}
          {...register('description', { required: t('fieldRequired') })}
          maxLength={100}
        ></TextArea>
        {errors.description && (
          <ErrorDescription>{errors.description?.message}</ErrorDescription>
        )}
        <div>
          <PhotoWrap>
            {preview && <img src={preview} alt="preview" />}
            {!!watch('oldPhoto') && (
              <img
                src={
                  api.withImageAPI + '/service-categories/' + watch('oldPhoto')
                }
                alt="preview"
              />
            )}
            {!preview && !watch('oldPhoto') && (
              <PhotoUpload isError={!!errors.image?.message}>
                <p> {t('clickAndSelectImage')}</p>
                <input
                  type="file"
                  accept="image/*"
                  className="visually-hidden"
                  {...register('image', {
                    required: {
                      value: !watch('oldPhoto') && !preview,
                      message: t('fieldRequired'),
                    },
                  })}
                />
              </PhotoUpload>
            )}
            <button type="button" onClick={() => deleteImage('image')}>
              <img src={Assets.CYAN_ICON} alt="close" />
            </button>
          </PhotoWrap>
          <PhotoWrap>
            {previewIcon && <img src={previewIcon} alt="preview" />}
            {!!watch('oldIcon') && (
              <img
                src={
                  api.withImageAPI + '/service-categories/' + watch('oldIcon')
                }
                alt="preview"
              />
            )}
            {!previewIcon && !watch('oldIcon') && (
              <PhotoUpload isError={!!errors.icon?.message}>
                <p> {t('clickAndSelectImageIcon')}</p>
                <input
                  type="file"
                  accept="image/*"
                  className="visually-hidden"
                  {...register('icon', {
                    required: {
                      value: !watch('oldIcon') && !previewIcon,
                      message: t('fieldRequired'),
                    },
                  })}
                />
              </PhotoUpload>
            )}
            <button type="button" onClick={() => deleteImage('icon')}>
              <img src={Assets.CYAN_ICON} alt="close" />
            </button>
          </PhotoWrap>
        </div>
        <SelectWrap>
          <p>{t('type')}</p>
          <select defaultValue={types[0].value} {...register('type')}>
            {types.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </SelectWrap>
      </InputsWrap>
      <HeadingWrap>
        <button onClick={deleteItem} type="button">
          {t('profile:delete')}
        </button>
        <button type="submit">{t('edit')}</button>
      </HeadingWrap>
    </CreteForm>
  )
}
