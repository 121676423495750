import React, { ChangeEvent, FC, useMemo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Assets } from '../../assets'
import { Divider, EInputTemplate, Input } from '../../components'
import { useTypedSelector, useValidation } from '../../hooks'
import { RoutesTree } from '../../router'
import { getControlAction, updateControlAction } from '../../store'
import {
  CheckboxImage,
  Container,
  StyledButton,
  StyledCheckboxesContainer,
  StyledEntity,
  StyledText,
  Title,
} from './styled'
import { TChangeManagerValidationSchema, TForm } from './types'
import { useTranslation } from 'react-i18next'

const Control: FC = () => {
  const { t } = useTranslation('control')
  const { user } = useTypedSelector(state => state.user)
  const { controlInfo } = useTypedSelector(state => state.control)
  const dispatch = useDispatch()

  const [form, setForm] = useState<TForm>({
    iOSVersion: '',
    androidVersion: '',
    maintenance: false,
  })

  const schema = useMemo<TChangeManagerValidationSchema>(
    () => ({
      androidVersion: {
        condition: !!form.androidVersion,
        error: `${t('enterVersion')} android`,
      },
      iOSVersion: {
        condition: !!form.iOSVersion,
        error: `${t('enterVersion')}  iOS`,
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
    // eslint-disable-next-line
  } = useValidation(schema)

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleCheckbox = () => {
    setForm(prev => ({ ...prev, maintenance: !prev.maintenance }))
  }

  const onPressSaveChanges = async () => {
    try {
      enableValidation()
      await validation()

      const result = window.confirm(t('saveChanges'))

      if (result) {
        dispatch(
          updateControlAction.request({
            data: {
              iOSVersion: form.iOSVersion,
              androidVersion: form.androidVersion,
              maintenance: form.maintenance,
            },
          }),
        )
      }

      disableValidation()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setForm({
      iOSVersion: controlInfo?.iOSVersion ?? '',
      androidVersion: controlInfo?.androidVersion ?? '',
      maintenance: controlInfo?.maintenance ?? false,
    })
  }, [controlInfo])

  useEffect(() => {
    dispatch(getControlAction.request())
  }, [])

  if (!user?.access?.control) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <Title>{t('versionControl')}</Title>

      <Divider height={30} />

      <StyledEntity>
        <Input
          label="Android"
          name="androidVersion"
          value={form.androidVersion ?? ''}
          placeholder="Введите версию android"
          template={EInputTemplate.DEFAULT}
          error={schema.androidVersion.error || ''}
          //@ts-ignore
          onChange={inputHandler}
          isValid={!isEnabled || validationSchema.androidVersion}
        />
      </StyledEntity>

      <Divider height={10} />

      <StyledEntity>
        <Input
          label="iOS"
          name="iOSVersion"
          value={form.iOSVersion ?? ''}
          placeholder="Введите версию iOS"
          template={EInputTemplate.DEFAULT}
          error={schema.iOSVersion.error || ''}
          //@ts-ignore
          onChange={inputHandler}
          isValid={!isEnabled || validationSchema.iOSVersion}
        />
      </StyledEntity>

      <Divider height={10} />

      <StyledCheckboxesContainer>
        <StyledText>{t('technicalBreak')}</StyledText>

        <Divider width={20} />

        <CheckboxImage
          onClick={handleCheckbox}
          src={
            form.maintenance
              ? Assets.CHECKBOX_ACTIVE_ICON
              : Assets.CHECKBOX_ICON
          }
        />
      </StyledCheckboxesContainer>

      <Divider height={20} />

      <StyledButton onClick={onPressSaveChanges}>{t('save')}</StyledButton>

      <Divider height={30} />
    </Container>
  )
}

export default Control
