import styled from 'styled-components'
import { COLORS } from '../../../../assets'
export const OneServiceItem = styled.li`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`
export const DescriptionText = styled.div`
  width: 250px;
  height: auto;
  padding: 8px;
  border: 1px solid ${COLORS.gray};
  border-radius: 8px;
`
export const Image = styled.img`
  width: 100px;
  object-fit: cover;
  border-radius: 8px;
`
export const EditButton = styled.button`
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: ${COLORS.gray};
  cursor: pointer;
  padding: 8px 10px;
`
export const Select = styled.select`
  border: 1px solid ${COLORS.gray};
  border-radius: 6px;
  padding: 6px;
`
export const TypeWrap = styled.div`
  border: 1px solid ${COLORS.gray};
  border-radius: 6px;
  padding: 6px;
`
