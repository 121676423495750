import { serviceInstance } from '../../../services'
import {
  TCreateMedicalDirection,
  TGetMedicalDirectionsResponse,
  ModelType,
} from './types'

export const getMedicalDirection = async () => {
  const response = await serviceInstance.get<TGetMedicalDirectionsResponse>(
    '/service/api/v1/admin/medical-directions',
    { params: { limit: 100 } },
  )
  return response.data
}

export const createMedicalDirection = async (data: TCreateMedicalDirection) => {
  await serviceInstance.post('/service/api/v1/admin/medical-directions', data)
}

export const getCurrentMedicalDirection = async (id: string) => {
  const response = await serviceInstance.get<ModelType>(
    `/service/api/v1/admin/medical-directions/${id}`,
  )
  return response.data
}

export const eitMedicalDirection = async (
  data: TCreateMedicalDirection,
  id: string,
) => {
  await serviceInstance.patch(
    `/service/api/v1/admin/medical-directions/${id}`,
    data,
  )
}

export const deleteMedicalDirection = async (id: string) => {
  await serviceInstance.delete(`/service/api/v1/admin/medical-directions/${id}`)
}
