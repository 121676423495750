import { useEffect, useMemo, useState } from 'react'
import Table from 'rc-table'
import { Divider } from '../../components'
import {
  Container,
  FullWidth,
  Header,
  Title,
  TableWrapper,
  StyledRow,
  StyledHeader,
  StyledTable,
  StyledPagination,
  StyledButtonEnd,
  StyledButton,
  DeleteButton,
} from './styled'
import { useTypedSelector } from '../../hooks'
import { RoutesTree } from '../../router'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { Service } from '../../services'
import moment from 'moment'
import { getCategoryTitle } from './utils'
import { useTranslate } from '../../hooks/useTranslate'
import _ from 'lodash'
import { TableData } from './types'

export const Mail = () => {
  const { t } = useTranslation('mail')
  const { user } = useTypedSelector(state => state.user)
  const { translate } = useTranslate()

  const [sortBy, setSortBy] = useState<string>('')
  const [order, setOrder] = useState<1 | -1>(-1)
  const [page, setPage] = useState<number>(1)
  const [tableData, setTableData] = useState<TableData[]>([])

  const { data, mutate } = useSWR(
    [
      'get-mails',
      {
        ...(sortBy ? { sortBy } : {}),
        ...(order ? { order } : {}),
      },
    ],
    ([_, key]) => Service.Mail.getMails(key),
    { revalidateOnFocus: false },
  )

  if (!user?.access?.mail) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  // eslint-disable-next-line
  const total = useMemo(() => {
    return data?.totalCount ?? 0
  }, [data])

  const sort = (field: string) => {
    setSortBy(field)

    if (order == -1) {
      setOrder(1)
      return
    }

    setOrder(-1)
  }

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('categories'),
      dataIndex: 'categories',
      key: 'categories',
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      key: 'delete',
    },
  ]

  // eslint-disable-next-line
  useEffect(() => {
    if (_.isEmpty(data)) {
      return
    }

    const fetchData = async () => {
      const result = await Promise.all(
        data?.models?.map(async mail => {
          const categories = await getCategoryTitle({
            categoriesId: mail?.categories,
          })

          const categoriesTitle = categories
            .map(item => translate(item.title))
            .join(', ')

          return {
            name: mail?.name,
            createdAt: moment(mail?.createdAt).isValid()
              ? moment(mail?.createdAt).format('HH:mm DD.MM.YYYY')
              : '-',
            email: mail?.email,
            delete: (
              <DeleteButton
                onClick={async () => {
                  await Service.Mail.deleteMail({ _id: mail?._id })
                  mutate()
                }}
              >
                {t('delete')}
              </DeleteButton>
            ),
            categories: categoriesTitle,
          }
        }),
      )

      setTableData(result)
    }

    fetchData()
  }, [data])

  return (
    <Container>
      <div>
        <Header>
          <Title>
            {t('mails')} ({data?.totalCount ?? 0})
          </Title>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          {data?.models && (
            <Table
              columns={columns}
              data={tableData}
              scroll={{ y: 650 }}
              components={{
                body: {
                  row: StyledRow,
                },
                header: {
                  cell: StyledHeader,
                },
                table: StyledTable,
              }}
            />
          )}
        </TableWrapper>
      </div>

      <FullWidth>
        <StyledPagination>
          {page === 1 ? (
            <StyledButtonEnd> {t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => setPage(prev => prev - 1)}>
              {t('back')}
            </StyledButton>
          )}

          <div>
            {page}/
            {!total
              ? 1
              : Math.ceil(total / 20) === 0
              ? 1
              : Math.ceil(total / 20)}
          </div>

          {1 === (!total ? 1 : Math.ceil(total / 20)) ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => setPage(prev => prev + 1)}>
              {t('next')}
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}
