import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import moment from 'moment'
import { Redirect } from 'react-router-dom'

import { Divider, EInputTemplate, Input } from '../../components'
import { useTypedSelector } from '../../hooks'
import {
  deleteComplaintsAdsAction,
  getAdsActionComplaints,
} from '../../store/ads'
import { Assets } from '../../assets'
import { RoutesTree } from '../../router'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Header,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
  StyledPagination,
  StyledButtonEnd,
  StyledButton,
  FullWidth,
  StyledEntity,
  Image,
  StyledSort,
  DeleteButton,
  BlockButton,
} from './styled'
import { LIMIT_PAGE } from './consts'
import { StyledLink } from '../Customers/styled'
import { StyledNumberLink } from '../Complaint/styled'

export const ComplaintsAds = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchPage, setSearchPage] = useState<number>(1)
  const [adsArrayData, setAdsArrayData] = useState<any>([])
  const { t } = useTranslation('complaintsAds')
  const dispatch = useDispatch()

  const { totalCountComplaints, complaints } = useTypedSelector(
    state => state.ads,
  )

  const { user } = useTypedSelector(state => state.user)

  const columns = [
    {
      title: (
        <StyledSort>
          <div>{t('complaintName')}</div>

          <Divider width={10} />
        </StyledSort>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <StyledSort>
          <div>{t('announcementName')}</div>

          <Divider width={10} />
        </StyledSort>
      ),
      dataIndex: 'adsName',
      key: 'adsName',
    },
    {
      title: (
        <StyledSort>
          <div>{t('creationDate')}</div>

          <Divider width={10} />
        </StyledSort>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: <div>{t('description')}</div>,
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
    },
  ]

  const choosePage = (page: number) => {
    dispatch(
      getAdsActionComplaints.request({
        page: page === 0 ? 1 : page,
        limit: LIMIT_PAGE,
      }),
    )

    setCurrentPage(page === 0 ? 1 : page)
  }

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
  }

  const enterKeyPress = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <=
          (totalCountComplaints === 0
            ? 1
            : Math.ceil(totalCountComplaints / LIMIT_PAGE))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1,
      )
  }

  const deleteComplaintsAds = (id: string) => {
    dispatch(deleteComplaintsAdsAction.request({ id }))
    getComplaintsAds()
    getComplaintsAds()
  }

  const getComplaintsAds = () => {
    dispatch(
      getAdsActionComplaints.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: LIMIT_PAGE,
      }),
    )
  }
  useEffect(() => {
    getComplaintsAds()
  }, [])

  useEffect(() => {
    if (complaints) {
      const newData = complaints.map((a: any) => ({
        name: a?.title ?? '-',
        adsName: (
          <StyledNumberLink
            to={{ pathname: `/advertisementProfile/${a?.ad?._id}` }}
          >
            {a?.ad?.title ?? '-'}
          </StyledNumberLink>
        ),
        createdAt: moment(a?.createdAt).isValid()
          ? moment(a?.createdAt).format('HH:mm DD.MM.YYYY')
          : '-',
        key: a?.id,
        description: a?.description,
        details: (
          <BlockButton>
            <DeleteButton onClick={() => deleteComplaintsAds(a?._id)}>
              {t('profile:delete')}
            </DeleteButton>
          </BlockButton>
        ),
      }))

      setAdsArrayData(newData)
    } else {
      setAdsArrayData([])
    }
  }, [complaints, t])

  if (!user?.access?.ads) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <div>
        <Header>
          <Title>{`${t('adComplaints')} (${totalCountComplaints ?? 0})`}</Title>

          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label={t('page')}
                name="searchPage"
                value={searchPage.toString()}
                placeholder={t('page')}
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (totalCountComplaints === 0
                      ? 1
                      : Math.ceil(totalCountComplaints / LIMIT_PAGE))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={adsArrayData}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>{t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              {t('back')}
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!totalCountComplaints
              ? 1
              : Math.ceil(totalCountComplaints / LIMIT_PAGE) === 0
              ? 1
              : Math.ceil(totalCountComplaints / LIMIT_PAGE)}
          </div>

          {currentPage ===
          (!totalCountComplaints
            ? 1
            : Math.ceil(totalCountComplaints / LIMIT_PAGE)) ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              {t('next')}
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}
