import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  CreateClinicActionPayload,
  DeleteClinicActionPayload,
  DeleteImageFromClinicActionPayload,
  GetClinicByIdActionPayload,
  GetClinicByIdActionResponse,
  GetClinicsActionResponse,
  GetClinicsPayload,
  putClinicLogoActionPayload,
  PutData,
  SearchClinicActionPayload,
  UpdateClinicActionPayload,
} from './types'

export const getClinicsAction = createApiActions<
  GetClinicsPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/GET_CLINICS'))

export const createClinicAction = createApiActions<
  CreateClinicActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/CREATE_CLINIC'))

export const updateClinicAction = createApiActions<
  UpdateClinicActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/UPDATE_CLINIC'))

export const putClinicLogoAction = createApiActions<
  putClinicLogoActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/PUT_CLINIC_LOGO'))

export const addPhotoToClinicAction = createApiActions<
  putClinicLogoActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/ADD_PHOTO_TO_CLINIC'))

export const addDocumentToClinicAction = createApiActions<
  putClinicLogoActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/ADD_DOCUMENT_TO_CLINIC'))

export const searchClinicAction = createApiActions<
  SearchClinicActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/SEARCH_CLINIC'))

export const deleteImageFromClinicAction = createApiActions<
  DeleteImageFromClinicActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/DELETE_IMAGE_FROM_CLINIC'))

export const deleteDocumentFromClinicAction = createApiActions<
  DeleteImageFromClinicActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('CLINICS/DELETE_DOCUMENT_FROM_CLINIC'))

export const putDataToClinicAction = createAction<
  PutData,
  'CLINICS/PUT_WORKING_TIME_TO_CLINIC'
>('CLINICS/PUT_WORKING_TIME_TO_CLINIC')

export const getClinicByIdAction = createApiActions<
  GetClinicByIdActionPayload,
  GetClinicByIdActionResponse,
  AxiosError
>(createActionTypes('CLINICS/GET_CLINIC_BY_ID'))

export const clearClinicAction = createAction<void, 'CLINICS/CLEAR_CLINIC'>(
  'CLINICS/CLEAR_CLINIC',
)

export const deleteClinicAction = createAction<
  DeleteClinicActionPayload,
  'CLINICS/DELETE_CLINIC'
>('CLINICS/DELETE_CLINIC')
