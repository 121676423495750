import { serviceInstance } from '../http.service'
import { CreateSeoRequest, GetSeoRequest, UpdateSeoRequest } from './types'

export class SEO {
  static async createSeo(
    data: CreateSeoRequest['payload'],
  ): Promise<CreateSeoRequest['response']> {
    const response = await serviceInstance.post(
      '/administrative/api/v1/admin/seodata',
      data,
    )

    return response.data
  }

  static async getSeo({
    page,
  }: GetSeoRequest['payload']): Promise<GetSeoRequest['response']> {
    const response = await serviceInstance.get(
      `/administrative/api/v1/admin/seodata/${page}`,
    )

    return response.data
  }

  static async updateSeo({
    _id,
    data,
  }: UpdateSeoRequest['payload']): Promise<UpdateSeoRequest['response']> {
    const response = await serviceInstance.patch(
      `/administrative/api/v1/admin/seodata/${_id}`,
      data,
    )

    return response.data
  }
}
