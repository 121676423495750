import React, { FC } from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Backdrop, EBackdropFadeIn } from '../../components'
import { TLoaderProps } from './types'

const Loader: FC<TLoaderProps> = ({ isLoading, classes }) => {
  return isLoading ? (
    <Backdrop
      zIndex={1002}
      onClick={() => {}}
      fadeIn={EBackdropFadeIn.fadeInDelayed}
    >
      <CircularProgress
        size={100}
        color="primary"
        variant="indeterminate"
        classes={{ circleIndeterminate: classes.barColorPrimary }}
      />
    </Backdrop>
  ) : null
}

const styles = () =>
  createStyles({
    barColorPrimary: {
      color: 'white',
    },
  })

export default withStyles(styles)(Loader)
