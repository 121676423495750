import React, { FC } from 'react'
import { Container, Title } from './styled'

const NotFound: FC = () => {
  return (
    <Container>
      <Title>Не найдено</Title>
    </Container>
  )
}

export default NotFound
