import { ChangeEvent, useEffect, useState } from 'react'
import {
  DescriptionText,
  EditButton,
  OneServiceItem,
  Select,
} from '../../../ApplicationContent/components/OneService/styled'
import { LanguageKeys } from '../../../ApplicationContent/services/types'
import { languageKeysArray } from '../../../../consts'
import { useTypedSelector } from '../../../../hooks'
import { t } from 'i18next'
import { AdditionalOptionProps } from './type'

export const AdditionalOption = ({
  setVisibleEditModal,
  setId,
  currentDirectional,
}: AdditionalOptionProps) => {
  const { language } = useTypedSelector(state => state.user)
  const [currentlang, setCurrentlang] = useState<LanguageKeys>(language)
  const langChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentlang(e.target.value as LanguageKeys)
  }

  useEffect(() => {
    setCurrentlang(language)
  }, [language])

  const onEditClick = () => {
    setId(currentDirectional._id)
    setVisibleEditModal(true)
  }
  return (
    <>
      <OneServiceItem>
        <Select value={currentlang} onChange={langChange}>
          {languageKeysArray
            .filter(el => el !== 'iw' && el !== 'ar')
            .map(item => (
              <option key={item}>{item}</option>
            ))}
        </Select>
        <DescriptionText>
          {currentDirectional?.title?.[currentlang] || ''}
        </DescriptionText>

        <EditButton onClick={onEditClick}>
          {t('medicalDirection:edit')}
        </EditButton>
      </OneServiceItem>
    </>
  )
}
