import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'

export const TabsWrapper = styled.div`
  max-width: 712px;
  width: 100%;
  display: flex;
`;

export const TabBlock = styled.div`
  text-align: center;
  width: 50%;
  cursor: pointer;
  padding-bottom: 9px;
  border-bottom: 1px solid #1F166A;
  
  &.active {
    border-bottom: 3px solid #1F166A;
    transition: all 0.1s;
    color: #1F166A;
  }
  
  &:hover {
    border-bottom: 3px solid #1F166A;
    transition: all 0.1s;
    color: #1F166A;
  }
`;

export const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 4px;
  color:#1F166A;
  font-size: 14px;
  font-weight: bold;
`;

export const Description = styled.textarea`
  width: 315px;
  height: 100px;

  padding: 10px;

  border:none;
  outline: none;
  background-color: #EEE;
`;

export const PhotoCateogry = styled.img`
  width: 250px;
  height: 100px;
`;

export const TabText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: start;
`

export const FullWidth = styled.div`
  width: 100%;
`

export const Image = styled.img`
  width: 16px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const NavigateButton = styled.button`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.gray};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const SortImage = styled(Image)`
  margin: 0;
`

export const StyledSort = styled.div`
  ${FLEX({ direction: 'row', wrap: 'nowrap', justify: 'flex-start' })}
`

export const StyledEntity = styled.div`
  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 315px;
`

export const Close = styled.div`
  cursor: pointer;
  font-size: 16px;
`

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`

export const FooterModal = styled.div`
  display: flex;
  align-items: center;
`

export const BodyModal = styled.div`
  padding: 15px 0;
`

export const DropdownBlock = styled.div`
  margin-top: 20px;
`

export const ButtonDelete = styled.button`
  width: 150px;
  padding: 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: white;
  color: #fc5c63;
  cursor: pointer;
  border: 2px solid #fc5c63;

  &:hover {
    background-color: #fc5c63;
    color: white;

    transition: all 0.3s;
  }
`

export const ButtonCreate = styled.button`
  width: 150px;
  padding: 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: white;
  color: #00c58d;
  cursor: pointer;
  border: 2px solid #00c58d;

  &:hover {
    background-color: #00c58d;
    color: white;

    transition: all 0.3s;
  }
`

export const ButtonUpdate = styled.button`
  width: 150px;
  padding: 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: white;
  color: #1f166a;
  cursor: pointer;
  border: 2px solid #1f166a;

  margin-left: 15px;

  &:hover {
    background-color: #1f166a;
    color: white;

    transition: all 0.3s;
  }
`

export const CreateButton = styled(Link)`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.green};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const Header = styled.div`
  width: 100%;

  padding: 25px;

  background: ${COLORS.white};

  ${FLEX({ justify: 'space-between', direction: 'row', wrap: 'nowrap' })}
`

export const Title = styled.h1`
  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const TableWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;

  background: ${COLORS.white};

  overflow-y: scroll;
`

export const StyledRow = styled.tr`
  & td {
    padding: 9px 15px 7px 15px;
    width: 20%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${FONT({ size: '16px', color: COLORS.lightBlue, weight: '400' })}
`

export const StyledHeader = styled.th`
  padding: 15px;

  ${SHADOW({ x: 2, y: 0 })};

  ${FONT({
    size: '16px',
    color: COLORS.lightBlue,
    weight: '700',
    align: 'left',
  })}
`

export const StyledTable = styled.table`
  width: 100%;
`

export const StyledLink = styled(Link)`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.gray};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const StyledButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 30%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const StyledButtonEnd = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;
  border-color: ${COLORS.lightGray};

  width: 30%;

  transition: 0.15s ease-in-out;

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightGray })}

  ${FLEX({})}
`

export const StyledPagination = styled.div`
  margin-bottom: 10px;
  ${FLEX({ justify: 'space-between' })}
`
