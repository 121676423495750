import { serviceInstance } from '../http.service'
import {
  CreateAdditionalOptionRequest,
  DeleteAdditionalOptionRequest,
  GetAdditionalOptionRequest,
  GetAdditionalOptionsRequest,
  UpdateAdditionalOptionRequest,
} from './types'

export class AdditionalOptions {
  static async getAdditionalOptions(
    params: GetAdditionalOptionsRequest['payload'],
  ): Promise<GetAdditionalOptionsRequest['response']> {
    const response = await serviceInstance.get(
      'service/api/v1/admin/additional-options',
      {
        params,
      },
    )

    return response.data
  }

  static async getAdditionalOption({
    _id,
  }: GetAdditionalOptionRequest['payload']): Promise<
    GetAdditionalOptionRequest['response']
  > {
    const response = await serviceInstance.get(
      `service/api/v1/admin/additional-options/${_id}`,
      {},
    )

    return response.data
  }

  static async updateAdditionalOption({
    _id,
    data,
  }: UpdateAdditionalOptionRequest['payload']): Promise<
    UpdateAdditionalOptionRequest['response']
  > {
    const response = await serviceInstance.patch(
      `service/api/v1/admin/additional-options/${_id}`,
      data,
      {},
    )

    return response.data
  }

  static async createAdditionalOption({
    data,
  }: CreateAdditionalOptionRequest['payload']): Promise<
    CreateAdditionalOptionRequest['response']
  > {
    const response = await serviceInstance.post(
      `service/api/v1/admin/additional-options`,
      data,
      {},
    )

    return response.data
  }

  static async deleteAdditionalOption({
    _id,
  }: DeleteAdditionalOptionRequest['payload']): Promise<
    DeleteAdditionalOptionRequest['response']
  > {
    const response = await serviceInstance.delete(
      `service/api/v1/admin/additional-options/${_id}`,
      {},
    )

    return response.data
  }
}
