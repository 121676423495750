import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SortImage, StyledSortName } from '../pages/Appointments/styled'
import { Divider } from '../components'
import { Assets } from '../assets'

type UseColumnsProps = {
  sort: (field: string) => void
  sortBy: string
  order: 1 | -1
}

export const useColumns = ({ sort, sortBy, order }: UseColumnsProps) => {
  const { t } = useTranslation('appointments')

  const columns = useMemo(() => {
    return [
      {
        title: (
          <StyledSortName onClick={() => sort('title')}>
            <div>{t('title')}</div>

            <Divider width={10} />

            <SortImage
              src={
                sortBy == 'title' && order == 1
                  ? Assets.UP_ARROW_ICON
                  : Assets.DOWN_ARROW_ICON
              }
            />
          </StyledSortName>
        ),
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: (
          <StyledSortName onClick={() => sort('owner')}>
            <div>{t('owner')}</div>

            <Divider width={10} />

            <SortImage
              src={
                sortBy == 'owner' && order == 1
                  ? Assets.UP_ARROW_ICON
                  : Assets.DOWN_ARROW_ICON
              }
            />
          </StyledSortName>
        ),
        dataIndex: 'owner',
        key: 'owner',
      },
      {
        title: (
          <StyledSortName onClick={() => sort('createdAt')}>
            <div>{t('createdAt')}</div>

            <Divider width={10} />

            <SortImage
              src={
                sortBy == 'createdAt' && order == 1
                  ? Assets.UP_ARROW_ICON
                  : Assets.DOWN_ARROW_ICON
              }
            />
          </StyledSortName>
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: (
          <StyledSortName>
            <div>{t('category')}</div>
          </StyledSortName>
        ),
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: (
          <StyledSortName onClick={() => sort('status')}>
            <div>{t('status')}</div>

            <Divider width={10} />

            <SortImage
              src={
                sortBy == 'status' && order == 1
                  ? Assets.UP_ARROW_ICON
                  : Assets.DOWN_ARROW_ICON
              }
            />
          </StyledSortName>
        ),
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: t('details'),
        dataIndex: 'details',
        key: 'details',
      },
    ]
  }, [sort, sortBy, order])

  return { columns }
}
