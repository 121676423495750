import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  GetPetsActionResponse,
  GetPetByIdActionPayload,
  GetPetByIdActionResponse,
  UpdatePetActionPayload,
  DeletePetActionPayload,
  GetOwnPetsActionPayload,
  GetPetsActionPayload,
} from './types'

export const getPetsAction = createApiActions<
  GetPetsActionPayload,
  GetPetsActionResponse,
  AxiosError
>(createActionTypes('PETS/GET_PETS'))

export const getOwnPetsAction = createApiActions<
  GetOwnPetsActionPayload,
  GetPetByIdActionResponse,
  AxiosError
>(createActionTypes('PETS/GET_OWN_PETS'))

export const getPetByIdAction = createApiActions<
  GetPetByIdActionPayload,
  GetPetByIdActionResponse,
  AxiosError
>(createActionTypes('PETS/GET_PETS_BY_ID'))

export const updatePetAction = createAction<
  UpdatePetActionPayload,
  'PETS/UPDATE_PET'
>('PETS/UPDATE_PET')

export const clearPetAction = createAction<void, 'PETS/CLEAR_PETS'>(
  'PETS/CLEAR_PETS',
)

export const clearOwnPetsAction = createAction<void, 'PETS/CLEAR_OWN_PETS'>(
  'PETS/CLEAR_OWN_PETS',
)

export const deletePetAction = createAction<
  DeletePetActionPayload,
  'PETS/DELETE_PET'
>('PETS/DELETE_PET')
