import { LocalizationProvider } from '@mui/lab'
import {
  AdviceImage,
  ClearImage,
  Container,
  DeleteButton,
  Image,
  Label,
  StyledButton,
  StyledDropDown,
  StyledInput,
  StyledLinkDiv,
  StyledTextarea,
  StyledTitle,
  Title,
} from './styled'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import Dropdown from 'react-dropdown'
import { languages } from './consts'
import 'react-dropdown/style.css'
import { useTypedSelector } from '../../hooks'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { RoutesTree } from '../../router'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../components'
import { Assets } from '../../assets'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { FormInput } from './types'
import { useEffect, useMemo, useState } from 'react'
import { Service } from '../../services'
import useSWR from 'swr'
import { TagsInput } from 'react-tag-input-component'
import _ from 'lodash'
import { ErrorMessage } from '../AdviceCreate/styled'
import { useDispatch } from 'react-redux'
import { showToastAction } from '../../store'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Types } from '../../types'

export const SEO = () => {
  const { t } = useTranslation('seo')
  const { user, language: lang } = useTypedSelector(state => state.user)
  const history = useHistory()
  const dispatch = useDispatch()

  const [language, setLanguage] = useState<string>(lang || 'en')
  const [metaKeywords, setMetaKeywords] = useState<string[]>([])
  const [resultKeywords, setResultKeywords] = useState<Types.Seo.Title[]>([])

  const { data } = useSWR(['get-seo', { page: 'advices' }], ([_, key]) =>
    Service.SEO.getSeo(key),
  )

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<FormInput>({
    defaultValues: async () => await Service.SEO.getSeo({ page: 'advices' }),
    mode: 'all',
  })

  const { fields: metaTitleFields, append: appendMetaTitle } = useFieldArray({
    control,
    name: 'metaTitle',
  })

  const { fields: metaDescriptionFields, append: appendMetadescription } =
    useFieldArray({
      control,
      name: 'metaDescription',
    })

  const [metaTitle, metaDescription] = watch(['metaTitle', 'metaDescription'])

  const handleBack = () => {
    history.goBack()
  }

  const changeType = (type: string) => {
    setLanguage(type)
  }

  const setSelectedMetaTitle = (lang: string, value: string) => {
    const existingMetaTitleIndex = metaTitleFields.findIndex(
      field => field.lang === lang,
    )
    if (existingMetaTitleIndex !== -1) {
      setValue(`metaTitle.${existingMetaTitleIndex}.value`, value)
    } else {
      value && appendMetaTitle({ value, lang })
    }
  }

  const setSelectedMetadescription = (lang: string, value: string) => {
    const existingMetaDescriptionIndex = metaDescriptionFields.findIndex(
      field => field.lang === lang,
    )

    if (existingMetaDescriptionIndex !== -1) {
      setValue(`metaDescription.${existingMetaDescriptionIndex}.value`, value)
    } else {
      value && appendMetadescription({ value, lang })
    }
  }

  const onSubmit = async (formData: FormInput) => {
    try {
      const ukDescription = formData.metaDescription.filter(
        item => item.lang == 'uk' && item.value,
      ).length

      const ukTitle = formData.metaTitle.filter(
        item => item.lang == 'uk' && item.value,
      ).length

      if (!ukDescription || !ukTitle) {
        dispatch(
          showToastAction.request(
            generateErrorToast(
              "Не можна видалити категорію до якої прив'язані пости",
            ),
          ),
        )
        return
      }

      await Service.SEO.updateSeo({
        _id: data?._id as string,
        data: { ...formData, metaKeywords: resultKeywords },
      }).then(res => {
        if (res) {
          dispatch(
            showToastAction.request(
              generateSuccessToast('Дані успішно оновлено'),
            ),
          )
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const onMetaKeywordsChange = (newTags: string[]) => {
    // Assuming you have a way to get language info for each tag
    const updatedTags = newTags?.map(tag => {
      // Find the tag in the existing state to get its language
      const existingTag = resultKeywords.find(t => t?.value === tag)
      return {
        value: tag,
        lang: existingTag ? existingTag?.lang : lang,
      }
    })

    setResultKeywords(updatedTags)
  }

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  useEffect(() => {
    if (_.isEmpty(data)) {
      return
    }

    setMetaKeywords(data?.metaKeywords?.map(item => item.value))
  }, [data])

  if (!user?.access?.advice) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
          <StyledTitle>
            {Assets.LEFT_ARROW_ICON ? (
              <StyledLinkDiv onClick={handleBack}>
                <Image src={Assets.LEFT_ARROW_ICON} />
              </StyledLinkDiv>
            ) : (
              ''
            )}

            <Title>{t('SEO')}</Title>

            <div></div>
          </StyledTitle>

          <Divider height={30} />

          <StyledDropDown>
            <Label>{t('language')}</Label>

            <Divider height={10} />

            <Dropdown
              options={languages}
              onChange={option => changeType(option.value)}
              value={language}
              placeholder="Виберите язык"
            />
          </StyledDropDown>

          <Divider height={30} />

          <Label>{t('metatitle')}</Label>

          <Divider height={10} />

          <Controller
            key={`metaTitle-${language}`}
            rules={{
              required:
                metaTitle?.filter(item => item.value && item.lang == language)
                  .length > 0
                  ? ''
                  : t('required'),
            }}
            name={`metaTitle.${metaTitleFields.findIndex(
              field => field.lang === language,
            )}.value`}
            control={control}
            render={({ field: { value } }) => (
              <StyledInput
                value={value}
                onChange={e => setSelectedMetaTitle(language, e.target.value)}
                placeholder="Enter meta title"
              />
            )}
          />

          {errors?.metaTitle && (
            <ErrorMessage>{errors?.metaTitle[0]?.value?.message}</ErrorMessage>
          )}

          <Divider height={30} />

          <Label>{t('metakeywords')}</Label>

          <Divider height={10} />

          <Controller
            key={`metaKeywords-${language}`}
            rules={{
              required:
                resultKeywords?.filter(
                  item => item?.value && item.lang == language,
                )?.length > 0
                  ? ''
                  : t('required'),
            }}
            name={`metaKeywords`}
            control={control}
            render={({ field }) => (
              <TagsInput
                value={metaKeywords}
                onChange={onMetaKeywordsChange}
                placeHolder="Enter keywords"
              />
            )}
          />

          {errors.metaKeywords && (
            <ErrorMessage>{errors?.metaKeywords?.message}</ErrorMessage>
          )}

          <Divider height={30} />

          <Label>{t('metadescription')}</Label>

          <Divider height={10} />

          <Controller
            key={`metaDescription-${language}`}
            name={`metaDescription.${metaDescriptionFields.findIndex(
              field => field.lang === language,
            )}.value`}
            control={control}
            rules={{
              required:
                metaDescription?.filter(
                  item => item.value && item.lang == language,
                ).length > 0
                  ? ''
                  : t('required'),
            }}
            render={({ field: { onChange, ...field } }) => (
              <StyledTextarea
                {...field}
                onChange={e =>
                  setSelectedMetadescription(language, e.target.value)
                }
                placeholder="Enter meta description"
              />
            )}
          />

          {errors?.metaDescription && (
            <ErrorMessage>
              {errors?.metaDescription[0]?.value?.message}
            </ErrorMessage>
          )}

          <StyledButton type="submit">{t('edit')}</StyledButton>
        </LocalizationProvider>
      </form>
    </Container>
  )
}
