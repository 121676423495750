import React from 'react'
import TimePicker from '@mui/lab/TimePicker'
import TextField from '@mui/material/TextField'
import { Accordion } from 'react-bootstrap'
import { Divider } from '../../../components'
import Checkbox from '@mui/material/Checkbox'
import {
  StyledAccordion,
  StyledAccordionBody,
  StyledFormControlLabel,
  StyledTimeDiv,
  StyledWeekendDiv,
  StyledWeekendRightPartDiv,
  StyledWeekField,
  StyledWeekTime,
} from '../styled'
import { Redirect } from 'react-router-dom'
import { RoutesTree } from '../../../router'
import { useTypedSelector } from '../../../hooks'
import moment from 'moment'
import { TWorkingTime } from '../types'
import { t } from 'i18next'

const WeekName = {
  monday: t('days:monday'),
  tuesday: t('days:tuesday'),
  wednesday: t('days:wednesday'),
  thursday: t('days:thursday'),
  friday: t('days:friday'),
  saturday: t('days:saturday'),
  sunday: t('days:sunday'),
}

const WorkingTime: React.FC<TWorkingTime> = ({
  timePicker,
  setTimePicker,
  additionalFields,
  setAdditionalFields,
}) => {
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.clinics) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  const timePickerHandler = (
    name: keyof typeof timePicker,
    time: keyof typeof timePicker['monday'],
    value: Date | number,
  ) => {
    if (
      moment(value).isValid() && time === 'start'
        ? value < timePicker[name]['end']
        : value > timePicker[name]['start']
    ) {
      setTimePicker(prev => ({
        ...prev,
        [name]: { ...prev[name], [time]: value },
      }))

      if (additionalFields['everyday'].enable === true)
        setAdditionalFields(prev => ({
          ...prev,
          everyday: {
            enable: false,
            start: new Date().setHours(0, 0, 0, 0),
            end: new Date().setHours(20, 0, 0, 0),
          },
        }))

      if (additionalFields['aroundTheClock'] === true)
        setAdditionalFields(prev => ({
          ...prev,
          aroundTheClock: false,
        }))
    }
  }

  const additionalTimePicker = (
    time: keyof typeof additionalFields['everyday'],
    value: Date | number,
    name: keyof typeof additionalFields = 'everyday',
  ) => {
    if (moment(value).isValid()) {
      setAdditionalFields(prev => ({
        ...prev,
        [name]: {
          ...(prev[name] as object),
          [time]: value,
        },
      }))

      name == 'everyday' &&
        setTimePicker(prev => ({
          ...prev,
          monday: { ...prev['monday'], [time]: value },
          tuesday: { ...prev['tuesday'], [time]: value },
          wednesday: { ...prev['wednesday'], [time]: value },
          thursday: { ...prev['thursday'], [time]: value },
          friday: { ...prev['friday'], [time]: value },
          saturday: { ...prev['saturday'], [time]: value },
          sunday: { ...prev['sunday'], [time]: value },
        }))
    }
  }

  const weekendHandler = (name: keyof typeof timePicker) => {
    if (!timePicker[name]['weekend'] === true) {
      setTimePicker(prev => ({
        ...prev,
        [name]: {
          weekend: !timePicker[name]['weekend'],
          start: new Date().setHours(0, 0, 0, 0),
          end: new Date().setHours(0, 0, 0, 0),
        },
      }))

      if (additionalFields['everyday'].enable === true)
        setAdditionalFields(prev => ({
          ...prev,
          everyday: {
            start: new Date().setHours(0, 0, 0, 0),
            end: new Date().setHours(20, 0, 0, 0),
            enable: false,
          },
        }))

      if (additionalFields['aroundTheClock'] === true)
        setAdditionalFields(prev => ({
          ...prev,
          aroundTheClock: false,
        }))
    } else {
      setTimePicker(prev => ({
        ...prev,
        [name]: {
          weekend: !timePicker[name]['weekend'],
          start: new Date().setHours(0, 0, 0, 0),
          end: new Date().setHours(20, 0, 0, 0),
        },
      }))

      if (additionalFields['everyday'].enable === true)
        setAdditionalFields(prev => ({
          ...prev,
          everyday: {
            start: new Date().setHours(0, 0, 0, 0),
            end: new Date().setHours(20, 0, 0, 0),
            enable: false,
          },
        }))

      if (additionalFields['aroundTheClock'] === true)
        setAdditionalFields(prev => ({
          ...prev,
          aroundTheClock: false,
        }))
    }
  }

  const pickAdditionalFields = (name: keyof typeof additionalFields) => {
    if (
      (name === 'everyday' || name == 'lunch') &&
      additionalFields[name]['enable'] === true
    ) {
      setAdditionalFields(prev => ({
        ...prev,
        [name]: {
          start: new Date().setHours(0, 0, 0, 0),
          end: new Date().setHours(20, 0, 0, 0),
          enable: false,
        },
      }))
    } else if (
      (name === 'everyday' || name == 'lunch') &&
      additionalFields[name]['enable'] === false
    ) {
      setAdditionalFields(prev => ({
        ...prev,
        [name]: {
          ...prev[name],
          enable: !additionalFields[name]['enable'],
        },
      }))

      if (additionalFields['aroundTheClock'])
        setAdditionalFields(prev => ({
          ...prev,
          aroundTheClock: !additionalFields[name],
        }))

      if (name == 'everyday') {
        setTimePicker(prev => ({
          ...prev,
          ...Object.keys(timePicker).reduce(
            (i, el) => ({
              ...i,
              [el]: {
                weekend: false,
                start: new Date().setHours(0, 0, 0, 0),
                end: new Date().setHours(20, 0, 0, 0),
              },
            }),
            {},
          ),
        }))
      }
    } else if (name === 'aroundTheClock' && additionalFields[name] === false) {
      setAdditionalFields(prev => ({
        ...prev,
        [name]: !additionalFields[name],
      }))

      if (additionalFields['everyday'].enable)
        setAdditionalFields(prev => ({
          ...prev,
          everyday: {
            enable: !additionalFields['everyday']['enable'],
            start: new Date().setHours(0, 0, 0, 0),
            end: new Date().setHours(20, 0, 0, 0),
          },
        }))

      if (additionalFields['lunch'].enable)
        setAdditionalFields(prev => ({
          ...prev,
          lunch: {
            enable: !additionalFields['lunch']['enable'],
            start: new Date().setHours(0, 0, 0, 0),
            end: new Date().setHours(20, 0, 0, 0),
          },
        }))

      setTimePicker(prev => ({
        ...prev,
        ...Object.keys(timePicker).reduce(
          (i, el) => ({
            ...i,
            [el]: {
              weekend: false,
              start: new Date().setHours(0, 0, 0, 0),
              end: new Date().setHours(23, 59, 59, 999),
            },
          }),
          {},
        ),
      }))
    } else if (name === 'aroundTheClock' && additionalFields[name] === true) {
      setAdditionalFields(prev => ({
        ...prev,
        [name]: !additionalFields[name],
      }))

      setTimePicker(prev => ({
        ...prev,
        ...Object.keys(timePicker).reduce(
          (i, el) => ({
            ...i,
            [el]: {
              weekend: false,
              start: new Date().setHours(0, 0, 0, 0),
              end: new Date().setHours(20, 0, 0, 0),
            },
          }),
          {},
        ),
      }))
    } else {
      setAdditionalFields(prev => ({
        ...prev,
        [name]: !prev[name],
      }))
    }
  }

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('clinics:workingHours')}</Accordion.Header>
          <Accordion.Body>
            <StyledAccordionBody>
              <div>
                {Object.keys(timePicker).map(el => (
                  <StyledWeekField key={el}>
                    <div>{WeekName[el as keyof typeof WeekName]}</div>
                    <Divider width={20} />
                    <StyledWeekTime>
                      <StyledTimeDiv>
                        <Divider height={5} />
                        <TimePicker
                          disabled={
                            timePicker[el as keyof typeof timePicker].weekend
                          }
                          label={t('clinics:start')}
                          value={
                            timePicker[el as keyof typeof timePicker].start
                          }
                          onChange={(newValue: any) =>
                            timePickerHandler(
                              el as keyof typeof timePicker,
                              'start',
                              newValue ?? new Date().setHours(0, 0, 0, 0),
                            )
                          }
                          renderInput={params => (
                            <TextField
                              {...{
                                ...params,
                                inputProps: {
                                  ...params['inputProps'],
                                  readOnly: true,
                                },
                              }}
                            />
                          )}
                        />
                      </StyledTimeDiv>
                      <Divider width={10} />
                      <StyledTimeDiv>
                        <Divider height={5} />
                        <TimePicker
                          disabled={
                            timePicker[el as keyof typeof timePicker].weekend
                          }
                          label={t('clinics:end')}
                          value={timePicker[el as keyof typeof timePicker].end}
                          onChange={(newValue: any) =>
                            timePickerHandler(
                              el as keyof typeof timePicker,
                              'end',
                              newValue ?? new Date().setHours(20, 0, 0, 0),
                            )
                          }
                          renderInput={params => (
                            <TextField
                              {...{
                                ...params,
                                inputProps: {
                                  ...params['inputProps'],
                                  readOnly: true,
                                },
                              }}
                            />
                          )}
                        />
                      </StyledTimeDiv>
                    </StyledWeekTime>
                  </StyledWeekField>
                ))}
              </div>

              <Divider width={10} />

              <StyledWeekendDiv>
                <Divider height={5} />
                {Object.keys(timePicker).map(el => (
                  <StyledFormControlLabel
                    key={el + 'weekend'}
                    control={
                      <Checkbox
                        checked={
                          timePicker[el as keyof typeof timePicker].weekend
                        }
                        onChange={() =>
                          weekendHandler(el as keyof typeof timePicker)
                        }
                      />
                    }
                    label={t('clinics:weekend')}
                  />
                ))}
              </StyledWeekendDiv>

              <Divider width={40} />

              <StyledWeekendRightPartDiv>
                <Divider height={5} />

                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={additionalFields['planned']}
                      onChange={() => pickAdditionalFields('planned')}
                    />
                  }
                  label={t('clinics:byAppointment')}
                />

                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={additionalFields['aroundTheClock']}
                      onChange={() => pickAdditionalFields('aroundTheClock')}
                    />
                  }
                  label={t('clinics:aroundTheClock')}
                />

                {/* Daily */}
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={additionalFields['everyday'].enable}
                      onChange={() => pickAdditionalFields('everyday')}
                    />
                  }
                  label={t('clinics:daily')}
                />
                <StyledWeekField>
                  <StyledWeekTime>
                    <StyledTimeDiv>
                      <TimePicker
                        disabled={!additionalFields['everyday'].enable}
                        label={t('clinics:start')}
                        value={additionalFields['everyday'].start}
                        onChange={(newValue: any) =>
                          additionalTimePicker(
                            'start',
                            newValue ?? new Date().setHours(0, 0, 0, 0),
                          )
                        }
                        renderInput={params => (
                          <TextField
                            {...{
                              ...params,
                              inputProps: {
                                ...params['inputProps'],
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                      />
                    </StyledTimeDiv>
                    <Divider width={10} />
                    <StyledTimeDiv>
                      <TimePicker
                        disabled={!additionalFields['everyday'].enable}
                        label={t('clinics:end')}
                        value={additionalFields['everyday'].end}
                        onChange={(newValue: any) =>
                          additionalTimePicker(
                            'end',
                            newValue ?? new Date().setHours(20, 0, 0, 0),
                          )
                        }
                        renderInput={params => (
                          <TextField
                            {...{
                              ...params,
                              inputProps: {
                                ...params['inputProps'],
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                      />
                    </StyledTimeDiv>
                  </StyledWeekTime>
                </StyledWeekField>

                {/* Lunch */}
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={additionalFields['lunch'].enable}
                      onChange={() => pickAdditionalFields('lunch')}
                    />
                  }
                  label={t('clinics:dinner')}
                />
                <StyledWeekField>
                  <StyledWeekTime>
                    <StyledTimeDiv>
                      <TimePicker
                        disabled={!additionalFields['lunch'].enable}
                        label={t('clinics:start')}
                        value={additionalFields['lunch'].start}
                        onChange={(newValue: any) =>
                          additionalTimePicker(
                            'start',
                            newValue ?? new Date().setHours(0, 0, 0, 0),
                            'lunch',
                          )
                        }
                        renderInput={params => (
                          <TextField
                            {...{
                              ...params,
                              inputProps: {
                                ...params['inputProps'],
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                      />
                    </StyledTimeDiv>
                    <Divider width={10} />
                    <StyledTimeDiv>
                      <TimePicker
                        disabled={!additionalFields['lunch'].enable}
                        label={t('clinics:end')}
                        value={additionalFields['lunch'].end}
                        onChange={(newValue: any) =>
                          additionalTimePicker(
                            'end',
                            newValue ?? new Date().setHours(20, 0, 0, 0),
                            'lunch',
                          )
                        }
                        renderInput={params => (
                          <TextField
                            {...{
                              ...params,
                              inputProps: {
                                ...params['inputProps'],
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                      />
                    </StyledTimeDiv>
                  </StyledWeekTime>
                </StyledWeekField>
              </StyledWeekendRightPartDiv>
            </StyledAccordionBody>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}

export default WorkingTime
