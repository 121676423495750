import moment from 'moment'

import { TAdditionalFields, TForm, TTime } from '../types'

const WeekName = {
  monday: 'Пн.',
  tuesday: 'Вт.',
  wednesday: 'Ср.',
  thursday: 'Чт.',
  friday: 'Пт.',
  saturday: 'Сб.',
  sunday: 'Вс.',
}

export const MakeData = ({
  form,
  timePicker,
  additionalFields,
}: {
  form: TForm
  timePicker: TTime
  additionalFields: TAdditionalFields
}) => {
  let workingTimeObject

  if (additionalFields['aroundTheClock']) {
    workingTimeObject = {
      monday: {
        start: '00:00',
        end: '23:59',
      },
      tuesday: {
        start: '00:00',
        end: '23:59',
      },
      wednesday: {
        start: '00:00',
        end: '23:59',
      },
      thursday: {
        start: '00:00',
        end: '23:59',
      },
      friday: {
        start: '00:00',
        end: '23:59',
      },
      saturday: {
        start: '00:00',
        end: '23:59',
      },
      sunday: {
        start: '00:00',
        end: '23:59',
      },
    }
  } else if (
    moment(timePicker.monday.start).isValid() &&
    moment(timePicker.monday.end).isValid() &&
    moment(timePicker.tuesday.start).isValid() &&
    moment(timePicker.tuesday.end).isValid() &&
    moment(timePicker.wednesday.start).isValid() &&
    moment(timePicker.wednesday.end).isValid() &&
    moment(timePicker.thursday.start).isValid() &&
    moment(timePicker.thursday.end).isValid() &&
    moment(timePicker.friday.start).isValid() &&
    moment(timePicker.friday.end).isValid() &&
    moment(timePicker.saturday.start).isValid() &&
    moment(timePicker.saturday.end).isValid() &&
    moment(timePicker.sunday.start).isValid() &&
    moment(timePicker.sunday.end).isValid()
  ) {
    workingTimeObject = {
      monday: {
        start: moment(timePicker['monday'].start).format('HH:mm'),
        end: moment(timePicker['monday'].end).format('HH:mm'),
      },
      tuesday: {
        start: moment(timePicker['tuesday'].start).format('HH:mm'),
        end: moment(timePicker['tuesday'].end).format('HH:mm'),
      },
      wednesday: {
        start: moment(timePicker['wednesday'].start).format('HH:mm'),
        end: moment(timePicker['wednesday'].end).format('HH:mm'),
      },
      thursday: {
        start: moment(timePicker['thursday'].start).format('HH:mm'),
        end: moment(timePicker['thursday'].end).format('HH:mm'),
      },
      friday: {
        start: moment(timePicker['friday'].start).format('HH:mm'),
        end: moment(timePicker['friday'].end).format('HH:mm'),
      },
      saturday: {
        start: moment(timePicker['saturday'].start).format('HH:mm'),
        end: moment(timePicker['saturday'].end).format('HH:mm'),
      },
      sunday: {
        start: moment(timePicker['sunday'].start).format('HH:mm'),
        end: moment(timePicker['sunday'].end).format('HH:mm'),
      },
    }
  }

  const weekArray = []

  if (additionalFields['everyday'].enable) {
    weekArray.push(
      `Пн. - Вс. ${moment(additionalFields['everyday'].start).format(
        'HH:mm',
      )} - ${moment(additionalFields['everyday'].end).format('HH:mm')}`,
    )
  } else if (additionalFields['aroundTheClock']) {
    weekArray.push(`Пн. - Вс. 00:00 - 24:00`)
  } else {
    const nameOfWeekObject = Object.keys(timePicker)

    let time = ''
    let i = 0
    let j = 0

    for (const [key, value] of Object.entries(timePicker)) {
      if (
        i < 6 &&
        timePicker[nameOfWeekObject[i] as keyof typeof timePicker].weekend &&
        timePicker[nameOfWeekObject[i] as keyof typeof timePicker].start ===
          timePicker[nameOfWeekObject[i + 1] as keyof typeof timePicker]
            .start &&
        timePicker[nameOfWeekObject[i] as keyof typeof timePicker].end ===
          timePicker[nameOfWeekObject[i + 1] as keyof typeof timePicker].end
      ) {
        time = time === '' ? `${WeekName[key as keyof typeof WeekName]}` : time
        j = i
      } else if (
        timePicker[nameOfWeekObject[i] as keyof typeof timePicker].weekend
      ) {
        time =
          time === ''
            ? `${WeekName[key as keyof typeof WeekName]} - выходной`
            : `${time} - ${WeekName[key as keyof typeof WeekName]} - выходной`
        weekArray.push(time)
        time = ''
      } else if (
        i < 6 &&
        timePicker[nameOfWeekObject[i] as keyof typeof timePicker].start ===
          timePicker[nameOfWeekObject[i + 1] as keyof typeof timePicker]
            .start &&
        timePicker[nameOfWeekObject[i] as keyof typeof timePicker].end ===
          timePicker[nameOfWeekObject[i + 1] as keyof typeof timePicker].end
      ) {
        time = time === '' ? `${WeekName[key as keyof typeof WeekName]}` : time
        j = i
      } else {
        time =
          time === ''
            ? `${WeekName[key as keyof typeof WeekName]} ${moment(
                timePicker[nameOfWeekObject[i] as keyof typeof timePicker]
                  .start,
              ).format('HH:mm')} - ${moment(
                timePicker[nameOfWeekObject[i] as keyof typeof timePicker].end,
              ).format('HH:mm')}`
            : `${time} - ${WeekName[key as keyof typeof WeekName]} ${moment(
                timePicker[nameOfWeekObject[j] as keyof typeof timePicker]
                  .start,
              ).format('HH:mm')} - ${moment(
                timePicker[nameOfWeekObject[i] as keyof typeof timePicker].end,
              ).format('HH:mm')}`
        weekArray.push(time)
        time = ''
      }
      i++
    }
  }

  return {
    description: form.description,
    medicalDirections: form.medicalDirections,
    type: 'PLACE',
    name: form.title,
    schedule: {
      lunchHours: {
        ...additionalFields.lunch,
        start: moment(additionalFields.lunch.start).format('HH:mm'),
        end: moment(additionalFields.lunch.end).format('HH:mm'),
      },
      details: {
        allDay: additionalFields.aroundTheClock,
        everyDay: additionalFields.everyday.enable,
        byAppointment: additionalFields.planned,
      },
      workingHours: workingTimeObject,
    },
    contactInfo: {
      email: form.email,
      social: form.social,
      phone: form.phones,
      site: form.site,
      location: {
        country: form.country,
        district: form.city,
        address: form.address,
        coords: {
          type: 'Point',
          coordinates: [form.coordinates.lng, form.coordinates.lat],
          centerPoint: [form.cityLog.lng, form.cityLog.lat],
        },
      },
    },
    specialisation: form.specialisation.id,
    additionalOptions: form.additionalOptions.map(item => item.id),
    needTranslate: form.isTranslate,
  }
}
