import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  SidebarWrapper,
  NavLink,
  MenuWrapper,
  Image,
  LogoutBtn,
  VersionWrap,
} from './styled'

import { useDispatch } from 'react-redux'
import { Assets } from '../../assets'
import { logoutAction } from '../../store'
import { useTypedSelector } from '../../hooks'
import { useTranslation } from 'react-i18next'

const fieldName = {
  pets: 'pets',
  users: 'customers',
  settings: 'settings',
  information: 'info',
  clinics: 'clinics',
  business: 'clinics',
  shelters: 'clinics',
  specialists: 'specialists',
  complaints: 'complaint',
  offers: 'proposal',
  inaccuracies: 'inaccuracy',
  control: 'control',
  places: 'places',
  // 'Категории клиник': 'categories',
  adCategories: 'categories',
  advertisement: 'ads',
  complaintsAboutAds: 'ads',
  notifications: 'notifications',
  analyticalGraphs: 'dashboard',
  analyticsOfClicks: 'eventsboard',
  functionalManagement: 'regions',
  feedback: 'feedback',
  applicationContentManagement: 'service-categories',
  managementOfServicesAndServices: 'medical-directions',
  additionalOptions: 'additional-options',
  tags: 'tags',
  advice: 'advice',
  mail: 'mail',
  seo: 'seo',
  appointments: 'appointments',
}

export default withRouter(function Sidebar({ onMenuItemClick }: any) {
  const { t } = useTranslation('sidebar')
  const dispatch = useDispatch()
  const currentType = process.env.REACT_APP_TYPE
  const currentVersion = process.env.REACT_APP_VERSION
  const { user } = useTypedSelector(store => store.user)

  const signout = () => {
    dispatch(logoutAction())
  }

  const sidebarMenus = [
    {
      name: 'analyticalGraphs',
      path: '/dashboard',
      exact: false,
      icon: Assets.DASHBOARD_ICON,
    },
    {
      name: 'analyticsOfClicks',
      path: '/eventsboard',
      exact: false,
      icon: Assets.DASHBOARD_ICON,
    },
    {
      name: 'users',
      path: '/customers',
      exact: false,
      icon: Assets.CUSTOMERS_ICON,
    },
    {
      name: 'pets',
      path: '/pets',
      exact: false,
      icon: Assets.PETS_ICON,
    },
    {
      name: 'information',
      path: '/info-section',
      exact: false,
      icon: Assets.INFO_SECTION_ICON,
    },
    {
      name: 'settings',
      path: '/settings',
      exact: false,
      icon: Assets.SETTINGS_ICON,
    },
    {
      name: 'clinics',
      path: '/clinics',
      exact: false,
      icon: Assets.CLINICS_ICON,
    },
    {
      name: 'business',
      path: '/bisnes-clinks',
      exact: false,
      icon: Assets.CLINICS_ICON,
    },
    {
      name: 'shelters',
      path: '/social-clinks',
      exact: false,
      icon: Assets.CLINICS_ICON,
    },
    {
      name: 'specialists',
      path: '/specialists',
      exact: false,
      icon: Assets.CLINICS_ICON,
    },
    {
      name: 'places',
      path: '/places',
      exact: false,
      icon: Assets.PLACES_ICON,
    },
    {
      name: 'feedback',
      path: '/feedback',
      exact: false,
      icon: Assets.PROPOSAL_ICON,
    },
    {
      name: 'adCategories',
      path: '/categories',
      exact: false,
      icon: Assets.CATEGORIES_ICON,
    },
    // {
    //   name: 'Категории клиник',
    //   path: '/clinicsCategory',
    //   exact: false,
    //   icon: Assets.CATEGORIES_ICON,
    // },
    {
      name: 'complaints',
      path: '/complaint',
      exact: false,
      icon: Assets.COMPLAINT_ICON,
    },
    {
      name: 'offers',
      path: '/proposal',
      exact: false,
      icon: Assets.PROPOSAL_ICON,
    },
    {
      name: 'inaccuracies',
      path: '/inaccuracy',
      exact: false,
      icon: Assets.INACCURACY_ICON,
    },
    {
      name: 'advertisement',
      path: '/advertisement',
      exact: false,
      icon: Assets.NOTIFICATION_ICON,
    },
    {
      name: 'complaintsAboutAds',
      path: '/complaintsAds',
      exact: false,
      icon: Assets.INACCURACY_ICON,
    },
    {
      name: 'control',
      path: '/control',
      exact: false,
      icon: Assets.CONTROL_ICON,
    },
    {
      name: 'notifications',
      path: '/notifications',
      exact: false,
      icon: Assets.NOTIFICATION_ICON,
    },
    {
      name: 'functionalManagement',
      path: '/regions',
      exact: false,
      icon: Assets.REGIONS_ICON,
    },
    {
      name: 'applicationContentManagement',
      path: '/functionals',
      exact: false,
      icon: Assets.APPLICATION_ICON,
    },
    {
      name: 'tags',
      path: '/tags',
      exact: false,
      icon: Assets.APPLICATION_ICON,
    },
    {
      name: 'advice',
      path: '/advice',
      exact: false,
      icon: Assets.APPLICATION_ICON,
    },
    {
      name: 'mail',
      path: '/mail',
      exact: false,
      icon: Assets.APPLICATION_ICON,
    },
    {
      name: 'seo',
      path: '/seo',
      exact: false,
      icon: Assets.NOTIFICATION_ICON,
    },
    {
      name: 'managementOfServicesAndServices',
      path: '/medical-directions',
      exact: false,
      icon: Assets.SETTING_LIST,
    },
    {
      name: 'additionalOptions',
      path: '/additional-options',
      exact: false,
      icon: Assets.SETTING_LIST,
    },
    {
      name: 'appointments',
      path: '/appointments',
      exact: false,
      icon: Assets.SETTING_LIST,
    },
  ]

  return (
    <SidebarWrapper>
      <MenuWrapper>
        {sidebarMenus.map((menu: any, index: number) => {
          // eslint-disable-next-line no-lone-blocks
          {
            return (
              // @ts-ignore
              user?.access?.[fieldName[menu.name]] && (
                <NavLink
                  key={menu.name + index}
                  to={menu.path}
                  exact={menu.exact}
                  onClick={onMenuItemClick}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {menu.icon ? (
                      <Image
                        style={{ justifySelf: 'center' }}
                        src={menu.icon}
                      />
                    ) : (
                      ''
                    )}
                    {t(menu.name)}
                  </div>
                </NavLink>
              )
            )
          }
        })}
      </MenuWrapper>
      <LogoutBtn onClick={signout}>
        <Image src={Assets.LOGOUT_ICON} />
        {t('exit')}
      </LogoutBtn>
      <VersionWrap>
        {t('version')} {currentType} {currentVersion}
      </VersionWrap>
    </SidebarWrapper>
  )
})
