import { TRoutesTree } from './types'
import {
  NotFoundPage,
  LoginPage,
  CustomersPage,
  ChangePasswordPage,
  PetsPage,
  PetDetailsPage,
  SettingsPage,
  AdminProfilePage,
  AdminCreatePage,
  InfoSectionPage,
  ClinicsPage,
  ComplaintPage,
  ClinicInfoPage,
  ProposalPage,
  ComplaintInfoPage,
  ProposalInfoPage,
  InaccuracyPage,
  InaccuracyInfoPage,
  ControlPage,
  PlacesPage,
  PlaceInfoPage,
  NotificationsPage,
  DashboardPage,
  RegionsPage,
  ProfilePage,
  FeedbacksPage,
  SupportProfile,
  Review,
  Eventsboard,
  EventsboardInfo,
  Categories,
  Advertisement,
  AdvertisementProfile,
  ClinicsCategory,
  ApplicationContent,
  MedicalDirections,
  Tags,
  TagsCreate,
  TagsDetail,
  Advice,
  AdviceCreate,
  AdviceDetail,
  AddtitionalOptions,
  Mail,
  SEO,
  Specialists,
  SpecialistInfo,
  Appointments,
  AppointmentsDetail,
} from '../pages'
import { ComplaintsAds } from '../pages/ComplaintsAds'
import { BisnesClinkPage } from '../pages/BisnesClinik'
import { BisnesClinInfoPage } from '../pages/BisnesClinkInfo'
import { SocialClincsPage } from '../pages/SocialClinics'
import { SocialClinkInfoPage } from '../pages/SocialClinicInfo'

export const RoutesTree: TRoutesTree = {
  login: {
    name: 'login',
    path: '/login',
    component: LoginPage,
  },
  customers: {
    name: 'customers',
    path: '/customers',
    component: CustomersPage,
  },
  advertisementProfile: {
    name: 'advertisementProfile',
    path: '/advertisementProfile/:id',
    component: AdvertisementProfile,
  },
  bisnesClinks: {
    name: 'bisnesClinks',
    path: '/bisnes-clinks',
    component: BisnesClinkPage,
  },
  bisnesCliniksInfo: {
    name: 'bisnesCliniksInfo',
    path: '/bisnes-cliniks-info/:id',
    component: BisnesClinInfoPage,
  },
  socialClinks: {
    name: 'socialClinks',
    path: '/social-clinks',
    component: SocialClincsPage,
  },
  socialClinksInfo: {
    name: 'socialClinksInfo',
    path: '/social-clinks-info/:id',
    component: SocialClinkInfoPage,
  },
  advertisement: {
    name: 'advertisement',
    path: '/advertisement',
    component: Advertisement,
  },
  complaintsAds: {
    name: 'complaintsAds',
    path: '/complaintsAds',
    component: ComplaintsAds,
  },
  supportProfile: {
    name: 'supportProfile',
    path: '/support-profile/:id',
    component: SupportProfile,
  },
  categories: {
    name: 'categories',
    path: '/categories',
    component: Categories,
  },
  review: {
    name: 'review',
    path: '/review/:id',
    component: Review,
  },
  clinics: {
    name: 'clinics',
    path: '/clinics',
    component: ClinicsPage,
  },
  dashboard: {
    name: 'dashboard',
    path: '/dashboard',
    component: DashboardPage,
  },
  feedbacks: {
    name: 'feedbacks',
    path: '/feedback',
    component: FeedbacksPage,
  },
  places: {
    name: 'places',
    path: '/places',
    component: PlacesPage,
  },
  regions: {
    name: 'regions',
    path: '/regions',
    component: RegionsPage,
  },
  pets: {
    name: 'pets',
    path: '/pets',
    component: PetsPage,
  },
  notifications: {
    name: 'notifications',
    path: '/notifications',
    component: NotificationsPage,
  },
  adminProfile: {
    name: 'adminProfile',
    path: '/admin-profile/:id',
    component: AdminProfilePage,
  },
  clinicsCategory: {
    name: 'clinicsCategory',
    path: '/clinicsCategory',
    component: ClinicsCategory,
  },
  adminCreate: {
    name: 'adminCreate',
    path: '/admin-create',
    component: AdminCreatePage,
  },
  settings: {
    name: 'settings',
    path: '/settings',
    component: SettingsPage,
  },
  notFound: {
    name: 'notFound',
    path: '/not-found',
    component: NotFoundPage,
  },
  changePassword: {
    name: 'changePassword',
    path: '/change-password',
    component: ChangePasswordPage,
  },
  complaint: {
    name: 'complaint',
    path: '/complaint',
    component: ComplaintPage,
  },
  complaintInfo: {
    name: 'complaintInfo',
    path: '/complaint/:id',
    component: ComplaintInfoPage,
  },
  inaccuracy: {
    name: 'inaccuracy',
    path: '/inaccuracy',
    component: InaccuracyPage,
  },
  inaccuracyInfo: {
    name: 'inaccuracyInfo',
    path: '/inaccuracy/:id',
    component: InaccuracyInfoPage,
  },
  proposal: {
    name: 'proposal',
    path: '/proposal',
    component: ProposalPage,
  },
  proposalInfo: {
    name: 'proposalInfo',
    path: '/proposal/:id',
    component: ProposalInfoPage,
  },
  profile: {
    name: 'profile',
    path: '/profile/:id',
    component: ProfilePage,
  },
  control: {
    name: 'control',
    path: '/control',
    component: ControlPage,
  },
  clinicInfo: {
    name: 'clinicInfo',
    path: '/clinic-info/:id',
    component: ClinicInfoPage,
  },
  placeInfo: {
    name: 'placeInfo',
    path: '/place-info/:id',
    component: PlaceInfoPage,
  },
  petDetails: {
    name: 'petDetails',
    path: '/pet-details/:id',
    component: PetDetailsPage,
  },
  infoSection: {
    name: 'infoSection',
    path: '/info-section',
    component: InfoSectionPage,
  },
  eventsboard: {
    name: 'eventsboard',
    path: '/eventsboard',
    component: Eventsboard,
  },
  eventsboardInfo: {
    name: 'eventsboardInfo',
    path: '/eventsboard-info/:id',
    component: EventsboardInfo,
  },
  applicationManagement: {
    name: 'applicationManagement',
    path: '/functionals',
    component: ApplicationContent,
  },
  medicalDirections: {
    name: 'medicalDirections',
    path: '/medical-directions',
    component: MedicalDirections,
  },
  tags: {
    name: 'tags',
    path: '/tags',
    component: Tags,
  },
  tagsCreate: {
    name: 'tagsCreate',
    path: '/tags/create',
    component: TagsCreate,
  },
  tagsDetail: {
    name: 'tagsDetail',
    path: '/tags/detail/:id',
    component: TagsDetail,
  },
  advice: {
    name: 'advice',
    path: '/advice',
    component: Advice,
  },
  adviceCreate: {
    name: 'adviceCreate',
    path: '/advice/create',
    component: AdviceCreate,
  },
  adviceDetail: {
    name: 'adviceDetail',
    path: '/advice/detail/:id',
    component: AdviceDetail,
  },
  mail: {
    name: 'mail',
    path: '/mail',
    component: Mail,
  },
  additionalOptions: {
    name: 'additionalOptions',
    path: '/additional-options',
    component: AddtitionalOptions,
  },
  seo: {
    name: 'seo',
    path: '/seo',
    component: SEO,
  },
  specialists: {
    name: 'specialists',
    path: '/specialists',
    component: Specialists,
  },
  specialistsInfo: {
    name: 'specialistsInfo',
    path: '/specialists/:id',
    component: SpecialistInfo,
  },
  appointments: {
    name: 'appointments',
    path: '/appointments',
    component: Appointments,
  },
  appointmentsDetail: {
    name: 'appointmentsDetail',
    path: '/appointments/:id',
    component: AppointmentsDetail,
  },
}
