import { LocalizationProvider } from '@mui/lab'
import {
  Container,
  DeleteButton,
  Image,
  Label,
  StyledButton,
  StyledDropDown,
  StyledInput,
  StyledLinkDiv,
  StyledTitle,
  Title,
} from './styled'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import Dropdown from 'react-dropdown'
import { languages } from './consts'
import 'react-dropdown/style.css'
import { useTypedSelector } from '../../hooks'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { RoutesTree } from '../../router'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../components'
import { Assets } from '../../assets'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { FormInput } from './types'
import { useEffect, useMemo, useState } from 'react'
import { Service } from '../../services'
import useSWR, { useSWRConfig } from 'swr'
import _ from 'lodash'
import { ErrorMessage } from '../AdviceCreate/styled'
import { generateSuccessToast } from '../../helpers'
import { showToastAction } from '../../store'
import { useDispatch } from 'react-redux'

export const TagsDetail = () => {
  const { t } = useTranslation('tags')
  const { user, language: lang } = useTypedSelector(state => state.user)
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { mutate } = useSWRConfig()

  const { data } = useSWR(
    ['get-tag', { _id: id }],
    ([_, key]) => Service.Tag.getTag(key),
    { revalidateOnFocus: false },
  )

  const [language, setLanguage] = useState<string>(lang || 'uk')

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: {
      title: data?.title,
    },
  })
  const { fields, append } = useFieldArray({
    control,
    name: 'title',
  })

  const [title] = watch(['title'])

  const handleBack = () => history.goBack()

  const changeType = (type: string) => {
    setLanguage(type)
  }

  const setSelectedTitle = (lang: string, value: string) => {
    const existingTitleIndex = fields.findIndex(field => field.lang === lang)

    if (existingTitleIndex !== -1) {
      setValue(`title.${existingTitleIndex}.value`, value)
    } else {
      append({ value, lang })
    }
  }

  const onSubmit = async (data: FormInput) => {
    try {
      await Service.Tag.updateTag({ _id: id, data })
      lang == 'uk' &&
        dispatch(
          showToastAction.request(generateSuccessToast('Успішно оновлено')),
        )

      lang == 'en' &&
        dispatch(
          showToastAction.request(
            generateSuccessToast('Tag successfully updated'),
          ),
        )
    } catch (e) {
      console.log(e)
    }
  }

  const deleteTag = async () => {
    try {
      await Service.Tag.deleteTag({ _id: id }).finally(() => {
        mutate('ads/api/v1/admin/tags')

        history.goBack()
      })
    } catch (e) {}
  }

  const showInput = useMemo(() => {
    return fields.findIndex(field => field.lang === language) != -1
  }, [language, fields])

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  useEffect(() => {
    if (data?.title) {
      setValue('title', data.title)
    }
  }, [data])

  if (!user?.access?.tags) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <StyledTitle>
          {Assets.LEFT_ARROW_ICON ? (
            <StyledLinkDiv onClick={handleBack}>
              <Image src={Assets.LEFT_ARROW_ICON} />
            </StyledLinkDiv>
          ) : (
            ''
          )}

          <Title>{t('tag')}</Title>

          <DeleteButton onClick={deleteTag}>{t('delete')}</DeleteButton>
        </StyledTitle>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('language')}</Label>

          <Divider height={10} />

          <Dropdown
            options={languages}
            onChange={option => changeType(option.value)}
            value={language}
            placeholder="Виберите язык"
          />
        </StyledDropDown>

        <Divider height={30} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Label>
            {t('title')} - {language}
          </Label>

          <Divider height={10} />

          {showInput && (
            <Controller
              key={language}
              name={`title.${fields.findIndex(
                field => field.lang === language,
              )}.value`}
              control={control}
              rules={{
                required:
                  title.filter(item => item?.value)?.length > 0
                    ? ''
                    : t('required'),
              }}
              render={({ field: { value } }) => (
                <StyledInput
                  value={value}
                  onChange={e => setSelectedTitle(language, e.target.value)}
                  placeholder="Enter Title"
                />
              )}
            />
          )}

          {errors?.title && (
            <ErrorMessage>{errors?.title[0]?.value?.message}</ErrorMessage>
          )}

          <Divider height={30} />

          <StyledButton type="submit">{t('edit')}</StyledButton>
        </form>
      </LocalizationProvider>
    </Container>
  )
}
