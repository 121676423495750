import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  CreateCategoriesActionPayload,
  DeleteCategoriesActionPayload,
  GetCategoriesActionResponse,
  GetCategoriesPayload,
  UpdateCategoriesActionPayload,
  GetSubCategoriesPayload,
  GetSubCategoriesActionResponse,
  CreateSubCategoriesActionPayload,
  UpdateSubCategoriesActionPayload,
  DeleteSubCategoriesActionPayload
} from './types'

export const getCategoriesAdsAction = createApiActions<
  GetCategoriesPayload,
  GetCategoriesActionResponse,
  AxiosError
>(createActionTypes('CATEGORIES_ADS/GET_CATEGORIES_ADS'))

export const createCategoriesAdsAction = createApiActions<
  CreateCategoriesActionPayload,
  GetCategoriesActionResponse,
  AxiosError
>(createActionTypes('CATEGORIES_ADS/CREATE_CATEGORIES_ADS'))

export const updateCategoriesAdsAction = createApiActions<
  UpdateCategoriesActionPayload,
  GetCategoriesActionResponse,
  AxiosError
>(createActionTypes('CATEGORIES_ADS/UPDATE_CATEGORIES_ADS'))

export const deleteCategoriesAdsAction = createApiActions<
  DeleteCategoriesActionPayload,
  GetCategoriesActionResponse,
  AxiosError
>(createActionTypes('CATEGORIES_ADS/DELETE_CATEGORIES_ADS'))

export const getSubCategoriesAdsAction = createApiActions<
  GetSubCategoriesPayload,
  GetSubCategoriesActionResponse,
  AxiosError
>(createActionTypes('SUBCATEGORIES_ADS/GET_SUBCATEGORIES_ADS'))

export const createSubCategoriesAdsAction = createApiActions<
  CreateSubCategoriesActionPayload,
  GetSubCategoriesActionResponse,
  AxiosError
>(createActionTypes('SUBCATEGORIES_ADS/CREATE_SUBCATEGORIES_ADS'))

export const updateSubCategoriesAdsAction = createApiActions<
  UpdateSubCategoriesActionPayload,
  GetSubCategoriesActionResponse,
  AxiosError
>(createActionTypes('SUBCATEGORIES_ADS/UPDATE_SUBCATEGORIES_ADS'))

export const deleteSubCategoriesAdsAction = createApiActions<
  DeleteSubCategoriesActionPayload,
  GetSubCategoriesActionResponse,
  AxiosError
>(createActionTypes('SUBCATEGORIES_ADS/DELETE_SUBCATEGORIES_ADS'))