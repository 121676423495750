import { AxiosResponse } from 'axios'
import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { NOTIFICATIONS_URL } from './config'
import {
  GetNotificationsActionResponse,
  SendNotificationActionPayload,
  PayloadAuth,
} from './types'

export class NotificationsAPI extends HttpService {
  static sendNotification(
    request: PayloadAuth & SendNotificationActionPayload,
  ): Promise<AxiosResponse<GetNotificationsActionResponse>> {
    return this.request('SEND_NOTIFICATION', {
      url: NOTIFICATIONS_URL.sendNotification,
      method: 'POST',
      data: request.data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
