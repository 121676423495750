import useSWR from 'swr'
import { useTypedSelector } from '../../../../hooks'
import { useMemo } from 'react'
import ReactSelect from 'react-select'
import { getMedicalDirection } from '../../../MedicalDirections/services'
import { customStyles } from './styled'
import { Controller, useFormContext } from 'react-hook-form'
import { Divider } from '../../../../components'
import { Label } from '../../styled'
import { useTranslation } from 'react-i18next'

export const MedicalDirections = () => {
  const { control } = useFormContext()
  const { t } = useTranslation('appointments')
  const { language: lang } = useTypedSelector(state => state.user)
  const { data } = useSWR('medical-directions', getMedicalDirection, {
    revalidateOnFocus: false,
  })

  const options = useMemo(() => {
    if (!data) {
      return []
    }

    return data?.models?.map(item => ({
      label: item?.title[lang],
      value: item?._id,
    }))
  }, [data, lang])

  return (
    <>
      <Label>{t('medicalDirections')}</Label>

      <Divider height={10} />

      <Controller
        name="medicalDirections"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <ReactSelect
              value={value}
              options={options}
              styles={customStyles}
              onChange={onChange}
              placeholder={t('enterCategory')}
              isClearable
            />
          )
        }}
      />
    </>
  )
}
