import { Controller, useFormContext } from 'react-hook-form'
import { Divider } from '../../../../components'
import { Label, StyledInput } from '../../styled'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../types'

export const Executor = () => {
  const { control, watch } = useFormContext<FormInput>()
  const { t } = useTranslation('appointments')

  const executor = watch('executor')

  if (!executor) {
    return null
  }

  return (
    <>
      <Label>{t('executor')}</Label>

      <Divider height={10} />

      <Controller
        name="executor"
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <StyledInput {...field} onChange={onChange} disabled />
        )}
      />
    </>
  )
}
