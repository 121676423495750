import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  addDocumentToClinicAction,
  addPhotoToClinicAction,
  clearClinicAction,
  createClinicAction,
  deleteDocumentFromClinicAction,
  deleteImageFromClinicAction,
  getClinicByIdAction,
  getClinicsAction,
  putClinicLogoAction,
  putDataToClinicAction,
  searchClinicAction,
  updateClinicAction,
} from './actions'

const InitialState: TInitialState = {
  clinics: [],
  clinic: null,
  loading: false,
  total: 0,
}

export const clinicsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getClinicsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getClinicsAction.success, (state, { payload }) => ({
      ...state,
      clinics: payload.clinics,
      total: payload.total,
      loading: false,
    }))
    builder.addCase(getClinicsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getClinicByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getClinicByIdAction.success, (state, { payload }) => ({
      ...state,
      clinic: payload,
      loading: false,
    }))
    builder.addCase(getClinicByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(searchClinicAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(searchClinicAction.success, (state, { payload }) => ({
      ...state,
      clinics: payload.data,
      total: payload.total,
      loading: false,
    }))
    builder.addCase(searchClinicAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(createClinicAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createClinicAction.success, (state, { payload }) => ({
      ...state,
      clinic: payload,
      loading: false,
    }))
    builder.addCase(createClinicAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(updateClinicAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateClinicAction.success, (state, { payload }) => ({
      ...state,
      clinic: payload,
      loading: false,
    }))
    builder.addCase(updateClinicAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(putClinicLogoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(putClinicLogoAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(putClinicLogoAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(clearClinicAction, state => ({
      ...state,
      clinic: null,
    }))

    builder.addCase(putDataToClinicAction, (state, { payload }) => ({
      ...state,
      clinic: {
        ...state['clinic']!,
        ...payload,
      },
    }))

    builder.addCase(addPhotoToClinicAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(addPhotoToClinicAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(addPhotoToClinicAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(addDocumentToClinicAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      addDocumentToClinicAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
    builder.addCase(
      addDocumentToClinicAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(deleteImageFromClinicAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      deleteImageFromClinicAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
    builder.addCase(
      deleteImageFromClinicAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(deleteDocumentFromClinicAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      deleteDocumentFromClinicAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
    builder.addCase(
      deleteDocumentFromClinicAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
  },
)
