import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { Checkbox } from '@mui/material'
import { CheckboxContainer, Container } from './styled'
import { useTranslation } from 'react-i18next'

const statuses = [
  { id: 'searching', label: 'Searching' },
  { id: 'in-progress', label: 'In Progress' },
  { id: 'pending-executor', label: 'Pending Executor' },
  { id: 'canceled-by-owner', label: 'Canceled by Owner' },
  { id: 'rejected-by-executor', label: 'Rejected by Executor' },
  { id: 'done', label: 'Done' },
]

type StatusFilterProps = {
  selectedStatuses: string[]
  setSelectedStatuses: Dispatch<SetStateAction<string[]>>
}

export const StatusFilter = ({
  selectedStatuses,
  setSelectedStatuses,
}: StatusFilterProps) => {
  const { t } = useTranslation('appointments')

  useEffect(() => {
    localStorage.setItem('statusFilter', JSON.stringify(selectedStatuses))
  }, [selectedStatuses])

  const handleCheckboxChange = (status: string) => {
    setSelectedStatuses(prevSelectedStatuses =>
      prevSelectedStatuses.includes(status)
        ? prevSelectedStatuses.filter(s => s !== status)
        : [...prevSelectedStatuses, status],
    )
  }

  return (
    <Container>
      {statuses.map(status => (
        <CheckboxContainer key={status.id}>
          <Checkbox
            checked={selectedStatuses.includes(status.id)}
            onChange={() => handleCheckboxChange(status.id)}
            color="primary"
          />
          <p>{t(status.id)}</p>
        </CheckboxContainer>
      ))}
    </Container>
  )
}
