import useSWR from 'swr'
import { getCurrentServiceCategory } from '../../services'

export const useGetCurrentInfo = ({
  token,
  id,
}: {
  token: string | null
  id: string
}) => {
  const {
    data: currentServiceData,
    isLoading,
    error,
  } = useSWR(
    token ? ['getServiceInfo', { id, token }] : null,
    ([, params]) => getCurrentServiceCategory(params),
    { revalidateOnFocus: false },
  )
  return { currentServiceData, isLoading, error }
}
