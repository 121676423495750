import React from 'react';

import starFilled from '../../assets/icons/star-filled.png';
import stars from '../../assets/icons/star.png';

import { IStarRatingProps } from "./types";
import {
	StarRatingContainer,
	Label,
	StyledImage,
	StarImageBlock
} from "./styled";

export const StarRating = ({ rating, size = 16 }: IStarRatingProps) => {
	return (
		<StarRatingContainer>
			{[ ...Array(5)].map((star, idx) => {
				const ratingValue: number = idx + 1;
				return <Label key={idx}>
					<StarImageBlock>
						<StyledImage src={ratingValue <= rating ? starFilled : stars} width={size} height={size}/>
					</StarImageBlock>
				</Label>
			})}
		</StarRatingContainer>
	);
};