import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  StyledData,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  SaveButton,
  DeleteButton,
  StyledContainer,
  Div,
  StyledField,
  StyledUnionField,
  StyledFieldText,
  StyledNameFieldText,
  StyledDiv,
} from './styled'
import { Assets } from '../../assets'
import { TForm, TChangeManagerValidationSchema, TAccess } from './types'
import { useTypedSelector, useValidation } from '../../hooks'
import { Log } from '../../utils'
import { RoutesTree } from '../../router'
import {
  clearAdminAction,
  deleteAdminAction,
  getAdminByIdAction,
  updateAdminAction,
} from '../../store'
import { useTranslation } from 'react-i18next'

// @ts-ignore
const AdminProfile: React.FC = () => {
  const { t } = useTranslation()
  const fieldName = {
    pets: t('settings:pets'),
    customers: t('settings:users'),
    settings: t('settings:settings'),
    info: t('settings:information'),
    clinics: t('settings:clinics'),
    complaint: t('settings:complaints'),
    proposal: t('settings:suggestions'),
    inaccuracy: t('settings:inaccuracies'),
    control: t('settings:control'),
    places: t('settings:locations'),
    notifications: t('settings:notifications'),
    dashboard: t('settings:panel'),
    regions: t('settings:regions'),
    feedback: t('settings:feedback'),
    eventsboard: t('settings:trendAnalytics'),
    categories: t('settings:categories'),
    ads: t('settings:announcements'),
    'service-categories': t('sidebar:applicationContentManagement'),
    'medical-directions': t('sidebar:managementOfServicesAndServices'),
    'additional-options': t('settings:additionalOptions'),
    tags: t('sidebar:tags'),
    advice: t('sidebar:advice'),
    mail: t('sidebar:mail'),
    specialists: t('settings:specialists'),
    appointments: t('settings:appointments'),
  }

  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState<TForm>({
    name: null,
    email: null,
  })
  const [access, setAccess] = useState<TAccess>({
    pets: false,
    customers: false,
    settings: false,
    info: false,
    clinics: false,
    complaint: false,
    proposal: false,
    inaccuracy: false,
    control: false,
    places: false,
    notifications: false,
    dashboard: false,
    regions: false,
    feedback: false,
    eventsboard: true,
    categories: false,
    ads: false,
    'service-categories': false,
    'medical-directions': false,
    tags: false,
    advice: false,
    mail: false,
    seo: false,
    'additional-options': false,
    specialists: false,
    appointments: false,
  })

  const history = useHistory()

  const dispatch = useDispatch()

  const { admin } = useTypedSelector(store => store.admins)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.settings) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  const inputHandler = useCallback(
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )
  // eslint-disable-next-line
  const schema = useMemo<TChangeManagerValidationSchema>(
    () => ({
      name: {
        condition: form.name
          ? form.name.length
            ? form.name.length >= 2
            : true
          : true,
        error: t('settings:enterName'),
      },
      email: {
        condition: form.email
          ? form.email.length
            ? form.email?.includes('@')
            : true
          : true,
        error: t('settings:invalidEmailFormat'),
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
    // eslint-disable-next-line
  } = useValidation(schema)

  const choose = (key: string, value: boolean) => {
    setAccess(prev => ({ ...prev, [key]: value }))
  }

  const generateAccess = () => {
    const adminAccess = []

    for (const [key, value] of Object.entries(access)) {
      adminAccess.push(
        <StyledField key={key}>
          {/* @ts-ignore */}
          <StyledFieldText>{fieldName[key]}</StyledFieldText>

          <StyledDiv>
            <>
              <input
                name={key}
                type="radio"
                checked={value}
                onChange={() => choose(key, true)}
              />
              <Divider width={190} />
              <input
                name={key}
                type="radio"
                onChange={() => choose(key, false)}
                checked={!value}
              />
            </>
          </StyledDiv>
        </StyledField>,
      )
    }
    return adminAccess
  }

  const onPressSaveChanges = async () => {
    try {
      enableValidation()
      await validation()

      const result = window.confirm(t('settings:updateAdmin'))

      if (result) {
        dispatch(
          updateAdminAction({
            data: {
              access,
              email: form.email as string,
              name: form.name as string,
            },
            id,
            authorization: '',
          }),
        )
      }

      disableValidation()
    } catch (error) {
      Log.ruddy('Event: onPressSaveChanges')
    }
  }

  const onPressDeleteAdmin = async () => {
    try {
      const result = window.confirm(t('settings:deleteAdmin'))

      if (result) {
        dispatch(
          deleteAdminAction({
            authorization: '',
            id,
          }),
        )

        history.goBack()
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeleteAdmin')
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getAdminByIdAction.request({ id, authorization: '' }))
    return () => {
      dispatch(clearAdminAction())
    }
  }, [id])
  // eslint-disable-next-line
  useEffect(() => {
    setForm({
      name: admin?.name ?? null,
      email: admin?.email ?? null,
    })
    setAccess({
      pets: admin?.access?.pets ?? false,
      customers: admin?.access?.customers ?? false,
      settings: admin?.access?.settings ?? false,
      info: admin?.access?.info ?? false,
      clinics: admin?.access?.clinics ?? false,
      complaint: admin?.access?.complaint ?? false,
      proposal: admin?.access?.proposal ?? false,
      inaccuracy: admin?.access?.inaccuracy ?? false,
      control: admin?.access?.control ?? false,
      places: admin?.access?.places ?? false,
      notifications: admin?.access?.notifications ?? false,
      dashboard: admin?.access?.dashboard ?? false,
      regions: admin?.access?.regions ?? false,
      feedback: admin?.access?.feedback ?? false,
      eventsboard: admin?.access?.eventsboard ?? false,
      categories: admin?.access?.categories ?? false,
      ads: admin?.access?.ads ?? false,
      'service-categories': admin?.access['service-categories'] ?? false,
      'medical-directions': admin?.access['medical-directions'] ?? false,
      tags: admin?.access?.tags ?? false,
      advice: admin?.access?.advice ?? false,
      mail: admin?.access?.mail ?? false,
      seo: admin?.access?.seo ?? false,
      'additional-options': admin?.access['additional-options'] ?? false,
      specialists: admin?.access?.specialists ?? false,
      appointments: admin?.access?.appointments ?? false,
    })
  }, [admin])

  return (
    <Container>
      <StyledTitle>
        {Assets.LEFT_ARROW_ICON ? (
          <Link
            to={{
              pathname: RoutesTree.settings.path,
            }}
          >
            <Image src={Assets.LEFT_ARROW_ICON} />
          </Link>
        ) : (
          ''
        )}

        <Title>{t('settings:profile')}</Title>

        <Div> </Div>
      </StyledTitle>

      <Divider height={10} />

      <StyledData>
        <StyledEntity>
          <Input
            label={t('settings:name')}
            name="name"
            value={form.name ?? ''}
            placeholder={t('settings:enterName')}
            template={EInputTemplate.NAME}
            error={schema.name.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.name}
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label="E-mail"
            name="email"
            value={form.email ?? ''}
            placeholder={t('settings:enterEmail')}
            error={schema.email.error || ''}
            template={EInputTemplate.DEFAULT}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.email}
          />
        </StyledEntity>

        <StyledUnionField>
          <StyledField>
            <StyledFieldText>{t('settings:access')}</StyledFieldText>
            <>
              <StyledNameFieldText>{t('settings:allowed')}</StyledNameFieldText>
              <StyledNameFieldText>{t('settings:denied')}</StyledNameFieldText>
            </>
          </StyledField>
        </StyledUnionField>

        <Divider height={10} />

        <StyledUnionField>{generateAccess()}</StyledUnionField>

        <Divider height={10} />

        <StyledContainer>
          <DeleteButton onClick={onPressDeleteAdmin}>
            {t('settings:delete')}
          </DeleteButton>

          <SaveButton onClick={onPressSaveChanges}>
            {' '}
            {t('settings:save')}
          </SaveButton>
        </StyledContainer>

        <Divider height={10} />
      </StyledData>
    </Container>
  )
}

export default AdminProfile
