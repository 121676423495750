import React, { FC } from 'react'
import { HashRouter } from 'react-router-dom'
import { getUserSelector } from '../store'
import NoAuthorizated from './NoAuthorizated'
import { useTypedSelector } from '../hooks'
import { LayoutComponent } from '../pages'
import Authorizated from './Authorizated'

const Router: FC = () => {
  const { token } = useTypedSelector(getUserSelector)

  return (
    <HashRouter basename="/">
      {token ? (
        <LayoutComponent>
          <Authorizated />
        </LayoutComponent>
      ) : (
        <NoAuthorizated />
      )}
    </HashRouter>
  )
}

export default Router
