import { useState } from 'react'
import {
  CreateDirectionWindows,
  OneDirection,
  EditDirection,
} from './components'
import { CategoryChar, ServiceList } from '../ApplicationContent/styled'
import { useGetMedicalDirection } from './services'
import { Modal } from '../../components/Modal'
import { useTranslation } from 'react-i18next'

export const MedicalDirections: React.FC = () => {
  const [visibleCreateModal, setVisibleCreateModal] = useState(false)
  const [visibleEditModal, setVisibleEditModal] = useState(false)
  const [id, setId] = useState('')
  const { medicalDirections } = useGetMedicalDirection()
  const { t } = useTranslation('medicalDirection')
  const onCreateClick = () => {
    setVisibleCreateModal(prev => !prev)
  }
  return (
    <div>
      {visibleCreateModal && (
        <Modal>
          <CreateDirectionWindows
            setVisibleCreateModal={setVisibleCreateModal}
          />
        </Modal>
      )}
      {visibleEditModal && (
        <Modal>
          <EditDirection
            id={id}
            setVisibleEditModal={setVisibleEditModal}
            setId={setId}
          />
        </Modal>
      )}
      <CategoryChar>
        <div>{t('language')}</div>
        <div>{t('name')}</div>

        <button onClick={onCreateClick}>{t('create')}</button>
      </CategoryChar>
      <ServiceList>
        {medicalDirections?.models.map(item => (
          <OneDirection
            key={item._id}
            setVisibleEditModal={setVisibleEditModal}
            setId={setId}
            currentDirectional={item}
          />
        ))}
      </ServiceList>
    </div>
  )
}
