import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  StyledData,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  DeleteButton,
  Div,
} from './styled'
import { Assets } from '../../assets'
import { TForm } from './types'
import { useTypedSelector } from '../../hooks'
import { Log } from '../../utils'
import { RoutesTree } from '../../router'
import {
  clearProposalAction,
  deleteProposalAction,
  getProposalByIdAction,
} from '../../store'
import { Map } from './components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
// @ts-ignore
const ProposalInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState<TForm>({
    title: '-',
    clinic: '-',
    phone: '-',
    address: '-',
    coordinates: {
      lat: 1,
      lng: 1,
    },
  })
  const { t } = useTranslation('proposal')
  const history = useHistory()

  const dispatch = useDispatch()

  const { proposal } = useTypedSelector(store => store.feedback)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.proposal) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  const onPressDeleteProposal = async () => {
    try {
      const result = window.confirm(t('deleteOffer'))

      if (result) {
        dispatch(
          deleteProposalAction({
            authorization: '',
            id,
          }),
        )

        history.goBack()
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeleteProposal')
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getProposalByIdAction.request({ id }))
    return () => {
      dispatch(clearProposalAction())
    }
  }, [id])
  // eslint-disable-next-line
  useEffect(() => {
    setForm({
      title: !!proposal?.title ? proposal?.title : '-',
      clinic: !!proposal?.clinic ? proposal?.clinic : '-',
      phone: !!proposal?.phone ? proposal?.phone : '-',
      address: !!proposal?.address ? proposal?.address : '-',
      coordinates: {
        lat: proposal?.coordinates?.lat ?? 1,
        lng: proposal?.coordinates?.lng ?? 1,
      },
    })
  }, [proposal])

  return (
    <Container>
      <StyledTitle>
        {Assets.LEFT_ARROW_ICON ? (
          <Link
            to={{
              pathname: RoutesTree.proposal.path,
            }}
          >
            <Image src={Assets.LEFT_ARROW_ICON} />
          </Link>
        ) : (
          ''
        )}

        <Title>{t('offer')}</Title>

        <Div> </Div>
      </StyledTitle>

      <Divider height={10} />

      <StyledData>
        <StyledEntity>
          <Input
            label={t('clinicName')}
            name="title"
            value={form.title ?? '-'}
            template={EInputTemplate.DEFAULT}
            onChange={() => {}}
            disabled
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label={t('clinicCategories')}
            name="clinic"
            value={form.clinic ?? '-'}
            template={EInputTemplate.DEFAULT}
            onChange={() => {}}
            disabled
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label={t('clinicPhone')}
            name="phone"
            value={form.phone ?? '-'}
            template={EInputTemplate.DEFAULT}
            onChange={() => {}}
            disabled
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label={t('clinicAddress')}
            name="address"
            value={form.address ?? '-'}
            template={EInputTemplate.DEFAULT}
            onChange={() => {}}
            disabled
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label={t('creationDateTime')}
            name="date"
            value={moment(proposal?.createdAt ?? '-').format(
              'HH:mm DD.MM.YYYY',
            )}
            template={EInputTemplate.DEFAULT}
            onChange={() => {}}
            disabled
          />
        </StyledEntity>

        <Divider height={10} />

        <Map coordinates={form.coordinates} />

        <Divider height={10} />

        <DeleteButton onClick={onPressDeleteProposal}>
          {t('delete')}
        </DeleteButton>
        <Divider height={10} />
      </StyledData>
    </Container>
  )
}

export default ProposalInfo
