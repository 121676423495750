import styled from 'styled-components'
import { FLEX } from '../../utils'
import { COLORS } from '../../assets'
export const Container = styled.div`
  width: 100%;
  height: 100%;

  ${FLEX({ justify: 'space-between', direction: 'column', wrap: 'nowrap' })}
`
export const ServiceList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
  width: 100%;
`
export const CategoryChar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 20px 32px;
  div {
    &:nth-child(1) {
      width: 30px;
    }
    &:nth-child(2) {
      width: 250px;
    }
    &:nth-child(3) {
      width: 150px;
    }
  }
  button {
    border: none;
    outline: none;
    border-radius: 6px;
    background-color: ${COLORS.gray};
    cursor: pointer;
    padding: 8px 10px;
  }
`
