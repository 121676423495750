import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  GetControlActionResponse,
  UpdateControlActionPayload,
  UpdateDefaultPermissionsActionPayload,
  UpdateRegionActionPayload,
} from './types'

export const getControlAction = createApiActions<
  void,
  GetControlActionResponse,
  AxiosError
>(createActionTypes('CONTROL/GET_CONTROL'))

export const updateControlAction = createApiActions<
  UpdateControlActionPayload,
  GetControlActionResponse,
  AxiosError
>(createActionTypes('CONTROL/UPDATE_CONTROL'))

export const updateRegionAction = createApiActions<
  UpdateRegionActionPayload,
  GetControlActionResponse,
  AxiosError
>(createActionTypes('CONTROL/UPDATE_REGION'))

export const updateDefaultPermissionsAction = createApiActions<
  UpdateDefaultPermissionsActionPayload,
  GetControlActionResponse,
  AxiosError
>(createActionTypes('CONTROL/UPDATE_DEFAULT_PERMISSIONS'))
