import { AxiosResponse } from 'axios'
import {
  DeletePlaceActionPayload,
  DeleteImageFromPlaceActionPayload,
  GetPlaceByIdActionResponse,
  GetPlacesActionResponse,
  GetPlacesPayload,
  PayloadSearchById,
  putPlaceLogoActionPayload,
  SearchPlaceActionPayload,
} from './types'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'
import { PLACES_URL } from './config'

export class PlacesAPI extends HttpService {
  static getPlaces(
    request: GetPlacesPayload & PayloadAuth,
  ): Promise<AxiosResponse<GetPlacesActionResponse>> {
    return this.request<GetPlacesActionResponse>('GET_PLACES', {
      url: PLACES_URL.getPlaces(request.page, request.limit, request.search, request.sortBy),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createPlace({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<GetPlacesActionResponse>> {
    return this.request<GetPlacesActionResponse>('CREATE_PLACE', {
      url: PLACES_URL.createPlace,
      method: 'POST',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updatePlace({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<GetPlacesActionResponse>> {
    return this.request<GetPlacesActionResponse>('UPDATE_PLACE', {
      url: PLACES_URL.updatePlace(id),
      method: 'PUT',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static putPlaceLogo({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<GetPlacesActionResponse>> {
    return this.request<GetPlacesActionResponse>('PUT_PLACE_LOGO', {
      url: PLACES_URL.putPlaceLogo(id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static addImageToPlace({
    authorization,
    data,
    id,
  }: PayloadAuth & putPlaceLogoActionPayload): Promise<
    AxiosResponse<GetPlacesActionResponse>
  > {
    return this.request<GetPlacesActionResponse>('ADD_PHOTO_TO_PLACE', {
      url: PLACES_URL.addImageToPlace(id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static addDocumentToPlace({
    authorization,
    data,
    id,
  }: PayloadAuth & putPlaceLogoActionPayload): Promise<
    AxiosResponse<GetPlacesActionResponse>
  > {
    return this.request<GetPlacesActionResponse>('ADD_DOCUMENT_TO_PLACE', {
      url: PLACES_URL.addDocumentToPlace(id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteImageFromPlace({
    authorization,
    image,
    id,
  }: PayloadAuth & DeleteImageFromPlaceActionPayload): Promise<
    AxiosResponse<GetPlacesActionResponse>
  > {
    return this.request<GetPlacesActionResponse>('DELETE_IMAGE_FROM_PLACE', {
      url: PLACES_URL.deleteImageFromPlace(id, image),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteDocumentFromPlace({
    authorization,
    image,
    id,
  }: PayloadAuth & DeleteImageFromPlaceActionPayload): Promise<
    AxiosResponse<GetPlacesActionResponse>
  > {
    return this.request<GetPlacesActionResponse>(
      'DELETE_DOCUMENT_FROM_PLACE',
      {
        url: PLACES_URL.deleteDocumentFromPlace(id, image),
        method: 'DELETE',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static searchPlace(
    request: PayloadAuth & SearchPlaceActionPayload,
  ): Promise<AxiosResponse<GetPlacesActionResponse>> {
    return this.request<GetPlacesActionResponse>('SEARCH_PLACE', {
      url: PLACES_URL.searchPlace(request.limit, request.page, request.title),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getPlaceById(
    request: PayloadSearchById,
  ): Promise<AxiosResponse<GetPlaceByIdActionResponse>> {
    return this.request<GetPlaceByIdActionResponse>('GET_PLACE_BY_ID', {
      url: PLACES_URL.getPlaceById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static deletePlace(
    request: DeletePlaceActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_PLACE', {
      url: PLACES_URL.deletePlace(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
