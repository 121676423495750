import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { Coord, MapProps, TAddress } from '../types'
import {
  containerStyle,
  StyledAutocomplete,
  StyledInputWrapper,
  StyledText,
  Error,
} from '../styled'
import Geocode from 'react-geocode'
import { Divider } from '../../../components'
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete'
import { lib } from '../../../consts'
import { t } from 'i18next'
import { useTypedSelector } from '../../../hooks'

// eslint-disable-next-line react/prop-types
const Map: React.FC<MapProps> = ({
  form,
  setForm,
  schema,
  validationSchema,
  isEnabled,
}) => {
  const { language } = useTypedSelector(state => state.user)
  const [center, setCenter] = useState<Coord>({
    lat: 50,
    lng: 30,
  })
  const [tempAddress, setTempAddress] = useState<string>(form.address.ru)

  Geocode.setLanguage(language)
  Geocode.setApiKey('AIzaSyDiVh218waTaUJqMExezt3uALFphAmjwsM')
  Geocode.setRegion(language)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDiVh218waTaUJqMExezt3uALFphAmjwsM',
    libraries: lib as any,
  })

  const getCityCoordinates = (city: string) => {
    geocodeByAddress(city)
      .then(results => {
        return getLatLng(results[0])
      })
      .then(resCoordinates => {
        setForm(prev => ({
          ...prev,
          //@ts-ignore
          cityLog: { lat: resCoordinates.lat, lng: resCoordinates.lng },
        }))
      })
      .catch(error => console.error(error))
  }

  const getElement = (results: any[], field: string) => {
    for (let i = 0; i < results.length; i++) {
      if (
        !!results[i].address_components.find((el: any) =>
          el.types.includes(field),
        ).long_name
      ) {
        return results[i].address_components.find((el: any) =>
          el.types.includes(field),
        ).long_name
      }
    }
  }

  const showMarkerPosition = (coordinates: any) => {
    const { latLng } = coordinates
    const lat = latLng.lat()
    const lng = latLng.lng()

    Geocode.fromLatLng(lat.toString(), lng.toString())
      .then(res => {
        const city = getElement(res.results, 'locality')

        const country = getElement(res.results, 'country')

        getCityCoordinates(city)

        // eslint-disable-next-line
        setForm(prev => ({
          ...prev,
          address: {
            ru: res.results[0].formatted_address,
            en: res.results[0].formatted_address,
            uk: res.results[0].formatted_address,
          },
          city: {
            ru: city,
            en: city,
            uk: city,
          },
          country: {
            ru: country,
            en: country,
            uk: country,
          },
          coordinates: { lat, lng },
        }))
      })
      .catch(e => console.log(e))
  }

  const addressPicker = (addressObject: TAddress) => {
    if (addressObject.hasOwnProperty('name')) {
      geocodeByAddress(addressObject.name as string)
        .then(results => {
          const city = getElement(results, 'locality')

          const country = getElement(results, 'country')

          getCityCoordinates(city)

          setForm(prev => ({
            ...prev,
            address: {
              ru: results[0].formatted_address,
              en: results[0].formatted_address,
              uk: results[0].formatted_address,
            },
            city: {
              ru: city,
              en: city,
              uk: city,
            },
            country: {
              ru: country,
              en: country,
              uk: country,
            },
          }))
          return getLatLng(results[0])
        })
        .then(resCoordinates => {
          setForm(prev => ({
            ...prev,
            //@ts-ignore
            coordinates: resCoordinates,
          }))
        })
        .catch(error => console.error(error))
    } else {
      geocodeByPlaceId(addressObject.place_id as string)
        .then(results => {
          const city = getElement(results, 'locality')

          const country = getElement(results, 'country')

          getCityCoordinates(city)

          setForm(prev => ({
            ...prev,
            address: {
              ru: results[0].formatted_address,
              en: results[0].formatted_address,
              uk: results[0].formatted_address,
            },
            city: {
              ru: city,
              en: city,
              uk: city,
            },
            country: {
              ru: country,
              en: country,
              uk: country,
            },
          }))
          return getLatLng(results[0])
        })
        .then(resCoordinates => {
          setForm(prev => ({
            ...prev,
            //@ts-ignore
            coordinates: resCoordinates,
          }))
        })
        .catch(error => console.error(error))
    }
  }

  useEffect(() => {
    if (!!form.coordinates.lat && !!form.coordinates.lng) {
      setCenter(form.coordinates)
    }
  }, [form.coordinates])

  useEffect(() => {
    setTempAddress(form.address.uk)
  }, [form.address])
  console.log(tempAddress)

  return isLoaded ? (
    <>
      <StyledInputWrapper>
        <StyledText>{t('clinics:location')}</StyledText>

        <Divider height={10} />

        <StyledAutocomplete
          options={{
            types: ['address'],
          }}
          //@ts-ignore
          value={tempAddress}
          language={language}
          onPlaceSelected={place => addressPicker(place)}
          //@ts-ignore
          onChange={place => setTempAddress(place.target.value)}
        />

        <Divider height={5} />

        <Error>
          {isEnabled && !validationSchema.address
            ? schema.address.error || ''
            : ''}
        </Error>
      </StyledInputWrapper>
      <Divider height={10} />
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
        <Marker position={center} draggable onDragEnd={showMarkerPosition} />
      </GoogleMap>
    </>
  ) : (
    <></>
  )
}

export default React.memo(Map)
