import { TagsInput } from 'react-tag-input-component'
import { Divider } from '../../../../components'
import { Label, StyledDropDown, StyledTextarea } from '../../styled'
import { useTranslation } from 'react-i18next'
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormHandleSubmit,
} from 'react-hook-form'
import { FormInput } from '../../types'
import { useMemo } from 'react'

type MetaProps = {
  metaKeywords: string[]
  onMetaKeywordsChange: (tags: string[]) => void
  language: string
  control: Control<FormInput>
  metadescriptionFields: FieldArrayWithId<FormInput, 'metadescription', 'id'>[]
  setSelectedMetadescription: (lang: string, value: string) => void
  handleSubmit: UseFormHandleSubmit<FormInput>
  onSubmit: (data: FormInput) => Promise<void>
}

export const Meta = ({
  metaKeywords,
  onMetaKeywordsChange,
  setSelectedMetadescription,
  language,
  control,
  metadescriptionFields,
  handleSubmit,
  onSubmit,
}: MetaProps) => {
  const { t } = useTranslation('advice')

  const showMeta = useMemo(() => {
    return metadescriptionFields.length != -1
  }, [metadescriptionFields])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledDropDown>
        <Label>{t('metakeywords')}</Label>

        <Divider height={10} />

        <TagsInput
          value={metaKeywords}
          onChange={onMetaKeywordsChange}
          placeHolder="Enter keywords"
        />
      </StyledDropDown>

      <Divider height={30} />

      <StyledDropDown>
        <Label>{t('metadescription')}</Label>

        <Divider height={10} />

        {showMeta && (
          <Controller
            key={`metadescription-${language}`}
            name={`metadescription.${metadescriptionFields.findIndex(
              field => field.lang === language,
            )}.value`}
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <StyledTextarea
                {...field}
                onChange={e =>
                  setSelectedMetadescription(language, e.target.value)
                }
                placeholder="Enter meta description"
              />
            )}
          />
        )}
      </StyledDropDown>
    </form>
  )
}
