import { serviceInstance } from '../http.service'
import { GetCategoriesRequest, GetCategoryRequest } from './types'

export class Category {
  static async getCategories(
    params: GetCategoriesRequest['payload'],
  ): Promise<GetCategoriesRequest['response']> {
    const response = await serviceInstance.get(
      'ads/api/v1/admin/ads-categories',
      { params },
    )

    return response.data
  }

  static async getCategory({
    _id,
  }: GetCategoryRequest['payload']): Promise<GetCategoryRequest['response']> {
    const response = await serviceInstance.get(
      `ads/api/v1/admin/ads-categories/${_id}`,
    )

    return response.data
  }
}
