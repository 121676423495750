import { AxiosResponse } from 'axios'
import {
  GetPetsActionResponse,
  GetOwnPetsActionPayload,
  GetPetByIdActionResponse,
  GetPetByIdActionPayload,
  UpdatePetActionPayload,
  DeletePetActionPayload,
  GetPetsActionPayload,
} from './types'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'
import { PETS_URL } from './config'

export class PetsAPI extends HttpService {
  static getPets(
    request: PayloadAuth & GetPetsActionPayload,
  ): Promise<AxiosResponse<GetPetsActionResponse>> {
    return this.request<GetPetsActionResponse>('GET_PETS', {
      url: PETS_URL.get_pets(
        request.page,
        request.limit,
        request.search,
        request.sortBy,
      ),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updatePet(
    request: UpdatePetActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('UPDATE_PET', {
      url: PETS_URL.updatePet(request.id),
      method: 'PUT',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static deletePet(
    request: DeletePetActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_PET', {
      url: PETS_URL.deletePet(request.userId, request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getPetById(
    request: GetPetByIdActionPayload,
  ): Promise<AxiosResponse<GetPetByIdActionResponse>> {
    return this.request<GetPetByIdActionResponse>('GET_PETS_BY_ID', {
      url: PETS_URL.getPetById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getOwnPets(
    request: GetOwnPetsActionPayload,
  ): Promise<AxiosResponse<GetPetByIdActionResponse>> {
    return this.request<GetPetByIdActionResponse>('GET_OWN_PETS', {
      url: PETS_URL.getOwnPets(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
