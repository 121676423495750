import { LocalizationProvider } from '@mui/lab'
import {
  AdviceImage,
  CheckboxContainer,
  CheckboxImage,
  ClearImage,
  Container,
  DeleteButton,
  Image,
  ImageContainer,
  Label,
  StyledDropDown,
  StyledInputPhoto,
  StyledLabel,
  StyledLinkDiv,
  StyledSearch,
  StyledTitle,
  Title,
} from './styled'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import Dropdown from 'react-dropdown'
import { languages } from './consts'
import 'react-dropdown/style.css'
import { useDebounceEffect, useTypedSelector } from '../../hooks'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { RoutesTree } from '../../router'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../components'
import { Assets } from '../../assets'
import { Controller, useForm } from 'react-hook-form'
import { FormInput } from './types'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Service } from '../../services'
import { Form, Meta } from './components'
import useSWR from 'swr'
import { useTranslate } from '../../hooks/useTranslate'
import _ from 'lodash'
import { api } from '../../store'
import Select from 'react-select'
import { ErrorMessage } from '../AdviceCreate/styled'
import { Hooks } from './hooks'
import { Types } from '../../types'
import { Loader } from '../../contexts'

export const AdviceDetail = () => {
  const { t } = useTranslation('advice')
  const { id } = useParams<{ id: string }>()
  const { translate } = useTranslate()
  const { user, language: lang } = useTypedSelector(state => state.user)
  const history = useHistory()

  const [language, setLanguage] = useState<string>(lang || 'en')
  const [file, setFile] = useState<File | null>(null)
  const [removeImage, setRemoveImage] = useState(false)
  const [metakeywords, setMetaKeywords] = useState<string[]>([])
  const [resultKeywords, setResultKeywords] = useState<Types.Advice.Title[]>([])
  const [primaryLanguage, setPrimaryLanguage] = useState<string>('')
  const [refreshed, setRefreshed] = useState(false)

  const { data } = useSWR(['get-advice', { _id: id }], ([_, key]) =>
    Service.Advice.getAdvice(key),
  )

  const { data: tags } = useSWR(
    ['get-tags', {}],
    ([_, key]) => Service.Tag.getTags(key),
    { revalidateOnFocus: false },
  )

  const { data: categories } = useSWR(
    ['get-categories', { limit: 100 }],
    ([_, key]) => Service.Category.getCategories(key),
    { revalidateOnFocus: false },
  )

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    resetField,
  } = useForm<FormInput>({
    defaultValues: {
      image: data?.images,
      tags: data?.tags?.map(item => ({
        value: item?._id,
        label: translate(item?.title) ?? '',
      })),
      category: data?.category?._id ?? '',
      subcategory: data?.subcategory?._id ?? '',
    },
  })

  const [title, description, tgs, category, subcategory, image] = watch([
    'title',
    'description',
    'tags',
    'category',
    'subcategory',
    'image',
  ])

  const { data: subcategories } = useSWR(
    category
      ? [
          'get-subcategories',
          {
            ...(category ? { category, limit: 100 } : {}),
          },
        ]
      : null,
    ([_, key]) => Service.Subcategory.getSubcategories(key),
    { revalidateOnFocus: false },
  )

  const {
    titleFields,
    descriptionFields,
    metadescriptionFields,
    updateDescription,
    updateTitle,
    updateMetaDescription,
    setSelectedTitle,
    setSelectedDescription,
    setSelectedMetadescription,
  } = Hooks.useFieldArraySelectors({ control, setValue })

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    const file = e?.target?.files[0]

    setFile(file)

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.onload = () => {
        setValue('image', reader.result as string) // Set image data as base64
      }
      reader.readAsDataURL(file)
    }
  }

  const handleBack = () => {
    history.goBack()
  }

  const changeType = (type: string) => {
    setLanguage(type)
  }

  const changePrimary = (type: string) => {
    setPrimaryLanguage(type)
  }

  const onSubmit = async (formData: FormInput) => {
    try {
      const uploadData = new FormData()

      formData.title.map(item => {
        const titles = data?.title?.map(item => item.value)

        if (!titles?.includes(item.value)) {
          item.value && uploadData.append('title[]', JSON.stringify(item))
        }
      })
      formData.description.map(item => {
        const description = data?.description.map(item => item?.value)

        if (!description?.includes(item?.value)) {
          item.value && uploadData.append('description[]', JSON.stringify(item))
        }
      })
      formData?.metadescription?.map(item => {
        const metadescription = data?.metaDescription?.map(item => item?.value)

        if (!metadescription?.includes(item?.value)) {
          uploadData.append('metaDescription[]', JSON.stringify(item))
        }
      })

      resultKeywords &&
        resultKeywords.map(
          item =>
            item?.value &&
            uploadData.append('metaKeywords[]', JSON.stringify(item)),
        )

      file && uploadData.append('images', file as Blob)

      formData.tags.map(
        item => item.value && uploadData.append('tags[]', item.value),
      )
      uploadData.append('category', formData.category)
      uploadData.append('subcategory', formData.subcategory)
      primaryLanguage && uploadData.append('primaryLang', primaryLanguage)

      await Service.Advice.updateAdvice({ _id: id, data: uploadData })
      handleBack()
    } catch (e) {
      console.log(e)
    }
  }

  const deleteAdvice = async () => {
    try {
      await Service.Advice.deleteAdvice({ _id: id })
      handleBack()
    } catch (error) {
      console.log(error)
    }
  }

  const onMetaKeywordsChange = (newTags: string[]) => {
    // Assuming you have a way to get language info for each tag
    const updatedTags = newTags?.map(tag => {
      // Find the tag in the existing state to get its language
      const existingTag = resultKeywords.find(t => t?.value === tag)
      return {
        value: tag,
        lang: existingTag ? existingTag?.lang : lang,
      }
    })

    setResultKeywords(updatedTags)
  }

  const showMeta = useMemo(() => {
    const dataMeta = data?.metaDescription?.map(item => ({
      value: item?.value,
      lang: item?.lang,
    }))
    const fieldMeta = metadescriptionFields?.map(item => ({
      value: item?.value,
      lang: item?.lang,
    }))

    if (_.isEqual(dataMeta, fieldMeta) || dataMeta?.length == 0) {
      return true
    }

    return false
  }, [data, metadescriptionFields])

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  useEffect(() => {
    if (_.isEmpty(data)) {
      return
    }

    setValue('title', data?.title)
    setValue('description', data?.description)
    setValue('metadescription', data?.metaDescription)
    setValue('category', data?.category?._id)
    setValue('subcategory', data?.subcategory?._id)
    setValue(
      'tags',
      data?.tags.map(item => ({
        value: item._id,
        label: translate(item.title) ?? '',
      })),
    )
    setValue('image', data?.images ?? '')
    data?.metaKeywords &&
      setMetaKeywords(data?.metaKeywords?.map(item => item?.value))
    data?.primaryLang && setPrimaryLanguage(data?.primaryLang)
  }, [data])

  useDebounceEffect(
    () => {
      data?.title?.map((item, index) => updateTitle(index, item))
      data?.description?.map((item, index) => updateDescription(index, item))

      setRefreshed(true)
    },
    500,
    [data],
  )

  if (!user?.access?.advice) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  if (!refreshed) {
    return <Loader isLoading={!refreshed} />
  }

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <StyledTitle>
          {Assets.LEFT_ARROW_ICON ? (
            <StyledLinkDiv onClick={handleBack}>
              <Image src={Assets.LEFT_ARROW_ICON} />
            </StyledLinkDiv>
          ) : (
            ''
          )}

          <Title>{t('advice')}</Title>

          <DeleteButton onClick={deleteAdvice}>{t('delete')}</DeleteButton>
        </StyledTitle>

        <ImageContainer>
          {_.isEmpty(watch('image')) && (
            <img src={Assets.DOWNLOAD_ICON} width={100} height={100} />
          )}

          {(_.isEmpty(data?.images) || removeImage || file) && (
            <AdviceImage src={watch('image')} />
          )}
          {!file && !_.isEmpty(data?.images) && !removeImage && (
            <AdviceImage src={api.withImageAPI + '/advice/' + data?.images} />
          )}

          <StyledSearch>
            <StyledLabel htmlFor="photo">
              <Image src={Assets.DOWNLOAD_ARROW_ICON} />
            </StyledLabel>

            <Controller
              key={language}
              name="image"
              control={control}
              rules={{ required: _.isEmpty(image) ? t('required') : '' }}
              render={({ field: { onChange } }) => (
                <StyledInputPhoto
                  id="photo"
                  name="photo"
                  type="file"
                  value=""
                  accept="image/*"
                  onChange={e => {
                    onImageChange(e)
                  }}
                />
              )}
            />

            <ClearImage
              onClick={() => {
                setRemoveImage(true)
                setValue('image', '')
              }}
              src={Assets.CYAN_ICON}
            />
          </StyledSearch>

          <ErrorMessage>{errors?.image?.message}</ErrorMessage>
        </ImageContainer>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('language')}</Label>

          <Divider height={10} />

          <Dropdown
            options={languages}
            onChange={option => changeType(option.value)}
            value={language}
            placeholder="Виберите язык"
          />
        </StyledDropDown>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('primary.language')}</Label>

          <Divider height={10} />

          <Dropdown
            options={languages}
            onChange={option => changePrimary(option.value)}
            value={primaryLanguage}
            placeholder="Виберите язык"
          />
        </StyledDropDown>

        <Divider height={30} />

        {showMeta && (
          <Meta
            metaKeywords={metakeywords}
            onMetaKeywordsChange={onMetaKeywordsChange}
            language={language}
            control={control}
            metadescriptionFields={metadescriptionFields}
            setSelectedMetadescription={setSelectedMetadescription}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        )}

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('tags')}</Label>

          <Divider height={10} />

          {tags && (
            <Controller
              name="tags"
              control={control}
              rules={{ required: _.isEmpty(tgs) ? t('required') : '' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={tags?.models.map(tag => ({
                    value: tag?._id,
                    label: translate(tag.title) ?? '',
                  }))}
                  onChange={(option, action) => onChange(option)}
                  value={value}
                  placeholder="Виберите тег"
                  isMulti
                />
              )}
            />
          )}

          <ErrorMessage>{errors?.tags?.message}</ErrorMessage>
        </StyledDropDown>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('category')}</Label>

          <Divider height={10} />

          {categories && (
            <Controller
              name="category"
              control={control}
              rules={{ required: _.isEmpty(category) ? t('required') : '' }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  options={categories?.models.map(category => ({
                    value: category?._id,
                    label: translate(category.title),
                  }))}
                  onChange={e => {
                    resetField('subcategory')
                    onChange(e.value)
                  }}
                  value={value}
                  placeholder="Виберите категорію"
                />
              )}
            />
          )}

          <ErrorMessage>{errors?.category?.message}</ErrorMessage>
        </StyledDropDown>

        <Divider height={30} />

        {!_.isEmpty(subcategories?.models) && subcategories && (
          <StyledDropDown>
            <Label>{t('subcategory')}</Label>

            <Divider height={10} />

            <Controller
              name="subcategory"
              control={control}
              rules={{ required: _.isEmpty(subcategory) ? t('required') : '' }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  options={subcategories?.models?.map(subcategory => ({
                    value: subcategory?._id,
                    label: translate(subcategory.title),
                  }))}
                  onChange={e => onChange(e.value)}
                  value={value}
                  placeholder="Виберите підкатегорію"
                />
              )}
            />

            <ErrorMessage>{errors?.subcategory?.message}</ErrorMessage>
          </StyledDropDown>
        )}

        <Divider height={30} />

        {refreshed && (
          <Form
            language={language}
            titleFields={titleFields}
            descriptionFields={descriptionFields}
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            setSelectedDescription={setSelectedDescription}
            setSelectedTitle={setSelectedTitle}
            title={title}
            description={description}
            errors={errors}
            _id={id}
          />
        )}
      </LocalizationProvider>
    </Container>
  )
}
