import { api } from '../config'

export const CLINICS_CATEGORY_URL = {
  getClinicsCategory: (page: number, limit: number) =>
    `${api.withAPI}service/api/v1/admin/service-categories/?page=${page}&limit=${limit}`,
  createClinicsCategory: `${api.withAPI}service/api/v1/admin/service-categories`,
  updateClinicsCategory: (id: string) =>
    `${api.withAPI}service/api/v1/admin/service-categories/${id}`,
  deleteClinicsCategory: (id: string) =>
    `${api.withAPI}service/api/v1/admin/service-categories/${id}`,
}
