import styled from 'styled-components'
import { COLORS } from '../../assets'
import { FLEX, FONT } from '../../utils'
import { Link } from 'react-router-dom'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Autocomplete from 'react-google-autocomplete'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledContainer = styled.div`
  width: 100%;

  ${FLEX({ align: 'flex-start', justify: 'space-around' })}
`

export const BottomDiv = styled.div`
  height: 10px;
`

export const StyledData = styled.div`
  width: 100%;

  ${FLEX({ align: 'center', direction: 'column' })}
`

export const ButtonContainer = styled.div`
  width: 300px;
  margin-bottom: 20px;
`

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;

  padding: 10px 15px;

  background: ${COLORS.gray};

  border: 2px solid ${COLORS.gray};

  &:focus {
    outline: none !important;
    border: 2px solid ${COLORS.green} !important;
    box-sizing: border-box;
    background: ${COLORS.white};
  }

  &::placeholder {
    ${FONT({
      size: '14px',
      weight: '700',
      color: '',
    })}
  }
`

export const Error = styled.span`
  width: 100%;
  height: 5px;

  ${FONT({
    size: '12px',
    weight: '700',
    align: 'right',
    color: COLORS.red,
  })}
`

export const StyledAllergiesContainer = styled.div`
  width: 100%;

  ${FLEX({ align: 'center', direction: 'column' })}
`

export const StyledVaccinesContainer = styled.div`
  width: 100%;

  ${FLEX({
    align: 'flex-start',
    justify: 'space-between',
    direction: 'column',
  })}
`

export const FullWidth = styled.div`
  width: 100%;

  ${FLEX({ direction: 'row', wrap: 'nowrap', justify: 'flex-start' })}
`

export const StyledFullName = styled.div`
  width: 100%;

  ${FLEX({ justify: 'space-between', wrap: 'nowrap' })}
`

export const StyledTitle = styled.div`
  ${FLEX({ align: 'flex-start', justify: 'space-between' })}
`

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const ProfileImage = styled.img`
  cursor: pointer;
  width: 400px;
  border-radius: 8px;
`

export const ProfileInput = styled.input`
  cursor: pointer;
`

export const CheckboxImage = styled.img`
  cursor: pointer;

  margin-right: 10px;
`

export const ContainerAccordion = styled.div`
  ${FLEX({
    direction: 'row',
    wrap: 'nowrap',
    justify: 'flex-start',
    align: 'center',
  })}

  margin-bottom: 10px;
`

export const StyledAccBody = styled.div`
  ${FLEX({ direction: 'column', wrap: 'nowrap', align: 'flex-start' })}
`

export const StyledPhotoFields = styled.div`
  ${FLEX({ direction: 'column', wrap: 'nowrap', justify: 'center' })}
`

export const StyledPhoneNumber = styled.div`
  ${FLEX({ direction: 'row', wrap: 'nowrap', justify: 'space-between' })}

  &:hover {
    background: ${COLORS.gray};
  }
`

export const StyledDeleteButton = styled.div`
  cursor: pointer;

  &:hover {
    color: ${COLORS.red};
  }
`

export const AddButton = styled.div`
  cursor: pointer;

  &:hover {
    color: ${COLORS.green};
  }
`

export const StyledTitleText = styled.div`
  ${FONT({ size: '20px', weight: '600', color: COLORS.black })}
`

export const StyledInputWrapper = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })}
  max-width: 100%;
  width: 100%;
`

export const StyledPhotoText = styled.div`
  width: 100%;
  ${FLEX({})}
  ${FONT({ size: '16px', color: COLORS.lightBlue, weight: '700' })}

  &:hover {
    color: ${COLORS.red};
  }
`

export const StyledInputPhoto = styled.input`
  visibility: hidden;

  position: fixed;

  cursor: pointer;

  outline: none;
`

export const StyledItemsInputPhoto = styled.input`
  cursor: pointer;

  outline: none;
`

export const PhotoImage = styled.img`
  height: 150px;

  margin-left: auto;
  margin-right: auto;

  ${FLEX({})}
`

export const StyledUrlImage = styled.a`
  text-decoration: none;
`

export const Url = styled.a`
  text-decoration: none;
  width: 150px;
  height: 150px;

  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  /* border-radius: 50%; */

  margin-left: auto;
  margin-right: auto;

  ${FLEX({ justify: 'flex-start' })}
`

export const containerStyle = {
  width: '100%',
  height: '32vh',
}

export const StyledDiv = styled.div`
  padding: 10px 20px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  margin-right: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })};
`

export const StyledCarousel = styled.div`
  width: 100%;

  /* padding: 0 200px; */

  ${FLEX({ wrap: 'nowrap', direction: 'row', justify: 'flex-start' })}

  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 200px;

  padding-left: 15px;
  padding-top: 10px;

  resize: vertical;

  background: ${COLORS.gray};

  border: 2px solid ${COLORS.gray};

  &:focus {
    outline: none !important;
    border: 2px solid ${COLORS.green} !important;
    box-sizing: border-box;
    background: ${COLORS.white};
  }

  &::placeholder {
    ${FONT({
      size: '14px',
      weight: '700',
      color: '',
    })}
  }
`

export const Label = styled.span`
  width: 100%;

  ${FONT({
    size: '14px',
    weight: '700',
    align: 'left',
    color: COLORS.lightBlue,
  })}
`

export const StyledProfileImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 150px;

  ${FLEX({})}
`

export const ImageContainer = styled.img`
  width: 110px;
  height: 120px;

  border-radius: 4px;
`

export const SuccessText = styled.p`
  ${FONT({
    family: 'Montserrat',
    size: '16px',
    color: COLORS.green,
    align: 'center',
  })}
`

export const FailedText = styled.p`
  ${FONT({
    family: 'Montserrat',
    size: '16px',
    color: COLORS.red,
    align: 'center',
  })}
`

export const StyledSearch = styled.div`
  ${FLEX({ direction: 'row' })}
`

export const StyledLabel = styled.label`
  ${FONT({
    size: '18px',
    weight: '400',
    color: COLORS.lightBlue,
  })}

  cursor: pointer;
`

export const ClearImage = styled(Image)`
  cursor: pointer;
`

export const StyledField = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })}
`

export const StyledEntity = styled.div`
  width: 100%;

  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledCategory = styled.div`
  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const Title = styled.h1`
  ${FONT({ size: '32px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledText = styled.div`
  ${FONT({ size: '14px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledRedText = styled.div`
  ${FONT({ size: '14px', color: COLORS.red, weight: '700' })}
`

export const StyledSort = styled.div`
  width: 100%;

  ${FLEX({ justify: 'flex-start' })}
`

export const StyledDropDown = styled.div`
  width: 270px;
`

export const Message = styled.h1`
  ${FLEX({})}

  ${FONT({ size: '24px', color: COLORS.lightBlue, weight: '700' })}
`

export const SaveButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.green};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const HistoryButton = styled(Link)`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.green};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const Div = styled.div`
  width: 31px;
  height: 26px;
`

export const DeleteButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.red};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const HideButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const StyledGender = styled.div`
  width: 100%;
  ${FLEX({ justify: 'flex-start' })}
`

export const StyledButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  /* width: 30%; */

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const StyledButtonEnd = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;
  border-color: ${COLORS.lightGray};

  /* width: 30%; */

  transition: 0.15s ease-in-out;

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightGray })}

  ${FLEX({})}
`

export const StyledAccordion = styled.div`
  width: 100%;
`

export const StyledSaveButton = styled.div`
  cursor: pointer;

  padding: 10px 10px;

  border-radius: 3px;

  width: 100%;

  background: ${COLORS.green};

  ${FLEX({})}

  ${FONT({ size: '14px', weight: '700', color: COLORS.white })}
`

export const StyledWeekField = styled.div`
  ${FLEX({
    direction: 'row',
    wrap: 'nowrap',
    align: 'center',
    justify: 'space-between',
  })}

  margin-bottom: 10px;
`

export const StyledWeekendDiv = styled(FormGroup)`
  ${FLEX({ direction: 'column', wrap: 'nowrap', justify: 'space-around' })}
`

export const StyledWeekendRightPartDiv = styled(FormGroup)`
  ${FLEX({ direction: 'column', wrap: 'nowrap', align: 'flex-start' })}
`

export const StyledAccordionBody = styled.div`
  ${FLEX({
    align: 'flex-start',
    direction: 'row',
    wrap: 'nowrap',
    justify: 'flex-start',
  })}

  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const StyledLinkDiv = styled.div``

export const ButtonField = styled.div`
  ${FLEX({ direction: 'row', wrap: 'nowrap', justify: 'flex-start' })}
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  height: 56px;

  margin-bottom: 15px;
`

export const StyledTimeDiv = styled.div`
  width: 130px;
`

export const StyledWeekTime = styled.div`
  ${FLEX({
    direction: 'row',
    wrap: 'nowrap',
    align: 'flex-start',
    justify: 'flex-start',
  })}
`
