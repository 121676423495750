import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { createActionTypes, createApiActions } from '../actions-util'
import { EventPropResponse, GetAnalyticPayload } from '../eventsboard/types'
import { allStackEvent, GetStackEventProp } from './types'

export const GetMoreStackEventPropAction = createApiActions<
  GetAnalyticPayload,
  EventPropResponse[],
  AxiosError
>(createActionTypes('STACKPROPS/GET_MORE_STACK_EVENT_PROP'))

export const GetStackEventsPropAction = createApiActions<
  GetStackEventProp,
  allStackEvent[],
  AxiosError
>(createActionTypes('STACKPROPS/GET_STACK_EVENTS_PROP'))

export const GetMoreStackEventPropAndroidAction = createApiActions<
  GetAnalyticPayload,
  EventPropResponse[],
  AxiosError
>(createActionTypes('STACKPROPS/GET_MORE_STACK_EVENT_PROP_ANDROID'))

export const SetPlatformAction = createAction(
  'STACKPROPS/SET_OS',
  (payload: string) => ({
    payload,
  }),
)
