import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import { sendNotificationAction } from './actions'

const InitialState: TInitialState = {
  loading: false,
}

export const notificationsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(sendNotificationAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(sendNotificationAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(sendNotificationAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
  },
)
