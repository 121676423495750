import { takeLatest, call, put } from '@redux-saga/core/effects'
import { select } from 'redux-saga/effects'
import {
  deletePetAction,
  getOwnPetsAction,
  getPetByIdAction,
  getPetsAction,
  updatePetAction,
} from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { PetsAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getPetsWorker({
  payload,
}: ReturnType<typeof getPetsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof PetsAPI.getPets>> =
      yield call([PetsAPI, PetsAPI.getPets], {
        authorization: token,
        ...payload,
      })

    yield put(
      getPetsAction.success({
        pets: response.data.pets,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getPetsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения питомцев')),
    )

    yield put(getPetsAction.failure(e as AxiosError))
  }
}

function* getOwnPetsWorker({
  payload,
}: ReturnType<typeof getOwnPetsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof PetsAPI.getOwnPets>> =
      yield call([PetsAPI, PetsAPI.getOwnPets], {
        authorization: token,
        id: payload.id,
      })

    yield put(getOwnPetsAction.success(response.data.data))
  } catch (e) {
    Log.ruddy('Error: getOwnPetsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения питомцев')),
    )

    yield put(getOwnPetsAction.failure(e as AxiosError))
  }
}

function* getPetByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<ReturnType<typeof PetsAPI.getPetById>> =
      yield call([PetsAPI, PetsAPI.getPetById], {
        authorization: token,
        id: payload.id,
      })

    yield put(getPetByIdAction.success(res.data))
  } catch (e) {
    Log.ruddy('Error: getPetByIdWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения питомца')),
    )

    yield put(getPetByIdAction.failure(e as AxiosError))
  }
}

function* updatePetWorker({ payload }: ReturnType<typeof updatePetAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof PetsAPI.updatePet>> =
      yield call([PetsAPI, PetsAPI.updatePet], {
        data: payload.data,
        authorization: token,
        id: payload.id,
      })

    yield put(
      showToastAction.request(generateSuccessToast('Питомец обновлен успешно')),
    )
  } catch (e) {
    Log.ruddy('Error: updatePetWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления питомца')),
    )
  }
}

function* deletePetWorker({ payload }: ReturnType<typeof deletePetAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof PetsAPI.deletePet>> =
      yield call([PetsAPI, PetsAPI.deletePet], {
        id: payload.id,
        userId: payload.userId,
        authorization: token,
      })

    yield put(
      showToastAction.request(generateSuccessToast('Питомец удален успешно')),
    )
    const currentPetsParameters = {
      page: 1,
      limit: 20,
      search: '',
      sortBy: '',
    }
    yield put(getPetsAction.request(currentPetsParameters))
  } catch (e) {
    Log.ruddy('Error: deletePetWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления питомца')),
    )
  }
}

export function* petsWatcher() {
  yield takeLatest(getPetsAction.request, getPetsWorker)
  yield takeLatest(getOwnPetsAction.request, getOwnPetsWorker)
  yield takeLatest(getPetByIdAction.request, getPetByIdWorker)
  yield takeLatest(updatePetAction, updatePetWorker)
  yield takeLatest(deletePetAction, deletePetWorker)
}
