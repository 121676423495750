import React, { useMemo, useState } from 'react'
import Table from 'rc-table'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  FullWidth,
  Header,
  Title,
  TableWrapper,
  StyledRow,
  StyledHeader,
  StyledTable,
  StyledLink,
  CreateButton,
  StyledPagination,
  StyledEntity,
  Image,
  StyledSortName,
  SortImage,
  StyledButtonEnd,
  StyledButton,
  DeleteButton,
} from './styled'
import { useTypedSelector } from '../../hooks'
import { RoutesTree } from '../../router'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { Service } from '../../services'
import { useTranslate } from '../../hooks/useTranslate'
import { Assets } from '../../assets'
import moment from 'moment'

export const Advice: React.FC = () => {
  const { t } = useTranslation('advice')
  const { user } = useTypedSelector(state => state.user)
  const { translate } = useTranslate()

  const [sortBy, setSortBy] = useState<string>('')
  const [order, setOrder] = useState<1 | -1>(-1)
  const [page, setPage] = useState<number>(1)

  const { data, mutate } = useSWR(
    [
      'get-advices',
      {
        ...(sortBy ? { sortBy } : {}),
        ...(order ? { order } : {}),
        limit: 20,
      },
    ],
    ([_, key]) => Service.Advice.getAdvices(key),
    { revalidateOnFocus: false },
  )

  // eslint-disable-next-line
  const total = useMemo(() => {
    return data?.totalCount ?? 0
  }, [data])

  const sort = (field: string) => {
    setSortBy(field)

    if (order == -1) {
      setOrder(1)
      return
    }

    setOrder(-1)
  }

  const columns = [
    {
      title: (
        <StyledSortName onClick={() => sort('title')}>
          <div>{t('title')}</div>

          <Divider width={10} />

          <SortImage
            src={
              sortBy == 'title' && order == 1
                ? Assets.UP_ARROW_ICON
                : Assets.DOWN_ARROW_ICON
            }
          />
        </StyledSortName>
      ),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: (
        <StyledSortName onClick={() => sort('createdAt')}>
          <div>{t('createdAt')}</div>

          <Divider width={10} />

          <SortImage
            src={
              sortBy == 'createdAt' && order == 1
                ? Assets.UP_ARROW_ICON
                : Assets.DOWN_ARROW_ICON
            }
          />
        </StyledSortName>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: t('details'),
      dataIndex: 'details',
      key: 'details',
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      key: 'delete',
    },
  ]

  const deleteAdvice = async (id: string) => {
    try {
      await Service.Advice.deleteAdvice({ _id: id })
      mutate()
    } catch (error) {
      console.log(error)
    }
  }

  if (!user?.access?.advice) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <div>
        <Header>
          <Title>
            {t('posts')} ({data?.totalCount ?? 0})
          </Title>

          <CreateButton
            to={{
              pathname: '/advice/create',
            }}
          >
            {t('create')}
          </CreateButton>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          {data?.models && (
            <Table
              columns={columns}
              data={data?.models?.map(advice => ({
                title: translate(advice?.title),
                createdAt: moment(advice?.createdAt).isValid()
                  ? moment(advice?.createdAt).format('HH:mm DD.MM.YYYY')
                  : '-',
                details: (
                  <StyledLink
                    to={{
                      pathname: `/advice/detail/${advice?._id}`,
                    }}
                  >
                    {t('details')}
                  </StyledLink>
                ),
                delete: (
                  <DeleteButton onClick={() => deleteAdvice(advice?._id)}>
                    {t('delete')}
                  </DeleteButton>
                ),
              }))}
              scroll={{ y: 650 }}
              components={{
                body: {
                  row: StyledRow,
                },
                header: {
                  cell: StyledHeader,
                },
                table: StyledTable,
              }}
            />
          )}
        </TableWrapper>
      </div>

      <FullWidth>
        <StyledPagination>
          {page === 1 ? (
            <StyledButtonEnd> {t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => setPage(prev => prev - 1)}>
              {t('back')}
            </StyledButton>
          )}

          <div>
            {page}/
            {!total
              ? 1
              : Math.ceil(total / 20) === 0
              ? 1
              : Math.ceil(total / 20)}
          </div>

          {1 === (!total ? 1 : Math.ceil(total / 20)) ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => setPage(prev => prev + 1)}>
              {t('next')}
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}
