import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getAdminsAction,
  getAdminByIdAction,
  clearAdminAction,
} from './actions'

const InitialState: TInitialState = {
  admins: [],
  loading: false,
  admin: null,
}

export const adminsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getAdminsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getAdminsAction.success, (state, { payload }) => ({
      ...state,
      admins: payload,
      loading: false,
    }))
    builder.addCase(getAdminsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getAdminByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getAdminByIdAction.success, (state, { payload }) => ({
      ...state,
      admin: payload,
      loading: false,
    }))
    builder.addCase(getAdminByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(clearAdminAction, state => ({
      ...state,
      admin: null,
    }))
  },
)
