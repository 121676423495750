import { takeLatest, call, put, take } from '@redux-saga/core/effects'
import { select } from 'redux-saga/effects'
import {
  getControlAction,
  updateControlAction,
  updateDefaultPermissionsAction,
  updateRegionAction,
} from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { ControlAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getControlWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ControlAPI.getControl>
    > = yield call([ControlAPI, ControlAPI.getControl], {
      authorization: token,
    })

    yield put(getControlAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getControlWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения информации'),
      ),
    )

    yield put(getControlAction.failure(e as AxiosError))
  }
}

function* updateControlWorker({
  payload,
}: ReturnType<typeof updateControlAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ControlAPI.updateControl>
    > = yield call([ControlAPI, ControlAPI.updateControl], {
      data: payload.data,
      authorization: token,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Информация обновлена успешно'),
      ),
    )

    yield put(updateControlAction.success({}))
  } catch (e) {
    Log.ruddy('Error: updateControlWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления информации'),
      ),
    )

    yield put(updateControlAction.failure(e as AxiosError))
  }
}

function* updateRegionWorker({
  payload,
}: ReturnType<typeof updateRegionAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ControlAPI.updateRegion>
    > = yield call([ControlAPI, ControlAPI.updateRegion], {
      permissions: payload.permissions,
      numericCode: payload.numericCode,
      authorization: token,
    })

    yield put(getControlAction.request())

    yield take(getControlAction.success)

    yield put(
      showToastAction.request(generateSuccessToast('Регион обновлен успешно')),
    )

    yield put(updateRegionAction.success({}))
  } catch (e) {
    Log.ruddy('Error: updateRegionWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления региона')),
    )

    yield put(updateRegionAction.failure(e as AxiosError))
  }
}

function* updateDefaultPermissionsWorker({
  payload,
}: ReturnType<typeof updateDefaultPermissionsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ControlAPI.updateDefaultPermissions>
    > = yield call([ControlAPI, ControlAPI.updateDefaultPermissions], {
      permissions: payload.permissions,
      authorization: token,
    })

    yield put(getControlAction.request())

    yield take(getControlAction.success)

    yield put(
      showToastAction.request(
        generateSuccessToast('Разрешения обновлены успешно'),
      ),
    )

    yield put(updateDefaultPermissionsAction.success({}))
  } catch (e) {
    Log.ruddy('Error: updateDefaultPermissionsWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления разрешений'),
      ),
    )

    yield put(updateDefaultPermissionsAction.failure(e as AxiosError))
  }
}

export function* controlWatcher() {
  yield takeLatest(getControlAction.request, getControlWorker)
  yield takeLatest(updateControlAction.request, updateControlWorker)
  yield takeLatest(updateRegionAction.request, updateRegionWorker)
  yield takeLatest(
    updateDefaultPermissionsAction.request,
    updateDefaultPermissionsWorker,
  )
}
