import { AxiosResponse } from 'axios'
// import {} from './types'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'
import { CONTROL_URL } from './config'
import {
  GetControlActionResponse,
  UpdateControlActionPayload,
  UpdateDefaultPermissionsActionPayload,
  UpdateRegionActionPayload,
} from './types'

export class ControlAPI extends HttpService {
  static getControl(
    request: PayloadAuth,
  ): Promise<AxiosResponse<GetControlActionResponse>> {
    return this.request<GetControlActionResponse>('GET_CONTROL', {
      url: CONTROL_URL.getControl,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateControl(
    request: PayloadAuth & UpdateControlActionPayload,
  ): Promise<AxiosResponse<GetControlActionResponse>> {
    return this.request('UPDATE_CONTROL', {
      url: CONTROL_URL.updateControl,
      method: 'PUT',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateRegion(
    request: PayloadAuth & UpdateRegionActionPayload,
  ): Promise<AxiosResponse<GetControlActionResponse>> {
    return this.request('UPDATE_REGION', {
      url: CONTROL_URL.updateRegion,
      method: 'POST',
      data: {
        numericCode: request.numericCode,
        permissions: request.permissions,
      },
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateDefaultPermissions(
    request: PayloadAuth & UpdateDefaultPermissionsActionPayload,
  ): Promise<AxiosResponse<GetControlActionResponse>> {
    return this.request('UPDATE_DEFAULT_PERMISSIONS', {
      url: CONTROL_URL.updateDefaultPermissions,
      method: 'POST',
      data: { permissions: request.permissions },
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
