import React, { FC } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RoutesTree } from './routes'

const Authorizated: FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={RoutesTree.customers.path}
        component={RoutesTree.customers.component}
      />
      <Route
        exact
        path={RoutesTree.profile.path}
        component={RoutesTree.profile.component}
      />
      <Route
        exact
        path={RoutesTree.adminProfile.path}
        component={RoutesTree.adminProfile.component}
      />
      {/* <Route
        exact
        path={RoutesTree.clinicsCategory.path}
        component={RoutesTree.clinicsCategory.component}
      /> */}
      <Route
        exact
        path={RoutesTree.advertisement.path}
        component={RoutesTree.advertisement.component}
      />
      <Route
        exact
        path={RoutesTree.complaintsAds.path}
        component={RoutesTree.complaintsAds.component}
      />
      <Route
        exact
        path={RoutesTree.categories.path}
        component={RoutesTree.categories.component}
      />
      <Route
        exact
        path={RoutesTree.advertisementProfile.path}
        component={RoutesTree.advertisementProfile.component}
      />
      <Route
        exact
        path={RoutesTree.adminCreate.path}
        component={RoutesTree.adminCreate.component}
      />
      <Route
        exact
        path={RoutesTree.regions.path}
        component={RoutesTree.regions.component}
      />
      <Route
        exact
        path={RoutesTree.infoSection.path}
        component={RoutesTree.infoSection.component}
      />
      <Route
        exact
        path={RoutesTree.bisnesClinks.path}
        component={RoutesTree.bisnesClinks.component}
      />
      <Route
        exact
        path={RoutesTree.bisnesCliniksInfo.path}
        component={RoutesTree.bisnesCliniksInfo.component}
      />
      <Route
        exact
        path={RoutesTree.socialClinks.path}
        component={RoutesTree.socialClinks.component}
      />
      <Route
        exact
        path={RoutesTree.socialClinksInfo.path}
        component={RoutesTree.socialClinksInfo.component}
      />
      <Route
        exact
        path={RoutesTree.clinics.path}
        component={RoutesTree.clinics.component}
      />
      <Route
        exact
        path={RoutesTree.clinicInfo.path}
        component={RoutesTree.clinicInfo.component}
      />
      <Route
        exact
        path={RoutesTree.pets.path}
        component={RoutesTree.pets.component}
      />
      <Route
        exact
        path={RoutesTree.settings.path}
        component={RoutesTree.settings.component}
      />
      <Route
        exact
        path={RoutesTree.petDetails.path}
        component={RoutesTree.petDetails.component}
      />
      <Route
        exact
        path={RoutesTree.complaint.path}
        component={RoutesTree.complaint.component}
      />
      <Route
        exact
        path={RoutesTree.complaintInfo.path}
        component={RoutesTree.complaintInfo.component}
      />
      <Route
        exact
        path={RoutesTree.supportProfile.path}
        component={RoutesTree.supportProfile.component}
      />
      <Route
        exact
        path={RoutesTree.review.path}
        component={RoutesTree.review.component}
      />
      <Route
        exact
        path={RoutesTree.inaccuracy.path}
        component={RoutesTree.inaccuracy.component}
      />
      <Route
        exact
        path={RoutesTree.control.path}
        component={RoutesTree.control.component}
      />
      <Route
        exact
        path={RoutesTree.dashboard.path}
        component={RoutesTree.dashboard.component}
      />
      <Route
        exact
        path={RoutesTree.feedbacks.path}
        component={RoutesTree.feedbacks.component}
      />
      <Route
        exact
        path={RoutesTree.places.path}
        component={RoutesTree.places.component}
      />
      <Route
        exact
        path={RoutesTree.notifications.path}
        component={RoutesTree.notifications.component}
      />
      <Route
        exact
        path={RoutesTree.placeInfo.path}
        component={RoutesTree.placeInfo.component}
      />
      <Route
        exact
        path={RoutesTree.inaccuracyInfo.path}
        component={RoutesTree.inaccuracyInfo.component}
      />
      <Route
        exact
        path={RoutesTree.proposalInfo.path}
        component={RoutesTree.proposalInfo.component}
      />
      <Route
        exact
        path={RoutesTree.proposal.path}
        component={RoutesTree.proposal.component}
      />
      <Route
        exact
        path={RoutesTree.notFound.path}
        component={RoutesTree.notFound.component}
      />
      <Route
        exact
        path={RoutesTree.eventsboard.path}
        component={RoutesTree.eventsboard.component}
      />
      <Route
        exact
        path={RoutesTree.eventsboardInfo.path}
        component={RoutesTree.eventsboardInfo.component}
      />
      <Route
        exact
        path={RoutesTree.applicationManagement.path}
        component={RoutesTree.applicationManagement.component}
      />
      <Route
        exact
        path={RoutesTree.medicalDirections.path}
        component={RoutesTree.medicalDirections.component}
      />
      <Route
        exact
        path={RoutesTree.tags.path}
        component={RoutesTree.tags.component}
      />
      <Route
        exact
        path={RoutesTree.tagsCreate.path}
        component={RoutesTree.tagsCreate.component}
      />
      <Route
        exact
        path={RoutesTree.tagsDetail.path}
        component={RoutesTree.tagsDetail.component}
      />
      <Route
        exact
        path={RoutesTree.advice.path}
        component={RoutesTree.advice.component}
      />
      <Route
        exact
        path={RoutesTree.adviceCreate.path}
        component={RoutesTree.adviceCreate.component}
      />
      <Route
        exact
        path={RoutesTree.adviceDetail.path}
        component={RoutesTree.adviceDetail.component}
      />
      <Route
        exact
        path={RoutesTree.mail.path}
        component={RoutesTree.mail.component}
      />
      <Route
        exact
        path={RoutesTree.additionalOptions.path}
        component={RoutesTree.additionalOptions.component}
      />
      <Route
        exact
        path={RoutesTree.seo.path}
        component={RoutesTree.seo.component}
      />
      <Route
        exact
        path={RoutesTree.specialists.path}
        component={RoutesTree.specialists.component}
      />
      <Route
        exact
        path={RoutesTree.specialistsInfo.path}
        component={RoutesTree.specialistsInfo.component}
      />
      <Route
        exact
        path={RoutesTree.appointments.path}
        component={RoutesTree.appointments.component}
      />
      <Route
        exact
        path={RoutesTree.appointmentsDetail.path}
        component={RoutesTree.appointmentsDetail.component}
      />
      <Redirect to={RoutesTree.dashboard.path} />
    </Switch>
  )
}

export default Authorizated
