import { TCreateDirectionProps, TCreateMedicalDirForm } from './types'
import { useForm, SubmitHandler } from 'react-hook-form'
import {
  CreteForm,
  Errormessage,
  HeadingWrap,
  InputsWrap,
} from '../../../ApplicationContent/components/CreateServiceWindow/styled'
import { Assets } from '../../../../assets'
import { languageKeysArray } from '../../../../consts'
import { useTranslation } from 'react-i18next'
import { useTypedSelector } from '../../../../hooks'
import { useRef, useState } from 'react'
import { Service } from '../../../../services'
import { useSWRConfig } from 'swr'

export const CreateAddOption = ({
  setVisibleCreateModal,
}: TCreateDirectionProps) => {
  const closeModal = () => setVisibleCreateModal(prev => !prev)
  const { t } = useTranslation('medicalDirection')
  const { language } = useTypedSelector(state => state.user)
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TCreateMedicalDirForm>({
    defaultValues: { lang: language || 'uk' },
  })
  const { mutate } = useSWRConfig()

  const [imagePreview, setImagePreview] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)

  const onSubmit: SubmitHandler<TCreateMedicalDirForm> = async data => {
    try {
      const payload = new FormData()

      const title = { [data.lang]: data.title }

      payload.append('title', JSON.stringify(title))

      file && payload.append('image', file)

      await Service.AdditionalOptions.createAdditionalOption({
        data: payload,
      })

      mutate(['get-additional-options', {}])
    } catch (error) {
      console.error(error)
    } finally {
      closeModal()
    }
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      setFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = () => {
    inputRef.current?.click()
  }

  return (
    <CreteForm onSubmit={handleSubmit(onSubmit)}>
      <HeadingWrap>
        <h3>{t('controlCreation')}</h3>
        <div onClick={closeModal}>
          <img src={Assets.CYAN_ICON} alt="close" />
        </div>
      </HeadingWrap>
      <InputsWrap>
        <div>
          <div>
            <p>{t('language')}</p>
            <select {...register('lang')}>
              {languageKeysArray.map(item => (
                <option key={item}>{item}</option>
              ))}
            </select>
          </div>
          <label>
            {t('name')}
            <input
              placeholder={t('name')}
              {...register('title', { required: t('mandatoryField') })}
            />
            {errors.title && (
              <Errormessage>{errors.title.message}</Errormessage>
            )}
          </label>
        </div>

        <div>
          {t('image')}
          <img
            src={Assets.DOWNLOAD_ARROW_ICON}
            onClick={handleButtonClick}
            style={{ width: '20px', height: '20px' }}
          />

          <label>
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              hidden
            />
          </label>

          {imagePreview && (
            <img
              src={imagePreview}
              alt="option image"
              style={{
                maxWidth: '200px',
                width: '100%',
                objectFit: 'contain',
              }}
            />
          )}
        </div>
      </InputsWrap>
      <HeadingWrap>
        <button onClick={closeModal} type="button">
          {t('close')}
        </button>
        <button type="submit">{t('create')}</button>
      </HeadingWrap>
    </CreteForm>
  )
}
