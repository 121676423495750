import { Tag } from './tags'
import { Advice } from './advice'
import { Category } from './categories'
import { Subcategory } from './subcategories'
import { Mail } from './mail'
import { SEO } from './seo'
import { AdditionalOptions } from './additional-options'
import { Appointments } from './appointments'

export const Service = {
  Tag,
  Advice,
  Category,
  Subcategory,
  Mail,
  SEO,
  AdditionalOptions,
  Appointments,
}
export * from './http.service'
