import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Log } from '../utils'
import { api } from '../store/config'
import { store } from '../store/index'

// create instance for microservice
export const serviceInstance = axios.create({
  baseURL: `${api.withAPI}`,
})

serviceInstance.interceptors.request.use(
  config => {
    const { token } = store.getState().user
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

export class HttpService {
  static async request<T = any>(
    identifier: string,
    axiosConfig: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const { method, url } = axiosConfig
    const baseMessage = `${method} ⟴ Axios ⟴ ${identifier} ⟴ URL: ${url}`

    try {
      const response = await axios.request(axiosConfig)

      Log.zelda(`${response.status} ${baseMessage}`, response)

      return response
    } catch (e: any) {
      Log.ruddy(
        `${e?.response?.status || 'UNANABLE'} ${baseMessage}`,
        e?.response || e,
      )

      throw new Error(e)
    }
  }
}
