import { serviceInstance } from '../../../services'
import { TOneService, TServiceCategoriesResponse } from './types'

export const getServicesApi = async (type?: string) => {
  const response = await serviceInstance.get<TServiceCategoriesResponse>(
    'service/api/v1/admin/service-categories',
    { params: { type: type || undefined } },
  )
  return response.data
}

export const createServiceCategory = async (data: FormData) => {
  await serviceInstance.post(`service/api/v1/admin/service-categories`, data)
}

export const deleteServiceCategory = async (id: string) => {
  await serviceInstance.delete(`service/api/v1/admin/service-categories/${id}`)
}

export const editCurentSetviceCategory = async (data: FormData, id: string) => {
  await serviceInstance.patch(
    `service/api/v1/admin/service-categories/${id}`,
    data,
  )
}

export const getCurrentServiceCategory = async ({ id }: { id: string }) => {
  const response = await serviceInstance.get<TOneService>(
    `service/api/v1/admin/service-categories/${id}`,
  )
  return response.data
}
