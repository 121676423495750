import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export const useStatusFilter = () => {
  const localStorageKey = 'statusFilter'

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(() => {
    const savedStatuses = localStorage.getItem(localStorageKey)
    return savedStatuses ? JSON.parse(savedStatuses) : []
  })

  const clearStatusFilter = useCallback(() => {
    localStorage.removeItem(localStorageKey)
    setSelectedStatuses([])
  }, [])

  useEffect(() => {
    if (!_.isEmpty(selectedStatuses)) {
      localStorage.setItem(localStorageKey, JSON.stringify(selectedStatuses))
    } else {
      localStorage.removeItem(localStorageKey)
    }
  }, [selectedStatuses])

  return {
    status: selectedStatuses,
    setStatus: setSelectedStatuses,
    clearStatusFilter,
  }
}
