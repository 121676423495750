import { serviceInstance } from '../http.service'
import {
  DeleteAppointmentRequest,
  GetAppointmentRequest,
  GetAppointmentResponseRequest,
  GetAppointmentsRequest,
  UpdateAppointmentRequest,
} from './types'

export class Appointments {
  static async getAppointments(
    params: GetAppointmentsRequest['payload'],
  ): Promise<GetAppointmentsRequest['response']> {
    const { data } = await serviceInstance.get(
      'service/api/v1/admin/appointment',
      {
        params,
      },
    )

    return data
  }

  static async getAppointment({
    _id,
  }: GetAppointmentRequest['payload']): Promise<
    GetAppointmentRequest['response']
  > {
    const { data } = await serviceInstance.get(
      `service/api/v1/admin/appointment/${_id}`,
      {},
    )

    return data
  }

  static async getAppointmentResponse({
    _id,
  }: GetAppointmentResponseRequest['payload']): Promise<
    GetAppointmentResponseRequest['response']
  > {
    const { data } = await serviceInstance.get(
      `service/api/v1/admin/appointment/${_id}/response`,
      {},
    )

    return data
  }

  static async updateAppointment({
    _id,
    data,
  }: UpdateAppointmentRequest['payload']): Promise<
    UpdateAppointmentRequest['response']
  > {
    const response = await serviceInstance.patch(
      `service/api/v1/admin/appointment/${_id}`,
      data,
    )

    return response.data
  }

  static async deleteAppointment({
    _id,
  }: DeleteAppointmentRequest['payload']): Promise<
    DeleteAppointmentRequest['response']
  > {
    const response = await serviceInstance.delete(
      `service/api/v1/admin/appointment/${_id}`,
    )

    return response.data
  }
}
