import { Controller, useFormContext } from 'react-hook-form'
import { Divider } from '../../../../components'
import { Label, StyledInput } from '../../styled'
import { useTranslation } from 'react-i18next'

export const Name = () => {
  const { control } = useFormContext()
  const { t } = useTranslation('appointments')

  return (
    <>
      <Label>{t('title')}</Label>

      <Divider height={10} />

      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <StyledInput
            {...field}
            onChange={onChange}
            placeholder={t('enterTitle')}
          />
        )}
      />
    </>
  )
}
