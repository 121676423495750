import styled from 'styled-components'
import { FLEX, FONT } from '../../utils'
import { COLORS } from '../../assets'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledDropDown = styled.div`
  width: 270px;
`

export const Title = styled.h1`
  ${FONT({ size: '32px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledTitle = styled.div`
  ${FLEX({ align: 'flex-start', justify: 'space-between' })}
`

export const StyledLinkDiv = styled.div``

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const StyledInput = styled.input`
  width: 100%;

  margin: 1;
  padding: 10px 14px;

  padding-right: 40px;

  outline: none;
  border: 2px solid ${COLORS.gray};

  background: ${COLORS.gray};

  ${FONT({
    size: '14px',
    weight: '700',
    color: COLORS.lightBlue,
  })}

  transition: 0.2s;

  &:focus {
    border: 2px solid ${COLORS.green};
    background: ${COLORS.white};
  }

  &::placeholder {
    ${FONT({
      size: '14px',
      weight: '700',
      color: '',
    })}
  }
`

export const StyledButton = styled.button`
  cursor: pointer;

  padding: 10px 10px;

  border-radius: 3px;

  width: 100%;

  background: ${COLORS.green};

  border: 0;

  ${FLEX({})}

  ${FONT({ size: '14px', weight: '700', color: COLORS.white })}
`

export const Label = styled.span`
  width: 100%;

  ${FONT({
    size: '14px',
    weight: '700',
    align: 'left',
    color: COLORS.lightBlue,
  })}
`

export const StyledSearch = styled.div`
  ${FLEX({ direction: 'row' })}
`

export const StyledLabel = styled.label`
  ${FONT({
    size: '18px',
    weight: '400',
    color: COLORS.lightBlue,
  })}

  cursor: pointer;
`

export const StyledInputPhoto = styled.input`
  visibility: hidden;

  position: fixed;

  cursor: pointer;

  outline: none;
`

export const ClearImage = styled(Image)`
  cursor: pointer;
`

export const AdviceImage = styled.img`
  cursor: pointer;
  width: 400px;
  border-radius: 8px;
`

export const ImageContainer = styled.div`
  ${FLEX({ direction: 'column', wrap: 'nowrap', justify: 'center' })}
`

export const DeleteButton = styled.button`
  padding: 10px 10px 10px 10px;
  border: 0;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.red};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100px;

  margin: 1;
  padding: 10px 14px;

  padding-right: 40px;

  outline: none;
  border: 2px solid ${COLORS.gray};

  background: ${COLORS.gray};

  ${FONT({
    size: '14px',
    weight: '700',
    color: COLORS.lightBlue,
  })}

  transition: 0.2s;

  &:focus {
    border: 2px solid ${COLORS.green};
    background: ${COLORS.white};
  }

  &::placeholder {
    ${FONT({
      size: '14px',
      weight: '700',
      color: '',
    })}
  }
`
