import { takeLatest, call, put } from '@redux-saga/core/effects'
import { select } from 'redux-saga/effects'
import { sendNotificationAction } from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { NotificationsAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* sendNotificationWorker({
  payload,
}: ReturnType<typeof sendNotificationAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof NotificationsAPI.sendNotification>
    > = yield call([NotificationsAPI, NotificationsAPI.sendNotification], {
      data: payload.data,
      authorization: token,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Уведомление отправлено успешно'),
      ),
    )

    yield put(sendNotificationAction.success({}))
  } catch (e) {
    Log.ruddy('Error: sendNotificationWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка отправки уведомления'),
      ),
    )

    yield put(sendNotificationAction.failure(e as AxiosError))
  }
}

export function* notificationsWatcher() {
  yield takeLatest(sendNotificationAction.request, sendNotificationWorker)
}
