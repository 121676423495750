import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  CreateAdsActionPayload,
  DeleteAdsActionPayload,
  GetAdsActionResponse,
  GetAdsPayload,
  UpdateAdsActionPayload,
  GetAdsByIdPayload,
  GetComplaintsAdsPayload,
  DeleteComplaintsActionPayload,
  GetComplaintsAdsActionResponse
} from './types'

export const getAdsAction = createApiActions<
  GetAdsPayload,
  GetAdsActionResponse,
  AxiosError
>(createActionTypes('ADS/GET_ADS'))

export const getAdsActionComplaints = createApiActions<
GetComplaintsAdsPayload,
  GetAdsActionResponse,
  AxiosError
>(createActionTypes('ADS/GET_ADS_COMPLAINTS'))

export const getAdsByIdAction = createApiActions<
  GetAdsByIdPayload,
  GetAdsActionResponse,
  AxiosError
>(createActionTypes('ADS/GET_ADS_BY_ID'))

export const createAdsAction = createApiActions<
  CreateAdsActionPayload,
  GetAdsActionResponse,
  AxiosError
>(createActionTypes('ADS/CREATE_ADS'))

export const updateAdsAction = createApiActions<
  UpdateAdsActionPayload,
  GetAdsActionResponse,
  AxiosError
>(createActionTypes('ADS/UPDATE_ADS'))

export const deleteAdsAction = createAction<
  DeleteAdsActionPayload,
  'ADS/DELETE_ADS'
>('ADS/DELETE_ADS')

export const deleteComplaintsAdsAction = createApiActions<
  DeleteComplaintsActionPayload,
  GetComplaintsAdsActionResponse,
  AxiosError
>(createActionTypes('ADS/COMPLAINTS_DELETE'))
