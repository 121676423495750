import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import { getInfoSectionsAction } from './actions'

const InitialState: TInitialState = {
  loading: false,
  info: [],
}

export const infoSectionReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getInfoSectionsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getInfoSectionsAction.success, (state, { payload }) => ({
      ...state,
      info: payload,
      loading: false,
    }))
    builder.addCase(getInfoSectionsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
  },
)
