import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  getAdminsAction,
  createAdminAction,
  deleteAdminAction,
  updateAdminAction,
  getAdminByIdAction,
} from './actions'
import { AuthorizationError } from '../../errors'
import { getUserSelector } from '../user'
import { PromiseReturnType } from '../types'
import { AdminAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getAdminsWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof AdminAPI.getAdmins>> =
      yield call([AdminAPI, AdminAPI.getAdmins], {
        authorization: token,
      })

    yield put(getAdminsAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getAdminsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения админов')),
    )

    yield put(getAdminsAction.failure(e as AxiosError))
  }
}

function* createAdminWorker({ payload }: ReturnType<typeof createAdminAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof AdminAPI.createAdmin>> =
      yield call([AdminAPI, AdminAPI.createAdmin], {
        name: payload.name,
        access: payload.access,
        password: payload.password,
        email: payload.email,
        authorization: token,
      })

    yield put(
      showToastAction.request(generateSuccessToast('Админ создан успешно')),
    )
  } catch (e) {
    Log.ruddy('Error: createAdminWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания админа')),
    )
  }
}

function* getAdminByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<ReturnType<typeof AdminAPI.getAdminById>> =
      yield call([AdminAPI, AdminAPI.getAdminById], {
        authorization: token,
        id: payload.id,
      })

    yield put(getAdminByIdAction.success(res.data))
  } catch (e) {
    Log.ruddy('Error: getAdminByIdWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения администратора'),
      ),
    )

    yield put(getAdminByIdAction.failure(e as AxiosError))
  }
}

function* updateAdminWorker({ payload }: ReturnType<typeof updateAdminAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof AdminAPI.updateAdmin>> =
      yield call([AdminAPI, AdminAPI.updateAdmin], {
        data: payload.data,
        authorization: token,
        id: payload.id,
      })

    yield put(
      showToastAction.request(generateSuccessToast('Админ обновлён успешно')),
    )
  } catch (e) {
    Log.ruddy('Error: updateAdminWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления админа')),
    )
  }
}

function* deleteAdminWorker({ payload }: ReturnType<typeof deleteAdminAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof AdminAPI.deleteAdmin>> =
      yield call([AdminAPI, AdminAPI.deleteAdmin], {
        id: payload.id,
        authorization: token,
      })

    const res: PromiseReturnType<ReturnType<typeof AdminAPI.getAdmins>> =
      yield call([AdminAPI, AdminAPI.getAdmins], {
        authorization: token,
      })

    yield put(getAdminsAction.success(res.data))

    yield put(
      showToastAction.request(generateSuccessToast('Админ удалён успешно')),
    )
  } catch (e) {
    Log.ruddy('Error: deleteAdminWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаление админа')),
    )
  }
}

export function* adminsWatcher() {
  yield takeLatest(getAdminsAction.request, getAdminsWorker)
  yield takeLatest(createAdminAction, createAdminWorker)
  yield takeLatest(updateAdminAction, updateAdminWorker)
  yield takeLatest(deleteAdminAction, deleteAdminWorker)
  yield takeLatest(getAdminByIdAction.request, getAdminByIdWorker)
}
