import { takeLatest, select, call, put, take } from '@redux-saga/core/effects'
import {
  deleteComplaintAction,
  deleteProposalAction,
  getComplaintAction,
  getComplaintByIdAction,
  getInaccuracyAction,
  getInaccuracyByIdAction,
  getProposalAction,
  getProposalByIdAction,
} from './actions'
import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { FeedbackAPI } from './api.service'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getComplaintWorker({
  payload,
}: ReturnType<typeof getComplaintAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getComplaint>
    > = yield call([FeedbackAPI, FeedbackAPI.getComplaint], {
      authorization: token,
      page: payload.page,
      limit: payload.limit,
    })

    yield put(
      getComplaintAction.success({
        data: response.data.complaint,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getComplaintWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения жалоб')),
    )

    yield put(getComplaintAction.failure(e as AxiosError))
  }
}

function* getInaccuracyWorker({
  payload,
}: ReturnType<typeof getInaccuracyAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getInaccuracy>
    > = yield call([FeedbackAPI, FeedbackAPI.getInaccuracy], {
      authorization: token,
      page: payload.page,
      limit: payload.limit,
    })

    yield put(
      getInaccuracyAction.success({
        data: response.data.complaint,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getInaccuracyWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения неточностей'),
      ),
    )

    yield put(getInaccuracyAction.failure(e as AxiosError))
  }
}

function* deleteComplaintWorker({
  payload,
}: ReturnType<typeof deleteComplaintAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.deleteComplaint>
    > = yield call([FeedbackAPI, FeedbackAPI.deleteComplaint], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      getComplaintAction.request({ page: 1, limit: 8, authorization: '' }),
    )

    yield take(getComplaintAction.success)

    yield put(
      getInaccuracyAction.request({ page: 1, limit: 8, authorization: '' }),
    )

    yield take(getInaccuracyAction.success)

    yield put(
      showToastAction.request(generateSuccessToast('Жалоба удалена успешно')),
    )
  } catch (e) {
    Log.ruddy('Error: deleteComplaintWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления жалобы')),
    )
  }
}

function* getComplaintByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getComplaintById>
    > = yield call([FeedbackAPI, FeedbackAPI.getComplaintById], {
      authorization: token,
      id: payload.id,
    })

    yield put(getComplaintByIdAction.success(res.data))
  } catch (e) {
    Log.ruddy('Error: getComplaintByIdWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения жалобы')),
    )

    yield put(getComplaintByIdAction.failure(e as AxiosError))
  }
}

function* getInaccuracyByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getInaccuracyById>
    > = yield call([FeedbackAPI, FeedbackAPI.getInaccuracyById], {
      authorization: token,
      id: payload.id,
    })

    yield put(getInaccuracyByIdAction.success({ data: res.data.complaint }))
  } catch (e) {
    Log.ruddy('Error: getInaccuracyByIdWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения неточности'),
      ),
    )

    yield put(getInaccuracyByIdAction.failure(e as AxiosError))
  }
}

function* getProposalWorker({
  payload,
}: ReturnType<typeof getProposalAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getProposal>
    > = yield call([FeedbackAPI, FeedbackAPI.getProposal], {
      authorization: token,
      page: payload.page,
      limit: payload.limit,
    })

    yield put(
      getProposalAction.success({
        data: response.data.proposal,
        total: response.data.total,
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getComplaintWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения предложений'),
      ),
    )

    yield put(getProposalAction.failure(e as AxiosError))
  }
}

function* deleteProposalWorker({
  payload,
}: ReturnType<typeof deleteProposalAction>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.deleteProposal>
    > = yield call([FeedbackAPI, FeedbackAPI.deleteProposal], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      getProposalAction.request({ page: 1, limit: 8, authorization: '' }),
    )

    yield take(getProposalAction.success)

    yield put(
      showToastAction.request(
        generateSuccessToast('Предложение удалено успешно'),
      ),
    )
  } catch (e) {
    Log.ruddy('Error: deleteProposalWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка удаления предложения'),
      ),
    )
  }
}

function* getProposalByIdWorker({ payload }: { payload: { id: string } }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const res: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getProposalById>
    > = yield call([FeedbackAPI, FeedbackAPI.getProposalById], {
      authorization: token,
      id: payload.id,
    })

    yield put(getProposalByIdAction.success({ data: res.data.proposal }))
  } catch (e) {
    Log.ruddy('Error: getProposalByIdWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Error getting proposal')),
    )

    yield put(getProposalByIdAction.failure(e as AxiosError))
  }
}

export function* feedbackWatcher() {
  yield takeLatest(getComplaintAction.request, getComplaintWorker)
  yield takeLatest(getInaccuracyAction.request, getInaccuracyWorker)
  yield takeLatest(deleteComplaintAction, deleteComplaintWorker)
  yield takeLatest(getProposalAction.request, getProposalWorker)
  yield takeLatest(deleteProposalAction, deleteProposalWorker)
  yield takeLatest(getComplaintByIdAction.request, getComplaintByIdWorker)
  yield takeLatest(getProposalByIdAction.request, getProposalByIdWorker)
  yield takeLatest(getInaccuracyByIdAction.request, getInaccuracyByIdWorker)
}
