import React, { useEffect, useMemo, useState } from 'react'
import startOfDay from 'date-fns/fp/startOfDay'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { Divider, EInputTemplate, Input } from '../../components'
import 'react-dropdown/style.css'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import {
  Container,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  ProfileImage,
  Div,
  StyledInputWrapper,
  Label,
  StyledTextArea,
  SuccessText,
  FailedText,
  StyledSearch,
  StyledLabel,
  StyledInputPhoto,
  ClearImage,
  StyledPhotoFields,
  StyledSaveButton,
  StyledContainer,
  DeleteButton,
  SaveButton,
  BottomDiv,
  StyledLinkDiv,
  ButtonContainer,
  StyledDropDown,
} from './styled'
import {
  TForm,
  TPhoto,
  TTime,
  TAdditionalFields,
  TLoginValidationSchema,
  TGallery,
} from './types'
import { Assets } from '../../assets'
import { useTypedSelector, useValidation } from '../../hooks'
import { Log } from '../../utils'
import { RoutesTree } from '../../router'
import {
  getClinicByIdAction,
  clearClinicAction,
  updateClinicAction,
  createClinicAction,
  deleteClinicAction,
  showToastAction,
  deleteImageFromClinicAction,
  deleteDocumentFromClinicAction,
  api,
} from '../../store'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  WorkingTime,
  MakeData,
  ShowPhotos,
  Map,
  PhoneNumbers,
  Services,
  Category,
  languages,
} from './components'
import _ from 'lodash'
import { generateErrorToast } from '../../helpers'
import Dropdown from 'react-dropdown'
import add from 'date-fns/add'
import { LanguageKeys } from '../ApplicationContent/services/types'
import { useTranslation } from 'react-i18next'
import { PetsTypes } from './components/PetsTypes'

const SocialClinicInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('clinics')
  const [formNoValid, setFormNoValid] = useState<TPhoto>({
    photo: null,
  })
  const { user, language: lang } = useTypedSelector(state => state.user)
  const [language, setLanguage] = useState<string>(lang || 'uk')

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  const [form, setForm] = useState<TForm>({
    description: {
      ru: '',
      uk: '',
      en: '',
      pl: '',
      cs: '',
      de: '',
      es: '',
      fr: '',
      it: '',
      ch: '',
      hi: '',
      ja: '',
      ko: '',
      tr: '',
      gr: '',
      kt: '',
      sv: '',
      sk: '',
      th: '',
      hr: '',
      fi: '',
      sl: '',
    },
    networkName: '',
    medicalDirections: [],
    phones: [],
    title: {
      ru: '',
      uk: '',
      en: '',
      pl: '',
      cs: '',
      de: '',
      es: '',
      fr: '',
      it: '',
      ch: '',
      hi: '',
      ja: '',
      ko: '',
      tr: '',
      gr: '',
      kt: '',
      sv: '',
      sk: '',
      th: '',
      hr: '',
      fi: '',
      sl: '',
    },
    address: { ru: '', uk: '', en: '' },
    city: { ru: '', uk: '', en: '' },
    coordinates: {
      lat: 1,
      lng: 1,
    },
    cityLog: {
      lat: 1,
      lng: 1,
    },
    country: { ru: '', uk: '', en: '' },
    site: '',
    email: '',
    specialisation: { title: '', id: '' },
    additionalOptions: [],
    petType: [],
  })
  const [timePicker, setTimePicker] = useState<TTime>({
    monday: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      weekend: false,
    },
    tuesday: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      weekend: false,
    },
    wednesday: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      weekend: false,
    },
    thursday: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      weekend: false,
    },
    friday: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      weekend: false,
    },
    saturday: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      weekend: false,
    },
    sunday: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      weekend: false,
    },
  })
  const [additionalFields, setAdditionalFields] = useState<TAdditionalFields>({
    everyday: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      enable: false,
    },
    lunch: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(20, 0, 0, 0),
      enable: false,
    },
    planned: false,
    aroundTheClock: false,
  })
  const [clinicDocuments, setClinicDocuments] = useState<TGallery>([])
  const [newClinicDocuments, setNewClinicDocuments] = useState<File[]>([])
  const [clinicPhotos, setClinicPhotos] = useState<TGallery>([])
  const [newClinicPhotos, setNewClinicPhotos] = useState<File[]>([])
  const [deletedPhotos, setDeletedPhotos] = useState<TGallery>([])
  const [deletedDocuments, setDeletedDocuments] = useState<TGallery>([])

  const dispatch = useDispatch()

  const history = useHistory()

  const { clinic } = useTypedSelector(state => state.clinics)

  if (!user?.access?.clinics) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  //eslint-disable-next-line
  const schema = useMemo<TLoginValidationSchema>(
    () => ({
      title: {
        condition:
          !!form.title.ru ||
          !!form.title.uk ||
          !!form.title.en ||
          !!form.title.cs ||
          !!form.title.de ||
          !!form.title.es ||
          !!form.title.fr ||
          !!form.title.it ||
          !!form.title.pl,
        error: t('enterTitle'),
      },
      address: {
        condition: !!form.address,
        error: t('enterAddress'),
      },
    }),
    [form],
  )

  const {
    validationSchema,
    validation,
    enableValidation,
    disableValidation,
    isEnabled,
    //eslint-disable-next-line
  } = useValidation(schema)

  const takeImageHandler = (e: any) => {
    if (e.target?.type === 'file') {
      setFormNoValid(prev => ({
        ...prev,
        [e.target.name]: e.target?.files?.length ? e.target?.files[0] : null,
      }))
    }
  }

  const clearImage = (name: string) => {
    setFormNoValid(prev => ({
      ...prev,
      [name]: null,
    }))
  }

  const changeType = (type: string) => {
    setLanguage(type)
  }

  const inputHandler = (e: any) => {
    setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const inputMultiLangHandler = (e: any) => {
    setForm(prev => ({
      ...prev,
      //@ts-ignore
      [e.target.name]: { ...prev[e.target.name], [language]: e.target.value },
    }))
  }

  const onPressSaveChanges = async () => {
    try {
      const result = window.confirm(t('updateClinic'))

      if (!!form.specialisation?.title) {
        if (result) {
          enableValidation()
          await validation()

          const data = new FormData()

          const dataImage = new FormData()

          newClinicPhotos.map(el => dataImage.append('image', el))

          const dataDocuments = new FormData()

          newClinicDocuments.map(el => dataDocuments.append('file', el))

          data.append('image', formNoValid.photo!)
          if (id !== 'undefined') {
            dispatch(
              updateClinicAction.request({
                data: MakeData({
                  form,
                  timePicker,
                  additionalFields,
                }),
                image:
                  !!formNoValid.photo &&
                  formNoValid.photo !== clinic?.meta?.logo
                    ? data
                    : '',
                id,
                dataImage: !!newClinicPhotos.length ? dataImage : '',
                dataDocuments: !!newClinicDocuments.length ? dataDocuments : '',
              }),
            )
          } else {
            dispatch(
              createClinicAction.request({
                data: MakeData({
                  form,
                  timePicker,
                  additionalFields,
                }),
                image:
                  !!formNoValid.photo &&
                  formNoValid.photo !== clinic?.meta?.logo
                    ? data
                    : '',
                dataImage: !!newClinicPhotos.length ? dataImage : '',
                dataDocuments: !!newClinicDocuments.length ? dataDocuments : '',
              }),
            )
          }

          !!deletedPhotos.length &&
            id !== 'undefined' &&
            dispatch(
              deleteImageFromClinicAction.request({
                id,
                image: deletedPhotos.reduce(
                  (acc, val) =>
                    `${acc}${!!acc ? '&' : '?'}photosIds[]=${val.id}`,
                  '',
                ),
              }),
            )

          !!deletedDocuments.length &&
            id !== 'undefined' &&
            dispatch(
              deleteDocumentFromClinicAction.request({
                id,
                image: deletedDocuments.reduce(
                  (acc, val) => `${acc}${!!acc ? '&' : '?'}docsIds[]=${val.id}`,
                  '',
                ),
              }),
            )

          setNewClinicDocuments([])
          setNewClinicPhotos([])
          setDeletedPhotos([])
          setDeletedDocuments([])
          disableValidation()
        }
      } else {
        dispatch(showToastAction.request(generateErrorToast(t('placeType'))))
      }
    } catch (error) {
      console.log(error)
      Log.ruddy('Event: onPressSaveChanges')
      //@ts-ignore
      error?.map(err => {
        dispatch(showToastAction.request(generateErrorToast(err)))
      })
    }
  }

  const onPressDeleteClinic = () => {
    try {
      const result = window.confirm(t('deleteClinic'))
      if (result) {
        dispatch(
          deleteClinicAction({
            authorization: '',
            id,
          }),
        )

        setNewClinicDocuments([])
        setNewClinicPhotos([])
        setDeletedPhotos([])
        setDeletedDocuments([])
        setClinicPhotos([])
        setClinicDocuments([])
        setFormNoValid({ photo: null })

        history.goBack()
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeleteClinic')
    }
  }

  //eslint-disable-next-line
  useEffect(() => {
    if (id !== 'undefined') dispatch(getClinicByIdAction.request({ id }))

    return () => {
      dispatch(clearClinicAction())
      setNewClinicDocuments([])
      setNewClinicPhotos([])
      setDeletedPhotos([])
      setDeletedDocuments([])
      setClinicPhotos([])
      setClinicDocuments([])
      setFormNoValid({ photo: null })
    }
  }, [dispatch, id])

  //eslint-disable-next-line
  useEffect(() => {
    if (id !== 'undefined') {
      //@ts-ignore
      setForm(prev => ({
        ...prev,
        description: clinic?.description ?? {
          ru: '',
          uk: '',
          en: '',
          pl: '',
          cs: '',
          de: '',
          es: '',
          fr: '',
          it: '',
          ch: '',
          hi: '',
          ja: '',
          ko: '',
          tr: '',
          gr: '',
          kt: '',
          sv: '',
          sk: '',
          th: '',
          hr: '',
          fi: '',
          sl: '',
        },

        petType: clinic?.petType.map(item => item._id) || [],

        networkName: clinic?.networkName ?? '',
        phones: clinic?.contactInfo?.phone ?? [],
        medicalDirections:
          clinic?.medicalDirections.map(item => ({
            name: item.title[language as LanguageKeys],
            id: item._id,
          })) ?? [],
        title: clinic?.name ?? {
          ru: '',
          uk: '',
          en: '',
          pl: '',
          cs: '',
          de: '',
          es: '',
          fr: '',
          it: '',
          ch: '',
          hi: '',
          ja: '',
          ko: '',
          tr: '',
          gr: '',
          kt: '',
          sv: '',
          sk: '',
          th: '',
          hr: '',
          fi: '',
          sl: '',
        },
        address: clinic?.contactInfo?.location?.address ?? {
          ru: '',
          en: '',
          uk: '',
        },
        city: clinic?.contactInfo?.location?.district ?? {
          ru: '',
          en: '',
          uk: '',
        },
        coordinates: {
          lat: clinic?.contactInfo?.location?.coords?.coordinates[1] ?? 1,
          lng: clinic?.contactInfo?.location?.coords?.coordinates[0] ?? 1,
        },
        email: clinic?.contactInfo?.email ?? '',
        site: clinic?.contactInfo?.site ?? '',
        specialisation: clinic?.specialisation
          ? {
              title: clinic?.specialisation.title[language as LanguageKeys],
              id: clinic?.specialisation._id,
            }
          : {
              title: '',
              id: '',
            },
        additionalOptions: clinic?.additionalOptions.map(item => ({
          title: item?.title?.[language as LanguageKeys]
            ? item.title[language as LanguageKeys]
            : '',
          id: item._id ? item._id : '',
        })),
      }))
      setFormNoValid(prev => ({
        ...prev,
        photo: clinic?.meta?.logo ?? null,
      }))
      setClinicPhotos(clinic?.meta?.photos ?? [])
      setClinicDocuments(clinic?.meta?.attachments ?? [])

      clinic?.schedule?.lunchHours &&
        setAdditionalFields(prev => ({
          ...prev,
          lunch: {
            ...clinic?.schedule?.lunchHours,
            start: add(startOfDay(new Date()), {
              hours: Number(
                String(clinic?.schedule?.lunchHours?.start).split(':')?.[0],
              ),
              minutes: Number(
                String(clinic?.schedule?.lunchHours?.start).split(':')?.[1],
              ),
            }).getTime(),
            end: add(startOfDay(new Date()), {
              hours: Number(
                String(clinic?.schedule?.lunchHours?.end).split(':')?.[0],
              ),
              minutes: Number(
                String(clinic?.schedule?.lunchHours?.end).split(':')?.[1],
              ),
            }).getTime(),
          },
        }))

      if (
        !!clinic?.schedule?.workingHours &&
        typeof clinic?.schedule?.workingHours?.monday.start === 'string' &&
        typeof clinic?.schedule?.workingHours?.monday.end === 'string' &&
        typeof clinic?.schedule?.workingHours?.tuesday.start === 'string' &&
        typeof clinic?.schedule?.workingHours?.tuesday.end === 'string' &&
        typeof clinic?.schedule?.workingHours?.wednesday.start === 'string' &&
        typeof clinic?.schedule?.workingHours?.wednesday.end === 'string' &&
        typeof clinic?.schedule?.workingHours?.thursday.start === 'string' &&
        typeof clinic?.schedule?.workingHours?.thursday.end === 'string' &&
        typeof clinic?.schedule?.workingHours?.friday.start === 'string' &&
        typeof clinic?.schedule?.workingHours?.friday.end === 'string' &&
        typeof clinic?.schedule?.workingHours?.saturday.start === 'string' &&
        typeof clinic?.schedule?.workingHours?.saturday.end === 'string' &&
        typeof clinic?.schedule?.workingHours?.sunday.start === 'string' &&
        typeof clinic?.schedule?.workingHours?.sunday.end === 'string'
      ) {
        if (
          clinic?.schedule?.details?.everyDay ||
          clinic?.schedule?.details?.allDay ||
          clinic?.schedule?.details?.everyDay
        ) {
          if (clinic?.schedule?.details?.everyDay) {
            !!clinic?.schedule?.workingHours &&
              setAdditionalFields(prev => ({
                ...prev,
                everyday: {
                  start: add(startOfDay(new Date()), {
                    hours: Number(
                      String(
                        clinic?.schedule?.workingHours?.monday?.start,
                      ).split(':')?.[0],
                    ),
                    minutes: Number(
                      String(
                        clinic?.schedule?.workingHours?.monday?.start,
                      ).split(':')?.[1],
                    ),
                  }).getTime(),
                  end: add(startOfDay(new Date()), {
                    hours: Number(
                      String(clinic?.schedule?.workingHours?.monday?.end).split(
                        ':',
                      )?.[0],
                    ),
                    minutes: Number(
                      String(clinic?.schedule?.workingHours?.monday?.end).split(
                        ':',
                      )?.[1],
                    ),
                  }).getTime(),
                  enable: true,
                },
              }))

            setTimePicker(prev => ({
              ...prev,
              ...Object.keys(timePicker).reduce(
                (i, el) => ({
                  ...i,
                  [el]: {
                    weekend: false,
                    start: add(startOfDay(new Date()), {
                      hours: Number(
                        String(
                          clinic?.schedule?.workingHours?.monday?.start,
                        ).split(':')?.[0],
                      ),
                      minutes: Number(
                        String(
                          clinic?.schedule?.workingHours?.monday?.start,
                        ).split(':')?.[1],
                      ),
                    }).getTime(),
                    end: add(startOfDay(new Date()), {
                      hours: Number(
                        String(
                          clinic?.schedule?.workingHours?.monday?.end,
                        ).split(':')?.[0],
                      ),
                      minutes: Number(
                        String(
                          clinic?.schedule?.workingHours?.monday?.end,
                        ).split(':')?.[1],
                      ),
                    }).getTime(),
                  },
                }),
                {},
              ),
            }))
          } else {
            setAdditionalFields(prev => ({
              ...prev,
              everyday: {
                start: new Date().setHours(0, 0, 0, 0),
                end: new Date().setHours(20, 0, 0, 0),
                enable: false,
              },
            }))
          }

          if (clinic?.schedule?.details?.allDay) {
            setAdditionalFields(prev => ({ ...prev, aroundTheClock: true }))
            setTimePicker(prev => ({
              ...prev,
              ...Object.keys(timePicker).reduce(
                (i, el) => ({
                  ...i,
                  [el]: {
                    weekend: false,
                    start: new Date().setHours(0, 0, 0, 0),
                    end: new Date().setHours(23, 59, 59, 999),
                  },
                }),
                {},
              ),
            }))
          } else {
            setAdditionalFields(prev => ({ ...prev, aroundTheClock: false }))
          }
        } else {
          clinic?.schedule.workingHours
            ? setTimePicker(prev => ({
                ...prev,
                ...Object.keys(timePicker).reduce(
                  (i, el) => ({
                    ...i,
                    [el]: {
                      weekend: clinic?.schedule?.workingHours
                        ? //@ts-ignore
                          clinic?.schedule?.workingHours[
                            (el as keyof typeof timePicker) ?? ''
                          ]?.end === '00:00'
                          ? true
                          : false
                        : false,
                      start: add(startOfDay(new Date()), {
                        hours: Number(
                          String(
                            clinic?.schedule?.workingHours[
                              (el as keyof typeof timePicker) ?? ''
                            ]?.start,
                          ).split(':')?.[0],
                        ),
                        minutes: Number(
                          String(
                            clinic?.schedule?.workingHours[
                              (el as keyof typeof timePicker) ?? ''
                            ]?.start,
                          ).split(':')?.[1],
                        ),
                      }).getTime(),
                      end: add(startOfDay(new Date()), {
                        hours: Number(
                          String(
                            clinic?.schedule?.workingHours[
                              (el as keyof typeof timePicker) ?? ''
                            ]?.end,
                          ).split(':')?.[0],
                        ),
                        minutes: Number(
                          String(
                            clinic?.schedule?.workingHours[
                              (el as keyof typeof timePicker) ?? ''
                            ]?.end,
                          ).split(':')?.[1],
                        ),
                      }).getTime(),
                    },
                  }),
                  {},
                ),
              }))
            : setTimePicker(prev => ({
                ...prev,
                ...Object.keys(timePicker).reduce(
                  (i, el) => ({
                    ...i,
                    [el]: {
                      weekend: false,
                      start: new Date().setHours(0, 0, 0, 0),
                      end: new Date().setHours(20, 0, 0, 0),
                    },
                  }),
                  {},
                ),
              }))

          setAdditionalFields(prev => ({
            ...prev,
            everyday: {
              start: new Date().setHours(0, 0, 0, 0),
              end: new Date().setHours(20, 0, 0, 0),
              enable: false,
            },
            planned: false,
            aroundTheClock: false,
          }))
        }

        if (clinic?.schedule?.details?.byAppointment) {
          setAdditionalFields(prev => ({ ...prev, planned: true }))
        } else {
          setAdditionalFields(prev => ({ ...prev, planned: false }))
        }
      } else {
        setTimePicker(prev => ({
          ...prev,
          ...Object.keys(timePicker).reduce(
            (i, el) => ({
              ...i,
              [el]: {
                weekend: false,
                start: new Date().setHours(0, 0, 0, 0),
                end: new Date().setHours(20, 0, 0, 0),
              },
            }),
            {},
          ),
        }))
      }
    } else {
      setNewClinicDocuments([])
      setNewClinicPhotos([])
      history.push(`/clinic-info/${clinic?._id}`)
    }
  }, [clinic, clinic?.schedule?.workingHours])

  const handleBack = () => {
    history.goBack()
  }

  const navigatorFeedbacksHandler = () => {
    history.push(`/review/${clinic?._id}`)
  }

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <StyledTitle>
          {Assets.LEFT_ARROW_ICON ? (
            <StyledLinkDiv onClick={handleBack}>
              <Image src={Assets.LEFT_ARROW_ICON} />
            </StyledLinkDiv>
          ) : (
            ''
          )}

          <Title>{t('functionals:shelter')}</Title>

          <Div> </Div>
        </StyledTitle>
        <Divider height={10} />
        <StyledPhotoFields>
          {typeof formNoValid?.photo === 'string' ? (
            <ProfileImage
              src={api.withImageAPI + '/clinic/' + formNoValid?.photo}
            />
          ) : !!formNoValid.photo ? (
            <SuccessText>{t('selected')}</SuccessText>
          ) : (
            <FailedText>{t('notSelected')}</FailedText>
          )}

          <StyledSearch>
            <StyledLabel htmlFor="photo">
              <Image src={Assets.DOWNLOAD_ARROW_ICON} />
            </StyledLabel>
            <StyledInputPhoto
              id="photo"
              name="photo"
              type="file"
              value=""
              accept="image/*"
              onChange={v => takeImageHandler(v)}
            />

            <ClearImage
              onClick={() => clearImage('photo')}
              src={Assets.CYAN_ICON}
            />
          </StyledSearch>
        </StyledPhotoFields>
        <Divider height={10} />
        <Label>{t('language')}</Label>
        <Divider height={10} />
        <StyledDropDown>
          <Dropdown
            options={languages}
            onChange={option => changeType(option.value)}
            value={language}
            placeholder="Виберите язык"
          />
        </StyledDropDown>
        <Divider height={30} />
        <StyledEntity>
          <Input
            label={`${t('title')} - ` + language}
            name="title"
            value={form.title[language as keyof typeof form.title] ?? ''}
            placeholder={t('enterTitle')}
            template={EInputTemplate.NAME}
            onChange={v => inputMultiLangHandler(v)}
            error={schema.title.error || ''}
            isValid={!isEnabled || validationSchema.title}
          />
        </StyledEntity>
        <Divider height={10} />
        <StyledInputWrapper>
          <Label>
            {t('description')} - {language}
          </Label>

          <Divider height={10} />

          <StyledTextArea
            required
            name="description"
            placeholder={t('enterDescription')}
            value={
              form.description[language as keyof typeof form.description] ?? ''
            }
            onChange={inputMultiLangHandler}
          ></StyledTextArea>
        </StyledInputWrapper>
        <Divider height={20} />
        <StyledEntity>
          <Input
            label="Email"
            name="email"
            value={form.email ?? ''}
            placeholder={t('email')}
            template={EInputTemplate.NAME}
            onChange={v => inputHandler(v)}
          />
        </StyledEntity>
        <Divider height={10} />
        <StyledEntity>
          <Input
            label={t('website')}
            name="site"
            value={form.site ?? ''}
            placeholder={t('website')}
            template={EInputTemplate.NAME}
            onChange={v => inputHandler(v)}
          />
        </StyledEntity>
        <Divider height={10} />
        {id !== 'undefined' && (
          <>
            <StyledEntity>
              <Input
                label={t('created')}
                name="created"
                value={`${clinic?.createdBy?.email ?? '-'} ${moment(
                  clinic?.createdAt ?? '-',
                ).format('HH:mm DD.MM.YYYY')}`}
                template={EInputTemplate.DEFAULT}
                onChange={() => {}}
                disabled
              />
            </StyledEntity>
            <Divider height={10} />
            <StyledEntity>
              <Input
                label={t('modified')}
                name="edited"
                value={`${clinic?.updatedBy?.email ?? '-'} ${moment(
                  clinic?.updatedAt ?? '-',
                ).format('HH:mm DD.MM.YYYY')}`}
                template={EInputTemplate.DEFAULT}
                onChange={() => {}}
                disabled
              />
            </StyledEntity>
            <Divider height={10} />
          </>
        )}
        <Map
          form={form}
          setForm={setForm}
          schema={schema}
          validationSchema={validationSchema}
          isEnabled={isEnabled}
        />
        <Divider height={30} />
        <Category form={form} setForm={setForm} lang={language} />
        <Divider height={10} />
        <Services form={form} setForm={setForm} lang={language} />
        <Divider height={10} />
        <PhoneNumbers form={form} setForm={setForm} />
        <Divider height={10} />
        <WorkingTime
          timePicker={timePicker}
          setTimePicker={setTimePicker}
          additionalFields={additionalFields}
          setAdditionalFields={setAdditionalFields}
        />
        <Divider height={10} />
        <PetsTypes form={form} setForm={setForm} lang={language} />
        <Divider height={30} />

        {id !== 'undefined' && (
          <ButtonContainer>
            <StyledSaveButton onClick={navigatorFeedbacksHandler}>
              {t('clinicReviews')}
            </StyledSaveButton>
          </ButtonContainer>
        )}
        <ShowPhotos
          items={[...clinicPhotos, ...newClinicPhotos]}
          setItems={setNewClinicPhotos}
          setDeleted={setDeletedPhotos}
          setFixedItems={setClinicPhotos}
          type={t('gallery')}
        />
        <Divider height={20} />
        <ShowPhotos
          items={[...clinicDocuments, ...newClinicDocuments]}
          setItems={setNewClinicDocuments}
          setDeleted={setDeletedDocuments}
          setFixedItems={setClinicDocuments}
          type={t('documentation')}
        />
        <Divider height={20} />
        {id === 'undefined' ? (
          <StyledSaveButton onClick={onPressSaveChanges}>
            {t('create')}
          </StyledSaveButton>
        ) : (
          <StyledContainer>
            <DeleteButton onClick={onPressDeleteClinic}>
              {t('delete')}
            </DeleteButton>

            <SaveButton onClick={onPressSaveChanges}>{t('save')}</SaveButton>
          </StyledContainer>
        )}
        <Divider height={10} />
        <BottomDiv />
      </LocalizationProvider>
    </Container>
  )
}

export default SocialClinicInfo
