import React, { FC, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { Button, Divider, EInputTemplate, Input } from '../../components'
import { useValidation } from '../../hooks'
import { Log } from '../../utils'
import { Container, Title, LoginWrapper, StyledLink } from './styled'
import { TForm, TChangePasswordValidationSchema } from './types'
import { changePasswordAction } from '../../store'
import { RoutesTree } from '../../router'

const useQuery = () => new URLSearchParams(useLocation().search)

export const ChangePassword: FC = () => {
  const [form, setForm] = useState<TForm>({ password: '', repeatPassword: '' })

  const dispatch = useDispatch()

  const query = useQuery()

  const inputHandler = useCallback(
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )

  const schema = useMemo<TChangePasswordValidationSchema>(
    () => ({
      password: {
        condition: !!form.password && form.password.length >= 6,
        error: `Enter password`,
      },
      repeatPassword: {
        condition:
          !!form.repeatPassword && form.repeatPassword === form.password,
        error: `Repeat password`,
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
  } = useValidation(schema)

  const onPressChangePassword = async () => {
    try {
      enableValidation()
      await validation()

      dispatch(
        changePasswordAction({
          password: form.password,
          authorization: query.get('token') ?? '',
        }),
      )

      disableValidation()
    } catch (error) {
      Log.ruddy('Event: onPressChangePassword')
    }
  }

  if (!query.get('token')) {
    return <Redirect to={RoutesTree.login.path} />
  }

  return (
    <Container>
      <LoginWrapper>
        <Title>Изменить пароль</Title>

        <Divider height={20} />

        <Input
          label="Пароль"
          name="password"
          value={form.password}
          placeholder="Ex: Demo1234"
          template={EInputTemplate.PASSWORD}
          error={schema.password.error || ''}
          onChange={v => inputHandler(v)}
          isValid={!isEnabled || validationSchema.password}
        />

        <Input
          label="Повторите пароль"
          name="repeatPassword"
          value={form.repeatPassword}
          placeholder="Ex: Demo1234"
          template={EInputTemplate.PASSWORD}
          error={schema.repeatPassword.error || ''}
          onChange={v => inputHandler(v)}
          isValid={!isEnabled || validationSchema.repeatPassword}
        />

        <Divider height={10} />

        <Button
          title="Изменить пароль"
          onClick={onPressChangePassword}
          type="submit"
        />

        <Divider height={20} />

        <StyledLink
          to={{
            pathname: RoutesTree.login.path,
          }}
        >
          Авторизация
        </StyledLink>
      </LoginWrapper>
    </Container>
  )
}

export default ChangePassword
