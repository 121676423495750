import { createReducer } from '@reduxjs/toolkit'

import {
  createCategoriesAdsAction,
  createSubCategoriesAdsAction,
  deleteCategoriesAdsAction,
  deleteSubCategoriesAdsAction,
  getCategoriesAdsAction,
  getSubCategoriesAdsAction,
  updateCategoriesAdsAction,
  updateSubCategoriesAdsAction,
} from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
  categoriesAds: [],
  subCategoriesAds:[],
  loading: false,
  totalCount: 0,
  subCategoryTotalCount: 0,
  response: null,
}

export const categoriesReducer = createReducer<TInitialState>(InitialState, builder => {
  builder.addCase(getCategoriesAdsAction.request, state => ({
    ...state,
    loading: true,
  }))
  builder.addCase(getCategoriesAdsAction.success, (state, { payload }) => ({
    ...state,
    categoriesAds: payload.categoriesAds,
    totalCount: payload.totalCount,
    loading: false,
    response: "GET_CATEGORIES"
  }))
  builder.addCase(getCategoriesAdsAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    response: "GET_CATEGORIES"
  }))
  builder.addCase(createCategoriesAdsAction.request, state => ({
    ...state,
    loading: true,
  }))
  builder.addCase(createCategoriesAdsAction.success, (state, { payload }) => ({
    ...state,
    loading: false,
    response: 'CREATE_CATEGORIES'
  }))
  builder.addCase(createCategoriesAdsAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    response: 'CREATE_CATEGORIES'
  }))

  builder.addCase(updateCategoriesAdsAction.request, state => ({
    ...state,
    loading: true,
  }))
  builder.addCase(updateCategoriesAdsAction.success, (state, { payload }) => ({
    ...state,
    loading: false,
    response: 'UPDATE_CATEGORIES'
  }))
  builder.addCase(updateCategoriesAdsAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    response: 'UPDATE_CATEGORIES'
  }))

  builder.addCase(deleteCategoriesAdsAction.request, state => ({
    ...state,
    loading: true,
  }))
  builder.addCase(deleteCategoriesAdsAction.success, (state, { payload }) => ({
    ...state,
    loading: false,
    response: 'DELETE_CATEGORIES'
  }))
  builder.addCase(deleteCategoriesAdsAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    response: 'DELETE_CATEGORIES'
  }))

  builder.addCase(getSubCategoriesAdsAction.request, state => ({
    ...state,
    loading: true,
  }))
  builder.addCase(getSubCategoriesAdsAction.success, (state, { payload }) => ({
    ...state,
    subCategoriesAds: payload.subCategoriesAds,
    subCategoryTotalCount: payload.subCategoryTotalCount,
    loading: false,
    response: "GET_CATEGORIES"
  }))
  builder.addCase(getSubCategoriesAdsAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    response: "GET_CATEGORIES"
  }))
  builder.addCase(createSubCategoriesAdsAction.request, state => ({
    ...state,
    loading: true,
  }))
  builder.addCase(createSubCategoriesAdsAction.success, (state, { payload }) => ({
    ...state,
    loading: false,
    response: 'CREATE_CATEGORIES'
  }))
  builder.addCase(createSubCategoriesAdsAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    response: 'CREATE_CATEGORIES'
  }))

  builder.addCase(updateSubCategoriesAdsAction.request, state => ({
    ...state,
    loading: true,
  }))
  builder.addCase(updateSubCategoriesAdsAction.success, (state, { payload }) => ({
    ...state,
    loading: false,
    response: 'UPDATE_CATEGORIES'
  }))
  builder.addCase(updateSubCategoriesAdsAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    response: 'UPDATE_CATEGORIES'
  }))

  builder.addCase(deleteSubCategoriesAdsAction.request, state => ({
    ...state,
    loading: true,
  }))
  builder.addCase(deleteSubCategoriesAdsAction.success, (state, { payload }) => ({
    ...state,
    loading: false,
    response: 'DELETE_CATEGORIES'
  }))
  builder.addCase(deleteSubCategoriesAdsAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    response: 'DELETE_CATEGORIES'
  }))

})
