import React from 'react'
import { Divider } from '../../../components'
import { FullWidth, ProfileInput, StyledCategory } from '../styled'
import { TCategoryObject } from '../types'

export type TMultiLangForm = {
  description: {}
  name: {}
  specialisation: {}
}

export const makeMultiLangForm = (languages: string[]) => {
  return languages.reduce(
    (store, lang) => ({
      ...store,
      description: { ...store.description, [lang]: '' },
      name: { ...store.name, [lang]: '' },
      specialisation: { ...store.specialisation, [lang]: '' },
    }),
    {} as TMultiLangForm,
  )
}

export const generateCategory = (
  selectedCategory: string,
  handleCheck: (data: TCategoryObject) => void,
  categories?: TCategoryObject[],
) => {
  return categories?.map(category => (
    <FullWidth key={category.id}>
      <ProfileInput
        name={category.id}
        type="radio"
        checked={selectedCategory === category.id}
        onChange={() => handleCheck(category)}
      />
      <Divider width={10} />
      <StyledCategory>{category.title}</StyledCategory>
    </FullWidth>
  ))
}
