import styled, { css } from 'styled-components'

import { TImageButton } from './types'
import { Assets } from '../../assets'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .photo-input {
    display: none;
  }
`

export const Text = styled.span`
  color: #1f166a;
  font-weight: 700;
  font-size: 16px;
`

export const Block = styled.div<TImageButton>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ src }) =>
    src &&
    css`
      display: none;
    `}
`

export const ContainerPhotoPicker = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
`

export const PhotoPickerBlock = styled.div`
  position: relative;
  display: flex;
`

export const ImageButton = styled.button<TImageButton>`
  position: relative;

  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ src }) => (src ? 'none' : `1px dashed #1f166a`)};

  background: #d1d1f0;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  cursor: pointer;
`
