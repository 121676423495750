import styled from 'styled-components'
import { COLORS } from '../../../../assets'

export const Container = styled.div`
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.25);

  background-color: ${COLORS.white};

  padding: 20px;

  width: 250px;

  position: absolute;
  top: 70px;
  left: 0;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
