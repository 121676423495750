import React, { useCallback, useMemo, useState } from 'react'
import Table from 'rc-table'
import { Button, Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  FullWidth,
  Header,
  Title,
  TableWrapper,
  StyledRow,
  StyledHeader,
  StyledTable,
  StyledLink,
  StyledPagination,
  StyledButtonEnd,
  StyledButton,
  InputContainer,
  StatusContainer,
} from './styled'
import { useColumns, useTypedSelector } from '../../hooks'
import { RoutesTree } from '../../router'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { Service } from '../../services'
import moment from 'moment'
import { CategoryFilter, DateFilter, StatusFilter } from './components'
import {
  useCategoryFilter,
  useDateFilter,
  useSearchFilter,
  useSort,
  useStatusFilter,
} from './hooks'

export const Appointments: React.FC = () => {
  const { t } = useTranslation('appointments')
  const { user, language: lang } = useTypedSelector(state => state.user)

  const [showStatusFilter, setShowStatusFilter] = useState(false)

  const { status, setStatus, clearStatusFilter } = useStatusFilter()
  const { medicalDirection, setMedicalDirection, clearCategoryFilter } =
    useCategoryFilter()
  const { startDate, endDate, setStartDate, setEndDate, clearDateFilter } =
    useDateFilter()
  const { search, setSearch, clearSearchFilter } = useSearchFilter()
  const { sortBy, setSortBy, order, setOrder, page, setPage, clearSort } =
    useSort()

  const clearAllFilters = useCallback(async () => {
    await Promise.all([
      clearStatusFilter(),
      clearCategoryFilter(),
      clearDateFilter(),
      clearSearchFilter(),
      clearSort(),
    ])
  }, [])

  const { data } = useSWR(
    [
      'get-appointments',
      {
        ...(sortBy ? { sortBy } : {}),
        ...(order ? { order } : {}),
        ...(search ? { title: search } : {}),
        ...(medicalDirection ? { medicalDirections: medicalDirection } : {}),
        ...(status ? { status } : {}),
        ...(startDate ? { createdAtLbDate: startDate } : {}),
        ...(endDate ? { createdAtHbDate: endDate } : {}),
        page,
      },
    ],
    ([_, key]) => Service.Appointments.getAppointments(key),
  )

  const sort = (field: string) => {
    setSortBy(field)

    if (order == -1) {
      setOrder(1)
      return
    }

    setOrder(-1)
  }
  const putTitle = (e: any) => {
    setSearch(e.target.value)
  }

  const { columns } = useColumns({ sort, sortBy, order })

  if (!user?.access?.tags) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  // eslint-disable-next-line
  const total = useMemo(() => {
    return data?.totalCount ?? 0
  }, [data])

  return (
    <Container>
      <div>
        <Title>
          {t('appointments')} ({data?.totalCount ?? 0})
        </Title>

        <Header>
          <InputContainer>
            <Input
              label={t('search')}
              name="search"
              value={search}
              placeholder={t('title')}
              template={EInputTemplate.NAME}
              onChange={putTitle}
            />
          </InputContainer>

          <DateFilter
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />

          <CategoryFilter
            option={medicalDirection}
            setOption={setMedicalDirection}
          />

          <StatusContainer>
            <Button
              type="submit"
              title={t('filter')}
              onClick={() => setShowStatusFilter(prev => !prev)}
              padding={'10px'}
            />

            {showStatusFilter && (
              <StatusFilter
                selectedStatuses={status}
                setSelectedStatuses={setStatus}
              />
            )}
          </StatusContainer>

          <StatusContainer>
            <Button
              type="submit"
              title={t('clearFilter')}
              onClick={clearAllFilters}
              padding={'10px'}
            />
          </StatusContainer>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          {data?.docs && (
            <Table
              columns={columns}
              data={data?.docs?.map(appointment => ({
                title: appointment?.title,
                owner: appointment?.owner?.name,
                createdAt: moment(appointment?.createdAt).isValid()
                  ? moment(appointment?.createdAt).format('HH:mm DD.MM.YYYY')
                  : '-',
                category: appointment?.medicalDirections?.title[lang],
                status: t(appointment.status),
                details: (
                  <StyledLink
                    to={{
                      pathname: `/appointments/${appointment?._id}`,
                    }}
                  >
                    {t('details')}
                  </StyledLink>
                ),
              }))}
              scroll={{ y: 650 }}
              components={{
                body: {
                  row: StyledRow,
                },
                header: {
                  cell: StyledHeader,
                },
                table: StyledTable,
              }}
            />
          )}
        </TableWrapper>
      </div>

      <FullWidth>
        <StyledPagination>
          {page === 1 ? (
            <StyledButtonEnd> {t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => setPage(prev => prev - 1)}>
              {t('back')}
            </StyledButton>
          )}

          <div>
            {page}/
            {!total
              ? 1
              : Math.ceil(total / 20) === 0
              ? 1
              : Math.ceil(total / 20)}
          </div>

          {1 === (!total ? 1 : Math.ceil(total / 20)) ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => setPage(prev => prev + 1)}>
              {t('next')}
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}
