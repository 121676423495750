import { api } from '../config'

export const ADS_URL = {
  get_ads: () => `${api.withAPI}ads/api/v1/admin/ads`,
  getAdsById: (id: string) => `${api.withAPI}ads/api/v1/admin/ads/${id}`,
  createAds: `${api.withAPI}ads/api/v1/admin/ads`,
  updateAds: (id: string) => `${api.withAPI}ads/api/v1/admin/ads/${id}`,
  deleteAds: (id: string) => `${api.withAPI}ads/api/v1/admin/ads/${id}`,
  getComplaints: (page: number, limit: number) =>
    `${api.withAPI}user/api/v1/admin/complaint/?page=${page}&limit=${limit}`,
  deleteComplaintAds: (id: string) =>
    `${api.withAPI}user/api/v1/admin/complaint/${id}`,
  getComplaintAdsById: (id: string) =>
    `${api.withAPI}user/api/v1/admin/complaint/${id}`,
}
