import { useMemo } from 'react'
import { Appointment } from '../../../services/appointments/types'
import { useTypedSelector } from '../../../hooks'

type UseDispatchDataProps = {
  data: Appointment | undefined
}

export const useDispatchData = ({ data }: UseDispatchDataProps) => {
  const { language } = useTypedSelector(state => state.user)

  const dispatchedData = useMemo(() => {
    if (!data) {
      return {
        title: '',
        description: '',
        owner: '',
        executor: '',
        medicalDirections: {
          label: '',
          value: '',
        },
        media: [],
      }
    }

    const title = data?.title
    const description = data?.description
    const owner = data?.owner?.name
    const executor = data?.executor?.name ?? ''
    const medicalDirections = {
      label: data?.medicalDirections?.title[language] ?? '',
      value: data?.medicalDirections?._id ?? '',
    }
    const pet = {
      label: data?.pet?.name ?? '',
      value: data?.pet?._id ?? '',
    }

    return {
      title,
      description,
      owner,
      executor,
      medicalDirections,
      pet,
    }
  }, [data, language])

  return {
    dispatchedData,
  }
}
