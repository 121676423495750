import { all } from 'redux-saga/effects'

import { toastsSaga } from './toasts'
import { userWatcher } from './user'
import { customerWatcher } from './customers'
import { petsWatcher } from './pets'
import { adminsWatcher } from './settings'
import { infoSectionWatcher } from './infoSection'
import { clinicsWatcher } from './clinics'
import { feedbackWatcher } from './feedback'
import { controlWatcher } from './control'
import { placesWatcher } from './places'
import { notificationsWatcher } from './notifications'
import { dashboardWatcher } from './dashboard'
import { supportWatcher } from './support'
import { eventsboardWatcher } from './eventsboard'
import { stackpropsWatcher } from './stackprops'
import { adsWatcher } from './ads'
import { categoriesWatcher } from './categoriesAds'
import { categoriesClinicsWorker } from './clinicsCategory'

function* rootSaga() {
  yield all([
    toastsSaga(),
    userWatcher(),
    customerWatcher(),
    petsWatcher(),
    adminsWatcher(),
    infoSectionWatcher(),
    clinicsWatcher(),
    feedbackWatcher(),
    controlWatcher(),
    placesWatcher(),
    notificationsWatcher(),
    dashboardWatcher(),
    supportWatcher(),
    eventsboardWatcher(),
    stackpropsWatcher(),
    adsWatcher(),
    categoriesWatcher(),
    categoriesClinicsWorker()
  ])
}

export default rootSaga
