import { serviceInstance } from '../http.service'
import {
  CreateTagRequest,
  DeleteTagRequest,
  GetTagRequest,
  GetTagsRequest,
  UpdateTagRequest,
} from './types'

export class Tag {
  static async getTags(
    params: GetTagsRequest['payload'],
  ): Promise<GetTagsRequest['response']> {
    const response = await serviceInstance.get('ads/api/v1/admin/tags', {
      params,
    })

    return response.data
  }

  static async getTag({
    _id,
  }: GetTagRequest['payload']): Promise<GetTagRequest['response']> {
    const response = await serviceInstance.get(`ads/api/v1/admin/tags/${_id}`)

    return response.data
  }

  static async createTag(
    data: CreateTagRequest['payload'],
  ): Promise<CreateTagRequest['response']> {
    const response = await serviceInstance.post('ads/api/v1/admin/tags', data)

    return response.data
  }

  static async updateTag({
    data,
    _id,
  }: UpdateTagRequest['payload']): Promise<UpdateTagRequest['response']> {
    const response = await serviceInstance.put(
      `ads/api/v1/admin/tags/${_id}`,
      data,
    )

    return response.data
  }

  static async deleteTag({
    _id,
  }: DeleteTagRequest['payload']): Promise<DeleteTagRequest['response']> {
    const response = await serviceInstance.delete(
      `ads/api/v1/admin/tags/${_id}`,
    )

    return response.data
  }
}
