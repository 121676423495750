import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getUserAction,
  logoutAction,
  loginAction,
  initAppAction,
  changeLanguage,
} from './actions'

const InitialState: TInitialState = {
  user: null,
  token: null,
  loading: false,
  language: 'uk',
}

export const userReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(loginAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(loginAction.success, (state, { payload }) => ({
      ...state,
      token: payload.accessToken,
      user: payload.user,
      loading: false,
    }))
    builder.addCase(loginAction.failure, (state, { payload }) => ({
      ...state,
      token: null,
      user: null,
      loading: false,
    }))

    builder.addCase(getUserAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getUserAction.success, (state, { payload }) => ({
      ...state,
      user: payload,
      loading: false,
    }))
    builder.addCase(getUserAction.failure, (state, { payload }) => ({
      ...state,
      token: null,
      user: null,
      loading: false,
    }))

    builder.addCase(logoutAction, state => ({
      ...state,
      token: null,
      user: null,
    }))

    builder.addCase(initAppAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
    builder.addCase(changeLanguage, (state, { payload }) => ({
      ...state,
      language: payload,
    }))
  },
)
