import { useState, useEffect, useRef } from 'react'

import {
  Container,
  ImageButton,
  ContainerPhotoPicker,
  PhotoPickerBlock,
  Block,
  Text,
} from './styled'
import { TPhotoPicker } from './types'
import { t } from 'i18next'

type Photo = {
  id: number
  url: string
}

export const PhotoPicker = ({
  width,
  height,
  name,
  setArrayPhotos,
  onChange,
  value,
  index,
  isArray,
  borderRadius = '2%',
}: TPhotoPicker) => {
  const [photo, setPhoto] = useState<string>(value as string)
  const [file, setFile] = useState<File | null>()

  const inputRef = useRef<HTMLInputElement>(null)

  const Events = {
    buttonClickHandler: () => {
      const input = inputRef.current as HTMLInputElement

      input.onchange = e => {
        const input = e.target as HTMLInputElement

        const newFile = input.files ? input.files[0] : null

        setFile(newFile)
        ;(e.target as HTMLInputElement).value = ''
      }

      input.click()
    },
  }

  const Utils = {
    readFileAsImage: () => {
      const reader = new FileReader()
      file && reader.readAsDataURL(file)
      reader.onload = e => {
        setPhoto((e.target as FileReader).result as string)
      }
      reader.onload = e => {
        if (isArray) {
          setArrayPhotos((prevState: Photo[]) => [
            ...prevState,
            (e.target as FileReader).result as string,
          ])
        } else {
          setPhoto((e.target as FileReader).result as string)
        }
      }
    },
  }

  useEffect(() => {
    file && Utils.readFileAsImage()
    file !== undefined &&
      onChange &&
      onChange(file, index as number, name as string)
  }, [file])

  useEffect(() => {
    setPhoto(value as string)
  }, [value])

  return (
    <Container>
      <ContainerPhotoPicker>
        <PhotoPickerBlock>
          <ImageButton
            width={width}
            height={height}
            type="button"
            onClick={Events.buttonClickHandler}
            src={photo}
            borderRadius={borderRadius}
          >
            <Block src={photo}>
              <Text>{t('categories:selectPhoto')}</Text>
            </Block>
            <input
              className="photo-input"
              name={name + 'input'}
              ref={inputRef}
              type="file"
            />
          </ImageButton>
        </PhotoPickerBlock>
      </ContainerPhotoPicker>
    </Container>
  )
}
