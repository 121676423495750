import { Assets } from '../../../../assets'
import { languageKeysArray } from '../../../../consts'
import {
  CreteForm,
  Errormessage,
  HeadingWrap,
  InputsWrap,
} from '../../../ApplicationContent/components/CreateServiceWindow/styled'
import { TEditMedicalDirectionWindow } from './type'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useGetCurrentMedicalDirection } from '../../services'
import { TCreateMedicalDirForm } from '../CreateDirection/types'
import { eitMedicalDirection, deleteMedicalDirection } from '../../services'
import { useEffect } from 'react'
import { mutate } from 'swr'
import { useTranslation } from 'react-i18next'
import { useTypedSelector } from '../../../../hooks'

export const EditDirection = ({
  id,
  setId,
  setVisibleEditModal,
}: TEditMedicalDirectionWindow) => {
  const { t } = useTranslation('medicalDirection')
  const { language } = useTypedSelector(state => state.user)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TCreateMedicalDirForm>({
    defaultValues: { lang: language || 'uk' },
  })

  const { currentMedicalDirection } = useGetCurrentMedicalDirection(id)

  useEffect(() => {
    const currentLang = watch('lang')
    currentMedicalDirection?.title &&
      setValue('title', currentMedicalDirection?.title?.[currentLang] || '')
  }, [currentMedicalDirection, setValue, watch])

  const onSubmit: SubmitHandler<TCreateMedicalDirForm> = async data => {
    await eitMedicalDirection(
      {
        title: { [data.lang]: data.title },
      },
      id,
    )
    mutate('medical-directions')
    closeModal()
  }

  const deleteItem = async () => {
    await deleteMedicalDirection(id)
    mutate('medical-directions')
    closeModal()
  }

  const closeModal = () => {
    setId('')
    setVisibleEditModal(prev => !prev)
  }
  return (
    <>
      <CreteForm onSubmit={handleSubmit(onSubmit)}>
        <HeadingWrap>
          <h3>{t('controlEdit')}</h3>
          <div onClick={closeModal}>
            <img src={Assets.CYAN_ICON} alt="close" />
          </div>
        </HeadingWrap>
        <InputsWrap>
          <div>
            <div>
              <p>{t('language')}</p>
              <select {...register('lang')}>
                {languageKeysArray.map(item => (
                  <option key={item}>{item}</option>
                ))}
              </select>
            </div>
            <label>
              {t('name')}
              <input
                placeholder={t('name')}
                {...register('title', { required: t('mandatoryField') })}
              />
              {errors.title && (
                <Errormessage>{errors.title.message}</Errormessage>
              )}
            </label>
          </div>
        </InputsWrap>
        <HeadingWrap>
          <button onClick={deleteItem} type="button">
            {t('profile:delete')}
          </button>
          <button type="submit">{t('edit')}</button>
        </HeadingWrap>
      </CreteForm>
    </>
  )
}
