import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

import { useTypedSelector } from '../../hooks'
import { getSupport, supportActions } from '../../store/support'
import { Text } from '../../components'
import { Assets } from '../../assets'
import { useTranslation } from 'react-i18next'

import {
  Container,
  FormBlock,
  FormContainer,
  Item,
  TextArea,
  Image,
  DeleteButton,
  Header,
} from './styled'

export const SupportProfile = () => {
  const { t } = useTranslation('feedBack')
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history = useHistory()

  const { support } = useTypedSelector(getSupport)

  useEffect(() => {
    dispatch(supportActions.getSupport({ _id: id }))
  }, [])

  const navigateHandler = () => {
    history.goBack()
  }

  const onDeleteFeedback = async () => {
    try {
      const result = window.confirm(t('deleteFeedback'))

      if (result) {
        dispatch(supportActions.deleteSupport({ _id: id }))
        history.goBack()
      }
    } catch (error) {}
  }

  return (
    <>
      <Header>
        <Image src={Assets.LEFT_ARROW_ICON} onClick={navigateHandler} />
        <DeleteButton onClick={onDeleteFeedback}>{t('delete')}</DeleteButton>
      </Header>
      <Container>
        <Text
          color="#1F166A"
          fontSize="16px"
          text={`${t('writingDate')}: ${moment(support?.createdAt).format(
            'DD-MM-YYYY HH:mm',
          )}`}
        />
        <FormContainer>
          <FormBlock>
            <Text text={t('typeOfProblem')} color="#1F166A" />
            <Item>{t(support?.type)}</Item>
          </FormBlock>
          <FormBlock>
            <Text text={t('email')} color="#1F166A" />
            <Item>{support?.email}</Item>
          </FormBlock>
        </FormContainer>
        <FormBlock>
          <Text text={t('text')} color="#1F166A" />
          <TextArea>{support?.message}</TextArea>
        </FormBlock>
      </Container>
    </>
  )
}
