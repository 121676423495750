import { AxiosError } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { AuthorizationError } from '../../errors'
import { generateErrorToast } from '../../helpers'
import { Log } from '../../utils'
import { showToastAction } from '../toasts'
import { PromiseReturnType } from '../types'
import { getUserSelector } from '../user'
import { GetMoreStackEventPropAction, GetStackEventsPropAction } from './action'
import { StackpropsAPI } from './api.service'
import { EventPropResponse } from './types'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getMoreStackEventPropWorker({
  payload,
}: ReturnType<typeof GetMoreStackEventPropAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof StackpropsAPI.getStackEventProp>
    > = yield call([StackpropsAPI, StackpropsAPI.getStackEventProp], {
      authorization: token,
      start: payload.start,
      end: payload.end,
      event: payload.event,
      app: payload.app,
    })

    yield put(GetMoreStackEventPropAction.success(response.data))
  } catch (e) {
    Log.ruddy('Error: getMoreStackEventPropWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения MoreStack')),
    )

    yield put(GetMoreStackEventPropAction.failure(e as AxiosError))
  }
}

function* getAllStackEvents({
  payload,
}: ReturnType<typeof GetStackEventsPropAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const events: string[] = payload.stackEvents
    const result: { name: string; length: number }[] = []

    for (let i = 0; i < events.length; i++) {
      const response: PromiseReturnType<
        ReturnType<typeof StackpropsAPI.getStackEventProp>
      > = yield call([StackpropsAPI, StackpropsAPI.getStackEventProp], {
        authorization: token,
        event: events[i],
        start: payload.start,
        end: payload.end,
        app: payload.app,
      })

      result.push({ name: events[i], length: response.data.length })
    }

    yield put(GetStackEventsPropAction.success(result))
  } catch (e) {
    Log.ruddy('Error: getMoreStackEventPropWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения MoreStack')),
    )

    yield put(GetStackEventsPropAction.failure(e as AxiosError))
  }
}

export function* stackpropsWatcher() {
  yield takeLatest(
    GetMoreStackEventPropAction.request,
    getMoreStackEventPropWorker,
  )
  yield takeLatest(GetStackEventsPropAction.request, getAllStackEvents)
}
