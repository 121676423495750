import { api } from '../config'

export const PLACES_URL = {
  getPlaces: (page: number, limit: number, search: string, sortBy: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/places?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}`,
  getPlaceById: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/${id}`,
  createPlace: `${api.withAPI}service/api/v1/admin/clinics`,
  updatePlace: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/${id}`,
  putPlaceLogo: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/update-logo/${id}`,
  deletePlace: (id: string) => `${api.withAPI}/admin/clinics/${id}`,
  addImageToPlace: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/add-photo/${id}`,
  addDocumentToPlace: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/add-document/${id}`,
  deleteImageFromPlace: (id: string, url: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/delete-photo/${id}/${url}`,
  deleteDocumentFromPlace: (id: string, url: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/delete-document/${id}/${url}`,
  searchPlace: (limit: number, page: number, title: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/?limit=${limit}&page=${page}&title=${title}`,
}
