import styled from 'styled-components'
import { COLORS } from '../../../../assets'

export const PhotoWrap = styled.div`
  display: flex;
  gap: 20px;
  position: relative;
  img {
    width: 155px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
  }
  button {
    cursor: pointer;
    width: 20px;
    height: 20px;
    outline: none;
    border: none;
    background-color: transparent;
    position: absolute;
    left: 155px;
    img {
      width: 20px;
      height: 20px;
    }
  }
`
export const PhotoUpload = styled.label<{ isError: boolean }>`
  width: 150px;
  height: 150px;
  padding: 4px;
  border-radius: 8px;
  border: 1px dashed ${({ isError }) => (!isError ? COLORS.black : COLORS.red)};
  cursor: pointer;
  input {
    width: 1px;
    height: 1px;
    &.visually-hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      padding: 0;
      white-space: nowrap;
      clip-path: inset(100%);
      clip: rect(0 0 0 0);
      overflow: hidden;
    }
  }
`
