import { api } from '../config'

export const CATEGORIES_ADS_URL = {
  getCategoriesAds: (page: number, limit: number) =>
    `${api.withAPI}ads/api/v1/admin/ads-categories/?page=${page}&limit=${limit}`,
  createCategoriesAds: `${api.withAPI}ads/api/v1/admin/ads-categories`,
  updateCategoriesAds: (id: string) =>
    `${api.withAPI}ads/api/v1/admin/ads-categories/${id}`,
  deleteCategoriesAds: (id: string) =>
    `${api.withAPI}ads/api/v1/admin/ads-categories/${id}`,
  getSubCategoriesAds: (page: number, limit: number, category: string) =>
    `${
      api.withAPI
    }ads/api/v1/admin/ads-subcategories/?page=${page}&limit=${limit}${
      category ? `&category=${category}` : ''
    }`,
  createSubCategoriesAds: `${api.withAPI}ads/api/v1/admin/ads-subcategories`,
  updateSubCategoriesAds: (id: string) =>
    `${api.withAPI}ads/api/v1/admin/ads-subcategories/${id}`,
  deleteSubCategoriesAds: (id: string) =>
    `${api.withAPI}ads/api/v1/admin/ads-subcategories/${id}`,
}
