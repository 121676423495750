import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ukTranslations from './translations/uk.json'
import enTranslations from './translations/en.json'

export const resources = {
  uk: ukTranslations,
  en: enTranslations,
}

export const initializeI18n = (language: string) => {
  i18n.use(initReactI18next).init({
    lng: language,
    resources,
  })
}

export default i18n
