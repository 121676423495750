import { Control, UseFormSetValue, useFieldArray } from 'react-hook-form'
import { FormInput } from '../types'

type UseFieldArraySelectorsProps = {
  control: Control<FormInput>
  setValue: UseFormSetValue<FormInput>
}

export const useFieldArraySelectors = ({
  control,
  setValue,
}: UseFieldArraySelectorsProps) => {
  const {
    fields: titleFields,
    append: appendTitle,
    update: updateTitle,
  } = useFieldArray({
    control,
    name: 'title',
  })
  const {
    fields: descriptionFields,
    append: appendDescription,
    update: updateDescription,
  } = useFieldArray({
    control,
    name: 'description',
  })
  const {
    fields: metadescriptionFields,
    append: appendMetadescription,
    update: updateMetaDescription,
  } = useFieldArray({
    control,
    name: 'metadescription',
  })

  const setSelectedTitle = (lang: string, value: string) => {
    const existingTitleIndex = titleFields.findIndex(
      field => field.lang === lang,
    )

    if (existingTitleIndex !== -1) {
      setValue(`title.${existingTitleIndex}.value`, value)
    } else {
      value && appendTitle({ value, lang })
    }
  }

  const setSelectedDescription = (lang: string, value: string) => {
    const existingDescriptionIndex = descriptionFields.findIndex(
      field => field.lang === lang,
    )

    if (existingDescriptionIndex !== -1) {
      setValue(`description.${existingDescriptionIndex}.value`, value)
    } else {
      value && appendDescription({ value, lang })
    }
  }

  const setSelectedMetadescription = (lang: string, value: string) => {
    const existingMetadescriptionIndex = metadescriptionFields.findIndex(
      field => field.lang === lang,
    )

    if (existingMetadescriptionIndex !== -1) {
      setValue(`metadescription.${existingMetadescriptionIndex}.value`, value)
    } else {
      value && appendMetadescription({ value, lang })
    }
  }

  return {
    titleFields,
    descriptionFields,
    metadescriptionFields,
    appendTitle,
    appendDescription,
    appendMetadescription,
    setSelectedTitle,
    setSelectedDescription,
    setSelectedMetadescription,
    updateDescription,
    updateMetaDescription,
    updateTitle,
  }
}
