import {
  useServiceCategories,
  OneService,
  CreateServiceWindow,
  EditServiceWindow,
} from './components'

import { CategoryChar, Container, ServiceList } from './styled'
import { useState } from 'react'
import { Modal } from '../../components/Modal'
import { useTranslation } from 'react-i18next'

export const ApplicationContent: React.FC = () => {
  const { serviceCategories } = useServiceCategories()
  const { t } = useTranslation('functionals')

  const [visibleCreateModal, setVisibleCreateModal] = useState(false)
  const [visibleEditModal, setVisibleEditModal] = useState(false)
  const [id, setId] = useState('')

  const onCreateClick = () => setVisibleCreateModal(prev => !prev)

  return (
    <Container>
      {visibleCreateModal && (
        <Modal>
          <CreateServiceWindow setVisibleCreateModal={setVisibleCreateModal} />
        </Modal>
      )}
      {visibleEditModal && (
        <Modal>
          <EditServiceWindow
            id={id}
            setVisibleEditModal={setVisibleEditModal}
            setId={setId}
          />
        </Modal>
      )}
      <CategoryChar>
        <div>{t('language')}</div>
        <div>{t('name')}</div>
        <div>{t('description')}</div>
        <div>{t('type')}</div>
        <div>{t('photo')}</div>
        <button onClick={onCreateClick}>{t('create')}</button>
      </CategoryChar>
      <ServiceList>
        {serviceCategories?.models.map(item => (
          <OneService
            setVisibleEditModal={setVisibleEditModal}
            setId={setId}
            service={item}
            key={item._id}
          ></OneService>
        ))}
      </ServiceList>
    </Container>
  )
}
