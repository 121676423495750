import { getPetsTypesList } from './services'
import useSWR from 'swr'

export const usePetsTypes = () => {
  const {
    data: petsTypesList,
    isLoading,
    error,
  } = useSWR('petsType', getPetsTypesList, { revalidateOnFocus: false })
  return { petsTypesList, isLoading, error }
}
