import React, { FC } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import { Container } from './styled'
// import { options, petType } from './components'
import { PieChartProps } from './types'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart: FC<PieChartProps> = ({ dataArray }) => {
  const { t } = useTranslation()

  const petType = {
    cat: t('petType:cat'),
    dog: t('petType:dog'),
    bird: t('petType:bird'),
    humster: t('petType:humster'),
    ferret: t('petType:ferret'),
    toad: t('petType:toad'),
    snail: t('petType:snail'),
    fish: t('petType:fish'),
    spider: t('petType:spider'),
    amphibian: t('petType:amphibian'),
    monkey: t('petType:monkey'),
    dinosaur: t('petType:dinosaur'),
    rabbit: t('petType:rabbit'),
  }

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: t('analitics:petsType'),
        font: {
          size: 20,
        },
      },
      legend: {
        display: false,
        position: 'top' as const,
      },
    },
    maintainAspectRatio: false,
  }

  const PieChartData = {
    labels: Object.values(petType),
    datasets: [
      {
        label: '# of Votes',
        data: dataArray,
        backgroundColor: [
          'rgba(231, 18, 64, 0.793)',
          'rgba(146, 205, 245, 0.992)',
          'rgba(196, 147, 26, 0.786)',
          'rgba(21, 245, 245, 0.779)',
          'rgba(85, 8, 240, 0.786)',
          'rgba(233, 222, 212, 0.765)',
          'rgba(215, 220, 223, 0.93)',
          'rgba(4, 146, 240, 0.738)',
          'rgb(255, 183, 1)',
          'rgba(63, 158, 158, 0.923)',
          'rgba(95, 29, 228, 0.731)',
          'rgba(167, 122, 77, 0.834)',
          'rgb(13, 145, 46)',
        ],
        borderColor: [
          'rgba(231, 18, 64, 0.793)',
          'rgba(146, 205, 245, 0.992)',
          'rgba(196, 147, 26, 0.786)',
          'rgba(21, 245, 245, 0.779)',
          'rgba(85, 8, 240, 0.786)',
          'rgba(233, 222, 212, 0.765)',
          'rgba(215, 220, 223, 0.93)',
          'rgba(4, 146, 240, 0.738)',
          'rgb(255, 183, 1)',
          'rgba(63, 158, 158, 0.923)',
          'rgba(95, 29, 228, 0.731)',
          'rgba(167, 122, 77, 0.834)',
          'rgb(13, 145, 46)',
        ],
        borderWidth: 1,
      },
    ],
  }

  return (
    <Container>
      <Bar data={PieChartData} options={options} height={350} />
    </Container>
  )
}

export default PieChart
