import { StylesConfig } from 'react-select'

export type Option = {
  label: string
  value: string
}

export const customStyles: StylesConfig<Option> = {
  control: provided => ({
    ...provided,
    backgroundColor: 'white',
    borderColor: '#ccc',
    boxShadow: 'none',

    width: '200px',
    height: '41px',

    '&:hover': {
      borderColor: '#aaa',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: 'gray',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'black',
    '&:hover': {
      color: 'blue',
    },
  }),
  menu: provided => ({
    ...provided,
    border: 'none',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',

    width: '200px',
  }),
}
