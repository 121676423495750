import { LocalizationProvider } from '@mui/lab'
import {
  Container,
  Image,
  Label,
  StyledButton,
  StyledDropDown,
  StyledInput,
  StyledLinkDiv,
  StyledTitle,
  Title,
} from './styled'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import Dropdown from 'react-dropdown'
import { languages } from './consts'
import 'react-dropdown/style.css'
import { useTypedSelector } from '../../hooks'
import { Redirect, useHistory } from 'react-router-dom'
import { RoutesTree } from '../../router'
import { useTranslation } from 'react-i18next'
import { Divider, Input } from '../../components'
import { Assets } from '../../assets'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { FormInput } from './types'
import { useEffect, useState } from 'react'
import { Service } from '../../services'
import { ErrorMessage } from '../AdviceCreate/styled'

export const TagsCreate = () => {
  const { t } = useTranslation('tags')
  const { user, language: lang } = useTypedSelector(state => state.user)
  const history = useHistory()

  const [language, setLanguage] = useState<string>(lang || 'en')

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: {
      title: [{ value: '', lang: lang || 'en' }],
    },
  })
  const { fields, append } = useFieldArray({
    control,
    name: 'title',
  })

  const [title] = watch(['title'])

  const handleBack = () => history.goBack()

  const changeType = (type: string) => {
    setLanguage(type)
  }

  const setSelectedTitle = (lang: string, value: string) => {
    const existingTitleIndex = fields.findIndex(field => field.lang === lang)

    if (existingTitleIndex !== -1) {
      setValue(`title.${existingTitleIndex}.value`, value)
    } else {
      append({ value, lang })
    }
  }

  const onSubmit = async (data: FormInput) => {
    try {
      await Service.Tag.createTag(data)
      handleBack()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  if (!user?.access?.tags) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <StyledTitle>
          {Assets.LEFT_ARROW_ICON ? (
            <StyledLinkDiv onClick={handleBack}>
              <Image src={Assets.LEFT_ARROW_ICON} />
            </StyledLinkDiv>
          ) : (
            ''
          )}

          <Title>{t('tag')}</Title>

          <div></div>
        </StyledTitle>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('language')}</Label>

          <Divider height={10} />

          <Dropdown
            options={languages}
            onChange={option => changeType(option.value)}
            value={language}
            placeholder="Виберите язык"
          />
        </StyledDropDown>

        <Divider height={30} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Label>
            {t('title')} - {language}
          </Label>

          <Divider height={10} />

          <Controller
            key={language}
            name={`title.${fields.findIndex(
              field => field.lang === language,
            )}.value`}
            control={control}
            rules={{
              required:
                title.filter(item => item?.value)?.length > 0
                  ? ''
                  : t('required'),
            }}
            render={({ field: { onChange, ...field } }) => (
              <StyledInput
                {...field}
                onChange={e => setSelectedTitle(language, e.target.value)}
                placeholder="Enter Title"
              />
            )}
          />

          {errors?.title && (
            <ErrorMessage>{errors?.title[0]?.value?.message}</ErrorMessage>
          )}

          <Divider height={30} />

          <StyledButton type="submit">{t('create')}</StyledButton>
        </form>
      </LocalizationProvider>
    </Container>
  )
}
