import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  clearOwnPetsAction,
  clearPetAction,
  getOwnPetsAction,
  getPetByIdAction,
  // clearManagerWorkingHistoryAction,
  getPetsAction,
  // getManagerWorkingHistoryAction,
} from './actions'

const InitialState: TInitialState = {
  pets: [],
  ownPets: null,
  pet: null,
  loading: false,
  total: 0,
}

export const petsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getPetsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getPetsAction.success, (state, { payload }) => ({
      ...state,
      pets: payload.pets,
      total: payload.total,
      loading: false,
    }))
    builder.addCase(getPetsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getOwnPetsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getOwnPetsAction.success, (state, { payload }) => ({
      ...state,
      ownPets: payload,
      loading: false,
    }))
    builder.addCase(getOwnPetsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getPetByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getPetByIdAction.success, (state, { payload }) => ({
      ...state,
      pet: payload,
      loading: false,
    }))
    builder.addCase(getPetByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(clearPetAction, state => ({
      ...state,
      pet: null,
    }))

    builder.addCase(clearOwnPetsAction, state => ({
      ...state,
      ownPets: null,
    }))

    // builder.addCase(getManagerWorkingHistoryAction.request, state => ({
    //   ...state,
    //   loading: true,
    // }))
    // builder.addCase(
    //   getManagerWorkingHistoryAction.success,
    //   (state, { payload }) => ({
    //     ...state,
    //     workingHistory: payload.data,
    //     loading: false,
    //   }),
    // )
    // builder.addCase(
    //   getManagerWorkingHistoryAction.failure,
    //   (state, { payload }) => ({
    //     ...state,
    //     error: payload,
    //     loading: false,
    //   }),
    // )

    // builder.addCase(clearManagerWorkingHistoryAction, state => ({
    //   ...state,
    //   workingHistory: [],
    // }))
  },
)
