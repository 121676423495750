import styled from 'styled-components';

import { COLORS } from '../../assets';
import { FLEX, FONT } from '../../utils';

export const Container = styled.div`
  width: auto;
  height: auto;

  background-color: white;

  padding: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 0;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
  
  cursor: pointer;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;

  line-height: 20px;

  max-width: 550px;
  width: 100%;

  border: 1px solid #1f166a;
  border-radius: 12px;

  padding: 10px 10px;
`;

export const TextArea = styled.div`
  max-width: 550px;
  width: 100%;

  height: 200px;

  overflow-y: auto;

  word-break: break-all;

  border: 1px solid #1f166a;
  border-radius: 12px;

  padding: 10px 10px;
`;

export const DeleteButton = styled.button`
  padding: 13px 10px;
  border: 1px solid red;

  background-color: white;

  border-radius: 10px;

  &:hover {
    background: ${COLORS.gray};
  }

  transition: 0.15s ease-in-out;
  cursor: pointer;

  ${FONT({ weight: '700', size: '16px', color: COLORS.red })};

  ${FLEX({})};
`;
