import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getActiveAndroidUsersAction,
  getActiveIosUsersAction,
  getActiveUsersSummary,
  GetAndroidModelsAction,
  GetAndroidSessionDurationAction,
  getDashboardInfoAction,
  GetIosModelsAction,
  GetIosSessionDurationAction,
  GetOsesAction,
  GetSessionCountsAction,
  GetIosVersionsAction,
  getYearInfoAction,
  GetAndroidVersionsAction,
  getPrevYearInfoAction,
  SetPlatformFilterexport,
  GetActiveIosUsersMonthAction,
  GetActiveAndroidUsersMonthAction,
  GetUsersByMonthAction,
  GetUsersByDateAction,
  GetPetsByMonthAction,
  GetAllIosVersionsAction,
  GetAllAndroidVersionsAction,
} from './actions'

const InitialState: TInitialState = {
  loading: false,
  dashboard: null,
  yearInfo: null,
  prevYearInfo: null,
  activeIosUsers: null,
  activeAndroidUsers: null,
  activeUsersSummary: null,
  oses: null,
  sessionCounts: null,
  iosModels: null,
  androidModels: null,
  iosSessionDuration: null,
  androidSessionDuration: null,
  iosAppVersions: null,
  androidAppVersions: null,
  filterPlatform: '',
  activeAndroidUsersMonth: null,
  activeIosUsersMonth: null,
  usersByMonth: null,
  usersByDate: null,
  petsByMonth: null,
  androidVersions: null,
  iosVersions: null,
}

export const dashboardReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getDashboardInfoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getDashboardInfoAction.success, (state, { payload }) => ({
      ...state,
      dashboard: payload.dashboard,
      loading: false,
    }))
    builder.addCase(getDashboardInfoAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getYearInfoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getYearInfoAction.success, (state, { payload }) => ({
      ...state,
      yearInfo: payload,
      loading: false,
    }))
    builder.addCase(getYearInfoAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getPrevYearInfoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getPrevYearInfoAction.success, (state, { payload }) => ({
      ...state,
      prevYearInfo: payload,
      loading: false,
    }))
    builder.addCase(getPrevYearInfoAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getActiveIosUsersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getActiveIosUsersAction.success, (state, { payload }) => ({
      ...state,
      activeIosUsers: payload,
      loading: false,
    }))
    builder.addCase(getActiveIosUsersAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetActiveIosUsersMonthAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetActiveIosUsersMonthAction.success,
      (state, { payload }) => ({
        ...state,
        activeIosUsersMonth: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetActiveIosUsersMonthAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetActiveAndroidUsersMonthAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetActiveAndroidUsersMonthAction.success,
      (state, { payload }) => ({
        ...state,
        activeAndroidUsersMonth: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetActiveAndroidUsersMonthAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(getActiveAndroidUsersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      getActiveAndroidUsersAction.success,
      (state, { payload }) => ({
        ...state,
        activeAndroidUsers: payload,
        loading: false,
      }),
    )
    builder.addCase(
      getActiveAndroidUsersAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(getActiveUsersSummary.request, state => ({
      ...state,
      loading: true,
    }))
    // builder.addCase(getActiveUsersSummary.success, (state, { payload }) => ({
    //   ...state,
    //   activeUsersSummary: payload,
    //   loading: false,
    // }))
    builder.addCase(getActiveUsersSummary.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetOsesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetOsesAction.success, (state, { payload }) => ({
      ...state,
      oses: payload,
      loading: false,
    }))
    builder.addCase(GetOsesAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetSessionCountsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetSessionCountsAction.success, (state, { payload }) => ({
      ...state,
      sessionCounts: payload,
      loading: false,
    }))
    builder.addCase(GetSessionCountsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetIosModelsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetIosModelsAction.success, (state, { payload }) => ({
      ...state,
      iosModels: payload,
      loading: false,
    }))
    builder.addCase(GetIosModelsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetAndroidModelsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetAndroidModelsAction.success, (state, { payload }) => ({
      ...state,
      androidModels: payload,
      loading: false,
    }))
    builder.addCase(GetAndroidModelsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetAndroidSessionDurationAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetAndroidSessionDurationAction.success,
      (state, { payload }) => ({
        ...state,
        androidSessionDuration: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetAndroidSessionDurationAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetIosSessionDurationAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetIosSessionDurationAction.success,
      (state, { payload }) => ({
        ...state,
        iosSessionDuration: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetIosSessionDurationAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )

    builder.addCase(GetIosVersionsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetIosVersionsAction.success, (state, { payload }) => ({
      ...state,
      iosAppVersions: payload,
      loading: false,
    }))
    builder.addCase(GetIosVersionsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetAndroidVersionsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetAndroidVersionsAction.success, (state, { payload }) => ({
      ...state,
      androidAppVersions: payload,
      loading: false,
    }))
    builder.addCase(GetAndroidVersionsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(SetPlatformFilterexport, (state, { payload }) => ({
      ...state,
      filterPlatform: payload,
    }))

    builder.addCase(GetUsersByMonthAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetUsersByMonthAction.success, (state, { payload }) => ({
      ...state,
      usersByMonth: payload,
      loading: false,
    }))
    builder.addCase(GetUsersByMonthAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetUsersByDateAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetUsersByDateAction.success, (state, { payload }) => ({
      ...state,
      usersByDate: payload,
      loading: false,
    }))
    builder.addCase(GetUsersByDateAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetPetsByMonthAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetPetsByMonthAction.success, (state, { payload }) => ({
      ...state,
      petsByMonth: payload,
      loading: false,
    }))
    builder.addCase(GetPetsByMonthAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetAllIosVersionsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(GetAllIosVersionsAction.success, (state, { payload }) => ({
      ...state,
      iosVersions: payload,
      loading: false,
    }))
    builder.addCase(GetAllIosVersionsAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(GetAllAndroidVersionsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      GetAllAndroidVersionsAction.success,
      (state, { payload }) => ({
        ...state,
        androidVersions: payload,
        loading: false,
      }),
    )
    builder.addCase(
      GetAllAndroidVersionsAction.failure,
      (state, { payload }) => ({
        ...state,
        error: payload,
        loading: false,
      }),
    )
  },
)
