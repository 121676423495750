import styled from "styled-components";

import { TText } from "./types";

export const Container = styled.div`
  display: flex;
`;

export const TextP = styled.p<TText>`
   color: ${({ color }) => `${color}`};
   font-size: ${({ fontSize }) => `${fontSize}px`};
   margin: ${({ margin }) => `${margin}`};
   cursor: ${({ $cursor }) => $cursor && 'pointer'};
   font-weight: ${({ fontWeight }) => `${fontWeight}`};
`;