import styled from 'styled-components'
import { COLORS } from '../../assets'
import { FLEX, FONT } from '../../utils'
import { LineProp } from './types'

export const Container = styled.div`
  width: 100%;
  min-height: 100%;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Title = styled.h1`
  ${FONT({ size: '32px', color: COLORS.lightBlue, weight: '700' })}

  ${FLEX({})}
`

export const StyledLinkDiv = styled.div``

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const LineBlue = styled.div<LineProp>`
  width: ${({ width }) => (width ? `${width}%` : '0')};
  border-bottom: 2px solid #1faccc;
`

export const LineGrey = styled.div<LineProp>`
  width: ${({ width }) => (width ? `${width}%` : '0')};
  border-bottom: 2px solid #333333;
`

export const LineContainer = styled.div`
  display: flex;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`

export const StyledTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
