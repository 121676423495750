import ReactSelect from 'react-select'
import useSWR from 'swr'
import { getMedicalDirection } from '../../../MedicalDirections/services'
import { useTypedSelector } from '../../../../hooks'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { customStyles, Container, Option } from './styled'
import { useTranslation } from 'react-i18next'

type CategoryFilterProps = {
  option: string
  setOption: Dispatch<SetStateAction<string>>
}

export const CategoryFilter = ({ option, setOption }: CategoryFilterProps) => {
  const { t } = useTranslation('appointments')
  const { language: lang } = useTypedSelector(state => state.user)
  const { data } = useSWR('medical-directions', getMedicalDirection, {
    revalidateOnFocus: false,
  })

  const options = useMemo(() => {
    if (!data) {
      return []
    }

    return data?.models?.map(item => ({
      label: item?.title[lang],
      value: item?._id,
    }))
  }, [data, lang])

  return (
    <Container>
      <ReactSelect
        key={option}
        value={options.find(item => item.value == option)}
        options={options}
        styles={customStyles}
        onChange={option => setOption((option as Option)?.value)}
        placeholder={t('enterCategory')}
        isClearable
      />
    </Container>
  )
}
