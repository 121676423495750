import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastsProvider, LoaderProvider } from './contexts'
import { persistor, store } from './store'
import { AppRouter } from './router'
import { initializeI18n } from './locales/i18n'
import { SWRConfig } from 'swr'

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
        onBeforeLift={() => {
          const currentLanguage = store.getState().user.language
          initializeI18n(currentLanguage)
        }}
      >
        <LoaderProvider>
          <ToastsProvider>
            <SWRConfig value={{ provider: () => new Map() }}>
              <AppRouter />
            </SWRConfig>
          </ToastsProvider>
        </LoaderProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
