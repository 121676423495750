import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import { AxiosError } from 'axios'

import { AuthorizationError } from '../../errors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Log } from '../../utils'
import { getUserSelector } from '../user'

import {
  createCategoriesAdsAction,
  createSubCategoriesAdsAction,
  deleteCategoriesAdsAction,
  deleteSubCategoriesAdsAction,
  getCategoriesAdsAction,
  getSubCategoriesAdsAction,
  updateCategoriesAdsAction,
  updateSubCategoriesAdsAction,
} from './actions'
import { CategoriesAdsAPI } from './api.service'

function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getCategoriesAdsWorker({
  payload,
}: ReturnType<typeof getCategoriesAdsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof CategoriesAdsAPI.getCategoriesAds>
    > = yield call([CategoriesAdsAPI, CategoriesAdsAPI.getCategoriesAds], {
      authorization: token,
      ...payload,
    })

    yield put(
      getCategoriesAdsAction.success({
        categoriesAds: response.data.models,
        totalCount: response.data.totalCount,
        response: 'GET_CATEGORIES'
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getClinicsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категорій')),
    )
  }
}

function* createCategoriesAdsWorker({
  payload,
}: ReturnType<typeof createCategoriesAdsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([CategoriesAdsAPI, CategoriesAdsAPI.createCategoriesAds], {
      authorization: token,
      data: payload.data,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Категорию успешно создано'),
      ),
    )
  } catch (e) {
    console.log('Error: createClinicWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания категории')),
    )

    yield put(createCategoriesAdsAction.failure(e as AxiosError))
  }finally{
    yield put(createCategoriesAdsAction.success({
      response: 'CREATE_CATEGORIES'
    }))
  }
}

function* updateCategoriesAdsWorker({
  payload,
}: ReturnType<typeof updateCategoriesAdsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([CategoriesAdsAPI, CategoriesAdsAPI.updateCategoriesAds], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Категория успешно обновлена'),
      ),
    )
  } catch (e) {
    console.log('Error: updateClinicWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoriesAdsAction.failure(e as AxiosError))
  }finally{
    yield put(updateCategoriesAdsAction.success({
      response: 'UPDATE_SUBCATEGORIES'
    }))
  }
}

function* deleteCategoriesAdsWorker({
  payload,
}: ReturnType<typeof deleteCategoriesAdsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)
    const response: PromiseReturnType<
      ReturnType<typeof CategoriesAdsAPI.deleteCategoriesAds>
    > = yield call([CategoriesAdsAPI, CategoriesAdsAPI.deleteCategoriesAds], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Категория удалена успешно'),
      ),
    )
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast('Категорию не возможно удалить если она используется')),
    )
  }finally{
    yield put(deleteCategoriesAdsAction.success({
      response: 'DELETE_CATEGORIES'
    }))
  }
}

function* getSubCategoriesAdsWorker({
  payload,
}: ReturnType<typeof getSubCategoriesAdsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof CategoriesAdsAPI.getSubCategoriesAds>
    > = yield call([CategoriesAdsAPI, CategoriesAdsAPI.getSubCategoriesAds], {
      authorization: token,
      ...payload,
    })

    yield put(
      getSubCategoriesAdsAction.success({
        subCategoriesAds: response.data.models,
        subCategoryTotalCount: response.data.totalCount,
        response: 'GET_CATEGORIES'
      }),
    )
  } catch (e) {
    Log.ruddy('Error: getClinicsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категории')),
    )

    yield put(getSubCategoriesAdsAction.failure(e as AxiosError))
  }
}

function* createSubCategoriesAdsWorker({
  payload,
}: ReturnType<typeof createSubCategoriesAdsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([CategoriesAdsAPI, CategoriesAdsAPI.createSubCategoriesAds], {
      authorization: token,
      data: payload.data,
    })

    yield put(createSubCategoriesAdsAction.success({
      response: 'CREATE_CATEGORIES'
    }))

    yield put(
      showToastAction.request(
        generateSuccessToast('Подкатегория успешно создана'),
      ),
    )
  } catch (e) {
    console.log('Error: createClinicWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка создания подкатегории'),
      ),
    )

    yield put(createSubCategoriesAdsAction.failure(e as AxiosError))
  }
}

function* updateSubCategoriesAdsWorker({
  payload,
}: ReturnType<typeof updateSubCategoriesAdsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([CategoriesAdsAPI, CategoriesAdsAPI.updateSubCategoriesAds], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(updateSubCategoriesAdsAction.success({
      response: 'UPDATE_SUBCATEGORIES'
    }))

    yield put(
      showToastAction.request(
        generateSuccessToast('Подкатегория успешно обновлена'),
      ),
    )
  } catch (e) {
    console.log('Error: updateClinicWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления подкатегории'),
      ),
    )

    yield put(updateSubCategoriesAdsAction.failure(e as AxiosError))
  }
}

function* deleteSubCategoriesAdsWorker({
  payload,
}: ReturnType<typeof deleteSubCategoriesAdsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)
    yield call([CategoriesAdsAPI, CategoriesAdsAPI.deleteSubCategoriesAds], {
      id: payload.id,
      authorization: token,
    })

    yield put(
      showToastAction.request(
        generateSuccessToast('Подкатегория успешно обновлена'),
      ),
    )

 
  }catch (e) {
      yield put(
        showToastAction.request(generateErrorToast('Подкатегорию не возможно удалить если она используется')),
      )
    }
  finally{
    yield put(deleteSubCategoriesAdsAction.success({
      response: 'DELETE_CATEGORIES'
    }))
  }
}

export function* categoriesWatcher() {
  yield takeLatest(getCategoriesAdsAction.request, getCategoriesAdsWorker)
  yield takeLatest(createCategoriesAdsAction.request, createCategoriesAdsWorker)
  yield takeLatest(updateCategoriesAdsAction.request, updateCategoriesAdsWorker)
  yield takeLatest(deleteCategoriesAdsAction.request, deleteCategoriesAdsWorker)

  yield takeLatest(getSubCategoriesAdsAction.request, getSubCategoriesAdsWorker)
  yield takeLatest(
    createSubCategoriesAdsAction.request,
    createSubCategoriesAdsWorker,
  )
  yield takeLatest(
    updateSubCategoriesAdsAction.request,
    updateSubCategoriesAdsWorker,
  )
  yield takeLatest(deleteSubCategoriesAdsAction.request, deleteSubCategoriesAdsWorker)
}
