import _ from 'lodash'
import i18n from '../locales/i18n'

type UseTranslate = { value: string; lang: string }[]

export const useTranslate = () => {
  const translate = (translations?: UseTranslate) => {
    if (_.isEmpty(translations)) {
      return ''
    }

    return translations?.find(item => item?.lang == i18n?.language)?.value
  }

  return { translate }
}
