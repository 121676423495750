import React, { FC, useMemo } from 'react'
import _ from 'lodash'
import { useTypedSelector } from '../../hooks'
import { getStoreSelector } from '../../store'
import Context from './context'
import Loader from './Loader'

const Provider: FC = ({ children }) => {
  const store = useTypedSelector(getStoreSelector)

  const isLoading = useMemo<boolean>(() => {
    const modulesLoading: Array<boolean> = Object.values(store).map(module => {
      return _.get(module, 'loading', false)
    })

    return modulesLoading.some(condition => condition)
  }, [store])

  return (
    <Context.Provider value={{ isLoading }}>
      <Loader isLoading={isLoading} />

      {children}
    </Context.Provider>
  )
}

export default Provider
