import { FC, useEffect, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField } from '@mui/material'
import ruLocale from 'date-fns/locale/ru'
import {
  Container,
  StyledActiveIosUsersContainer,
  StyledCircleDiagram,
  StyledDateContainer,
  StyledDropDown,
  StyledNumber,
  StyledSessionDevicesWrapper,
  StyledText,
  StyledVersionsContainer,
  TextContainer,
  Title,
} from './styled'
import {
  BarChart,
  Divider,
  DoughnutChart,
  HorizantalBarChart,
  LineChart,
  PieChart,
} from '../../components'
import { Redirect } from 'react-router-dom'
import { RoutesTree } from '../../router'
import { useTypedSelector } from '../../hooks'
import { useDispatch } from 'react-redux'
import {
  getActiveAndroidUsersAction,
  getActiveIosUsersAction,
  getActiveUsersSummary,
  GetAndroidModelsAction,
  GetAndroidSessionDurationAction,
  getDashboardInfoAction,
  GetIosModelsAction,
  GetIosSessionDurationAction,
  GetOsesAction,
  GetSessionCountsAction,
  GetIosVersionsAction,
  GetAndroidVersionsAction,
  getPrevYearInfoAction,
  GetActiveAndroidUsersMonthAction,
  GetActiveIosUsersMonthAction,
  GetUsersByMonthAction,
  GetUsersByDateAction,
  SetPlatformFilterexport,
  GetPetsByMonthAction,
  GetAllIosVersionsAction,
  GetAllAndroidVersionsAction,
} from '../../store'
import Dropdown from 'react-dropdown'
import { useTranslation } from 'react-i18next'

const Dashboard: FC = () => {
  const { t } = useTranslation('analitics')
  const {
    dashboard,
    activeUsersSummary,
    yearInfo,
    activeIosUsers,
    activeAndroidUsers,
    sessionCounts,
    iosModels,
    androidModels,
    androidSessionDuration,
    iosSessionDuration,
    iosAppVersions,
    androidAppVersions,
    prevYearInfo,
    filterPlatform,
    activeAndroidUsersMonth,
    activeIosUsersMonth,
    usersByMonth,
    usersByDate,
    petsByMonth,
    iosVersions,
    androidVersions,
  } = useTypedSelector(state => state.dashboard)
  const { user } = useTypedSelector(state => state.user)
  const dispatch = useDispatch()

  const [year, setYear] = useState<Date | string>('2023')
  const [dropdownOption, setOption] = useState<string>()

  const [searchForm, setSearchForm] = useState({
    start: new Date(new Date().setDate(new Date().getDate() - 7))
      .toJSON()
      .split('T')[0],
    end: '',
  })

  const dropdownOptions = [
    {
      label: t('currentDay'),
      value: 'day',
    },
    {
      label: t('week'),
      value: 'week',
    },
    { label: t('month'), value: 'month' },
    { label: t('theLast3Months'), value: 'last3Month' },
    { label: t('theLast6Months'), value: 'last6Month' },
    { label: t('year'), value: 'year' },
  ]

  const handleChange = (newValue: Date | null) => {
    setYear(new Date(newValue!))
  }

  const changeType = (type: string) => {
    if (type === 'week') {
      setSearchForm({
        start: new Date(new Date().setDate(new Date().getDate() - 7))
          .toJSON()
          .split('T')[0],
        end: new Date().toJSON().split('T')[0],
      })
    }
    if (type === 'day') {
      setSearchForm({
        start: new Date().toJSON().split('T')[0],
        end: new Date().toJSON().split('T')[0],
      })
    }
    if (type === 'month') {
      setSearchForm({
        start: new Date(new Date().setMonth(new Date().getMonth() - 1))
          .toJSON()
          .split('T')[0],
        end: new Date().toJSON().split('T')[0],
      })
    }
    if (type === 'last3Month') {
      setSearchForm({
        start: new Date(new Date().setMonth(new Date().getMonth() - 3))
          .toJSON()
          .split('T')[0],
        end: new Date().toJSON().split('T')[0],
      })
    }
    if (type === 'last6Month') {
      setSearchForm({
        start: new Date(new Date().setMonth(new Date().getMonth() - 6))
          .toJSON()
          .split('T')[0],
        end: new Date().toJSON().split('T')[0],
      })
    }
    if (type === 'year') {
      setSearchForm({
        start: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
          .toJSON()
          .split('T')[0],
        end: new Date().toJSON().split('T')[0],
      })
    }
    setOption(type)
  }

  const Requests = {
    getActiveIosUsers: () => {
      dispatch(
        getActiveIosUsersAction.request({
          start: searchForm.start,
          end: '',
        }),
      )
    },
    getActiveAndroidUsers: () => {
      dispatch(
        getActiveAndroidUsersAction.request({
          start: searchForm.start,
          end: '',
          app: 'android',
        }),
      )
    },
    getActiveUsersSummary: () => {
      dispatch(getActiveUsersSummary.request({}))
    },
    getOses: () => {
      dispatch(
        GetOsesAction.request({
          start: searchForm.start,
          end: searchForm.end,
          app: 'android',
        }),
      )
    },
    getSessionCounts: () => {
      dispatch(
        GetSessionCountsAction.request({
          start: searchForm.start,
          end: searchForm.end,
          app: 'android',
        }),
      )
    },
    getModelsIos: () => {
      dispatch(
        GetIosModelsAction.request({
          start: searchForm.start,
          end: searchForm.end,
        }),
      )
      utils.getAllDevices('ios')
    },
    getAndroidModels: () => {
      dispatch(
        GetAndroidModelsAction.request({
          start: searchForm.start,
          end: searchForm.end,
          app: 'anroid',
        }),
      )
      utils.getAllDevices('android')
    },
    getAndroidSessionDuration: () => {
      dispatch(
        GetAndroidSessionDurationAction.request({
          start: searchForm.start,
          end: searchForm.end,
        }),
      )
      utils.getSessionDuration('android')
    },
    getIosSessionDuration: () => {
      dispatch(
        GetIosSessionDurationAction.request({
          start: searchForm.start,
          end: searchForm.end,
        }),
      )
      utils.getSessionDuration('ios')
    },
    getIosVersions: () => {
      dispatch(
        GetIosVersionsAction.request({
          start: searchForm.start,
          end: searchForm.end,
        }),
      )
      utils.getVersions('ios')
    },
    getAndroidVersions: () => {
      dispatch(
        GetAndroidVersionsAction.request({
          start: searchForm.start,
          end: searchForm.end,
        }),
      )
      utils.getVersions('android')
    },
    getYearInfo: () => {
      dispatch(
        getDashboardInfoAction.request({ year: new Date(year).getFullYear() }),
      )
    },
    getPrevYearInfo: () => {
      dispatch(
        getPrevYearInfoAction.request({ year: new Date('2022').getFullYear() }),
      )
    },
    getActiveAndroidUsersMonth: () => {
      dispatch(
        GetActiveAndroidUsersMonthAction.request({
          start: new Date(new Date().setDate(new Date().getDate() - 31))
            .toJSON()
            .split('T')[0],
          end: '',
          app: 'android',
        }),
      )
    },
    getActiveIosUsersMonth: () => {
      dispatch(
        GetActiveIosUsersMonthAction.request({
          start: new Date(new Date().setDate(new Date().getDate() - 31))
            .toJSON()
            .split('T')[0],
          end: '',
        }),
      )
    },
    getUsersByMonth: () => {
      dispatch(
        GetUsersByMonthAction.request({
          start: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            .toJSON()
            .split('T')[0],
          end: new Date().toJSON().split('T')[0],
        }),
      )
    },
    getUsersByDate: () => {
      dispatch(
        GetUsersByDateAction.request({
          start: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            .toJSON()
            .split('T')[0],
          end: new Date().toJSON().split('T')[0],
        }),
      )
    },
    getPetsByMonth: () => {
      dispatch(
        GetPetsByMonthAction.request({
          start: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            .toJSON()
            .split('T')[0],
          end: new Date().toJSON().split('T')[0],
        }),
      )
    },
    getAllIosVersions: () => {
      dispatch(
        GetAllIosVersionsAction.request({
          start: searchForm.start,
          end: searchForm.end,
          app: 'ios',
        }),
      )
    },
    getAllAndroidVersions: () => {
      dispatch(
        GetAllAndroidVersionsAction.request({
          start: searchForm.start,
          end: searchForm.end,
          app: 'android',
        }),
      )
    },
  }

  const utils = {
    getAllDevices: (model: string) => {
      if (model === 'android') {
        const amountOfDevices = {}
        iosModels
          //@ts-ignore
          ?.map(item => item.prop)
          //@ts-ignore
          .forEach(item => {
            //@ts-ignore
            amountOfDevices[item] = (amountOfDevices[item] || 0) + 1
          })
        return amountOfDevices
      }
      if (model === 'ios') {
        const amountOfDevices = {}
        androidModels
          //@ts-ignore
          ?.map(item => item.prop)
          //@ts-ignore
          .forEach(item => {
            //@ts-ignore
            amountOfDevices[item] = (amountOfDevices[item] || 0) + 1
          })
        return amountOfDevices
      }
    },
    getSessionDuration: (platform: string) => {
      if (platform === 'ios') {
        const sessionDuration = {}

        iosSessionDuration
          ?.map(item => item)
          .forEach(item => {
            //@ts-ignore
            sessionDuration[item.prop] =
              //@ts-ignore
              (sessionDuration[item.prop] || 0) + item.count
          })

        return sessionDuration
      }
      if (platform === 'android') {
        const sessionDuration = {}

        androidSessionDuration
          ?.map(item => item)
          .forEach(item => {
            //@ts-ignore
            sessionDuration[item.prop] =
              //@ts-ignore
              (sessionDuration[item.prop] || 0) + item.count
          })

        return sessionDuration
      }
    },
    getSummaryDay: () => {
      if (activeAndroidUsers && activeIosUsers) {
        if (filterPlatform === 'ios' && activeIosUsers.length >= 1) {
          const res = activeIosUsers
            .filter(
              item =>
                item.datetime.split('T')[0] ==
                new Date().toJSON().split('T')[0],
            )
            .map(item => item.count)
            .slice(0, 1)

          return res.length
            ? res.reduce((a, b) => {
                return a + b
              })
            : 0
        }

        if (filterPlatform === 'android' && activeAndroidUsers.length >= 1) {
          const res = activeAndroidUsers
            .filter(
              item =>
                item.datetime.split('T')[0] ==
                new Date().toJSON().split('T')[0],
            )
            .map(item => item.count)
            .slice(0, 1)

          return res.length
            ? res.reduce((a, b) => {
                return a + b
              })
            : 0
        }

        if (
          filterPlatform === '' &&
          activeIosUsers.length >= 1 &&
          activeAndroidUsers.length >= 1 &&
          activeIosUsers.filter(
            item =>
              item.datetime.split('T')[0] === new Date().toJSON().split('T')[0],
          ).length &&
          activeAndroidUsers.filter(
            item =>
              item.datetime.split('T')[0] === new Date().toJSON().split('T')[0],
          ).length
        ) {
          let summary: number =
            activeAndroidUsers
              .filter(
                item =>
                  item.datetime.split('T')[0] ===
                  new Date().toJSON().split('T')[0],
              )
              .map(item => item.count)
              .slice(0, 1)
              .reduce((a, b) => {
                return a + b
              }) +
            activeIosUsers
              .filter(
                item =>
                  item.datetime.split('T')[0] ==
                  new Date().toJSON().split('T')[0],
              )
              .map(item => item.count)
              .slice(0, 1)
              .reduce((a, b) => {
                return a + b
              })
          return summary ? summary : 0
        }
      }
    },
    getSummaryMonth: () => {
      if (activeAndroidUsersMonth && activeIosUsersMonth) {
        if (filterPlatform === 'ios') {
          return activeIosUsersMonth.length
            ? activeIosUsersMonth
                ?.map(item => item.count)
                ?.slice(0, 31)
                ?.reduce((a, b) => {
                  return a + b
                })
            : 0
        }
        if (filterPlatform === 'android') {
          return activeAndroidUsersMonth.length
            ? activeAndroidUsersMonth
                ?.map(item => item.count)
                .slice(0, 31)
                ?.reduce((a, b) => {
                  return a + b
                })
            : 0
        }
        if (filterPlatform === '') {
          let summary: number = activeAndroidUsersMonth.length
            ? activeAndroidUsersMonth
                ?.map(item => item.count)
                ?.slice(0, 31)
                ?.reduce((a, b) => {
                  return a + b
                })
            : 0 + activeIosUsersMonth.length
            ? activeIosUsersMonth
                .map(item => item.count)
                ?.slice(0, 31)
                ?.reduce((a, b) => {
                  return a + b
                })
            : 0
          return summary ? summary : 0
        }
      }
    },

    getSummaryWeek: () => {
      if (activeAndroidUsersMonth && activeIosUsersMonth) {
        if (filterPlatform === 'ios') {
          return activeIosUsersMonth.length
            ? activeIosUsersMonth
                ?.map(item => item.count)
                ?.slice(0, 7)
                ?.reduce((a, b) => {
                  return a + b
                })
            : 0
        }
        if (filterPlatform === 'android') {
          return activeAndroidUsersMonth.length
            ? activeAndroidUsersMonth
                ?.map(item => item.count)
                ?.slice(0, 7)
                ?.reduce((a, b) => {
                  return a + b
                })
            : 0
        }
        if (filterPlatform === '') {
          let summary: number = activeAndroidUsersMonth.length
            ? activeAndroidUsersMonth
                ?.map(item => item.count)
                ?.slice(0, 7)
                ?.reduce((a, b) => {
                  return a + b
                })
            : 0 + activeIosUsersMonth.length
            ? activeIosUsersMonth
                ?.map(item => item.count)
                ?.slice(0, 7)
                ?.reduce((a, b) => {
                  return a + b
                })
            : 0
          return summary ? summary : 0
        }
      }
    },
    getVersions: (platform: string) => {
      if (platform === 'ios') {
        const versions = {}

        iosAppVersions
          ?.map(item => item.prop)
          //@ts-ignore
          .forEach(item => (versions[item] = (versions[item] || 0) + 1))

        return versions
      }
      if (platform === 'android') {
        const versions = {}

        androidAppVersions
          ?.map(item => item.prop)
          //@ts-ignore
          .forEach(item => (versions[item] = (versions[item] || 0) + 1))

        return versions
      }
    },
    getYear: () => {
      if (year) {
        return new Date('2022').getFullYear().toString()
      }
    },
    getYearInfo: () => {
      if (yearInfo && prevYearInfo) {
        const YearArrayPets = {
          //@ts-ignore
          December: prevYearInfo.yearsInfo.pets.December,
          //@ts-ignore
          November: prevYearInfo.yearsInfo.pets.November,
          //@ts-ignore
          October: prevYearInfo.yearsInfo.pets.October,
          //@ts-ignore
          September: prevYearInfo.yearsInfo.pets.September,
          //@ts-ignore
          August: prevYearInfo.yearsInfo.pets.August,
          //@ts-ignore
          July: prevYearInfo.yearsInfo.pets.July,
          //@ts-ignore
          June: prevYearInfo.yearsInfo.pets.June,
          //@ts-ignore
          May: prevYearInfo.yearsInfo.pets.May,
          //@ts-ignore
          April: prevYearInfo.yearsInfo.pets.April,
          //@ts-ignore
          March: prevYearInfo.yearsInfo.pets.March,
          February: yearInfo.pets.February,
          January: yearInfo.pets.January,
        }

        const YearArrayUsers = {
          //@ts-ignore
          December: prevYearInfo.yearsInfo.users.December,
          //@ts-ignore
          November: prevYearInfo.yearsInfo.users.November,
          //@ts-ignore
          October: prevYearInfo.yearsInfo.users.October,
          //@ts-ignore
          September: prevYearInfo.yearsInfo.users.September,
          //@ts-ignore
          August: prevYearInfo.yearsInfo.users.August,
          //@ts-ignore
          July: prevYearInfo.yearsInfo.users.July,
          //@ts-ignore
          June: prevYearInfo.yearsInfo.users.June,
          //@ts-ignore
          May: prevYearInfo.yearsInfo.users.May,
          //@ts-ignore
          April: prevYearInfo.yearsInfo.users.April,
          //@ts-ignore
          March: prevYearInfo.yearsInfo.users.March,
          February: yearInfo.users.February,
          January: yearInfo.users.January,
        }

        const object1 = { users: YearArrayUsers, pets: YearArrayPets }

        return object1
      }
    },
    getUsersByMonth: () => {
      if (usersByMonth) {
        const res: number[] = []
        for (let i of Object.keys(usersByMonth[0])) {
          res.push(usersByMonth[0][i][0]?.count)
        }
        return res
      }
    },
    getPetsByMonth: () => {
      if (petsByMonth) {
        const res: number[] = []
        for (let i of Object.keys(petsByMonth[0])) {
          res.push(petsByMonth[0][i][0]?.count)
        }
        return res
      }
    },
    getUsersByDate: () => {
      if (usersByDate) {
        const arr: { [index: string]: number }[] = usersByDate?.map(item => ({
          [item._id.slice(5, 7)]: item.count,
        }))
        const obj = {}
        const obj1 = {
          '01': 0,
          '02': 0,
          '03': 0,
          '04': 0,
          '05': 0,
          '06': 0,
          '07': 0,
          '08': 0,
          '09': 0,
          '10': 0,
          '11': 0,
          '12': 0,
        }
        arr.map(item => Object.assign(obj, item))
        return Object.assign(obj1, obj)
      }
    },
  }

  useEffect(() => {
    Requests.getYearInfo()
    Requests.getPrevYearInfo()
    Requests.getActiveAndroidUsers()
    Requests.getActiveIosUsers()
    Requests.getActiveUsersSummary()
    Requests.getOses()
    Requests.getSessionCounts()
    Requests.getModelsIos()
    Requests.getAndroidModels()
    Requests.getIosSessionDuration()
    Requests.getAndroidSessionDuration()
    Requests.getIosVersions()
    Requests.getAndroidVersions()
    Requests.getActiveAndroidUsersMonth()
    Requests.getActiveIosUsersMonth()
    Requests.getUsersByMonth()
    Requests.getUsersByDate()
    Requests.getPetsByMonth()
    Requests.getAllIosVersions()
    Requests.getAllAndroidVersions()
    dispatch(SetPlatformFilterexport(''))
  }, [])

  useEffect(() => {
    Requests.getYearInfo()
    Requests.getPrevYearInfo()
    Requests.getActiveIosUsers()
    Requests.getActiveAndroidUsers()
    Requests.getActiveUsersSummary()
    Requests.getOses()
    Requests.getSessionCounts()
    Requests.getModelsIos()
    Requests.getAndroidModels()
    Requests.getIosSessionDuration()
    Requests.getAndroidSessionDuration()
    Requests.getIosVersions()
    Requests.getAndroidVersions()
    Requests.getActiveAndroidUsersMonth()
    Requests.getActiveIosUsersMonth()
    Requests.getUsersByMonth()
    Requests.getUsersByDate()
    Requests.getPetsByMonth()
    Requests.getAllIosVersions()
    Requests.getAllAndroidVersions()
  }, [dropdownOption, year])

  if (!user?.access?.dashboard) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <Title>{t('analyticsInCharts')}</Title>

        <Divider height={30} />

        <StyledDateContainer>
          <DatePicker
            value={year}
            inputFormat="yyyy"
            maxDate={new Date()}
            minDate={new Date('2022')}
            onChange={handleChange}
            views={['year']}
            renderInput={params => (
              <TextField
                {...{
                  ...params,
                  inputProps: {
                    ...params['inputProps'],
                    readOnly: true,
                  },
                }}
              />
            )}
          />

          <Divider width={80} />

          <StyledDropDown>
            <Dropdown
              options={dropdownOptions}
              onChange={option => changeType(option.value)}
              value={dropdownOption ? dropdownOption : 'week'}
              placeholder="Виберите период"
            />
          </StyledDropDown>
        </StyledDateContainer>

        <StyledActiveIosUsersContainer>
          <LineChart
            activeAndroidUsersLabelArray={activeAndroidUsers
              ?.map(item => item.datetime)
              .sort()}
            activeAndroidUsersDate={activeAndroidUsers?.map(item => item.count)}
            activeIosUsersDate={activeIosUsers?.map(item => item.count)}
            activeIosUsersLabelArray={activeIosUsers
              ?.map(item => item.datetime)
              .sort()}
          />
          <TextContainer>
            <div>
              <StyledText>{t('overTheLastMonth')}</StyledText>
              <StyledNumber>
                {activeUsersSummary?.month ? activeUsersSummary.month : '0'}
              </StyledNumber>
            </div>
            <div>
              <StyledText>{t('inTheLast7days')}</StyledText>
              <StyledNumber>
                {activeUsersSummary?.week ? activeUsersSummary.week : '0'}
              </StyledNumber>
            </div>
            <div style={{ paddingRight: '20px' }}>
              <StyledText>{t('forTheCurrent1Day')}</StyledText>
              <StyledNumber>
                {activeUsersSummary?.day ? activeUsersSummary.day : '0'}
              </StyledNumber>
            </div>
          </TextContainer>
        </StyledActiveIosUsersContainer>

        <Divider height={30} />

        <StyledCircleDiagram>
          <LineChart
            labelArray={Object.keys(utils.getYearInfo()?.users ?? [])}
            customersData={Object.values(utils.getUsersByMonth() ?? [])}
            petsData={Object.values(utils.getPetsByMonth() ?? [])}
          />

          <BarChart
            androidData={[dashboard?.os?.Android ?? 0]}
            iosData={[dashboard?.os?.IOS ?? 0]}
            otherData={[dashboard?.os?.undefined ?? 0]}
          />
        </StyledCircleDiagram>

        <Divider height={30} />

        <StyledCircleDiagram>
          <BarChart
            usersByMonthData={utils.getUsersByMonth()}
            usersByDateData={Object.values(
              utils.getUsersByDate() ? (utils.getUsersByDate() as object) : {},
            )}
            usersByMonthLabelArray={
              usersByMonth ? Object.keys(usersByMonth[0]) : []
            }
          />
        </StyledCircleDiagram>

        <Divider height={30} />

        <StyledSessionDevicesWrapper>
          <LineChart
            sessionCountsData={
              sessionCounts?.map(item => item.count)?.sort()
                ? sessionCounts.map(item => item.count).sort()
                : []
            }
            sessionCountsLabelArray={
              sessionCounts?.map(item => item.datetime)?.sort()
                ? sessionCounts.map(item => item.datetime).sort()
                : []
            }
          />
          <HorizantalBarChart
            iosModelsData={Object.values(
              //@ts-ignore
              utils.getAllDevices('ios') ? utils.getAllDevices('ios') : {},
            )}
            androidModelsData={Object.values(
              //@ts-ignore
              utils.getAllDevices('android')
                ? utils.getAllDevices('android')
                : {},
            )}
            iosModelsLabelArray={Object.keys(
              //@ts-ignore
              utils.getAllDevices('ios') ? utils.getAllDevices('ios') : {},
            )}
            androidModelsLabelArray={Object.keys(
              //@ts-ignore
              utils.getAllDevices('android')
                ? utils.getAllDevices('android')
                : {},
            )}
          />
          <BarChart
            iosSessionDurationData={Object.values(
              //@ts-ignore
              utils.getSessionDuration('ios')
                ? utils.getSessionDuration('ios')
                : {},
            )}
            iosSessionDurationLabelsArray={Object.keys(
              //@ts-ignore
              utils.getSessionDuration('ios')
                ? utils.getSessionDuration('ios')
                : {},
            )}
            androidSessionDurationData={Object.values(
              //@ts-ignore
              utils.getSessionDuration('android')
                ? utils.getSessionDuration('android')
                : {},
            )}
            androidSessionDurationLabelsArray={Object.keys(
              //@ts-ignore
              utils.getSessionDuration('android')
                ? utils.getSessionDuration('android')
                : {},
            )}
          />
        </StyledSessionDevicesWrapper>

        <Divider height={30} />

        <StyledVersionsContainer>
          <BarChart
            iosVersionsData={
              iosVersions?.map(item => item.total) as number[]
              // //@ts-ignore
              // utils.getVersions('ios') ? utils.getVersions('ios') : {},
            }
            iosVersionsLabelsArray={
              iosVersions?.map(item => item._id) as string[]
            }
            androidVersionsData={androidVersions?.map(item => item.total)}
            androidVersionsLabelsArray={androidVersions?.map(item => item._id)}
          />
        </StyledVersionsContainer>

        <Divider height={30} />

        <StyledCircleDiagram>
          <PieChart
            labelArray={Object.keys(dashboard?.types ?? [])}
            dataArray={Object.values(dashboard?.types ?? [])}
          />

          <DoughnutChart
            labelArray={Object.keys(dashboard?.genders ?? [])}
            dataArray={Object.values(dashboard?.genders ?? [])}
          />
        </StyledCircleDiagram>
      </LocalizationProvider>
    </Container>
  )
}

export default Dashboard
