import React, { FC } from 'react'
import { CheckboxImage, ContainerAccordion, StyledAccordion } from '../styled'
import { Accordion } from 'react-bootstrap'

import { PhonesProps } from '../types'

import { Assets } from '../../../assets'

import _ from 'lodash'
import { useGetMedicalDirection } from '../../ClinicInfo/services/useGetServices'
import { LanguageKeys } from '../../ApplicationContent/services/types'
import { useTranslation } from 'react-i18next'

export const Services: FC<PhonesProps> = ({ form, setForm, lang }) => {
  const { t } = useTranslation('clinics')

  const { medicalDirections } = useGetMedicalDirection()

  const medicalDirectionsList = medicalDirections?.models.map(item => ({
    name: lang ? item.title[lang as LanguageKeys] : item.title.uk,
    id: item._id,
  }))

  const changeService = (data: { name: string; id: string }) => {
    const elementInForm = form.medicalDirections.find(
      item => item.id === data.id,
    )
    if (elementInForm) {
      // delete from form

      setForm(prev => ({
        ...prev,
        medicalDirections: prev.medicalDirections.filter(
          item => item.id !== elementInForm.id,
        ),
      }))
    } else {
      // add to form
      setForm(prev => ({
        ...prev,
        medicalDirections: [...prev.medicalDirections, data],
      }))
    }
  }

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('clinicServices')}</Accordion.Header>
          <Accordion.Body>
            <Accordion defaultActiveKey="0">
              {medicalDirectionsList?.map((el, index) => (
                <ContainerAccordion key={el?.id + index}>
                  <CheckboxImage
                    onClick={() => changeService(el)}
                    src={
                      !!form.medicalDirections.filter(
                        service => service.name === el?.name,
                      ).length
                        ? Assets.CHECKBOX_ACTIVE_ICON
                        : Assets.CHECKBOX_ICON
                    }
                  />

                  <StyledAccordion>
                    <Accordion.Item eventKey={(index + 1).toString()}>
                      <Accordion.Header>{el?.name}</Accordion.Header>
                    </Accordion.Item>
                  </StyledAccordion>
                </ContainerAccordion>
              ))}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
