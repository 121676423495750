import { Controller, useFormContext } from 'react-hook-form'
import { Divider } from '../../../../components'
import { Label, StyledInput } from '../../styled'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../types'

export const Owner = () => {
  const { control } = useFormContext<FormInput>()
  const { t } = useTranslation('appointments')

  return (
    <>
      <Label>{t('owner')}</Label>

      <Divider height={10} />

      <Controller
        name="owner"
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <StyledInput {...field} onChange={onChange} disabled />
        )}
      />
    </>
  )
}
