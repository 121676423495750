import useSWR from 'swr'
import { serviceInstance } from '../../../services'
import { TAdditionalOptionsResponse } from './types'

const getAdditionalOptions = async () => {
  const response = await serviceInstance.get<TAdditionalOptionsResponse>(
    '/service/api/v1/admin/additional-options',
  )
  return response.data
}

export const useGetAdditionalOptions = () => {
  const {
    data: additionalOptionsList,
    isLoading,
    error,
  } = useSWR('additionalOption', getAdditionalOptions, {
    revalidateOnFocus: false,
  })
  return { additionalOptionsList, isLoading, error }
}
