import { useState } from 'react'
import { CategoryChar, ServiceList } from '../ApplicationContent/styled'
import { Modal } from '../../components/Modal'
import { useTranslation } from 'react-i18next'
import { AdditionalOption, CreateAddOption, EditAddOption } from './components'
import useSWR from 'swr'
import { Service } from '../../services'

export const AddtitionalOptions = () => {
  const { t } = useTranslation('medicalDirection')

  const { data } = useSWR(
    ['get-additional-options', { limit: 100, page: 1 }],
    ([_, params]) => Service.AdditionalOptions.getAdditionalOptions(params),
    {
      revalidateOnFocus: false,
    },
  )

  const [visibleCreateModal, setVisibleCreateModal] = useState(false)
  const [visibleEditModal, setVisibleEditModal] = useState(false)
  const [id, setId] = useState('')

  const onCreateClick = () => {
    setVisibleCreateModal(prev => !prev)
  }

  return (
    <div>
      {visibleCreateModal && (
        <Modal>
          <CreateAddOption setVisibleCreateModal={setVisibleCreateModal} />
        </Modal>
      )}

      {visibleEditModal && (
        <Modal>
          <EditAddOption
            id={id}
            setVisibleEditModal={setVisibleEditModal}
            setId={setId}
          />
        </Modal>
      )}

      <CategoryChar>
        <div>{t('language')}</div>
        <div>{t('name')}</div>

        <button onClick={onCreateClick}>{t('create')}</button>
      </CategoryChar>

      <ServiceList>
        {data?.models?.map(item => (
          <AdditionalOption
            key={item._id}
            setVisibleEditModal={setVisibleEditModal}
            setId={setId}
            currentDirectional={item}
          />
        ))}
      </ServiceList>
    </div>
  )
}
