import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  addDocumentToPlaceAction,
  addPhotoToPlaceAction,
  clearPlaceAction,
  createPlaceAction,
  deleteDocumentFromPlaceAction,
  deleteImageFromPlaceAction,
  getPlaceByIdAction,
  getPlacesAction,
  putPlaceLogoAction,
  putDataToPlaceAction,
  searchPlaceAction,
  updatePlaceAction,
} from './actions'

const InitialState: TInitialState = {
  places: [],
  place: null,
  loading: false,
  total: 0,
}

export const placesReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getPlacesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getPlacesAction.success, (state, { payload }) => ({
      ...state,
      places: payload.places,
      total: payload.total,
      loading: false,
    }))
    builder.addCase(getPlacesAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(getPlaceByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getPlaceByIdAction.success, (state, { payload }) => ({
      ...state,
      place: payload,
      loading: false,
    }))
    builder.addCase(getPlaceByIdAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(searchPlaceAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(searchPlaceAction.success, (state, { payload }) => ({
      ...state,
      places: payload.data,
      total: payload.total,
      loading: false,
    }))
    builder.addCase(searchPlaceAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(createPlaceAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createPlaceAction.success, (state, { payload }) => ({
      ...state,
      place: payload,
      loading: false,
    }))
    builder.addCase(createPlaceAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(updatePlaceAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updatePlaceAction.success, (state, { payload }) => ({
      ...state,
      place: payload,
      loading: false,
    }))
    builder.addCase(updatePlaceAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(putPlaceLogoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(putPlaceLogoAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(putPlaceLogoAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(clearPlaceAction, state => ({
      ...state,
      place: null,
    }))

    builder.addCase(putDataToPlaceAction, (state, { payload }) => ({
      ...state,
      place: {
        ...state['place']!,
        ...payload,
      },
    }))

    builder.addCase(addPhotoToPlaceAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(addPhotoToPlaceAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(addPhotoToPlaceAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(addDocumentToPlaceAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      addDocumentToPlaceAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
    builder.addCase(
      addDocumentToPlaceAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(deleteImageFromPlaceAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      deleteImageFromPlaceAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
    builder.addCase(
      deleteImageFromPlaceAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(deleteDocumentFromPlaceAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      deleteDocumentFromPlaceAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
    builder.addCase(
      deleteDocumentFromPlaceAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
  },
)
