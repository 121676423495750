import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import { Divider } from '../../components'
import {
  Container,
  Header,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
  StyledLink,
  CreateButton,
  StyledPagination,
  StyledButtonEnd,
  StyledButton,
  FullWidth,
} from './styled'
import { getAdminsAction } from '../../store'
import { usePagination, useTypedSelector } from '../../hooks'
import { TTypedAdmins, TAdmins } from './types'
import { RoutesTree } from '../../router'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Settings: React.FC = () => {
  const [typedAdmins, setTypedAdmins] = useState<TTypedAdmins[]>([])
  const { t } = useTranslation('settings')
  const dispatch = useDispatch()

  const { admins } = useTypedSelector(state => state.admins)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.settings) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  const {
    isEnd,
    list: adminsList,
    nextPage,
    // @ts-ignore
    previousPage,
    page,
    // eslint-disable-next-line
  } = usePagination<TAdmins>({
    initialPage: 1,
    initialPerPage: 7,
    //@ts-ignore
    data: admins ?? [],
  })
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getAdminsAction.request())
  }, [])

  const columns = [
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('profile'),
      dataIndex: 'profile',
      key: 'profile',
    },
  ]

  const data = () => {
    return adminsList.map(admin => {
      return {
        email: admin.email,
        key: admin.email + admin._id,
        profile: (
          <StyledLink
            to={{
              pathname: `/admin-profile/${admin._id}`,
            }}
          >
            {t('profile')}
          </StyledLink>
        ),
      }
    })
  }
  // eslint-disable-next-line
  useEffect(() => {
    setTypedAdmins(data())
  }, [adminsList, t])

  return (
    <Container>
      <div>
        <Header>
          <Title>{t('settings')}</Title>
          <CreateButton
            to={{
              pathname: RoutesTree.adminCreate.path,
            }}
          >
            {t('create')}
          </CreateButton>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedAdmins}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {page === 1 ? (
            <StyledButtonEnd>{t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={previousPage}>{t('back')}</StyledButton>
          )}

          <div>
            {page}/{Math.ceil(admins?.length / 7)}
            {/* Здесь 7 это число элементов с падинга на страницу*/}
          </div>

          {isEnd ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={nextPage}>{t('next')}</StyledButton>
          )}
        </StyledPagination>

        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}

export default Settings
