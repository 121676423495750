import { ChangeEvent, useEffect, useState } from 'react'
import { languageKeysArray } from '../../../../consts'
import { LanguageKeys } from '../../services/types'
import {
  DescriptionText,
  OneServiceItem,
  Image,
  EditButton,
  Select,
  TypeWrap,
} from './styled'
import { ToneServiceprops } from './types'
import { useTypedSelector } from '../../../../hooks'
import { t } from 'i18next'
import { api } from '../../../../store'

export const OneService = ({
  service,
  setId,
  setVisibleEditModal,
}: ToneServiceprops) => {
  const { language } = useTypedSelector(state => state.user)

  const [currentlang, setCurrentlang] = useState<LanguageKeys>(language || 'uk')
  useEffect(() => {
    setCurrentlang(language)
  }, [language])
  const langChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentlang(e.target.value as LanguageKeys)
  }

  const onEditClick = () => {
    setId(service._id)
    setVisibleEditModal(true)
  }

  const typeSelect = (type: string) => {
    if (type === 'clinic') {
      return t('functionals:clinic')
    }
    if (type === 'social') {
      return t('functionals:shelter')
    }
    if (type === 'place') {
      return t('functionals:place')
    }
  }

  return (
    <OneServiceItem>
      <Select value={currentlang} onChange={langChange}>
        {languageKeysArray
          .filter(el => el !== 'iw' && el !== 'ar')
          .map(item => (
            <option key={item}>{item}</option>
          ))}
      </Select>
      <DescriptionText>{service?.title?.[currentlang] || ''}</DescriptionText>
      <DescriptionText>
        {service?.description?.[currentlang] || ''}
      </DescriptionText>
      <TypeWrap>{typeSelect(service.type)}</TypeWrap>
      <Image
        src={api.withImageAPI + '/service-categories/' + service.image}
        alt="preview"
      />
      <EditButton onClick={onEditClick}>{t('functionals:edit')}</EditButton>
    </OneServiceItem>
  )
}
