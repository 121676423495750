import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { Divider, EInputTemplate, Input } from '../../components'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { usePetsTypes } from './services'
import {
  Container,
  StyledData,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  SaveButton,
  DeleteButton,
  StyledContainer,
  ProfileImage,
  Div,
  StyledGender,
  StyledSort,
  StyledText,
  StyledAllergiesContainer,
  StyledVaccinesContainer,
  StyledDropDown,
  StyledAccordion,
  StyledRedText,
  StyledLink,
} from './styled'
import { TTypes } from './types'
import { Assets } from '../../assets'
import { TForm, TChangeManagerValidationSchema } from './types'
import { useTypedSelector, useValidation } from '../../hooks'
import { Log } from '../../utils'
import { RoutesTree } from '../../router'
import {
  getPetByIdAction,
  clearPetAction,
  updatePetAction,
  deletePetAction,
  api,
} from '../../store'
import moment from 'moment'
import { TAllergie } from '../../store/pets/types'
import { Accordion } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useTranslation } from 'react-i18next'

const PetDetails: React.FC = () => {
  const { t } = useTranslation('petDetails')

  const { id } = useParams<{ id: string }>()

  const gender = [
    { label: t('male'), value: 'MALE' },
    { label: t('female'), value: 'FEMALE' },
  ]

  const [form, setForm] = useState<TForm>({
    allergies: [],
    weight: '',
    breed: '',
    birthDate: moment().format('YYYY-MM-DD'),
    gender: gender[0].value,
    vaccines: {
      RABIES: [],
      GERPES_ETC: [],
      VIRUS_ETC: [],
      OTHER: [],
    },
    name: '',
    type: '',
  })

  const history = useHistory()

  const dispatch = useDispatch()

  const { pet } = useTypedSelector(store => store.pets)
  const { user, language } = useTypedSelector(state => state.user)

  const { petsTypesList } = usePetsTypes()

  if (!user?.access?.pets) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  const inputHandler = useCallback(
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )
  // eslint-disable-next-line
  const schema = useMemo<TChangeManagerValidationSchema>(
    () => ({
      name: {
        condition: form.name
          ? form.name.length
            ? form.name.length >= 1
            : true
          : true,
        error: t('enterName'),
      },
      breed: {
        condition: form.breed
          ? form.breed.length
            ? form.breed.length >= 1
            : true
          : true,
        error: t('enterBreed'),
      },
      weight: {
        condition: form.weight ? !isNaN(Number(form.weight)) : true,
        error: t('enterWeight'),
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
    // eslint-disable-next-line
  } = useValidation(schema)

  const changeGender = (type: { value: string; label: string }) => {
    setForm(prev => ({ ...prev, gender: type.value }))
  }

  const changeType = (type: TTypes) => {
    //@ts-ignore
    setForm(prev => ({ ...prev, type: type.value }))
  }

  const changeAllergies = (
    id: string,
    value: string,
    type: 'allergens' | 'category',
  ) => {
    let currentAllergia: TAllergie[] = form.allergies.filter(
      elem => elem.id === id,
    )

    const tempAllergie: TAllergie = {
      allergens: currentAllergia[0].allergens,
      category: currentAllergia[0].category,
      id: currentAllergia[0].id,
    }
    tempAllergie[type] = value

    setForm(prev => ({
      ...prev,
      allergies: [
        ...form.allergies.map(elem =>
          elem.id === id ? (tempAllergie as any) : elem,
        ),
      ],
    }))
  }

  const onPressSaveChanges = async () => {
    try {
      enableValidation()
      await validation()

      const result = window.confirm(t('upgradeYourPet'))

      if (result) {
        // form.type =
        //   petsTypesList?.models.find(item => item.title.ru === form.type)?.title
        //     .ru || ''
        // form.gender = genderType[gender.indexOf(form.gender as string)]

        dispatch(
          updatePetAction({
            data: {
              ...form,
              type:
                petsTypesList?.models.find(
                  item => item.title[language] === form.type,
                )?._id || '',
            },
            authorization: '',
            id,
          }),
        )
        // form.type =
        //   petsTypesList?.models.find(item => item.title.ru === form.type)
        //     ?.title[language] || ''
        // form.gender = gender[genderType.indexOf(form.gender as string)]
      }

      disableValidation()
    } catch (error) {
      Log.ruddy('Event: onPressSaveChanges')
    }
  }
  const onPressDeletePet = () => {
    try {
      const result = window.confirm(t('deletePetsPrompt'))

      if (result) {
        dispatch(
          deletePetAction({
            authorization: '',
            id,
            userId: pet?.currentOwner ?? '',
          }),
        )

        history.goBack()
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeletePet')
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getPetByIdAction.request({ id, authorization: '' }))

    return () => {
      dispatch(clearPetAction())
    }
  }, [dispatch, id])
  // eslint-disable-next-line
  useEffect(() => {
    setForm({
      weight: pet?.weight ?? '',
      breed: pet?.breed ?? '',
      birthDate:
        moment(pet?.birthDate).format('YYYY-MM-DD') ??
        moment().format('YYYY-MM-DD'),
      //@ts-ignore
      gender: pet?.gender,
      vaccines: pet?.vaccines ?? {
        RABIES: [],
        GERPES_ETC: [],
        VIRUS_ETC: [],
        OTHER: [],
      },
      name: pet?.name ?? '',
      type: pet?.type?.title[language] || '',
      allergies: pet?.allergies ?? [],
    })
  }, [pet])

  const getVaccineName = (key: string) => {
    let name = ''
    switch (key) {
      case 'RABIES':
        name = t('rabies')
        break
      case 'GERPES_ETC':
        name = t('fromPanleukopeniaCalicivirusHerpes')
        break
      case 'VIRUS_ETC':
        name = t('fromLeukemiaVirusChlamydiosis')
        break
      case 'OTHER':
        name = t('otherVaccinations')
        break
      default:
        break
    }

    return name
  }

  const generateVacines = (ind: number) => {
    const vaccines = []

    for (const [key, value] of Object.entries(form.vaccines)) {
      ind = ind + 1
      vaccines.push(
        <Accordion.Item eventKey={ind.toString()} key={ind}>
          <Accordion.Header>{getVaccineName(key)}</Accordion.Header>
          <Accordion.Body>
            {value.length !== 0 ? (
              value.map((vac: any, ind: number) => {
                return (
                  <StyledVaccinesContainer key={ind}>
                    <div>
                      {t('brand')}: {vac.brand}
                    </div>
                    {vac?.purpose ? (
                      <div>
                        {t('fromWhat')}: {vac?.purpose}
                      </div>
                    ) : (
                      ''
                    )}
                    {vac?.serialNumber ? (
                      <div>
                        {t('serialNumber')}: {vac?.serialNumber}
                      </div>
                    ) : (
                      ''
                    )}
                    {vac?.periodicity ? (
                      <div>
                        {t('frequency')}: {vac?.periodicity}
                      </div>
                    ) : (
                      ''
                    )}
                    {vac?.location ? (
                      <div>
                        {t('location')}: {vac?.location}
                      </div>
                    ) : (
                      ''
                    )}
                    {vac?.executor ? (
                      <div>
                        {t('executor')}: {vac?.executor}
                      </div>
                    ) : (
                      ''
                    )}
                    <div>
                      {t('date')}:{' '}
                      {moment(vac.vaccinationDate).format('DD.MM.YYYY')}
                    </div>
                    <Divider height={10} />
                  </StyledVaccinesContainer>
                )
              })
            ) : (
              <StyledRedText key={ind}>{t('noSuchVaccine')}</StyledRedText>
            )}
          </Accordion.Body>
        </Accordion.Item>,
      )
    }
    return vaccines
  }

  const exit = () => {
    history.goBack()
  }

  return (
    <Container>
      <StyledTitle>
        {Assets.LEFT_ARROW_ICON ? (
          <div onClick={exit}>
            <Image src={Assets.LEFT_ARROW_ICON} />
          </div>
        ) : (
          ''
        )}

        <Title>{t('information')}</Title>

        <Div> </Div>
      </StyledTitle>

      <Divider height={10} />

      {pet?.photo ? (
        <ProfileImage
          src={
            pet?.photo.slice(0, 5) === 'https'
              ? pet.photo
              : api.withImageAPI + '/pets/' + pet.photo
          }
        />
      ) : (
        <ProfileImage
          src={
            'https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png'
          }
        />
      )}

      <Divider height={10} />

      <StyledData>
        <StyledEntity>
          <Input
            label={t('name')}
            name="name"
            value={form.name ?? ''}
            placeholder={t('profile:enterName')}
            template={EInputTemplate.NAME}
            error={schema.name.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.name}
          />
        </StyledEntity>

        <StyledSort>
          <StyledText>{t('type')}</StyledText>
          <Divider width={10} />
          <StyledDropDown>
            <Dropdown
              options={
                petsTypesList?.models.map(item => item?.title[language]) || []
              }
              onChange={value => changeType(value as any)}
              value={form.type as any}
              placeholder={t('enterType')}
            />
          </StyledDropDown>
        </StyledSort>

        <Divider height={20} />

        <StyledEntity>
          <Input
            label={t('breed')}
            name="breed"
            value={form.breed ?? ''}
            placeholder={t('enterBreed')}
            template={EInputTemplate.NAME}
            error={schema.breed.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.breed}
          />
        </StyledEntity>

        <StyledGender>
          <StyledText>{t('gender')}</StyledText>

          <Divider width={10} />

          <StyledDropDown>
            <Dropdown
              options={gender}
              onChange={value => changeGender(value as any)}
              value={form.gender as any}
              placeholder={t('gender')}
            />
          </StyledDropDown>
        </StyledGender>

        <Divider height={10} />

        <StyledEntity>
          <Input
            label={t('weight')}
            name="weight"
            value={form.weight ?? ''}
            placeholder={t('enterWeight')}
            template={EInputTemplate.NAME}
            error={schema.weight.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.weight}
          />
        </StyledEntity>

        <StyledSort>
          <StyledText>{t('birthday')}</StyledText>
          <Divider width={10} />
          <input
            type="date"
            id="birthDate"
            name="birthDate"
            value={
              moment(form.birthDate).format('YYYY-MM-DD') ??
              moment().format('YYYY-MM-DD')
            }
            onChange={v => inputHandler(v)}
            max={new Date().toISOString()}
          />
        </StyledSort>

        <Divider height={20} />

        <StyledAccordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t('allergies')}</Accordion.Header>
              <Accordion.Body>
                {form?.allergies && form.allergies.length !== 0 ? (
                  form.allergies.map(allergy => {
                    return (
                      <StyledAllergiesContainer key={allergy.id}>
                        <StyledEntity>
                          <Input
                            label={t('category')}
                            name="category"
                            value={allergy.category ?? ''}
                            placeholder={t('enterCategory')}
                            template={EInputTemplate.NAME}
                            error={
                              allergy.category
                                ? allergy.category.length >= 1
                                  ? ''
                                  : t('enterCategory')
                                : t('enterCategory') || ''
                            }
                            onChange={v =>
                              changeAllergies(
                                allergy.id as string,
                                //@ts-ignore
                                v.target.value,
                                'category',
                              )
                            }
                            isValid={!isEnabled}
                          />
                        </StyledEntity>
                        <StyledEntity>
                          <Input
                            label={t('allergens')}
                            name="allergens"
                            value={allergy.allergens ?? ''}
                            placeholder={t('enterAllergens')}
                            template={EInputTemplate.NAME}
                            error={
                              allergy.allergens
                                ? allergy.allergens.length >= 1
                                  ? ''
                                  : t('enterAllergens')
                                : t('enterAllergens') || ''
                            }
                            onChange={v =>
                              changeAllergies(
                                allergy.id as string,
                                //@ts-ignore
                                v.target.value,
                                'allergens',
                              )
                            }
                            isValid={!isEnabled}
                          />
                        </StyledEntity>
                      </StyledAllergiesContainer>
                    )
                  })
                ) : (
                  <StyledRedText>{t('noAllergies')}</StyledRedText>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{t('vaccines')}</Accordion.Header>
              <Accordion.Body>
                {form?.vaccines ? (
                  <Accordion defaultActiveKey="0">
                    {generateVacines(2)}
                    {/*// Сюда прокинуть eventKey который выше */}
                  </Accordion>
                ) : (
                  <StyledText>{t('noSuchVaccine')}</StyledText>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </StyledAccordion>

        <Divider height={10} />

        <StyledContainer>
          <StyledLink to={{ pathname: `/profile/${pet?.currentOwner}` }}>
            {t('goToOwner')}
          </StyledLink>

          <DeleteButton onClick={onPressDeletePet}>{t('delete')}</DeleteButton>

          <SaveButton onClick={onPressSaveChanges}>{t('save')}</SaveButton>
        </StyledContainer>

        <Divider height={10} />
      </StyledData>
    </Container>
  )
}

export default PetDetails
