import useSWR from 'swr'
import { getServicesApi } from '../services'

export const useServiceCategories = (type?: string) => {
  const {
    data: serviceCategories,
    isLoading,
    error,
  } = useSWR(['serviceCategories', type], ([, type]) => getServicesApi(type), {
    revalidateOnFocus: false,
  })
  return { serviceCategories, isLoading, error }
}
