import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormHandleSubmit,
} from 'react-hook-form'
import { Divider } from '../../../../components'
import { Editor } from '@tinymce/tinymce-react'
import { Label, StyledButton, StyledInput } from './styled'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../types'
import { useMemo } from 'react'
import { Title } from '../../../../types/tags/tags'
import { ErrorMessage } from '../../../AdviceCreate/styled'
import { Service } from '../../../../services'
import { api } from '../../../../store'

type FormProps = {
  handleSubmit: UseFormHandleSubmit<FormInput>
  onSubmit: (data: FormInput) => Promise<void>
  setSelectedTitle: (lang: string, value: string) => void
  setSelectedDescription: (lang: string, value: string) => void
  language: string
  titleFields: FieldArrayWithId<FormInput, 'title', 'id'>[]
  descriptionFields: FieldArrayWithId<FormInput, 'description', 'id'>[]
  control: Control<FormInput>
  title: Title[]
  description: Title[]
  errors: FieldErrors<FormInput>
  _id: string
}

export const Form = ({
  language,
  titleFields,
  descriptionFields,
  control,
  title,
  description,
  errors,
  handleSubmit,
  onSubmit,
  setSelectedTitle,
  setSelectedDescription,
}: FormProps) => {
  const { t } = useTranslation('advice')

  const showInput = useMemo(() => {
    return titleFields.findIndex(field => field.lang === language) != -1
  }, [language, titleFields])

  const showDescription = useMemo(() => {
    return descriptionFields.length != -1
  }, [language, descriptionFields])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Label>
        {t('title')} - {language}
      </Label>

      <Divider height={10} />

      {showInput && (
        <Controller
          key={language}
          name={`title.${titleFields.findIndex(
            field => field.lang === language,
          )}.value`}
          rules={{
            required:
              title.filter(item => item.value).length > 0 ? '' : t('required'),
          }}
          control={control}
          render={({ field: { value } }) => (
            <StyledInput
              value={value}
              onChange={e => setSelectedTitle(language, e.target.value)}
              placeholder="Enter Title"
            />
          )}
        />
      )}

      {errors?.title && (
        <ErrorMessage>{errors?.title[0]?.value?.message}</ErrorMessage>
      )}

      <Divider height={30} />

      <Label>
        {t('description')} - {language}
      </Label>

      <Divider height={10} />

      {showDescription && (
        <Controller
          key={`description-${language}`}
          name={`description.${descriptionFields.findIndex(
            field => field.lang === language,
          )}.value`}
          control={control}
          rules={{
            required:
              description?.filter(item => item.value).length > 0
                ? ''
                : t('required'),
          }}
          render={({ field: { value } }) => (
            <Editor
              apiKey="xqr8jro50f2g4mbpxmvahaihrjuurgun8az56m20j8m0cu83"
              value={value || ''}
              init={{
                height: 350,
                width: '100%',
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor textcolor',
                  'searchreplace visualblocks code fullscreen textcolor ',
                  'insertdatetime media table paste code help wordcount image textcolor',
                ],
                image_title: false,
                images_upload_url: `https://preproduction.api.petshealth.kitg.com.ua/ads/api/v1/admin/advice/upload`,
                location:
                  'https://preproduction.api.petshealth.kitg.com.ua/public/advice/',
                images_reuse_filename: true,
                automatic_uploads: true,
                images_upload_handler: async function (
                  blobInfo,
                  success,
                  failure,
                ) {
                  const formData = new FormData()
                  formData.append(
                    'images',
                    blobInfo.blob(),
                    blobInfo.filename(),
                  )

                  try {
                    const response = await Service.Advice.uploadImage(formData)
                    console.log('Upload response:', response)

                    if (response) {
                      success(api.withImageAPI + '/advice/' + response[0])
                    } else {
                      failure(
                        'The server did not return the expected format for the image location.',
                      )
                    }
                  } catch (error: any) {
                    console.error('Image upload failed:', error)
                    failure(
                      'Image upload failed: ' +
                        (error.message || 'No error message available.'),
                    )
                  }
                },

                toolbar:
                  'undo redo | formatselect | bold italic backcolor forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | image code',
              }}
              onEditorChange={(a, e) => {
                console.log(e.getContent({ format: 'html' }))
                setSelectedDescription(language, a)
              }}
            />
          )}
        />
      )}

      {errors?.description && (
        <ErrorMessage>{errors?.description[0]?.value?.message}</ErrorMessage>
      )}

      <Divider height={30} />

      <StyledButton type="submit">{t('edit')}</StyledButton>
    </form>
  )
}
