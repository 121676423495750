import { useCallback, useEffect, useState } from 'react'

export const useCategoryFilter = () => {
  const localStorageKey = 'categoryFilter'

  const [medicalDirection, setMedicalDirection] = useState<string>(() => {
    const savedStatuses = localStorage.getItem(localStorageKey)
    return savedStatuses ? savedStatuses : ''
  })

  const clearCategoryFilter = useCallback(() => {
    localStorage.removeItem(localStorageKey)
    setMedicalDirection('')
  }, [])

  useEffect(() => {
    if (medicalDirection) {
      localStorage.setItem(localStorageKey, medicalDirection ?? '')
    } else {
      localStorage.removeItem(localStorageKey)
    }
  }, [medicalDirection])

  return { medicalDirection, setMedicalDirection, clearCategoryFilter }
}
