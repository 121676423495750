import _ from 'lodash'
import { Service } from '../../../services'

type GetCategoryTitleProps = {
  categoriesId: string[]
}

export const getCategoryTitle = async ({
  categoriesId,
}: GetCategoryTitleProps) => {
  if (_.isEmpty(categoriesId)) {
    return []
  }

  const promises = categoriesId.map(item =>
    Service.Category.getCategory({ _id: item }),
  )

  const response = await Promise.all(promises).then(res => res)

  return response
}
