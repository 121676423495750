import styled from 'styled-components'
import { FLEX, FONT } from '../../../../utils'
import { COLORS } from '../../../../assets'

export const ImageContainer = styled.div`
  ${FLEX({ direction: 'column', wrap: 'nowrap', justify: 'center' })}
`

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const AppointmentImage = styled.img`
  cursor: pointer;
  width: 400px;
  border-radius: 8px;
`

export const MediaContainer = styled.div`
  ${FLEX({ direction: 'row' })}
`

export const StyledLabel = styled.label`
  ${FONT({
    size: '18px',
    weight: '400',
    color: COLORS.lightBlue,
  })}

  cursor: pointer;
`

export const ClearImage = styled(Image)`
  cursor: pointer;
`

export const StyledInputPhoto = styled.input`
  visibility: hidden;

  position: fixed;

  cursor: pointer;

  outline: none;
`
