import { LanguageKeys } from '../pages/ApplicationContent/services/types'

export const lib = ['places']

export const languageKeysArray: LanguageKeys[] = [
  'ar',
  'ch',
  'cs',
  'de',
  'en',
  'es',
  'fi',
  'fr',
  'gr',
  'hi',
  'hr',
  'iw',
  'ja',
  'ko',
  'kt',
  'pl',
  'sk',
  'sl',
  'sv',
  'th',
  'tr',
  'uk',
  'ru',
  'nl',
]
