import { serviceInstance } from '../http.service'
import { DeleteMailRequest, GetMailsRequest } from './types'

export class Mail {
  static async getMails(
    params: GetMailsRequest['payload'],
  ): Promise<GetMailsRequest['response']> {
    const response = await serviceInstance.get(
      'user/api/v1/admin/mailing-list',
      { params },
    )

    return response.data
  }

  static async deleteMail({
    _id,
  }: DeleteMailRequest['payload']): Promise<DeleteMailRequest['response']> {
    const response = await serviceInstance.delete(
      `user/api/v1/admin/mailing-list/${_id}`,
    )

    return response.data
  }
}
