import { TGenerateToast } from './types'

export const generateErrorToast: TGenerateToast = message => ({
  severity: 'error',
  message,
})

export const generateSuccessToast: TGenerateToast = message => ({
  severity: 'success',
  message,
})

export const generateInfoToast: TGenerateToast = message => ({
  severity: 'info',
  message,
})

export const generateWarningToast: TGenerateToast = message => ({
  severity: 'warning',
  message,
})
