import styled from 'styled-components'
import { COLORS } from '../../assets'
import { FLEX, FONT } from '../../utils'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  min-height: 100%;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTitle = styled.div`
  ${FLEX({ align: 'center', justify: 'center' })}
`

export const StyledEntity = styled.div`
  width: 100%;

  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const StyledLinkDiv = styled.div``

export const Title = styled.h1`
  ${FONT({ size: '32px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledInputWrapper = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })}
  max-width: 100%;
  width: 100%;
`

export const Label = styled.span`
  width: 100%;

  ${FONT({
    size: '14px',
    weight: '700',
    align: 'left',
    color: COLORS.lightBlue,
  })}
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 200px;

  padding-left: 15px;
  padding-top: 10px;

  resize: vertical;

  background: ${COLORS.gray};

  border: 2px solid ${COLORS.gray};

  &:focus {
    outline: none !important;
    border: 2px solid ${COLORS.green} !important;
    box-sizing: border-box;
    background: ${COLORS.white};
  }

  &::placeholder {
    ${FONT({
      size: '14px',
      weight: '700',
      color: '',
    })}
  }
`

export const Error = styled.span`
  width: 100%;
  height: 5px;

  ${FONT({
    size: '12px',
    weight: '700',
    align: 'right',
    color: COLORS.red,
  })}
`

export const StyledButton = styled.div`
  cursor: pointer;

  padding: 10px 10px;

  border-radius: 3px;

  width: 100%;

  background: ${COLORS.green};

  ${FLEX({})}

  ${FONT({ size: '14px', weight: '700', color: COLORS.white })}
`
