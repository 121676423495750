import { useCallback, useEffect, useState } from 'react'

export const useSort = () => {
  const [sortBy, setSortBy] = useState<string>(() => {
    const savedStatuses = localStorage.getItem('sortBy')
    return savedStatuses ? savedStatuses : ''
  })
  const [order, setOrder] = useState<number>(() => {
    const savedStatuses = localStorage.getItem('order')
    return Number(savedStatuses) ?? -1
  })
  const [page, setPage] = useState<number>(() => {
    const savedStatuses = localStorage.getItem('page')
    return Number(savedStatuses) ? Number(savedStatuses) : 1
  })

  const clearSort = useCallback(() => {
    localStorage.removeItem('sortBy')
    localStorage.removeItem('order')
    localStorage.removeItem('page')
    setSortBy('')
    setOrder(-1)
    setPage(1)
  }, [])

  useEffect(() => {
    if (sortBy) {
      localStorage.setItem('sortBy', sortBy)
    } else {
      localStorage.removeItem('sortBy')
    }
  }, [sortBy])

  useEffect(() => {
    if (order) {
      localStorage.setItem('order', `${order}`)
    } else {
      localStorage.removeItem('order')
    }
  }, [order])

  useEffect(() => {
    if (page) {
      localStorage.setItem('page', `${page}`)
    } else {
      localStorage.removeItem('page')
    }
  }, [page])

  return {
    sortBy,
    setSortBy,
    order: order as 1 | -1,
    setOrder,
    page,
    setPage,
    clearSort,
  }
}
