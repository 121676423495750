import React, { FC, useEffect, useState } from 'react'
import {
  Container,
  Header,
  StyledButton,
  StyledContainer,
  StyledDropDown,
  StyledText,
  Title,
} from './styled'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { Editor } from '@tinymce/tinymce-react'
import { Divider } from '../../components'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks'
import { Redirect } from 'react-router-dom'
import { RoutesTree } from '../../router'
import { getInfoSectionsAction, updateInfoSectionAction } from '../../store'
import { Log } from '../../utils'
import { useTranslation } from 'react-i18next'

const AllLanguages = ['en', 'uk']

const InfoSection: FC = () => {
  const { t } = useTranslation('infoSection')

  const FieldsName = {
    privacy: t('privacyPolicy'),
    about: t('aboutPetsHealth'),
    conditions: t('appUsageTerms'),
  }

  const [form, setForm] = useState({
    privacy: {
      title: '',
      _id: '',
    },
    about: {
      title: '',
      _id: '',
    },
    conditions: {
      title: '',
      _id: '',
    },
  })
  const [formNoChange, setFormNoChange] = useState({
    privacy: {
      title: '',
      title_ua: '',
      title_ru: '',
      _id: '',
    },
    about: {
      title: '',
      title_ua: '',
      title_ru: '',
      _id: '',
    },
    conditions: {
      title: '',
      title_ua: '',
      title_ru: '',
      _id: '',
    },
  })

  const dispatch = useDispatch()

  const { user, language: lang } = useTypedSelector(state => state.user)
  const { info } = useTypedSelector(state => state.info)

  const [language, setLanguage] = useState<string>(lang)

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  const inputHandler = (text: string, name: keyof typeof form) => {
    setForm(prev => ({
      ...prev,
      [name]: { title: text, _id: formNoChange[name]._id },
    }))
  }

  const changeType = (type: string) => {
    setLanguage(type)
  }

  if (!user?.access?.info) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getInfoSectionsAction.request())
  }, [])
  // eslint-disable-next-line
  useEffect(() => {
    const defaultPrivacy = info?.filter(el => el.name === 'privacy')
    const defaultAbout = info?.filter(el => el.name === 'about')
    const defaultConditions = info?.filter(el => el.name === 'conditions')

    setFormNoChange({
      privacy: {
        title: defaultPrivacy[0]?.title ?? '',
        title_ua: defaultPrivacy[0]?.title_ua ?? '',
        title_ru: defaultPrivacy[0]?.title_ru ?? '',
        _id: defaultPrivacy[0]?._id! ?? '',
      },
      about: {
        title: defaultAbout[0]?.title ?? '',
        title_ua: defaultAbout[0]?.title_ua ?? '',
        title_ru: defaultAbout[0]?.title_ru ?? '',
        _id: defaultAbout[0]?._id! ?? '',
      },
      conditions: {
        title: defaultConditions[0]?.title ?? '',
        title_ua: defaultConditions[0]?.title_ua ?? '',
        title_ru: defaultConditions[0]?.title_ru ?? '',
        _id: defaultConditions[0]?._id! ?? '',
      },
    })
  }, [info])
  // eslint-disable-next-line
  useEffect(() => {
    setForm(prev => ({
      ...prev,
      privacy: {
        title:
          language === 'en'
            ? formNoChange.privacy.title
            : language === 'ru'
            ? formNoChange.privacy.title_ru
            : formNoChange.privacy.title_ua,
        _id: prev.privacy._id,
      },
      about: {
        title:
          language === 'en'
            ? formNoChange.about.title
            : language === 'ru'
            ? formNoChange.about.title_ru
            : formNoChange.about.title_ua,
        _id: prev.about._id,
      },
      conditions: {
        title:
          language === 'en'
            ? formNoChange.conditions.title
            : language === 'ru'
            ? formNoChange.conditions.title_ru
            : formNoChange.conditions.title_ua,
        _id: prev.conditions._id,
      },
    }))
  }, [language, formNoChange])

  const onPressSaveChanges = (name: keyof typeof form) => {
    return () => {
      try {
        const result = window.confirm(t('upgradeYourPet'))

        if (result) {
          dispatch(
            updateInfoSectionAction({
              data: {
                name,
                title:
                  language === 'en'
                    ? form[name].title
                    : formNoChange[name].title,
                title_ru:
                  language === 'ru'
                    ? form[name].title
                    : formNoChange[name].title_ru,
                title_ua:
                  language === 'uk'
                    ? form[name].title
                    : formNoChange[name].title_ua,
              },
              authorization: '',
              id: form[name]._id,
            }),
          )
        }
      } catch (error) {
        Log.ruddy('Event: onPressSaveChanges')
      }
    }
  }

  return (
    <Container>
      <Header>
        <Title>{t('information')}</Title>

        <StyledDropDown>
          <Dropdown
            options={AllLanguages}
            onChange={option => changeType(option.value)}
            value={language}
            placeholder="Виберите язык"
          />
        </StyledDropDown>
      </Header>

      <Divider height={30} />

      {Object.keys(form).map(el => (
        <React.Fragment key={el}>
          <StyledText>{FieldsName[el as keyof typeof FieldsName]}</StyledText>
          <Editor
            apiKey="xqr8jro50f2g4mbpxmvahaihrjuurgun8az56m20j8m0cu83"
            value={form[el as keyof typeof form].title}
            init={{
              height: 350,
              width: '100%',
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor textcolor',
                'searchreplace visualblocks code fullscreen textcolor ',
                'insertdatetime media table paste code help wordcount image textcolor',
              ],
              image_title: false,
              automatic_uploads: false,
              images_upload_url: 'postAcceptor.php',
              toolbar:
                'undo redo | formatselect | bold italic backcolor forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | image code',
            }}
            onEditorChange={c => {
              inputHandler(c, el as keyof typeof form)
            }}
          />

          <Divider height={10} />

          <StyledContainer>
            <StyledButton onClick={onPressSaveChanges(el as keyof typeof form)}>
              {t('save')}
            </StyledButton>
          </StyledContainer>

          <Divider height={30} />
        </React.Fragment>
      ))}

      <Divider height={10} />
    </Container>
  )
}

export default InfoSection
