import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import moment from 'moment'
import Dropdown from 'react-dropdown'

import { Divider, EInputTemplate, Input } from '../../components'
import { useTypedSelector, useValidation } from '../../hooks'
import { Assets } from '../../assets'
import {
  createSubCategoriesAdsAction,
  deleteSubCategoriesAdsAction,
  getCategoriesAdsAction,
  getSubCategoriesAdsAction,
  updateSubCategoriesAdsAction,
} from '../../store/categoriesAds'
import { Modal } from '../../components/Modal'

import {
  Header,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
  StyledPagination,
  Error,
  StyledButtonEnd,
  StyledButton,
  FullWidth,
  StyledEntity,
  Image,
  StyledSort,
  Select,
  HeaderTitle,
  HeaderModal,
  FooterModal,
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
  Close,
  NavigateButton,
  BodyModal,
  DropdownBlock,
  StyledWrapper,
  ErrorBlock,
  CategoryTitle,
} from './styled'
import { PAGE_LiMIT, languages } from './consts'
import { useTranslation } from 'react-i18next'
import { getAdsAction } from '../../store/ads'
import { showToastAction } from '../../store'
import { generateErrorToast } from '../../helpers'

export const SubCategoriesTab = () => {
  const [typeModal, setTypeModal] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [subCateoriesArray, setSubCategoriesArray] = useState<any>([])
  const [searchPage, setSearchPage] = useState<number>(1)
  const [currentData, setCurrentData] = useState<any>(null)
  const { t } = useTranslation('categories')
  const [formData, setFormData] = useState<{
    titleUk: string
    titleEn: string
    category: string
  }>({
    titleUk: '',
    titleEn: '',
    category: '',
  })
  const { language: lang } = useTypedSelector(state => state.user)

  const [language, setLanguage] = useState<string>(lang || 'uk')

  const dispatch = useDispatch()

  const schema = useMemo<any>(
    () => ({
      titleUk: {
        condition: !!formData.titleUk,
        error: `${t('enterTheTitleOn')} uk`,
      },
    }),
    [formData],
  )

  const {
    validationSchema,
    validation,
    enableValidation,
    isEnabled,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema)

  const { subCategoryTotalCount, subCategoriesAds, categoriesAds, response } =
    useTypedSelector(state => state.categories)

  const { totalCount } = useTypedSelector(state => state.ads)

  const columns = [
    {
      title: <div>{t('name')}</div>,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: <div>{t('category')}</div>,
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: (
        <StyledSort>
          <div>{t('creationDate')}</div>

          <Divider width={10} />
        </StyledSort>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: t('moreDetails'),
      dataIndex: 'details',
      key: 'details',
    },
  ]

  const choosePage = (page: number) => {
    dispatch(
      getSubCategoriesAdsAction.request({
        page: page === 0 ? 1 : page,
        limit: PAGE_LiMIT,
        sortBy: '',
      }),
    )
    setCurrentPage(page === 0 ? 1 : page)
  }

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
  }

  const enterKeyPress = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <=
          (subCategoryTotalCount === 0
            ? 1
            : Math.ceil(subCategoryTotalCount / PAGE_LiMIT))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1,
      )
  }

  const createSubCategories = async () => {
    enableValidation()
    await validation()

    const data = {
      title: [
        { lang: 'uk', value: formData.titleUk },
        { lang: 'en', value: formData.titleEn },
      ].filter(item => item.value),
      category: formData.category,
    }
    dispatch(createSubCategoriesAdsAction.request({ data }))
    disableValidation()
  }

  const updateSubCategories = async () => {
    enableValidation()
    await validation()

    const data = {
      title: [
        { lang: 'uk', value: formData.titleUk },
        { lang: 'en', value: formData.titleEn },
      ].filter(item => item.value),
      category: formData.category,
    }

    dispatch(
      updateSubCategoriesAdsAction.request({ id: currentData._id, data }),
    )
    disableValidation()
  }

  const deleteSubCategories = () => {
    if (totalCount > 0) {
      dispatch(
        showToastAction.request(
          generateErrorToast(
            'Нельзя удалить категорию к которой привязаны объявления',
          ),
        ),
      )

      return
    }

    dispatch(deleteSubCategoriesAdsAction.request({ id: currentData._id }))
  }

  const handleInputChange = (e: any) => {
    const { value, name } = e.target as HTMLInputElement
    setFormData({ ...formData, [name]: value })
  }

  const getSubCategories = () => {
    dispatch(
      getSubCategoriesAdsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: PAGE_LiMIT,
        sortBy: 'createdAt',
      }),
    )
  }
  // eslint-disable-next-line
  useEffect(() => {
    getSubCategories()
  }, [])

  useEffect(() => {
    if (
      response === 'CREATE_CATEGORIES' ||
      response === 'UPDATE_CATEGORIES' ||
      response === 'DELETE_CATEGORIES'
    ) {
      setTypeModal('')
      setFormData({
        titleUk: '',
        titleEn: '',
        category: '',
      })
      getSubCategories()
    }
  }, [response])

  const openCurrentCategory = (data: any) => {
    setTypeModal('update')
    setCurrentData(data)
  }

  const closeModal = () => {
    setTypeModal(null)
    setCurrentData(null)
    setFormData({
      titleUk: '',
      titleEn: '',
      category: '',
    })
  }

  useEffect(() => {
    setSubCategoriesArray(
      subCategoriesAds?.map((c: any) => ({
        //@ts-ignore
        title: c?.title?.find(item => item?.lang == lang)?.value ?? '-',
        createdAt: moment(c?.createdAt).isValid()
          ? moment(c?.createdAt).format('HH:mm DD.MM.YYYY')
          : '-',
        key: c?._id,
        category:
          //@ts-ignore
          c?.category?.title?.find(item => item?.lang == lang)?.value ?? '-',
        details: (
          <NavigateButton onClick={() => openCurrentCategory(c)}>
            {t('moreDetails')}
          </NavigateButton>
        ),
      })),
    )
  }, [subCategoriesAds, t])

  useEffect(() => {
    if (currentData) {
      setFormData({
        //@ts-ignore
        titleUk: currentData?.title?.find(item => item.lang == 'uk')?.value,
        //@ts-ignore
        titleEn: currentData?.title?.find(item => item.lang == 'en')?.value,
        category: currentData?.category?._id,
      })
    }
  }, [currentData])

  const getCategories = () => {
    dispatch(
      getCategoriesAdsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: 500,
        sortBy: 'createdAt',
      }),
    )
  }

  const getAds = () => {
    dispatch(
      getAdsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: 500,
        sortBy: 'createdAt',
        subcategory: [currentData?._id],
      }),
    )
  }
  // eslint-disable-next-line
  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (typeModal === 'create') {
      setFormData((prevState: any) => ({
        ...prevState,
        category: categoriesAds[0]?._id,
      }))
    }
  }, [categoriesAds, typeModal])

  useEffect(() => {
    getAds()
  }, [currentData?._id])

  const changeType = (type: string) => {
    setLanguage(type)
  }

  return (
    <>
      <div>
        <Header>
          <Title>{`${t('adsSubcategories')} (${subCategoryTotalCount})`}</Title>
          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label={t('page')}
                name="searchPage"
                value={searchPage.toString()}
                placeholder="Страница"
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (subCategoryTotalCount === 0
                      ? 1
                      : Math.ceil(subCategoryTotalCount / PAGE_LiMIT))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>
          <ButtonCreate onClick={() => setTypeModal('create')}>
            {t('create')}
          </ButtonCreate>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={subCateoriesArray}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>{t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              {t('users:back')}
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!subCategoryTotalCount
              ? 1
              : Math.ceil(subCategoryTotalCount / PAGE_LiMIT) === 0
              ? 1
              : Math.ceil(subCategoryTotalCount / PAGE_LiMIT)}
          </div>

          {currentPage ===
          (!subCategoryTotalCount
            ? 1
            : Math.ceil(subCategoryTotalCount / PAGE_LiMIT)) ? (
            <StyledButtonEnd>{t('users:next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              {t('users:next')}
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
      {typeModal && (
        <Modal>
          <HeaderModal>
            <HeaderTitle>
              {typeModal === 'create'
                ? t('createSubCategory')
                : t('updateSubCategory')}
            </HeaderTitle>
            <Close onClick={closeModal}>&#x2716;</Close>
          </HeaderModal>
          <BodyModal>
            <StyledWrapper>
              <Dropdown
                options={languages}
                onChange={option => changeType(option.value)}
                value={language}
                placeholder={t('selectLanguage')}
              />
            </StyledWrapper>
            {language === 'uk' && (
              <Input
                label={`${t('title')} - uk`}
                name="titleUk"
                value={formData.titleUk}
                placeholder={t('enterName')}
                template={EInputTemplate.NAME}
                onChange={handleInputChange}
              />
            )}
            <ErrorBlock>
              {!(!isEnabled || validationSchema.titleUk) && (
                <Error>{schema.titleUk.error}</Error>
              )}
            </ErrorBlock>
            {language === 'en' && (
              <Input
                label={`${t('title')} - eng`}
                name="titleEn"
                value={formData.titleEn}
                placeholder={t('enterName')}
                template={EInputTemplate.NAME}
                onChange={handleInputChange}
              />
            )}
            {categoriesAds && (
              <DropdownBlock>
                <CategoryTitle>{t('category')}</CategoryTitle>
                <Select
                  onChange={e =>
                    setFormData((prevState: any) => ({
                      ...prevState,
                      category: e.target.value,
                    }))
                  }
                  value={formData.category}
                >
                  {categoriesAds?.map(el => (
                    <option value={el?._id}>
                      {el?.title?.find(item => item?.lang == lang)?.value}
                    </option>
                  ))}
                </Select>
              </DropdownBlock>
            )}
          </BodyModal>
          <FooterModal>
            {typeModal === 'create' ? (
              <ButtonCreate onClick={createSubCategories}>
                {t('create')}
              </ButtonCreate>
            ) : (
              <>
                <ButtonDelete onClick={deleteSubCategories}>
                  {t('delete')}
                </ButtonDelete>
                <ButtonUpdate onClick={updateSubCategories}>
                  {t('update')}
                </ButtonUpdate>
              </>
            )}
          </FooterModal>
        </Modal>
      )}
    </>
  )
}
