import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  SendNotificationActionPayload,
  GetNotificationsActionResponse,
} from './types'

export const sendNotificationAction = createApiActions<
  SendNotificationActionPayload,
  GetNotificationsActionResponse,
  AxiosError
>(createActionTypes('NOTIFICATIONS/SEND_NOTIFICATION'))
