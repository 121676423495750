import useSWR from 'swr'
import { serviceInstance } from '../../../services'

import { TMedicalDirectionResponse } from './types'

const getMedicalDirection = async () => {
  const response = await serviceInstance.get<TMedicalDirectionResponse>(
    '/service/api/v1/admin/medical-directions',
    {
      params: { limit: 100 },
    },
  )
  return response.data
}

export const useGetMedicalDirection = () => {
  const {
    data: medicalDirections,
    isLoading,
    error,
  } = useSWR('medical-directions', getMedicalDirection, {
    revalidateOnFocus: false,
  })
  return { medicalDirections, isLoading, error }
}
