import { LocalizationProvider } from '@mui/lab'
import {
  AppointmentResponseContainer,
  Container,
  DeleteButton,
  Image,
  Label,
  StyledButton,
  StyledLinkDiv,
  StyledTitle,
  Title,
} from './styled'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import 'react-dropdown/style.css'
import { useTypedSelector } from '../../hooks'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { RoutesTree } from '../../router'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../components'
import { Assets } from '../../assets'
import { FormProvider, useForm } from 'react-hook-form'
import { FormInput } from './types'
import { useEffect, useMemo, useState } from 'react'
import { Service } from '../../services'
import useSWR from 'swr'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import {
  Description,
  Executor,
  ImageField,
  MedicalDirections,
  Name,
  Owner,
  Pets,
} from './components'
import { useDispatchData } from './hooks'
import { api, getOwnPetsAction, showToastAction } from '../../store'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { Text } from '../Appointments/styled'

export const AppointmentsDetail = () => {
  const { t } = useTranslation('appointments')
  const { user, language: lang } = useTypedSelector(state => state.user)
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()

  const { data, mutate } = useSWR(
    ['get-appointment', { _id: id }],
    ([_, key]) => Service.Appointments.getAppointment(key),
    { revalidateOnFocus: false },
  )

  const { data: appointmentResponse } = useSWR(
    ['get-appointment-response', { _id: id }],
    ([_, key]) => Service.Appointments.getAppointmentResponse(key),
    { revalidateOnFocus: false },
  )

  const [file, setFile] = useState<File | null>(null)

  const methods = useForm<FormInput>()

  const handleBack = () => history.goBack()

  const onSubmit = async (form: FormInput) => {
    if (!data) {
      return
    }

    try {
      const payload = new FormData()

      payload.append('title', form.title)
      payload.append('description', form.description)
      payload.append('medicalDirections', form?.medicalDirections?.value)
      payload.append('pet', form?.pet?.value)
      file && payload.append('media', file)

      await Service.Appointments.updateAppointment({
        _id: id,
        data: payload,
      })

      dispatch(showToastAction.request(generateSuccessToast('Заява оновлена')))

      mutate()
    } catch (e) {
      console.log(e)
    }
  }

  const deleteAppointment = async () => {
    try {
      await Service.Appointments.deleteAppointment({ _id: id })

      dispatch(showToastAction.request(generateSuccessToast('Заява видалена')))
      history.goBack()
    } catch (e) {
      dispatch(
        showToastAction.request(
          generateErrorToast('Заява має відповіді, не можна видалити'),
        ),
      )
      console.error(e)
    }
  }

  const showAppointmentResponse = useMemo(() => {
    if (!appointmentResponse || !data) {
      return false
    }

    return appointmentResponse?.totalCount > 0 && data?.status == 'searching'
  }, [data, appointmentResponse])

  const { dispatchedData } = useDispatchData({ data })

  useEffect(() => {
    if (dispatchedData) {
      methods.reset(dispatchedData)
    }
  }, [dispatchedData])

  useEffect(() => {
    if (data?.pet?.currentOwner) {
      dispatch(getOwnPetsAction.request({ id: data?.pet?.currentOwner }))
    }
  }, [data])

  if (!user?.access?.tags) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <StyledTitle>
          {Assets.LEFT_ARROW_ICON ? (
            <StyledLinkDiv onClick={handleBack}>
              <Image src={Assets.LEFT_ARROW_ICON} />
            </StyledLinkDiv>
          ) : (
            ''
          )}

          <Title>{t('appointment')}</Title>

          <DeleteButton onClick={deleteAppointment}>{t('delete')}</DeleteButton>
        </StyledTitle>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Divider height={30} />

            <ImageField image={data?.media as string[]} setFile={setFile} />

            <Divider height={20} />

            <Name />

            <Divider height={20} />

            <Description />

            <Divider height={20} />

            <Owner />

            <Divider height={20} />

            <Executor />

            <Divider height={20} />

            <MedicalDirections />

            <Divider height={20} />

            {showAppointmentResponse && (
              <>
                <AppointmentResponseContainer>
                  <Label style={{ maxWidth: '100px' }}>{t('responses')}</Label>

                  {appointmentResponse?.docs?.map(item => (
                    <Text>{item?.executor?.name}</Text>
                  ))}
                </AppointmentResponseContainer>

                <Divider height={20} />
              </>
            )}
            <Label>{t('pet')}</Label>

            <Divider height={10} />

            <Label>{data?.pet?.name}</Label>

            <Divider height={10} />

            <img
              src={`${api.withImageAPI}pets/${data?.pet?.photo}`}
              style={{ width: '50px', objectFit: 'contain' }}
            />

            <Divider height={10} />

            <Pets />

            <Divider height={20} />

            <StyledButton type="submit">{t('edit')}</StyledButton>

            <Divider height={30} />
          </form>
        </FormProvider>
      </LocalizationProvider>
    </Container>
  )
}
