import { useTypedSelector } from '../../../../hooks'
import { useMemo } from 'react'
import ReactSelect from 'react-select'
import { customStyles } from './styled'
import { Controller, useFormContext } from 'react-hook-form'
import { Divider } from '../../../../components'
import { Label } from '../../styled'
import { useTranslation } from 'react-i18next'

export const Pets = () => {
  const { control } = useFormContext()
  const { t } = useTranslation('appointments')
  const { ownPets } = useTypedSelector(state => state.pets)

  const options = useMemo(() => {
    if (!ownPets) {
      return []
    }

    return ownPets?.map(item => ({
      label: item?.name,
      value: item?._id,
    }))
  }, [ownPets])

  return (
    <>
      <Label>{t('pets')}</Label>

      <Divider height={10} />

      <Controller
        name="pet"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <ReactSelect
              value={value}
              options={options}
              styles={customStyles}
              onChange={onChange}
              placeholder={t('pets')}
              isClearable
            />
          )
        }}
      />
    </>
  )
}
