import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  Header,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
  StyledLink,
  StyledPagination,
  StyledButtonEnd,
  StyledButton,
  FullWidth,
  StyledEntity,
  Image,
  HeaderSearchContainer,
} from './styled'
import { getControlAction } from '../../store'
import { usePagination, useTypedSelector } from '../../hooks'
import { TypedRegions, TRegion, TPermissions } from './types'
import { RoutesTree } from '../../router'
import { Redirect } from 'react-router-dom'
import { Modal } from './components'
import { Assets } from '../../assets'
import { useTranslation } from 'react-i18next'

const Regions: React.FC = () => {
  const [typedRegions, setTypedRegions] = useState<TypedRegions[]>([])
  const [search, setSearch] = useState<string>('')
  const [newRegionData, setNewRegionData] = useState<
    {
      alphaCode2: string
      alphaCode3: string
      countryName: string
      numericCode: string
      permissions: TPermissions
    }[]
  >([])
  const { t } = useTranslation('regions')
  const dispatch = useDispatch()

  const { controlInfo } = useTypedSelector(state => state.control)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.regions) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  const {
    isEnd,
    list: regionsList,
    nextPage,
    previousPage,
    goToStart,
    page,
    // eslint-disable-next-line
  } = usePagination<TRegion>({
    initialPage: 1,
    initialPerPage: 20,
    // eslint-disable-next-line
    data: useMemo(() => newRegionData ?? [], [newRegionData]),
  })

  // eslint-disable-next-line
  const [currentService, setCurrentService] = useState<TRegion | null>(null)
  // eslint-disable-next-line
  const [open, setOpen] = useState<boolean>(false)

  const columns = [
    {
      title: t('region'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('code'),
      dataIndex: 'code',
      key: 'code',
    },
  ]

  const putTitle = (e: any) => {
    setSearch(e.target.value)
  }

  const enterKeyPress = (e: any) => {
    const re = new RegExp('^' + search, 'i')

    if (e?.code === 'Enter') {
      setNewRegionData(
        controlInfo?.regionalData?.filter(el => {
          return el?.countryName?.search(re) !== -1
        }) ?? [],
      )

      goToStart()
    }
  }

  const onPressSearch = () => {
    const re = new RegExp('^' + search, 'i')

    setNewRegionData(
      controlInfo?.regionalData?.filter(el => {
        return el?.countryName?.search(re) != -1
      }) ?? [],
    )

    goToStart()
  }

  const data = () => {
    if (!!regionsList?.length) {
      return regionsList?.map(region => {
        return {
          address: (
            <StyledLink onClick={handleModal(region)}>
              {region?.countryName}
            </StyledLink>
          ),
          key: region?.countryName,
          code: (
            <StyledLink onClick={handleModal(region)}>
              {region?.alphaCode2}
            </StyledLink>
          ),
        }
      })
    } else {
      return []
    }
  }

  const handleModal = (datat: TRegion | null) => {
    return () => {
      setCurrentService(datat)
      setOpen(prev => !prev)
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    if (!!regionsList?.length) {
      setTypedRegions(data())
    }
  }, [regionsList])

  // eslint-disable-next-line
  useEffect(() => {
    const re = new RegExp('^' + search, 'i')

    setNewRegionData(
      controlInfo?.regionalData?.filter(el => {
        return el?.countryName?.search(re) != -1
      }) ?? [],
    )
  }, [controlInfo?.regionalData])

  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getControlAction.request())
  }, [])

  return (
    <Container>
      <div>
        <Header>
          <Title>{t('regions')}</Title>

          <StyledButton onClick={handleModal(null)}>
            {t('editAccess')}
          </StyledButton>

          <HeaderSearchContainer>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label={t('search')}
                name="search"
                value={search}
                placeholder={t('region')}
                template={EInputTemplate.NAME}
                onChange={putTitle}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image src={Assets.SEARCH_ICON} onClick={onPressSearch} />
          </HeaderSearchContainer>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedRegions}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {page === 1 ? (
            <StyledButtonEnd>{t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={previousPage}>{t('back')}</StyledButton>
          )}

          <div>
            {page}/{Math.ceil((newRegionData?.length ?? 1) / 20)}
            {/* Здесь 20 это число элементов с падинга на страницу*/}
          </div>

          {isEnd ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={nextPage}>{t('next')}</StyledButton>
          )}
        </StyledPagination>

        <Divider height={10} />
      </FullWidth>

      <Modal
        open={open}
        handleModal={handleModal}
        currentService={currentService}
        goToStart={goToStart}
      />
    </Container>
  )
}

export default Regions
