import { HttpService } from '../../services'
import { AxiosResponse } from 'axios'
import { EventPropResponse, GetAnalyticPayload, PayloadAuth } from './types'
import { EVENTSBOARD_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'

export class StackpropsAPI extends HttpService {
  static getStackEventProp(
    request: PayloadAuth & GetAnalyticPayload,
  ): Promise<AxiosResponse<EventPropResponse[]>> {
    return this.request('GET_STACK_EVENT_PROP', {
      url: EVENTSBOARD_URL.getEventsProps(request.event),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
      params: {
        start: request.start,
        end: request.end,
        app: request.app,
      },
    })
  }
}
