import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export const useSearchFilter = () => {
  const localStorageKey = 'searchFilter'

  const [search, setSearch] = useState<string>(() => {
    const savedStatuses = localStorage.getItem(localStorageKey)
    return savedStatuses ? savedStatuses : ''
  })

  const clearSearchFilter = useCallback(() => {
    localStorage.removeItem(localStorageKey)
    setSearch('')
  }, [])

  useEffect(() => {
    if (search) {
      localStorage.setItem(localStorageKey, search)
    } else {
      localStorage.removeItem(localStorageKey)
    }
  }, [search])

  return { search, setSearch, clearSearchFilter }
}
