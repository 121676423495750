import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'

export const Container = styled.div`
  width: 100%;
  min-height: 100%;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Title = styled.h1`
  ${FONT({ size: '32px', color: COLORS.lightBlue, weight: '700' })}

  ${FLEX({})}
`

export const StyledCircleDiagram = styled.div`
  width: 100%;

  ${FLEX({ direction: 'row', justify: 'center', gap: '20px' })}
`
export const StyledSessionDevicesWrapper = styled.div`
  width: 100%;

  ${FLEX({ direction: 'row', justify: 'space-between' })}
`

export const StyledDateContainer = styled.div`
  width: 100%;

  ${FLEX({ direction: 'row', justify: 'flex-start' })}
`

export const StyledActiveIosUsersContainer = styled.div`
  width: 100%;

  box-shadow: 1px 5px 2.5px 2.5px #f7f7f7;

  ${FLEX({ justify: 'center' })}
`

export const StyledVersionsContainer = styled.div`
  width: 100%;

  box-shadow: 1px 5px 2.5px 2.5px #f7f7f7;

  ${FLEX({ justify: 'center' })}
`

export const StyledDropDown = styled.div`
  .Dropdown-control {
    width: 258px;
    height: 55px;
    display: flex;
    align-items: center;
  }
  .Dropdown-arrow {
    top: 23px;
    right: 16px;
  }
`

export const TextContainer = styled.div`
  padding-left: 24px;
  ${FLEX({ direction: 'column', gap: '16px', justify: 'flex-start' })}
`

export const StyledText = styled.p`
  ${FONT({ size: '16px', weight: '500' })}
`

export const StyledNumber = styled.p`
  ${FONT({ size: '20px', weight: '600' })}
`
