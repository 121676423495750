import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { LineChartProps } from './types'
import { Container } from './styled'
// import { options, sessionCountsOptions, YearArray } from './components'
import { SetPlatformFilterexport } from '../../store'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const LineChart: FC<LineChartProps> = ({
  labelArray,
  activeIosUsersLabelArray,
  activeIosUsersDate,
  activeAndroidUsersDate,
  activeAndroidUsersLabelArray,
  sessionCountsData,
  sessionCountsLabelArray,
  customersData,
  petsData,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('analitics:usersAndPets'),
        font: {
          size: 20,
        },
      },
    },
    maintainAspectRatio: false,
  }

  const sessionCountsOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('analitics:numberOfSessionsPerDay'),
        font: {
          size: 20,
        },
      },
    },
    maintainAspectRatio: false,
  }
  const YearArray = {
    March: t('month:March'),
    April: t('month:April'),
    May: t('month:May'),
    June: t('month:June'),
    July: t('month:July'),
    August: t('month:August'),
    September: t('month:September'),
    October: t('month:October'),
    November: t('month:November'),
    December: t('month:December'),
    January: t('month:January'),
    February: t('month:February'),
  }

  const data = {
    labels: Object.values(YearArray),
    datasets: [
      {
        label: t('sidebar:users'),
        data: customersData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: t('sidebar:pets'),
        data: petsData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }

  const activeIosUsersData = {
    labels:
      activeIosUsersLabelArray &&
      activeIosUsersLabelArray.map(el =>
        new Date(el).toDateString().split(' ').slice(0, -1),
      ),
    datasets: [
      {
        label: 'iOS',
        data: activeIosUsersDate,
        borderColor: '#2c7cb8',
        backgroundColor: 'rgb(44,124,184)',
      },
      {
        label: 'Android',
        data: activeAndroidUsersDate,
        borderColor: '#1fac18',
        backgroundColor: 'rgb(31,172,24)',
      },
    ],
  }

  const sessionCountsDataChart = {
    labels:
      sessionCountsLabelArray &&
      sessionCountsLabelArray.map(el => new Date(el).toJSON().split('T')[0]),
    datasets: [
      {
        label: t('analitics:NumberOfSessions'),
        data: sessionCountsData,
        borderColor: '#2c7cb8',
        backgroundColor: 'rgb(44,124,184)',
      },
    ],
  }

  const activeIosUsersOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        onClick: function (e: any, legendItem: any, legend: any) {
          const index = legendItem.datasetIndex
          const ci = legend.chart
          if (ci.isDatasetVisible(index)) {
            ci.hide(index)
            legendItem.hidden = true
            dispatch(SetPlatformFilterexport(index ? 'ios' : 'android'))
          } else {
            ci.show(index)
            legendItem.hidden = false
            dispatch(SetPlatformFilterexport(''))
          }
        },
      },
      title: {
        display: true,
        text: t('analitics:activeUsers'),
        font: {
          size: 20,
        },
      },
    },
    maintainAspectRatio: false,
  }

  return (
    <Container style={{ width: sessionCountsData ? '35%' : '80%' }}>
      {petsData && <Line options={options} data={data} height={350} />}
      {!petsData && !sessionCountsData && (
        <Line
          options={activeIosUsersOptions}
          data={activeIosUsersData}
          height={350}
        />
      )}
      {!petsData && sessionCountsData && (
        <Line
          options={sessionCountsOptions}
          data={sessionCountsDataChart}
          height={350}
        />
      )}
    </Container>
  )
}

export default LineChart
