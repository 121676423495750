import { TCreateDirectionProps, TCreateMedicalDirForm } from './types'
import { useForm, SubmitHandler } from 'react-hook-form'
import { createMedicalDirection } from '../../services'
import {
  CreteForm,
  Errormessage,
  HeadingWrap,
  InputsWrap,
} from '../../../ApplicationContent/components/CreateServiceWindow/styled'
import { Assets } from '../../../../assets'
import { languageKeysArray } from '../../../../consts'
import { mutate } from 'swr'
import { useTranslation } from 'react-i18next'
import { useTypedSelector } from '../../../../hooks'

export const CreateDirectionWindows = ({
  setVisibleCreateModal,
}: TCreateDirectionProps) => {
  const closeModal = () => setVisibleCreateModal(prev => !prev)
  const { t } = useTranslation('medicalDirection')
  const { language } = useTypedSelector(state => state.user)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TCreateMedicalDirForm>({
    defaultValues: { lang: language || 'uk' },
  })

  const onSubmit: SubmitHandler<TCreateMedicalDirForm> = async data => {
    await createMedicalDirection({
      title: { [data.lang]: data.title },
    })
    mutate('medical-directions')
    closeModal()
  }

  return (
    <CreteForm onSubmit={handleSubmit(onSubmit)}>
      <HeadingWrap>
        <h3>{t('controlCreation')}</h3>
        <div onClick={closeModal}>
          <img src={Assets.CYAN_ICON} alt="close" />
        </div>
      </HeadingWrap>
      <InputsWrap>
        <div>
          <div>
            <p>{t('language')}</p>
            <select {...register('lang')}>
              {languageKeysArray.map(item => (
                <option key={item}>{item}</option>
              ))}
            </select>
          </div>
          <label>
            {t('name')}
            <input
              placeholder={t('name')}
              {...register('title', { required: t('mandatoryField') })}
            />
            {errors.title && (
              <Errormessage>{errors.title.message}</Errormessage>
            )}
          </label>
        </div>
      </InputsWrap>
      <HeadingWrap>
        <button onClick={closeModal} type="button">
          {t('close')}
        </button>
        <button type="submit">{t('create')}</button>
      </HeadingWrap>
    </CreteForm>
  )
}
