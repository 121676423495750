import { LocalizationProvider } from '@mui/lab'
import {
  AdviceImage,
  CheckboxContainer,
  CheckboxImage,
  ClearImage,
  Container,
  ErrorMessage,
  Image,
  ImageContainer,
  Label,
  StyledButton,
  StyledDropDown,
  StyledInput,
  StyledInputPhoto,
  StyledLabel,
  StyledLinkDiv,
  StyledSearch,
  StyledTextarea,
  StyledTitle,
  Title,
} from './styled'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import Dropdown from 'react-dropdown'
import { languages } from './consts'
import 'react-dropdown/style.css'
import { useTypedSelector } from '../../hooks'
import { Redirect, useHistory } from 'react-router-dom'
import { RoutesTree } from '../../router'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../components'
import { Assets } from '../../assets'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { FormInput } from './types'
import { ChangeEvent, useEffect, useState } from 'react'
import { Service } from '../../services'
import { Form } from './components'
import useSWR from 'swr'
import { useTranslate } from '../../hooks/useTranslate'
import Select from 'react-select'
import _ from 'lodash'
import { TagsInput } from 'react-tag-input-component'
import { Types } from '../../types'

export const AdviceCreate = () => {
  const { t } = useTranslation('advice')
  const { translate } = useTranslate()
  const { user, language: lang } = useTypedSelector(state => state.user)
  const history = useHistory()

  const [language, setLanguage] = useState<string>(lang || 'en')
  const [file, setFile] = useState<File | null>(null)
  const [metakeywords, setMetaKeywords] = useState<string[]>([])
  const [resultKeywords, setResultKeywords] = useState<Types.Advice.Title[]>([])
  const [primaryLanguage, setPrimaryLanguage] = useState<string>('')

  const { data: tags } = useSWR(
    ['get-tags', {}],
    ([_, key]) => Service.Tag.getTags(key),
    { revalidateOnFocus: false },
  )

  const { data: categories } = useSWR(
    ['get-categories', { limit: 100 }],
    ([_, key]) => Service.Category.getCategories(key),
    { revalidateOnFocus: false },
  )

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: {
      title: [{ value: '', lang: lang || 'en' }],
      description: [{ value: '', lang: lang || 'en' }],
      image: '',
      tags: [],
      category: '',
      subcategory: '',
    },
  })

  const [title, description, tgs, category, subcategory, image] = watch([
    'title',
    'description',
    'tags',
    'category',
    'subcategory',
    'image',
  ])

  const { data: subcategories } = useSWR(
    watch('category')
      ? [
          'get-subcategories',
          {
            ...(watch('category')
              ? { category: watch('category'), limit: 100 }
              : {}),
          },
        ]
      : null,
    ([_, key]) => Service.Subcategory.getSubcategories(key),
    { revalidateOnFocus: false },
  )

  const { fields: titleFields, append: appendTitle } = useFieldArray({
    control,
    name: 'title',
  })
  const { fields: descriptionFields, append: appendDescription } =
    useFieldArray({
      control,
      name: 'description',
    })
  const { fields: metadescriptionFields, append: appendMetadescription } =
    useFieldArray({
      control,
      name: 'metadescription',
    })

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    const file = e?.target?.files[0]

    setFile(file)

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.onload = () => {
        setValue('image', reader.result as string) // Set image data as base64
      }
      reader.readAsDataURL(file)
    }
  }

  const handleBack = () => history.goBack()

  const changeType = (type: string) => {
    setLanguage(type)
  }

  const changePrimary = (type: string) => {
    setPrimaryLanguage(type)
  }

  const setSelectedTitle = (lang: string, value: string) => {
    const existingTitleIndex = titleFields.findIndex(
      field => field.lang === lang,
    )

    if (existingTitleIndex !== -1) {
      setValue(`title.${existingTitleIndex}.value`, value)
    } else {
      value && appendTitle({ value, lang })
    }
  }

  const setSelectedDescription = (lang: string, value: string) => {
    const existingDescriptionIndex = descriptionFields.findIndex(
      field => field.lang === lang,
    )

    if (existingDescriptionIndex !== -1) {
      setValue(`description.${existingDescriptionIndex}.value`, value)
    } else {
      value && appendDescription({ value, lang })
    }
  }

  const setSelectedMetadescription = (lang: string, value: string) => {
    const existingMetadescriptionIndex = metadescriptionFields.findIndex(
      field => field.lang === lang,
    )

    if (existingMetadescriptionIndex !== -1) {
      setValue(`metadescription.${existingMetadescriptionIndex}.value`, value)
    } else {
      value && appendMetadescription({ value, lang })
    }
  }

  const onSubmit = async (data: FormInput) => {
    try {
      console.log(data)
      const uploadData = new FormData()

      data.title.map(
        item =>
          item.value && uploadData.append('title[]', JSON.stringify(item)),
      )
      data.description.map(
        item =>
          item.value &&
          uploadData.append('description[]', JSON.stringify(item)),
      )
      data?.metadescription?.map(
        item =>
          item.value &&
          uploadData.append('metaDescription[]', JSON.stringify(item)),
      )
      file && uploadData.append('images', file as Blob)
      data.tags.map(
        item => item.value && uploadData.append('tags[]', item.value),
      )

      resultKeywords &&
        resultKeywords.map(
          item =>
            item?.value &&
            uploadData.append('metaKeywords[]', JSON.stringify(item)),
        )

      uploadData.append('category', data.category)
      uploadData.append('subcategory', data.subcategory)
      primaryLanguage && uploadData.append('primaryLang', primaryLanguage)

      await Service.Advice.createAdvice(uploadData)
      handleBack()
    } catch (e) {
      console.log(e)
    }
  }

  const onMetaKeywordsChange = (newTags: string[]) => {
    // Assuming you have a way to get language info for each tag
    const updatedTags = newTags?.map(tag => {
      // Find the tag in the existing state to get its language
      const existingTag = resultKeywords.find(t => t?.value === tag)
      return {
        value: tag,
        lang: existingTag ? existingTag?.lang : lang,
      }
    })

    setResultKeywords(updatedTags)
    setMetaKeywords(newTags)
  }

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  if (!user?.access?.advice) {
    return <Redirect to={RoutesTree.notFound.path} />
  }

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <StyledTitle>
          {Assets.LEFT_ARROW_ICON ? (
            <StyledLinkDiv onClick={handleBack}>
              <Image src={Assets.LEFT_ARROW_ICON} />
            </StyledLinkDiv>
          ) : (
            ''
          )}

          <Title>{t('advice')}</Title>

          <div></div>
        </StyledTitle>

        <ImageContainer>
          {_.isEmpty(watch('image')) && (
            <img src={Assets.DOWNLOAD_ICON} width={100} height={100} />
          )}

          <AdviceImage src={watch('image')} />

          <StyledSearch>
            <StyledLabel htmlFor="photo">
              <Image src={Assets.DOWNLOAD_ARROW_ICON} />
            </StyledLabel>

            <Controller
              key={language}
              name="image"
              control={control}
              rules={{ required: _.isEmpty(image) ? t('required') : '' }}
              render={({ field: { onChange } }) => (
                <StyledInputPhoto
                  id="photo"
                  name="photo"
                  type="file"
                  value=""
                  accept="image/*"
                  onChange={e => {
                    onImageChange(e)
                  }}
                />
              )}
            />

            <ClearImage
              onClick={() => setValue('image', '')}
              src={Assets.CYAN_ICON}
            />
          </StyledSearch>

          <ErrorMessage>{errors?.image?.message}</ErrorMessage>
        </ImageContainer>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('language')}</Label>

          <Divider height={10} />

          <Dropdown
            options={languages}
            onChange={option => changeType(option.value)}
            value={language}
            placeholder="Виберите язык"
          />
        </StyledDropDown>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('primary.language')}</Label>

          <Divider height={10} />

          <Dropdown
            options={languages}
            onChange={option => changePrimary(option.value)}
            value={primaryLanguage}
            placeholder="Виберите язык"
          />
        </StyledDropDown>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('metakeywords')}</Label>

          <Divider height={10} />

          <TagsInput
            value={metakeywords}
            onChange={onMetaKeywordsChange}
            placeHolder="Enter keywords"
          />
        </StyledDropDown>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('metadescription')}</Label>

          <Divider height={10} />

          <Controller
            key={`metadescription-${language}`}
            name={`metadescription.${metadescriptionFields.findIndex(
              field => field.lang === language,
            )}.value`}
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <StyledTextarea
                {...field}
                onChange={e =>
                  setSelectedMetadescription(language, e.target.value)
                }
                placeholder="Enter meta description"
              />
            )}
          />
        </StyledDropDown>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('tags')}</Label>

          <Divider height={10} />

          {tags && (
            <Controller
              name="tags"
              control={control}
              rules={{ required: _.isEmpty(tgs) ? t('required') : '' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={tags?.models.map(tag => ({
                    value: tag?._id,
                    label: translate(tag.title) ?? '',
                  }))}
                  onChange={(option, action) => onChange(option)}
                  value={value}
                  placeholder="Виберите тег"
                  isMulti
                />
              )}
            />
          )}

          <ErrorMessage>{errors?.tags?.message}</ErrorMessage>
        </StyledDropDown>

        <Divider height={30} />

        <StyledDropDown>
          <Label>{t('category')}</Label>

          <Divider height={10} />

          {categories && (
            <Controller
              name="category"
              control={control}
              rules={{ required: _.isEmpty(category) ? t('required') : '' }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  options={categories?.models.map(category => ({
                    value: category?._id,
                    label: translate(category.title),
                  }))}
                  onChange={e => onChange(e.value)}
                  value={value}
                  placeholder="Виберите категорію"
                />
              )}
            />
          )}

          <ErrorMessage>{errors?.category?.message}</ErrorMessage>
        </StyledDropDown>

        <Divider height={30} />

        {subcategories && (
          <StyledDropDown>
            <Label>{t('subcategory')}</Label>

            <Divider height={10} />

            <Controller
              name="subcategory"
              control={control}
              rules={{ required: _.isEmpty(subcategory) ? t('required') : '' }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  options={subcategories?.models?.map(subcategory => ({
                    value: subcategory?._id,
                    label: translate(subcategory.title),
                  }))}
                  onChange={e => onChange(e.value)}
                  value={value}
                  placeholder="Виберите підкатегорію"
                />
              )}
            />

            <ErrorMessage>{errors?.subcategory?.message}</ErrorMessage>
          </StyledDropDown>
        )}

        <Divider height={30} />

        <Form
          language={language}
          titleFields={titleFields}
          descriptionFields={descriptionFields}
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          setSelectedDescription={setSelectedDescription}
          setSelectedTitle={setSelectedTitle}
          title={title}
          description={description}
          errors={errors}
        />
      </LocalizationProvider>
    </Container>
  )
}
