import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  DeleteClinicActionPayload,
  GetClinicsActionResponse,
  GetClinicsPayload,
  GetComplaintByIdActionPayload,
} from './types'

export const getComplaintAction = createApiActions<
  GetClinicsPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('FEEDBACK/GET_COMPLAINT'))

export const getInaccuracyAction = createApiActions<
  GetClinicsPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('FEEDBACK/GET_INACCURACY'))

export const clearComplaintAction = createAction<
  void,
  'FEEDBACK/CLEAR_COMPALINT'
>('FEEDBACK/CLEAR_COMPALINT')

export const deleteComplaintAction = createAction<
  DeleteClinicActionPayload,
  'FEEDBACK/DELETE_COMPLAINT'
>('FEEDBACK/DELETE_COMPLAINT')

export const getProposalAction = createApiActions<
  GetClinicsPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('FEEDBACK/GET_PROPOSAL'))

export const clearProposalAction = createAction<
  void,
  'FEEDBACK/CLEAR_PROPOSAL'
>('FEEDBACK/CLEAR_PROPOSAL')

export const clearInaccuracyAction = createAction<
  void,
  'FEEDBACK/CLEAR_INACCURACY'
>('FEEDBACK/CLEAR_INACCURACY')

export const deleteProposalAction = createAction<
  DeleteClinicActionPayload,
  'FEEDBACK/DELETE_PROPOSAL'
>('FEEDBACK/DELETE_PROPOSAL')

export const getComplaintByIdAction = createApiActions<
  GetComplaintByIdActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('FEEDBACK/GET_COMPLAINT_BY_ID'))

export const getInaccuracyByIdAction = createApiActions<
  GetComplaintByIdActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('FEEDBACK/GET_INACCURACY_BY_ID'))

export const getProposalByIdAction = createApiActions<
  GetComplaintByIdActionPayload,
  GetClinicsActionResponse,
  AxiosError
>(createActionTypes('FEEDBACK/GET_PROPOSAL_BY_ID'))
