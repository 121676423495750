import { api } from '../config'

export const ADMIN_URL = {
  create_admin: `${api.withAPI}/administrative/api/v1/admin/admin`,
  get_admins: `${api.withAPI}administrative/api/v1/admin/admin`,
  getAdminById: (id: string) =>
    `${api.withAPI}administrative/api/v1/admin/admin/${id}`,
  updateAdmin: (id: string) =>
    `${api.withAPI}administrative/api/v1/admin/admin/${id}`,
  deleteAdmin: (id: string) =>
    `${api.withAPI}administrative/api/v1/admin/admin/${id}`,
}
