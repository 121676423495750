import React, { ChangeEvent } from 'react'
import { Assets } from '../../assets'
import { Divider } from '../../components'
import { useTypedSelector } from '../../hooks'
import {
  TopbarWrapper,
  Logo,
  LogoIcon,
  LogoContainer,
  SelectWrap,
} from './styled'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from '../../store'
import i18n from '../../locales/i18n'
import { LanguageKeys } from '../ApplicationContent/services/types'
import { useDispatch } from 'react-redux'

const Topbar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user, language } = useTypedSelector(state => state.user)

  const selectLangChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    dispatch(changeLanguage(value as LanguageKeys))
    i18n.changeLanguage(value)
  }

  return (
    <TopbarWrapper>
      <LogoContainer>
        <LogoIcon src={Assets.PETS_LOGO_ICON} />
        <Divider width={10} />
        <Logo>Pets Health</Logo>
      </LogoContainer>

      <SelectWrap>
        <select value={language} onChange={selectLangChange}>
          <option value="uk">{i18n.t('languages:ukrainian')}</option>
          <option value="en">{i18n.t('languages:english')}</option>
        </select>
        <div>{`${t('sidebar:admin')}: ${user?.email ?? '-'}`}</div>
      </SelectWrap>
    </TopbarWrapper>
  )
}

export default Topbar
