import { api } from '../config'

export const FEEDBACK_URL = {
  get_complaint: (page: number, limit: number) =>
    `${api.withAPI}user/api/v1/admin/complaint/?page=${page}&limit=${limit}`,
  get_inaccuracy: (page: number, limit: number) =>
    `${api.withAPI}user/api/v1/admin/complaint/inaccuracy/?page=${page}&limit=${limit}`,
  deleteComplaint: (id: string) =>
    `${api.withAPI}user/api/v1/admin/complaint/${id}`,
  getComplaintById: (id: string) =>
    `${api.withAPI}user/api/v1/admin/complaint/${id}`,
  get_proposal: (page: number, limit: number) =>
    `${api.withAPI}service/api/v1/admin/proposal/?page=${page}&limit=${limit}`,
  deleteProposal: (id: string) =>
    `${api.withAPI}service/api/v1/admin/proposal/${id}`,
  getProposalById: (id: string) =>
    `${api.withAPI}service/api/v1/admin/proposal/${id}`,
  getInaccuracyById: (id: string) =>
    `${api.withAPI}user/api/v1/admin/complaint/${id}`,
}
