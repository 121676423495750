import { serviceInstance } from '../http.service'
import {
  CreateSubcategoryRequest,
  DeleteSubcategoryRequest,
  GetSubcategoriesRequest,
  GetSubcategoryRequest,
  UpdateSubcategoryRequest,
} from './types'

export class Subcategory {
  static async getSubcategories(
    params: GetSubcategoriesRequest['payload'],
  ): Promise<GetSubcategoriesRequest['response']> {
    const response = await serviceInstance.get(
      'ads/api/v1/admin/advice-subcategories',
      { params },
    )

    return response.data
  }

  static async getSubcategory({
    _id,
  }: GetSubcategoryRequest['payload']): Promise<
    GetSubcategoryRequest['response']
  > {
    const response = await serviceInstance.get(
      `ads/api/v1/admin/advice-subcategories/${_id}`,
    )

    return response.data
  }

  static async createSubcategory(
    data: CreateSubcategoryRequest['payload'],
  ): Promise<CreateSubcategoryRequest['response']> {
    const response = await serviceInstance.post(
      `ads/api/v1/admin/advice-subcategories`,
      data,
    )

    return response.data
  }

  static async updateSubcategory({
    _id,
    data,
  }: UpdateSubcategoryRequest['payload']): Promise<
    UpdateSubcategoryRequest['response']
  > {
    const response = await serviceInstance.put(
      `ads/api/v1/admin/advice-subcategories/${_id}`,
      data,
    )

    return response.data
  }

  static async deleteSubcategory({
    _id,
  }: DeleteSubcategoryRequest['payload']): Promise<
    DeleteSubcategoryRequest['response']
  > {
    const response = await serviceInstance.delete(
      `ads/api/v1/admin/advice-subcategories/${_id}`,
    )

    return response.data
  }
}
