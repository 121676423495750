import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { COLORS } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'

export const Container = styled.div`
  width: 100%;
  min-height: 100%;

  /* overflow-y: scroll; */

  /* &::-webkit-scrollbar {
    display: none;
  } */
`
export const Title = styled.h1`
  ${FONT({ size: '32px', color: COLORS.lightBlue, weight: '700' })}

  ${FLEX({})}
`

export const StyledEntity = styled.div`
  /* width: 100%; */

  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const TableWrapper = styled.div`
  width: 100%;
  /* height: calc(70%); */
  padding-bottom: 10px;

  background: ${COLORS.white};

  /* overflow-y: scroll; */
`

export const StyledSort = styled.div`
  /* width: 100%; */

  /* padding: 25px; */

  ${FLEX({
    justify: 'center',
    direction: 'row',
    wrap: 'nowrap',
    align: 'center',
  })}
`

export const StyledRow = styled.tr`
  & td {
    padding: 9px 15px 7px 15px;
    width: 20%;
    /* overflow-x: scroll; */
    /* &::-webkit-scrollbar {
      display: none;
    } */
  }

  &:hover {
    transition: 0.3s;
    background: ${COLORS.gray};
  }

  ${FONT({ size: '16px', color: COLORS.lightBlue, weight: '400' })}
`

export const StyledHeader = styled.th`
  padding: 15px;

  ${SHADOW({ x: 2, y: 0 })};

  ${FONT({
    size: '16px',
    color: COLORS.lightBlue,
    weight: '700',
    align: 'left',
  })}
`

export const StyledTable = styled.table`
  width: 100%;
`

export const StyledDropDown = styled.div`
  display: flex;
  gap: 30px;

  .Dropdown-control {
    width: 258px;
    max-width: 258px;
    height: 55px;
    display: flex;
    align-items: center;
  }
  .Dropdown-arrow {
    top: 23px;
    right: 16px;
  }
  .Dropdown-menu {
    width: 258px;
  }
`
export const StyledNumberLink = styled(Link)`
  ${FONT({
    weight: '400',
    size: '16px',
    color: COLORS.lightBlue,
  })}

  cursor: pointer;

  text-decoration: none;
`
