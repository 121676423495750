import styled from 'styled-components'
import { COLORS } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledData = styled.div`
  width: 100%;

  ${FLEX({ align: 'center', direction: 'column' })}
`

export const containerStyle = {
  width: '100%',
  height: '32vh',
}

export const StyledDiv = styled.div`
  ${FLEX({})}
`

export const StyledFieldData = styled.div`
  width: 100%;

  ${FLEX({
    align: 'flex-start',
    direction: 'row',
  })}
`

export const StyledContainer = styled.div`
  width: 100%;

  ${FLEX({ align: 'flex-start', justify: 'space-around' })}
`

export const StyledFullName = styled.div`
  width: 100%;

  ${FLEX({ justify: 'space-between', wrap: 'nowrap' })}
`

export const StyledTitle = styled.div`
  ${FLEX({ align: 'flex-start', justify: 'space-between' })}
`

export const Image = styled.img`
  width: 16px;
  margin-right: 15px;
  margin-top: 10px;

  cursor: pointer;

  ${FLEX({})}
`

export const ProfileImage = styled.img`
  width: 200px;
  height: 200px;

  border-radius: 50%;

  margin-left: auto;
  margin-right: auto;

  ${FLEX({})}
`

export const PetImage = styled.img`
  /* width: 100%; */
  height: 150px;

  /* border-radius: 50%; */

  margin-left: auto;
  margin-right: auto;

  ${FLEX({})}
`

export const StyledField = styled.div`
  width: 500px;
  ${FLEX({ direction: 'row', align: 'flex-start', justify: 'space-between' })}
`

export const StyledUnionField = styled.div`
  width: 100%;
  ${FLEX({ direction: 'column', align: 'flex-start' })};
`

export const StyledEntity = styled.div`
  width: 100%;

  ${FONT({ size: '18px', color: COLORS.lightBlue, weight: '700' })}
`

export const Title = styled.h1`
  ${FONT({ size: '32px', color: COLORS.lightBlue, weight: '700' })}
`

export const Message = styled.h1`
  ${FLEX({})}

  ${FONT({ size: '24px', color: COLORS.lightBlue, weight: '700' })}
`

export const SaveButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.green};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const HistoryButton = styled(Link)`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.green};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const Div = styled.div`
  width: 31px;
  height: 26px;
`

export const TableWrapper = styled.div`
  width: 100%;
  /* height: calc(70%); */
  padding-bottom: 10px;

  background: ${COLORS.white};

  overflow-y: scroll;
`

export const StyledRow = styled.tr`
  & td {
    padding: 9px 15px 7px 15px;
    width: 20%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${FONT({ size: '16px', color: COLORS.lightBlue, weight: '400' })}
`

export const StyledHeader = styled.th`
  padding: 15px;

  ${SHADOW({ x: 2, y: 0 })};

  ${FONT({
    size: '16px',
    color: COLORS.lightBlue,
    weight: '700',
    align: 'left',
  })}
`

export const StyledTable = styled.table`
  width: 100%;
`

export const StyledText = styled.div`
  width: 100%;
  ${FONT({ size: '14px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledNameFieldText = styled.div`
  /* width: 100%; */
  ${FONT({ size: '14px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledFieldText = styled.div`
  flex-basis: 20%;

  ${FONT({ size: '14px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledPetText = styled.div`
  width: 100%;
  ${FLEX({})}
  ${FONT({ size: '16px', color: COLORS.lightBlue, weight: '700' })}
`

export const StyledCarousel = styled.div`
  width: 100%;

  ${FLEX({ wrap: 'nowrap', direction: 'row' })}

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledRedText = styled.div`
  width: 100%;
  ${FONT({ size: '16px', color: COLORS.red, weight: '700' })}
`

export const DeleteButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.red};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const HideButton = styled.div`
  padding: 10px 10px 10px 10px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  width: 25%;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })}

  ${FLEX({})}
`

export const StyledLink = styled(Link)`
  padding: 10px 20px;
  border: 1px solid;
  text-decoration: none;
  border-radius: 10px;

  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${COLORS.aqua};
  }

  ${FONT({ weight: '700', size: '16px', color: COLORS.lightBlue })};

  /* ${FLEX({})} */
`
