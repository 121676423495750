import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import { Divider } from '../../components'
import {
  Container,
  DeleteButton,
  FullWidth,
  Header,
  StyledButton,
  StyledButtonEnd,
  StyledHeader,
  StyledNumberLink,
  StyledPagination,
  StyledRow,
  StyledTable,
  TableWrapper,
  Title,
} from './styled'
import { deleteProposalAction, getProposalAction } from '../../store'
import { useTypedSelector } from '../../hooks'
import { TypedProposal } from './types'
import { RoutesTree } from '../../router'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { Log } from '../../utils'
import { useTranslation } from 'react-i18next'

const Proposal: React.FC = () => {
  const [typedProposals, setTypedProposals] = useState<TypedProposal[]>([])
  const [paginationLimit, setPaginationLimit] = useState<number>(8)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { t } = useTranslation('proposal')
  const dispatch = useDispatch()

  const { proposals, proposalTotal } = useTypedSelector(state => state.feedback)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.proposal) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  useEffect(() => {
    const localValues = localStorage.getItem('proposal') ?? ''
    const parseLocalValues = !!localValues ? JSON.parse(localValues) : ''
    // eslint-disable-next-line
    !!localValues
      ? (setCurrentPage(parseLocalValues.page),
        dispatch(
          getProposalAction.request({
            page: parseLocalValues.page,
            limit: paginationLimit,
            authorization: '',
          }),
        ))
      : dispatch(
          getProposalAction.request({
            page: currentPage,
            limit: paginationLimit,
            authorization: '',
          }),
        )
  }, [])

  const columns = [
    {
      title: t('clinicName'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('clinicCategories'),
      dataIndex: 'clinic',
      key: 'clinic',
    },
    {
      title: t('clinicAddress'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('creationDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
    },
  ]

  const choosePage = (page: number) => {
    localStorage.setItem(
      'proposal',
      JSON.stringify({
        page: page === 0 ? 1 : page,
      }),
    )

    dispatch(
      getProposalAction.request({
        page,
        limit: paginationLimit,
        authorization: '',
      }),
    )

    setCurrentPage(page)
  }

  const onPressDeleteProposal = async (id: string) => {
    try {
      const result = window.confirm('Удалить предложение?')

      if (result) {
        dispatch(
          deleteProposalAction({
            authorization: '',
            id,
          }),
        )

        setCurrentPage(1)
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeleteProposal')
    }
  }

  const data = () => {
    return proposals?.map(proposal => {
      return {
        title: (
          <StyledNumberLink to={{ pathname: `/proposal/${proposal?._id}` }}>
            {proposal?.title ?? '-'}
          </StyledNumberLink>
        ),
        clinic: (
          <StyledNumberLink to={{ pathname: `/proposal/${proposal?._id}` }}>
            {proposal?.clinic ?? '-'}
          </StyledNumberLink>
        ),
        address: (
          <StyledNumberLink to={{ pathname: `/proposal/${proposal?._id}` }}>
            {proposal?.address ?? '-'}
          </StyledNumberLink>
        ),
        createdAt: (
          <StyledNumberLink to={{ pathname: `/complaint/${proposal?._id}` }}>
            {moment(proposal?.createdAt).isValid()
              ? moment(proposal?.createdAt).format('HH:mm DD.MM.YYYY')
              : '-'}
          </StyledNumberLink>
        ),
        delete: (
          <DeleteButton onClick={() => onPressDeleteProposal(proposal?._id)}>
            {t('delete')}
          </DeleteButton>
        ),
        key: proposal?._id,
      }
    })
  }
  // eslint-disable-next-line
  useEffect(() => {
    setTypedProposals(data())
  }, [proposals, t])

  return (
    <Container>
      <div>
        <Header>
          <Title>
            {t('offers')} ({proposalTotal ?? 0})
          </Title>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedProposals}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd>{t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              {t('back')}
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {Math.ceil(proposalTotal / paginationLimit) === 0
              ? 1
              : Math.ceil(proposalTotal / paginationLimit)}
          </div>

          {currentPage ===
          (proposalTotal === 0
            ? 1
            : Math.ceil(proposalTotal / paginationLimit)) ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              {t('next')}
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}

export default Proposal
