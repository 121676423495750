import styled from 'styled-components'
import { FLEX, FONT } from '../../utils'
import { COLORS } from '../../assets'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  background: ${COLORS.gray};

  ${FLEX({})}
`

export const LoginWrapper = styled.div`
  width: 470px;

  padding: 50px;

  border-radius: 3px;

  background: ${COLORS.white};
`

export const Title = styled.h1`
  ${FONT({
    size: '20px',
    weight: '700',
    align: 'center',
    color: COLORS.lightBlue,
  })}
`

export const Link = styled.p`
  text-decoration: none;

  cursor: pointer;

  ${FONT({
    size: '14px',
    weight: '700',
    align: 'center',
    color: COLORS.lightBlue,
  })}
`
