import { useCallback, useEffect, useState } from 'react'

export const useDateFilter = () => {
  const localStorageKeyStart = 'startDateFilter'
  const localStorageKeyEnd = 'endDateFilter'

  const [startDate, setStartDate] = useState<Date | null>(() => {
    const savedStartDate = localStorage.getItem(localStorageKeyStart)
    return savedStartDate ? new Date(savedStartDate) : null
  })

  const [endDate, setEndDate] = useState<Date | null>(() => {
    const savedEndDate = localStorage.getItem(localStorageKeyEnd)
    return savedEndDate ? new Date(savedEndDate) : null
  })

  const clearDateFilter = useCallback(() => {
    localStorage.removeItem(localStorageKeyStart)
    localStorage.removeItem(localStorageKeyEnd)
    setStartDate(null)
    setEndDate(null)
  }, [])

  useEffect(() => {
    if (startDate) {
      localStorage.setItem(localStorageKeyStart, startDate.toISOString())
    } else {
      localStorage.removeItem(localStorageKeyStart)
    }
  }, [startDate])

  useEffect(() => {
    if (endDate) {
      localStorage.setItem(localStorageKeyEnd, endDate.toISOString())
    } else {
      localStorage.removeItem(localStorageKeyEnd)
    }
  }, [endDate])

  return { startDate, setStartDate, endDate, setEndDate, clearDateFilter }
}
