import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  StyledData,
  StyledEntity,
  Image,
  Title,
  StyledTitle,
  SaveButton,
  DeleteButton,
  StyledContainer,
  ProfileImage,
  Div,
  StyledText,
  StyledRedText,
  StyledLink,
  StyledPetText,
  PetImage,
  StyledCarousel,
  StyledEvent,
  StyledEventText,
} from './styled'
import { Assets } from '../../assets'
import { TForm, TChangeManagerValidationSchema } from './types'
import { useTypedSelector, useValidation } from '../../hooks'
import { Log } from '../../utils'
import { RoutesTree } from '../../router'
import {
  clearCustomerAction,
  clearOwnPetsAction,
  deleteCustomerAction,
  getCustomerByIdAction,
  getCustomerEventsAction,
  getOwnPetsAction,
  updateCustomerAction,
} from '../../store'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

// @ts-ignore
const Profile: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState<TForm>({
    name: null,
    phone: null,
    email: null,
  })

  const history = useHistory()
  const { t } = useTranslation('profile')
  const dispatch = useDispatch()

  const { customer, events } = useTypedSelector(store => store.customers)
  const { ownPets } = useTypedSelector(store => store.pets)
  const { user } = useTypedSelector(state => state.user)

  if (!user?.access?.customers) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  const inputHandler = useCallback(
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )
  // eslint-disable-next-line
  const schema = useMemo<TChangeManagerValidationSchema>(
    () => ({
      name: {
        condition: form.name
          ? form.name.length
            ? form.name.length >= 2
            : true
          : true,
        error: t('enterName'),
      },
      phone: {
        condition: form.phone
          ? form.phone.length
            ? form.phone.length >= 6
            : true
          : true,
        error: t('enterNumber'),
      },
      email: {
        // @ts-ignore
        condition: form.email
          ? form.email.length
            ? form.email?.includes('@')
            : true
          : true,
        error: t('invalidEmailFormat'),
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
    // eslint-disable-next-line
  } = useValidation(schema)

  const onPressSaveChanges = async () => {
    try {
      enableValidation()
      await validation()

      const result = window.confirm(t('updateUserPrompt'))

      if (result) {
        if (form.phone && form.name && form.email) {
          dispatch(
            updateCustomerAction({
              name: form.name,
              phone: form.phone,
              email: form.email,
              authorization: '',
              _id: id,
            }),
          )
        } else if (form.name && form.email) {
          dispatch(
            updateCustomerAction({
              name: form.name,
              email: form.email,
              authorization: '',
              _id: id,
            }),
          )
        } else if (form.phone) {
          dispatch(
            updateCustomerAction({
              phone: form.phone,
              authorization: '',
              _id: id,
            }),
          )
        } else if (form.name) {
          dispatch(
            updateCustomerAction({
              name: form.name,
              authorization: '',
              _id: id,
            }),
          )
        } else if (form.email) {
          dispatch(
            updateCustomerAction({
              email: form.email,
              authorization: '',
              _id: id,
            }),
          )
        }
      }

      disableValidation()
    } catch (error) {
      Log.ruddy('Event: onPressSaveChanges')
    }
  }

  const onPressDeleteCustomer = async () => {
    try {
      const result = window.confirm(t('deleteUserPrompt'))

      if (result) {
        dispatch(
          deleteCustomerAction({
            authorization: '',
            _id: id,
          }),
        )

        history.goBack()
      }
    } catch (error) {
      Log.ruddy('Event: onPressDeleteCustomer')
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getCustomerByIdAction.request({ id }))
    dispatch(getOwnPetsAction.request({ id, authorization: '' }))
    dispatch(getCustomerEventsAction.request({ id }))

    return () => {
      dispatch(clearCustomerAction())
      dispatch(clearOwnPetsAction())
    }
  }, [id])
  // eslint-disable-next-line
  useEffect(() => {
    setForm({
      name: customer?.name,
      phone: customer?.phone,
      email: customer?.email,
    })
  }, [customer])

  const handleBack = () => {
    history.goBack()
  }

  const onBlockingCustomer = () => {}

  return (
    <Container>
      <StyledTitle>
        {Assets.LEFT_ARROW_ICON ? (
          <div onClick={handleBack}>
            <Image src={Assets.LEFT_ARROW_ICON} />
          </div>
        ) : (
          ''
        )}

        <Title>{t('profile')}</Title>

        <Div> </Div>
      </StyledTitle>

      <Divider height={10} />

      {customer?.photo ? (
        <ProfileImage src={customer?.photo} />
      ) : (
        <ProfileImage
          src={
            'https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png'
          }
        />
      )}

      <Divider height={10} />

      <StyledData>
        <StyledEntity>
          <Input
            label={t('name')}
            name="name"
            value={form.name ?? ''}
            placeholder="Your name"
            template={EInputTemplate.NAME}
            error={schema.name.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.name}
          />
        </StyledEntity>

        <Divider width={20} />

        <StyledEntity>
          <Input
            label={t('phoneNumber')}
            name="phone"
            value={form.phone ?? ''}
            placeholder="+(code)999999999"
            template={EInputTemplate.NAME}
            error={schema.phone.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.phone}
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label={t('email')}
            name="email"
            value={form.email ?? ''}
            placeholder="Your Email"
            error={schema.email.error || ''}
            template={EInputTemplate.DEFAULT}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.email}
          />
        </StyledEntity>

        <StyledEntity>
          <Input
            label={t('birthday')}
            name="birthDate"
            value={
              customer?.birthDate
                ? moment(customer?.birthDate).format('YYYY-MM-DD HH:mm')
                : ''
            }
            placeholder="Your BirthDay"
            template={EInputTemplate.DEFAULT}
            isValid={!isEnabled}
            disabled={true}
          />
        </StyledEntity>

        <StyledText>{t('pets')}</StyledText>

        {!!ownPets?.length ? (
          <StyledCarousel>
            {ownPets?.map((pet, idx) => {
              return (
                <React.Fragment key={idx}>
                  <StyledLink
                    to={{ pathname: `/pet-details/${pet.id}` }}
                    key={pet.id}
                  >
                    <StyledPetText>
                      {t('nickname')}: {pet?.name}
                    </StyledPetText>
                    <Divider height={7} />

                    {pet?.photo ? (
                      <PetImage src={pet?.photo} />
                    ) : (
                      <PetImage
                        src={
                          'https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png'
                        }
                      />
                    )}
                  </StyledLink>
                </React.Fragment>
              )
            })}
          </StyledCarousel>
        ) : (
          <StyledRedText>{t('noPets')}</StyledRedText>
        )}

        <Divider height={10} />

        <StyledText>{t('events')}</StyledText>

        {!!events?.length ? (
          <StyledCarousel>
            {events?.map((event, idx) => {
              return (
                <React.Fragment key={idx}>
                  <StyledEvent key={event._id}>
                    <StyledPetText>
                      {!!event.clinic
                        ? `${t('clinic')} ${event.clinic}`
                        : t('personal')}
                    </StyledPetText>
                    <Divider height={7} />
                    <StyledPetText>
                      {t('date')}
                      {moment(event.date).format('HH:mm DD.MM.YYYY')}
                    </StyledPetText>
                    <Divider height={7} />
                    <StyledPetText>{event.title}</StyledPetText>
                    <Divider height={7} />
                    <StyledPetText>{event.description}</StyledPetText>
                    <Divider height={7} />
                    <StyledEventText>
                      {ownPets?.filter(el => el.id === event.pet)[0]?.name ??
                        ''}
                    </StyledEventText>
                  </StyledEvent>
                </React.Fragment>
              )
            })}
          </StyledCarousel>
        ) : (
          <StyledRedText>{t('noEvents')}</StyledRedText>
        )}

        <Divider height={10} />

        <StyledContainer>
          <DeleteButton onClick={onBlockingCustomer}>{t('block')}</DeleteButton>

          <DeleteButton onClick={onPressDeleteCustomer}>
            {t('delete')}
          </DeleteButton>

          <SaveButton onClick={onPressSaveChanges}>{t('save')}</SaveButton>
        </StyledContainer>

        <Divider height={10} />
      </StyledData>
    </Container>
  )
}

export default Profile
