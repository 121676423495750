import React, { FC, useCallback, useMemo } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Divider, EInputTemplate, Input } from '../../components'
import { useValidation } from '../../hooks'
import { forgotPasswordAction, loginAction } from '../../store'
import { Log } from '../../utils'
import { Container, Link, LoginWrapper, Title } from './styled'
import { TForm, TLoginValidationSchema } from './types'

export const Login: FC = () => {
  const [form, setForm] = useState<TForm>({ email: '', password: '' })

  const dispatch = useDispatch()

  const inputHandler = useCallback(
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )

  const schema = useMemo<TLoginValidationSchema>(
    () => ({
      email: {
        condition: form.email.includes('@'),
        error: `Неверный формат email`,
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
  } = useValidation(schema)

  const onPressLogin = async () => {
    try {
      enableValidation()
      await validation()

      dispatch(loginAction.request(form))

      disableValidation()
    } catch (error) {
      Log.ruddy('Event: onPressLogin')
    }
  }

  const onPressForgotPassword = async () => {
    try {
      enableValidation()
      await validation()

      dispatch(forgotPasswordAction({ email: form.email }))

      disableValidation()
    } catch (e) {
      Log.ruddy('Event: onPressForgotPassword')
    }
  }

  return (
    <Container>
      <LoginWrapper>
        <Title>Войти в админ панель</Title>

        <Divider height={20} />

        <Input
          label="E-mail"
          name="email"
          value={form.email}
          placeholder="Your Email"
          error={schema.email.error || ''}
          template={EInputTemplate.DEFAULT}
          onChange={v => inputHandler(v)}
          isValid={!isEnabled || validationSchema.email}
        />

        <Input
          label="Пароль"
          name="password"
          value={form.password}
          placeholder="Your Password"
          template={EInputTemplate.PASSWORD}
          error={''}
          onChange={v => inputHandler(v)}
          isValid={!isEnabled}
        />

        <Divider height={10} />

        <Button title="Войти" onClick={onPressLogin} type="submit" />

        <Divider height={20} />

        <Link onClick={onPressForgotPassword}>Забыли пароль?</Link>
      </LoginWrapper>
    </Container>
  )
}

export default Login
