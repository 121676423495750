import React, { FC } from 'react'
import { StyledAccordion } from '../styled'
import { Accordion } from 'react-bootstrap'
import { PhonesProps } from '../types'
import { t } from 'i18next'
import { generateCategory } from './helpers'
import { useServiceCategories } from '../../ApplicationContent'
import { LanguageKeys } from '../../ApplicationContent/services/types'

export const Category: FC<PhonesProps> = ({ form, setForm, lang }) => {
  const { serviceCategories } = useServiceCategories('place')

  const categoryObject = serviceCategories?.models.map(item => ({
    title: lang ? item.title[lang as LanguageKeys] : item.title.uk,
    id: item._id,
  }))

  const handleCheck = (data: { title: string; id: string }) => {
    setForm(prev => ({
      ...prev,
      specialisation: data,
    }))
  }

  return (
    <StyledAccordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('clinics:placeType')}</Accordion.Header>
          <Accordion.Body>
            {generateCategory(
              form.specialisation.id,
              handleCheck,
              categoryObject,
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyledAccordion>
  )
}
