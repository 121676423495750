import {
  CreteForm,
  ErrorDescription,
  Errormessage,
  HeadingWrap,
  InputsWrap,
  PhotoUpload,
  PhotoWrap,
  SelectWrap,
  TextArea,
} from './styled'
import { TCreateServiseTypes, TCreateWindowProps } from './types'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Assets } from '../../../../assets'
import { languageKeysArray } from '../../../../consts'
import { useEffect, useState } from 'react'
import { createServiceCategory } from '../../services'
import { mutate } from 'swr'
import { useTranslation } from 'react-i18next'
import { useTypedSelector } from '../../../../hooks'

export const CreateServiceWindow = ({
  setVisibleCreateModal,
}: TCreateWindowProps) => {
  const [preview, setPreview] = useState('')
  const [previewIcon, setPreviewIcon] = useState('')
  const { language } = useTypedSelector(state => state.user)
  const { t } = useTranslation('functionals')

  const types = [
    { value: 'clinic', label: t('functionals:clinic') },
    { value: 'place', label: t('functionals:place') },
    { value: 'social', label: t('functionals:shelter') },
  ]
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TCreateServiseTypes>({
    defaultValues: { lang: language || 'uk', type: types[0].value },
  })

  useEffect(() => {
    const file = watch('image')?.[0]
    const iconFile = watch('icon')?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
    if (iconFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewIcon(reader.result as string)
      }
      reader.readAsDataURL(iconFile)
    }
  }, [watch('image'), watch('icon')])

  const closeModal = () => setVisibleCreateModal(prev => !prev)

  const onSubmit: SubmitHandler<TCreateServiseTypes> = async data => {
    try {
      const formData = new FormData()
      formData.append('title', JSON.stringify({ [data.lang]: data.title }))
      formData.append(
        'description',
        JSON.stringify({ [data.lang]: data.description }),
      )
      formData.append('type', data.type)
      data.image && formData.append('image', data.image[0])
      data.icon && formData.append('icon', data.icon[0])
      await createServiceCategory(formData)
      mutate(['serviceCategories', undefined])
      closeModal()
    } catch (error) {}
  }

  const deleteImage = (name: string) => {
    if (name === 'image') {
      setValue('image', null)
      setPreview('')
    }
    if (name === 'icon') {
      setValue('icon', null)
      setPreviewIcon('')
    }
  }

  return (
    <CreteForm onSubmit={handleSubmit(onSubmit)}>
      <HeadingWrap>
        <h3>{t('controlCreation')}</h3>
        <div onClick={closeModal}>
          <img src={Assets.CYAN_ICON} alt="close" />
        </div>
      </HeadingWrap>
      <InputsWrap>
        <div>
          <div>
            <p>{t('language')}</p>
            <select {...register('lang')}>
              {languageKeysArray.map(item => (
                <option key={item}>{item}</option>
              ))}
            </select>
          </div>
          <label>
            {t('name')}
            <input
              placeholder={t('name')}
              {...register('title', { required: t('fieldRequired') })}
            />
            {errors.title && (
              <Errormessage>{errors.title.message}</Errormessage>
            )}
          </label>
        </div>

        <TextArea
          placeholder={t('enterDescription')}
          {...register('description', { required: t('fieldRequired') })}
          maxLength={100}
        ></TextArea>
        {errors.description && (
          <ErrorDescription>{errors.description?.message}</ErrorDescription>
        )}
        <div>
          <PhotoWrap>
            {!preview ? (
              <PhotoUpload isError={!!errors.image?.message}>
                <p> {t('clickAndSelectImage')}</p>
                <input
                  type="file"
                  accept="image/*"
                  className="visually-hidden"
                  {...register('image', {
                    required: {
                      value: true,
                      message: t('fieldRequired'),
                    },
                  })}
                />
              </PhotoUpload>
            ) : (
              <img src={preview} alt="preview" />
            )}
            <button type="button" onClick={() => deleteImage('image')}>
              <img src={Assets.CYAN_ICON} alt="close" />
            </button>
          </PhotoWrap>
          <PhotoWrap>
            {!previewIcon ? (
              <PhotoUpload isError={!!errors.icon?.message}>
                <p> {t('clickAndSelectImageIcon')}</p>
                <input
                  type="file"
                  accept="image/*"
                  className="visually-hidden"
                  {...register('icon', {
                    required: {
                      value: true,
                      message: t('fieldRequired'),
                    },
                  })}
                />
              </PhotoUpload>
            ) : (
              <img src={previewIcon} alt="preview" />
            )}
            <button type="button" onClick={() => deleteImage('icon')}>
              <img src={Assets.CYAN_ICON} alt="close" />
            </button>
          </PhotoWrap>
        </div>
        <SelectWrap>
          <p>{t('type')}</p>
          <select defaultValue={types[0].value} {...register('type')}>
            {types.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </SelectWrap>
      </InputsWrap>
      <HeadingWrap>
        <button onClick={closeModal} type="button">
          {t('close')}
        </button>
        <button type="submit">{t('create')}</button>
      </HeadingWrap>
    </CreteForm>
  )
}
