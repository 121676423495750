import { AxiosResponse } from 'axios'

import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'

import { CATEGORIES_ADS_URL } from './config'
import {
  GetCategoriesActionResponse,
  GetCategoriesPayload,
  DeleteCategoriesActionPayload,
  GetSubCategoriesPayload,
  GetSubCategoriesActionResponse,
  DeleteSubCategoriesActionPayload,
} from './types'

export class CategoriesAdsAPI extends HttpService {
  static getCategoriesAds(
    request: GetCategoriesPayload & PayloadAuth,
  ): Promise<AxiosResponse<GetCategoriesActionResponse>> {
    return this.request<GetCategoriesActionResponse>('GET_CATEGORIES_ADS', {
      url: CATEGORIES_ADS_URL.getCategoriesAds(
        request.page,
        request.limit,
      ),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createCategoriesAds({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<GetCategoriesActionResponse>> {
    return this.request<GetCategoriesActionResponse>('CREATE_CATEGORIES_ADS', {
      url: CATEGORIES_ADS_URL.createCategoriesAds,
      method: 'POST',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateCategoriesAds({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<GetCategoriesActionResponse>> {
    return this.request<GetCategoriesActionResponse>('UPDATE_CATEGORIES_ADS', {
      url: CATEGORIES_ADS_URL.updateCategoriesAds(id),
      method: 'PUT',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteCategoriesAds(
    request: DeleteCategoriesActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_CATEGORIES_ADS', {
      url: CATEGORIES_ADS_URL.deleteCategoriesAds(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getSubCategoriesAds(
    request: GetSubCategoriesPayload & PayloadAuth,
  ): Promise<AxiosResponse<GetSubCategoriesActionResponse>> {
    return this.request<GetSubCategoriesActionResponse>('GET_SUBCATEGORIES_ADS', {
      url: CATEGORIES_ADS_URL.getSubCategoriesAds(
        request.page,
        request.limit,
        request.category
      ),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createSubCategoriesAds({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<GetSubCategoriesActionResponse>> {
    return this.request<GetSubCategoriesActionResponse>('CREATE_SUBCATEGORIES_ADS', {
      url: CATEGORIES_ADS_URL.createSubCategoriesAds,
      method: 'POST',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateSubCategoriesAds({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<GetSubCategoriesActionResponse>> {
    return this.request<GetSubCategoriesActionResponse>('UPDATE_SUBCATEGORIES_ADS', {
      url: CATEGORIES_ADS_URL.updateSubCategoriesAds(id),
      method: 'PUT',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteSubCategoriesAds(
    request: DeleteSubCategoriesActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('DELETE_SUBCATEGORIES_ADS', {
      url: CATEGORIES_ADS_URL.deleteSubCategoriesAds(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
