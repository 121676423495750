import React, { FC } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Bar, Doughnut } from 'react-chartjs-2'
// import { options, genderType } from './components'
import { DoughnutChartProps } from './types'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend)

const DoughnutChart: FC<DoughnutChartProps> = ({ labelArray, dataArray }) => {
  const { t } = useTranslation('analitics')

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: t('genderOfPets'),
        font: {
          size: 20,
        },
      },
      legend: {
        display: false,
        position: 'top' as const,
      },
    },
    maintainAspectRatio: false,
  }

  const DoughnutChartData = {
    labels: [t('girl'), t('boy')],
    datasets: [
      {
        data: [dataArray[0], dataArray[1]],
        backgroundColor: [
          'rgba(231, 18, 64, 0.793)',
          'rgba(146, 205, 245, 0.992)',
        ],
        borderColor: ['rgba(231, 18, 64, 0.793)', 'rgba(146, 205, 245, 0.992)'],
        borderWidth: 1,
      },
    ],
  }

  return (
    <Container>
      <Bar data={DoughnutChartData} options={options} height={350} />
    </Container>
  )
}

export default DoughnutChart
