import { serviceInstance } from '../http.service'
import {
  CreateAdviceRequest,
  DeleteAdviceRequest,
  GetAdviceRequest,
  GetAdvicesRequest,
  UpdateAdviceRequest,
  UploadImageRequest,
} from './types'

export class Advice {
  static async getAdvices(
    params: GetAdvicesRequest['payload'],
  ): Promise<GetAdvicesRequest['response']> {
    const response = await serviceInstance.get('ads/api/v1/admin/advice', {
      params,
    })

    return response.data
  }

  static async getAdvice({
    _id,
  }: GetAdviceRequest['payload']): Promise<GetAdviceRequest['response']> {
    const response = await serviceInstance.get(`ads/api/v1/admin/advice/${_id}`)

    return response.data
  }

  static async updateAdvice({
    _id,
    data,
  }: UpdateAdviceRequest['payload']): Promise<UpdateAdviceRequest['response']> {
    const response = await serviceInstance.put(
      `ads/api/v1/admin/advice/${_id}`,
      data,
    )

    return response.data
  }

  static async createAdvice(
    data: CreateAdviceRequest['payload'],
  ): Promise<CreateAdviceRequest['response']> {
    const response = await serviceInstance.post('ads/api/v1/admin/advice', data)

    return response.data
  }

  static async deleteAdvice({
    _id,
  }: DeleteAdviceRequest['payload']): Promise<DeleteAdviceRequest['response']> {
    const response = await serviceInstance.delete(
      `ads/api/v1/admin/advice/${_id}`,
    )

    return response.data
  }

  static async uploadImage(
    data: UploadImageRequest['payload'],
  ): Promise<UploadImageRequest['response']> {
    const response = await serviceInstance.post(
      `ads/api/v1/admin/advice/upload`,
      data,
    )

    return response.data
  }
}
