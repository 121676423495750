import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Table from 'rc-table'
import { Divider, EInputTemplate, Input } from '../../components'
import {
  Container,
  CreateButton,
  FullWidth,
  Header,
  StyledButton,
  StyledButtonEnd,
  StyledHeader,
  StyledNumberLink,
  StyledPagination,
  StyledRow,
  Image,
  StyledTable,
  TableWrapper,
  Title,
  StyledEntity,
  SortImage,
  StyledSortName,
} from './styled'
import { getClinicsAction } from '../../store'
import { useTypedSelector } from '../../hooks'
import { TypedClinics, TSort } from './types'
import { RoutesTree } from '../../router'
import { Redirect } from 'react-router-dom'
import { Assets } from '../../assets'
import { useTranslation } from 'react-i18next'

const Clinics: React.FC = () => {
  const [typedClinics, setTypedClinics] = useState<TypedClinics[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationLimit, setPaginationLimit] = useState<number>(20)
  const [searchPage, setSearchPage] = useState<number>(1)
  const [sort, setSort] = useState<TSort>({
    title: false,
    type: false,
    rating: false,
  })
  const [sortName, setSortName] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const dispatch = useDispatch()
  const { t } = useTranslation('clinics')
  const { clinics, total } = useTypedSelector(state => state.clinics)
  const { user, language } = useTypedSelector(state => state.user)

  if (!user?.access?.clinics) {
    return <Redirect to={RoutesTree.notFound.path} />
  }
  // eslint-disable-next-line
  useEffect(() => {
    const localValues = localStorage.getItem('clinics') ?? ''
    const parseLocalValues = !!localValues ? JSON.parse(localValues) : ''
    // eslint-disable-next-line
    !!localValues
      ? (setCurrentPage(parseLocalValues.page),
        setSearchPage(parseLocalValues.page),
        setSort(parseLocalValues.sort),
        setSortName(parseLocalValues.sortName),
        setSearch(parseLocalValues.search),
        dispatch(
          getClinicsAction.request({
            page: parseLocalValues.page,
            limit: paginationLimit,
            search: parseLocalValues.search,
            sortBy: parseLocalValues.sortName,
            type: 'CLINIC',
          }),
        ))
      : dispatch(
          getClinicsAction.request({
            page: currentPage,
            limit: paginationLimit,
            search,
            sortBy: '',
            type: 'CLINIC',
          }),
        )
  }, [])

  const sortByParam = (name: string) => {
    setSortName(sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC')

    setSort(prev => ({ ...prev, [name]: !prev[name as keyof TSort] }))

    localStorage.setItem(
      'clinics',
      JSON.stringify({
        page: currentPage,
        sort: { ...sort, [name]: !sort[name as keyof TSort] },
        sortName: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
        search,
      }),
    )

    dispatch(
      getClinicsAction.request({
        page: currentPage === 0 ? 1 : currentPage,
        limit: paginationLimit,
        search,
        type: 'CLINIC',
        sortBy: sort[name as keyof TSort] ? name + 'ASC' : name + 'DESC',
      }),
    )
  }

  const columns = [
    {
      title: (
        <StyledSortName onClick={() => sortByParam('title')}>
          <div>{t('title')}</div>

          <Divider width={10} />

          <SortImage
            src={sort.title ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: (
        <StyledSortName onClick={() => sortByParam('type')}>
          <div>{t('type')}</div>

          <Divider width={10} />

          <SortImage
            src={sort.type ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: (
        <StyledSortName onClick={() => sortByParam('rating')}>
          <div>{t('clinicRating')}</div>

          <Divider width={10} />

          <SortImage
            src={sort.rating ? Assets.UP_ARROW_ICON : Assets.DOWN_ARROW_ICON}
          />
        </StyledSortName>
      ),
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: t('city'),
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: t('country'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: t('location'),
      dataIndex: 'location',
      key: 'location',
    },
  ]

  const choosePage = (page: number) => {
    localStorage.setItem(
      'clinics',
      JSON.stringify({
        page: page === 0 ? 1 : page,
        sort: sort,
        sortName: sortName,
        search,
      }),
    )

    dispatch(
      getClinicsAction.request({
        page: page === 0 ? 1 : page,
        limit: paginationLimit,
        search,
        type: 'CLINIC',
        sortBy: sortName,
      }),
    )

    setCurrentPage(page === 0 ? 1 : page)
  }

  const putSearchPage = (e: React.SyntheticEvent<Element, Event> | any) => {
    setSearchPage(isNaN(+e.target.value) ? 1 : +e.target.value)
  }

  const enterKeyPress = (e: any) => {
    e?.code === 'Enter' &&
      choosePage(
        searchPage <= (total === 0 ? 1 : Math.ceil(total / paginationLimit))
          ? searchPage !== currentPage
            ? searchPage
            : 1
          : 1,
      )
  }

  const putTitle = (e: any) => {
    setSearch(e.target.value)
  }

  const data = () => {
    return clinics?.map(clinic => {
      return {
        title: (
          <StyledNumberLink to={{ pathname: `/clinic-info/${clinic?._id}` }}>
            {clinic?.name?.[language]
              ? clinic?.name?.[language]
              : clinic?.name?.ru || '-'}
          </StyledNumberLink>
        ),
        type: (
          <StyledNumberLink to={{ pathname: `/clinic-info/${clinic?._id}` }}>
            {clinic?.specialisation?.title?.[language] ||
              clinic?.specialisation?.title?.ru ||
              '-'}
          </StyledNumberLink>
        ),
        rating: (
          <StyledNumberLink to={{ pathname: `/clinic-info/${clinic?._id}` }}>
            {/* {(clinic?.schedule?.details?.allDay ? 'круглосуточно; ' : '') +
              (clinic?.schedule?.details?.byAppointment
                ? 'по предварительной записи; '
                : '') +
              (clinic?.schedule?.details?.everyDay ? 'ежедневно' : '')} */}
            {clinic.rating ? clinic.rating.toFixed(1) : t('noRating')}
          </StyledNumberLink>
        ),
        city: (
          <StyledNumberLink to={{ pathname: `/clinic-info/${clinic?._id}` }}>
            {clinic?.contactInfo?.location?.district?.[language] ||
              clinic?.contactInfo?.location?.district?.ru ||
              '-'}
          </StyledNumberLink>
        ),
        country: (
          <StyledNumberLink to={{ pathname: `/clinic-info/${clinic?._id}` }}>
            {clinic?.contactInfo?.location?.country?.[language] ||
              clinic?.contactInfo?.location?.country?.ru ||
              '-'}
          </StyledNumberLink>
        ),
        location: (
          <StyledNumberLink to={{ pathname: `/clinic-info/${clinic?._id}` }}>
            {clinic?.contactInfo?.location?.address?.[language] ||
              clinic?.contactInfo?.location?.address?.ru ||
              '-'}
          </StyledNumberLink>
        ),
        key: clinic?._id,
      }
    })
  }
  // eslint-disable-next-line
  useEffect(() => {
    setTypedClinics(data())
  }, [clinics, t])

  return (
    <Container>
      <div>
        <Header>
          <Title>
            {t('clinics')}({total ?? 0})
          </Title>

          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label={t('search')}
                name="search"
                value={search}
                placeholder={t('title')}
                template={EInputTemplate.NAME}
                onChange={putTitle}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (total === 0 ? 1 : Math.ceil(total / paginationLimit))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>

          <StyledPagination>
            <StyledEntity onKeyPress={enterKeyPress}>
              <Input
                label={t('page')}
                name="searchPage"
                value={searchPage.toString()}
                placeholder={t('page')}
                template={EInputTemplate.NAME}
                onChange={putSearchPage}
              />
            </StyledEntity>
            <Divider width={10} />
            <Image
              src={Assets.SEARCH_ICON}
              onClick={() =>
                choosePage(
                  searchPage <=
                    (total === 0 ? 1 : Math.ceil(total / paginationLimit))
                    ? searchPage
                    : 1,
                )
              }
            />
          </StyledPagination>

          <CreateButton
            to={{
              pathname: 'clinic-info/undefined',
            }}
          >
            {t('create')}
          </CreateButton>
        </Header>

        <Divider height={30} />

        <TableWrapper>
          <Table
            columns={columns}
            data={typedClinics}
            scroll={{ y: 650 }}
            components={{
              body: {
                row: StyledRow,
              },
              header: {
                cell: StyledHeader,
              },
              table: StyledTable,
            }}
          />
        </TableWrapper>

        <Divider height={20} />
      </div>

      <FullWidth>
        <StyledPagination>
          {currentPage === 1 ? (
            <StyledButtonEnd> {t('back')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage - 1)}>
              {t('back')}
            </StyledButton>
          )}

          <div>
            {currentPage}/
            {!total
              ? 1
              : Math.ceil(total / paginationLimit) === 0
              ? 1
              : Math.ceil(total / paginationLimit)}
          </div>

          {currentPage === (!total ? 1 : Math.ceil(total / paginationLimit)) ? (
            <StyledButtonEnd>{t('next')}</StyledButtonEnd>
          ) : (
            <StyledButton onClick={() => choosePage(currentPage + 1)}>
              {t('next')}
            </StyledButton>
          )}
        </StyledPagination>
        <Divider height={10} />
      </FullWidth>
    </Container>
  )
}

export default Clinics
