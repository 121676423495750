import styled from 'styled-components'
import { COLORS } from '../../../../assets'
import { FONT } from '../../../../utils'

export const Container = styled.div`
  display: flex;
  align-items: flex-end;

  max-width: 220px;
  width: 100%;

  height: 51px;
`

export const StyledInput = styled.input`
  width: 100%;

  margin: 1;
  padding: 10px 14px;

  padding-right: 40px;

  outline: none;
  border: 2px solid ${COLORS.gray};

  background: ${COLORS.gray};

  ${FONT({
    size: '14px',
    weight: '700',
    color: COLORS.lightBlue,
  })}

  transition: 0.2s;

  &:focus {
    border: 2px solid ${COLORS.green};
    background: ${COLORS.white};
  }

  &::placeholder {
    ${FONT({
      size: '14px',
      weight: '700',
      color: '',
    })}
  }
`
