import { api } from '../config'

export const CLINICS_URL = {
  get_clinics: (
    page: number,
    limit: number,
    search: string,
    sortBy: string,
    type: string,
  ) =>
    `${api.withAPI}service/api/v1/admin/clinics?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}&type=${type}`,
  getClinicById: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/${id}`,
  createClinic: `${api.withAPI}service/api/v1/admin/clinics`,
  updateClinic: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/${id}`,
  putClinicLogo: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/update-logo/${id}`,
  deleteClinic: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/${id}`,
  addImageToClinic: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/add-photo/${id}`,
  addDocumentToClinic: (id: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/add-document/${id}`,
  deleteImageFromClinic: (id: string, url: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/delete-photo/${id}/${url}`,
  deleteDocumentFromClinic: (id: string, url: string) =>
    `${api.withAPI}service/api/v1/admin/clinics/delete-document/${id}/${url}`,
  searchClinic: (limit: number, page: number, title: string) =>
    `${api.withAPI}service/api/v1/admin/clinics?limit=${limit}&page=${page}&title=${title}`,
}
