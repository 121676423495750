import React, { FC } from 'react'
import { StyledButton, Label } from './styled'
import { TButtonProps } from './types'

export const Button: FC<TButtonProps> = ({
  onClick,
  title,
  type,
  padding = '14px 16px',
}) => {
  return (
    <StyledButton type={type} onClick={onClick} padding={padding}>
      <Label>{title}</Label>
    </StyledButton>
  )
}
