import { Dispatch, SetStateAction, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { Container, StyledInput } from './styled'

type DateFilterProps = {
  startDate: Date | null
  setStartDate: Dispatch<SetStateAction<Date | null>>
  endDate: Date | null
  setEndDate: Dispatch<SetStateAction<Date | null>>
}

export const DateFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: DateFilterProps) => {
  const { t } = useTranslation('appointments')

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  return (
    <Container>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        placeholderText={t('pickDate')}
        customInput={<StyledInput />}
        dateFormat={'dd/MM/YYYY'}
        selectsRange
        isClearable
      />
    </Container>
  )
}
