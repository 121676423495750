import { Controller, useFormContext } from 'react-hook-form'
import { Assets } from '../../../../assets'
import {
  AppointmentImage,
  ClearImage,
  Image,
  ImageContainer,
  MediaContainer,
  StyledInputPhoto,
  StyledLabel,
} from './styled'
import _ from 'lodash'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { ErrorMessage } from '../../../AdviceCreate/styled'
import { useTranslation } from 'react-i18next'
import { api } from '../../../../store'

type ImageFieldProps = {
  image: string[]
  setFile: Dispatch<SetStateAction<File | null>>
}

export const ImageField = ({ image, setFile }: ImageFieldProps) => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext()
  const { t } = useTranslation('appointments')

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    const file = e?.target?.files[0]

    setFile(file)

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.onload = () => {
        setValue('media', [reader.result as string])
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <ImageContainer>
      {_.isEmpty(watch('media')) && image?.length == 0 && (
        <img src={Assets.DOWNLOAD_ICON} width={100} height={100} />
      )}

      <AppointmentImage
        src={_.isEmpty(watch('media')) ? '' : watch('media')[0]}
      />

      {image?.length > 0 && _.isEmpty(watch('media')) && (
        <AppointmentImage src={`${api.withImageAPI}appointment/${image[0]}`} />
      )}

      <MediaContainer>
        <StyledLabel htmlFor="photo">
          <Image src={Assets.DOWNLOAD_ARROW_ICON} />
        </StyledLabel>

        <Controller
          name="media"
          control={control}
          render={({ field }) => (
            <StyledInputPhoto
              id="photo"
              name="photo"
              type="file"
              value=""
              accept="image/*"
              onChange={e => {
                onImageChange(e)
              }}
            />
          )}
        />

        <ClearImage
          onClick={() => setValue('media', [])}
          src={Assets.CYAN_ICON}
        />
      </MediaContainer>

      <ErrorMessage>{errors?.media?.message}</ErrorMessage>
    </ImageContainer>
  )
}
