import useSWR from 'swr'
import { getMedicalDirection, getCurrentMedicalDirection } from './services'

export const useGetMedicalDirection = () => {
  const {
    data: medicalDirections,
    isLoading,
    error,
  } = useSWR('medical-directions', getMedicalDirection, {
    revalidateOnFocus: false,
  })
  return { medicalDirections, isLoading, error }
}

export const useGetCurrentMedicalDirection = (id: string) => {
  const {
    data: currentMedicalDirection,
    isLoading,
    error,
  } = useSWR(
    ['current-medical-direction', id],
    ([, id]) => getCurrentMedicalDirection(id),
    {
      revalidateOnFocus: false,
    },
  )
  return { currentMedicalDirection, isLoading, error }
}
